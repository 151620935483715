import { flatten, isEmpty, toPairs } from "lodash-es";
import {
  CatalogProduct,
  HomePageSingleBrand,
  HomepageSingleCategoryProduct,
  ImageBannerData,
  SummaryBrand,
} from "./types/graphql.d";
import { ProductSort } from "./types/productSort";
import { SearchState } from "react-instantsearch-core";
import { ContextKey } from "src/utils/algolia/contextKeys";
import { RankingInfo } from "./contexts/app.context";

enum SEGMENT_EVENTS {
  passwordUpdated = "Password Updated",
  cartViewed = "Cart Viewed",
  checkoutStarted = "Checkout Started",
  checkoutStepCompleted = "Checkout Step Completed",
  orderCompleted = "Order Completed",
  productAdded = "Product Added",
  productListFiltered = "Product List Filtered",
  productListFiltersReset = "Product List Filter Reset",
  productListFilterOpened = "Product List Filter Opened",
  productListViewed = "Product List Viewed",
  productListClicked = "Product List Clicked",
  productOutOfStock = "Product Out of Stock",
  productRemoved = "Product Removed",
  productSizeSelected = "Product Size Selected",
  productSizeSelectorClicked = "Product Size Selector Clicked",
  productViewed = "Product Viewed",
  productEngaged = "Product Engaged",
  productClicked = "Product Clicked",
  brandInfoOpened = "Brand Information Opened",
  brandConsciousHowWeRateClicked = "Brand Conscious How We Rate Clicked",
  signedIn = "Signed In",
  signedUp = "Signed Up",
  signedOut = "Signed Out",
  userEmailUpdated = "User Email Updated",
  productOutOfStockCT = "Product Out of Stock",
  domainChanged = "Domain Changed",
  searchOverlayOpen = "Search Overlay Open",
  searchOverlayClose = "Search Overlay Close",
  searchOverlayCarouselsViewed = "Search Overlay Carousels Viewed",
  productsSearched = "Products Searched",
  searchLinkClick = "Search Link Click",
  searchRecommendationClicked = "Search Recommendation Clicked",
  viewSearchAllBrands = "View Search All Brands",
  viewSearchResults = "View Search Results Page",
  forgotPasswordFlowStarted = "Forgot Password Flow Started",
  pdpInfoBoxOpened = "PDP Info Box Opened",
  clickedPromotion = "Clicked Promotion",
  viewedPromotion = "Viewed Promotion",
  notificationDismissed = "Notification Dismissed",
  brandFavourited = "Brand Favourited",
  brandUnfavourited = "Brand Unfavourited",
  brandFavouritedWhenLoggedOut = "Brand Favourited When Logged-Out",
  couponEntered = "Coupon Entered",
  couponApplied = "Coupon Applied",
  couponDenied = "Coupon Denied",
  couponRemoved = "Coupon Removed",
  formError = "Form Error",
  formTracking = "Form Tracking",
  referAFriendFormViewed = "Refer A Friend Form Viewed",
  optIn = "Opt-in",
  userProfileUpdated = "User Profile Updated",
  authBannerViewed = "Auth Banner Viewed",
  authBannerSelect = "Auth Banner Select",
  authPopupClosed = "Auth Popup Closed",
  guestOptInBannerViewed = "Guest Opt In Banner Viewed",
  guestOptInBannerDismissed = "Guest Opt In Banner Dismissed",
  guestOptInBannerCtaClick = "Guest Opt In Banner CTA Click",
  guestOptInPopupDismissed = "Guest Opt In Popup Dismissed",
  googlePayButtonClicked = "Google Pay Button Clicked",
  categoryItemClicked = "Category Item Clicked",
  eppoRandomizedAssignment = "Eppo Randomized Assignment",
  error = "Error",
  productFavouritedWhenLoggedOut = "Product Favourited When Logged-Out",
  productFavourited = "Product Favourited",
  productUnfavourited = "Product Unfavourited",
  personalizationOnboardingStart = "Personalization Onboarding Start",
  personalizationOnboardingStage = "Personalization Onboarding Stage",
  personalizationOnboardingCompleted = "Personalization Onboarding Completed",
  personalizationOnboardingError = "Personalization Onboarding Error",
  menuNavClicked = "Menu Nav Clicked",
  optInDeeplinkViewed = "Opt-in Deeplink Viewed",
  gatedPopupOpened = "Gated Popup Opened",
  gatedPopupLoginClicked = "Gated Popup Login Clicked",
  gatedPopupRegisterClicked = "Gated Popup Register Clicked",
  gatedPopupClosed = "Gated Popup Closed",
  gatedPopupRedirected = "Gated Popup Redirected",
}

const SEGMENT_IN_VIEW_THRESHOLD = 0.7;
const SEGMENT_ENGAGEMENT_TIMEOUT = 30; // in seconds

enum SEGMENT_LIST_TYPE {
  brand = "brand",
  category = "category",
  curatedCategory = "curated category",
  favourited = "favourited",
  products = "products",
}

enum SEGMENT_LIST_FORMAT {
  carousel = "carousel",
  default = "default",
  search = "search",
}

enum SEGMENT_POSITIONS {
  actionBlock = "action-block",
  carousel = "carousel",
  curated = "curated",
  favourite = "favourite",
  featuredShop = "featured-shop",
  referAFriend = "refer-a-friend",
}

enum SEGMENT_PROMOTION_TYPE {
  spotlightBrands = "spotlight_brands",
  featuredShop = "featured_shop",
  referAFriend = "refer_a_friend",
  inspirationalSections = "inspirational_sections",
  designerBrandsSections = "design-brands_sections",
  meetBrand = "meet_brand",
  topAlertBar = "top_alert_bar",
  actionBlock = "action_block",
  plpPromoBanner = "plp_promo_banner",
  toasterNotification = "toaster_notifications",
  wishlistBrandCollection = "wishlist_brand_collection",
  popularBrands = "popular_brands",
  justArrivedShops = "just_arrived_shops",
  popularShops = "popular_shops",
  favouritedShops = "favourited_shops",
  departmentDesigner = "department_designer",
  consciousShops = "conscious_shops",
  imageBanners = "image_banners",
  brandCarousel = "brands_carousel",
}

const SEGMENT_DATA_UNAVAILABLE = "data unavailable";

const SEGMENT_MISSING_DATA = "n/a";

enum SEGMENT_LIST_ID {
  favouritedProducts = "Favourited Products",
  search = "Search",
}

export type SegmentCatalogProduct = CatalogProduct & {
  position: number;
};

export type SegmentHomepageSingleCategoryProduct =
  HomepageSingleCategoryProduct & {
    position: number;
  };

export type SegmentProduct = (
  | CatalogProduct
  | HomepageSingleCategoryProduct
) & {
  position: number;
  objectID?: string;
  entityID?: string;
};

export type SegmentProductClicked = {
  algoliaIndex?: string;
  isPersonalised?: boolean;
  position: number;
  product: (CatalogProduct | HomepageSingleCategoryProduct) & {
    objectID?: string;
    entityID?: string;
  };
  productListMeta: SegmentProductListMeta;
  queryID?: string;
  rankingInfo?: RankingInfo;
};

export interface SegmentProductListMeta {
  listFormat: SEGMENT_LIST_FORMAT;
  listId: SEGMENT_LIST_ID | string;
  listType: SEGMENT_LIST_TYPE | ContextKey;
  shopType?: string | undefined;
}

export interface BrandInjectedProps {
  onClickHandler: () => void;
}

const mapFacetToSegment: { [key: string]: string } = {
  ages: "age",
  brands: "brand",
  cato_cat: "category",
  colors: "color",
  goodOnYou: "good_on_you",
  patterns: "pattern",
  sizes: "size",
  styles: "style",
};

enum SORT_ORDER {
  asc = "asc",
  desc = "desc",
}

enum SORT_BY {
  price = "price",
  new = "new",
  new_discount = "new_discount",
  popularity = "popularity",
}

export type MapSortToSegmentValue = {
  type: SORT_BY;
  value: SORT_ORDER;
};

export type SegmentFilters = {
  type: string;
  value: string | number | null | undefined;
}[];

enum SEGMENT_TOGGLE_WISHLIST_TYPE {
  favourited = "favourited",
  unFavourited = "unFavourited",
  favouritedWhenLoggedOut = "favouritedWhenLoggedOut",
}

const mapSortToSegment: { [key in ProductSort]: MapSortToSegmentValue } = {
  PRICE_LOW_TO_HIGH: { type: SORT_BY.price, value: SORT_ORDER.asc },
  PRICE_HIGH_TO_LOW: {
    type: SORT_BY.price,
    value: SORT_ORDER.desc,
  },
  NEW: { type: SORT_BY.new, value: SORT_ORDER.asc },
  NEW_PROMOTION: {
    type: SORT_BY.new_discount,
    value: SORT_ORDER.asc,
  },
  POPULARITY: { type: SORT_BY.popularity, value: SORT_ORDER.asc },
};

export type Brand = SummaryBrand | HomePageSingleBrand | ImageBannerData;

export type SegmentCouponTracking = {
  couponID?: string;
  couponCode: string;
  currency: string | undefined;
  cartValue: string | number | undefined | null;
  cartTotalProducts: number | undefined;
};

const mapFieldToFormName: { [key: string]: string } = {
  billingAddress: "Checkout Billing Address",
  contactInformation: "Checkout Contact Information",
  shippingAddress: "Checkout Shipping Address",
};

const getFormName = (field: string): string =>
  mapFieldToFormName[
    field.includes(".") ? field.split(".")[0] : "contactInformation"
  ];

export type Filter = {
  type: string;
  value: string | number | null | undefined;
};

type RefinementList = {
  [key: string]: string[];
};

type Menu = {
  [key: string]: string;
};

/**
 * Restructures `refinementList` into an array of individual objects containing the type and value
 * @example
 * restructureRefinements({
 *   "color.meta": ["BLUE||Blu||#4C96FF", "BLACK||Black||#000000"],
 *   "brand.meta": ["33048||NA-KD"],
 *   "brand.good_on_you.meta": ["3||It's a start"],
 * });
 * // returns
 * [
 *   { type: "color", value: "BLUE" },
 *   { type: "color", value: "BLACK" },
 *   { type: "brand", value: "NA-KD" },
 *   { type: "conscious", value: "It's a start" },
 * ]
 */
const restructureRefinements = (refinementList: RefinementList): Filter[] => {
  const refinements: Filter[][] = toPairs(refinementList).reduce(
    (filtered, refinement) => {
      const [refinementType, values] = refinement;
      if (values) {
        const [type, ...rest] = refinementType?.split(".");
        const formattedRefinement: Filter[] = values.map((val) => {
          const isConscious =
            type === "brand" &&
            rest?.[0] === "good_on_you_info" &&
            val.length > 0;
          return {
            type: isConscious ? "conscious" : type,
            value: ["brand", "categories"].includes(type)
              ? val?.split("||")[1]
              : val?.split("||")[0],
          };
        });

        if (!isEmpty(refinement)) {
          filtered.push(formattedRefinement);
        }
      }
      return filtered;
    },
    [] as Filter[][]
  );

  return flatten(refinements);
};

/**
 * Restructures range into an array of individual objects containing the type and value
 * @example
 * restructureRefinements({ min: 10, max: 100 });
 * // returns
 * [{ type: "min_price", value: 10 }, { type: "max_price", value: 100}]
 */
const restructureRange = (
  range: { min: number; max: number } | undefined
): Filter[] => {
  if (!range) return [];
  return Object.keys(range).map((key) => {
    return {
      type: key === "min" ? "min_price" : "max_price",
      value: key === "min" ? range.min : range.max,
    };
  });
};

/**
 * Restructures `menu` into an array of individual objects containing the type and value
 * @example
 * restructureMenu({
 *   "discount_buckets.meta": "70 || From 70 % off",
 * });
 * // returns
 * [
 *   { type: "discount", value: "70" },
 * ]
 */
const restructureMenu = (menu: Menu): Filter[] =>
  Object.keys(menu).map((menuType) => {
    const type = menuType?.split(".")[0];
    const isDiscount = type === "discount_buckets";
    return {
      type: isDiscount ? "discount" : type,
      value: menu[menuType].split("||")[0],
    };
  });

/**
 * Restructures range, refinementList and menu in the searchState into an array of individual
 * objects containing the type and value. It combines the functions of `restructureRefinements` and
 * `restructureRange`
 * @example
 * restructureRefinements({
 *   "refinementList": {
 *     "color.meta": ["BROWN||Brown||#AE7F60"],
 *     "brand.meta": ["41200||Aigle", "14133||Alchemist"],
 *   },
 *   "range": { "price": { "min": 49, "max": 75 } },
 *   "menu": {
 *     "discount_buckets.meta": "70 || From 70 % off",
 *   },
 *   ... other values
 * });
 * // returns
 * [
 * {"type":"color","value":"BROWN"},{"type":"brand","value":"Aigle"},{"type":"brand","value":"Alchemist"},
 * {"type":"min_price","value":49},{"type":"max_price","value":75},
 * {"type": "discount", value: "70"}
 * ]
 */
const getFilters = (
  partialSearchState: Pick<SearchState, "refinementList" | "range" | "menu">
): Filter[] => {
  return [
    ...restructureRefinements(partialSearchState.refinementList ?? {}),
    ...restructureRange(partialSearchState?.range?.price),
    ...restructureMenu(partialSearchState.menu ?? {}),
  ];
};

export {
  SEGMENT_DATA_UNAVAILABLE,
  SEGMENT_EVENTS,
  SEGMENT_IN_VIEW_THRESHOLD,
  SEGMENT_ENGAGEMENT_TIMEOUT,
  SEGMENT_LIST_FORMAT,
  SEGMENT_LIST_ID,
  SEGMENT_LIST_TYPE,
  SEGMENT_MISSING_DATA,
  SEGMENT_POSITIONS,
  SEGMENT_PROMOTION_TYPE,
  SEGMENT_TOGGLE_WISHLIST_TYPE,
  getFormName,
  mapFacetToSegment,
  mapSortToSegment,
  getFilters,
  restructureRefinements,
  restructureRange,
  restructureMenu,
};
