import styled from "@emotion/styled";
import { keyframes } from "@emotion/css";
import { Box } from "@otrium/core";
import { SearchBlackIcon } from "src/icons/SearchBlackIcon";
import CloseButton from "src/atoms/CloseButton/CloseButton";

const closeButtonAnimation = keyframes`
    0%, 80% {    
      opacity: 0;
    }
    95%, 100% {
      opacity: 1;
    }
`;

const SearchHeader = styled(Box)<{ expand?: boolean; duration?: number }>`
  position: absolute;
  width: ${({ expand }) => (expand ? "calc(100% - 40px)" : "204px")};
  height: 32px;
  background-color: ${({ theme }) => theme.colors.tone.white};
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  transition: width ${({ duration }) => duration || 0}ms ease-in-out;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    border-bottom: 1px solid
      ${({ theme }) => theme.colors.primary.platinumLight};
  }
`;

const SearchIcon = styled(SearchBlackIcon)`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

const SearchInput = styled.input`
  width: 95%;
  border: none;
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  letter-spacing: 0.4px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.tone.black};
  padding-left: ${({ theme }) => theme.space.space32};
  ::placeholder {
    color: ${({ theme }) => theme.colors.tone.anthraciteLight};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    left: 30px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    padding-left: ${({ theme }) => theme.space.space0};
  }
`;

const SearchCloseButton = styled(CloseButton)<{ duration?: number }>`
  position: absolute;
  right: 0;
  top: 0;
  justify-content: flex-end;
  animation: ${closeButtonAnimation}
    ${({ duration }) => (duration || 0) + 200}ms
    cubic-bezier(0.25, 0.1, 0.25, 1);
`;

export { SearchHeader, SearchIcon, SearchInput, SearchCloseButton };
