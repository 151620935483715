import { FC, SVGAttributes } from "react";

const FlagEn: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 15 11"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="none"
    {...props}
  >
    <defs>
      <path id="a" d="M0 0h14v10H0z" />
    </defs>
    <g transform="translate(.993 .932)" fill="none" fillRule="evenodd">
      <path fill="#0A17A7" mask="url(#b)" d="M0 0h14v10H0z" />
      <path
        d="M3.337 6.667H0V3.333h3.337L-1.387.147-.64-.958l5.974 4.03V-.667h3.334v3.739l5.974-4.03.746 1.105-4.724 3.186H14v3.334h-3.337l4.724 3.186-.746 1.105-5.974-4.03v3.739H5.333V6.928l-5.974 4.03-.746-1.105 4.724-3.186z"
        fill="#FFF"
        mask="url(#b)"
      />
      <path
        d="M9.334 3.166L15.667-1"
        stroke="#DB1F35"
        strokeWidth=".667"
        strokeLinecap="round"
        mask="url(#b)"
      />
      <path
        d="M10.006 10.675l5.677-3.826"
        stroke="#DB1F35"
        strokeWidth=".667"
        strokeLinecap="round"
        mask="url(#b)"
        transform="matrix(1 0 0 -1 0 17.524)"
      />
      <path
        d="M4.003 3.155L-1.92-.835"
        stroke="#DB1F35"
        strokeWidth=".667"
        strokeLinecap="round"
        mask="url(#b)"
      />
      <path
        d="M4.645 11.155l-6.564-4.353"
        stroke="#DB1F35"
        strokeWidth=".667"
        strokeLinecap="round"
        mask="url(#b)"
        transform="matrix(1 0 0 -1 0 17.958)"
      />
      <path fill="#E6273E" mask="url(#b)" d="M0 6h6v4h2V6h6V4H8V0H6v4H0z" />
    </g>
  </svg>
);

export { FlagEn };
