import { Flex } from "@otrium/core";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/css";

const animation = keyframes`
    0% {    
      background-color: rgba(0, 0, 0, 0);
    }
    100% {
      background-color: rgba(0, 0, 0, 0.5);
    }
`;

const Wrapper = styled(Flex)<{
  mobileTop?: number;
  tabletTop?: number;
  desktopTop?: number;
  isActive?: boolean;
  delay?: number;
}>`
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndex.popup};
  background-color: ${({ isActive }) =>
    isActive ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0)"};
  ${({ delay }) =>
    delay &&
    `
    transition: background-color ${delay}ms ease-in-out;
    animation: ${animation} ${delay}ms ease-in-out;
    `}

  ${({ mobileTop, desktopTop, theme }) =>
    mobileTop &&
    `;
  top: ${mobileTop}px;
  height: calc(100% - ${mobileTop}px);
  @media (min-width: ${theme.breakpoints[3]}) {
    top: ${desktopTop}px;
    height: calc(100% - ${desktopTop}px);
  }
  `}

  ${({ tabletTop, theme }) =>
    tabletTop &&
    `
  @media (min-width: ${theme.breakpoints[2]}) {
    top: ${tabletTop}px;
    height: calc(100% - ${tabletTop}px);
  }
  `}
`;

export { Wrapper };
