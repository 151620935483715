/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  GraphQLDate: { input: any; output: any; }
  UnixTimestamp: { input: any; output: any; }
};

export type AbortMultipartUploadInput = {
  /** Name of the file that needs to be cancelled uploading. */
  file_name: Scalars['String']['input'];
  /** Otrium brand UUID */
  ot_brand_id?: InputMaybe<Scalars['String']['input']>;
  /** The upload id of the file that needs to be cancelled uploading. */
  upload_id: Scalars['String']['input'];
  /** Deprecated - Use ot_brand_id instead */
  vendor_id?: InputMaybe<Scalars['String']['input']>;
};

export type AbortMultipartUploadResult = {
  __typename?: 'AbortMultipartUploadResult';
  /** The result of the action. */
  result: Scalars['String']['output'];
};

/** AboutSaleDescription Type. */
export type AboutSaleDescription = {
  __typename?: 'AboutSaleDescription';
  /** Description. */
  description: Scalars['String']['output'];
  /** Language. */
  lang: Scalars['String']['output'];
};

export type ActiveCommercialSettings = {
  __typename?: 'ActiveCommercialSettings';
  /** Active commission model. Can be null until it is set */
  commission_model?: Maybe<CommissionModel>;
  /** Active fulfillment services. Can be null until it is set */
  fulfillment_services?: Maybe<FulfillmentServicesModel>;
  /** Active promotional discount (or the Platform Discount). Can't be null. It is set to disable at vendor creation */
  promotional_discount: VendorPromotionalDiscountModel;
  /** Active value added services. Can be null until it is set */
  value_added_services?: Maybe<VendorValueAddedServicesModel>;
};

export type AddressFilter = {
  additionalStreetInfo?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  streetName?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Possible ways that a subinvoice can be credited. */
export enum AdhocSubInvoiceType {
  Full = 'FULL',
  Partial = 'PARTIAL'
}

/** AgeFilterOption Type. */
export type AgeFilterOption = Node & {
  __typename?: 'AgeFilterOption';
  /** Number of products under this age. */
  count: Scalars['Int']['output'];
  /** id of the age. */
  id: Scalars['ID']['output'];
  /** Name of the age. */
  name: Scalars['String']['output'];
  /** Slug of the age. */
  slug: Scalars['String']['output'];
};

export type AlertBannerData = {
  __typename?: 'AlertBannerData';
  bg_color: Scalars['String']['output'];
  content: Scalars['String']['output'];
  link: Scalars['String']['output'];
  text_color: Scalars['String']['output'];
};

export type AlertBannerSection = {
  __typename?: 'AlertBannerSection';
  data?: Maybe<Array<AlertBannerData>>;
  type: Scalars['String']['output'];
};

export type AlertBar = {
  __typename?: 'AlertBar';
  bg_color: Scalars['String']['output'];
  content: Scalars['String']['output'];
  link: Scalars['String']['output'];
  text_color: Scalars['String']['output'];
  /** Date and time in ISO1801 format. You will get the corresponding UTC time. */
  valid_till?: Maybe<Scalars['String']['output']>;
};

export type AlertBars = {
  __typename?: 'AlertBars';
  alert?: Maybe<Array<AlertBar>>;
};

export type AllowedCountriesData = {
  __typename?: 'AllowedCountriesData';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ApiKey = ApiKeyInterface & {
  __typename?: 'ApiKey';
  /** Created datetime object. */
  created_at: ApiKeyCreatedAt;
  /** Brand API Key. */
  key: Scalars['String']['output'];
};

export type ApiKeyCreatedAt = Timestamp & {
  __typename?: 'ApiKeyCreatedAt';
  date: Scalars['String']['output'];
};

/** API Key Filtering options */
export type ApiKeyFilter = {
  /** Otrium Brand UUID */
  ot_brand_id: Scalars['String']['input'];
};

/** ApiKey Interface. */
export type ApiKeyInterface = {
  /** Created datetime object. */
  created_at: ApiKeyCreatedAt;
  /** Brand API Key. */
  key: Scalars['String']['output'];
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  /** The timestamp of the day it was created */
  created_at?: Maybe<Scalars['String']['output']>;
  /** email address */
  email: Scalars['String']['output'];
  /** gender type */
  gender: Scalars['String']['output'];
  /** ot_accounts ID for segment flow */
  member_id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** If it is new sign up */
  new_signup: Scalars['Boolean']['output'];
  /** On-Boarding metadata of the user */
  onboarding_metadata: OnboardingMetadata;
  /** if user accepted promo terms or no */
  opt_in: Scalars['Boolean']['output'];
  /** List of shop preferences */
  shop_preference?: Maybe<Array<Scalars['String']['output']>>;
  /** User id. For vendor login, this could be vendor id which is assigned to each vendor. */
  user_id: Scalars['ID']['output'];
  /** Jason web token to use with other secured end points */
  user_token: UserToken;
  /** Warnings block */
  warnings?: Maybe<Array<SingleAuthWarning>>;
};

/** Input type for the brand product visuals auto crop query */
export type AutoCropBrandProductVisualsInput = {
  /** Woo brand id */
  brand_id: Scalars['ID']['input'];
  /** Comma separated image names to be auto cropped */
  image_names?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Oware brand id to override */
  ot_brand_id?: InputMaybe<Scalars['String']['input']>;
  /** Woo Vendor id */
  vendor_id: Scalars['ID']['input'];
};

/** Result type of the brand product visuals auto crop query. */
export type AutoCropBrandProductVisualsResult = {
  __typename?: 'AutoCropBrandProductVisualsResult';
  /** If the auto crop was picked up and processed. */
  acknowledged?: Maybe<Scalars['Boolean']['output']>;
  /** The id of the auto crop operation. */
  operation_id?: Maybe<Scalars['String']['output']>;
};

/** AvailableFilters Type. */
export type AvailableFilters = {
  __typename?: 'AvailableFilters';
  /** Ages options. */
  ages?: Maybe<Array<AgeFilterOption>>;
  /** Brand filter options. */
  brands?: Maybe<Array<BrandFilterOption>>;
  /** Category options. */
  categories?: Maybe<Array<CategoryFilterOption>>;
  /** Colors options. */
  colors?: Maybe<Array<ColorFilterOption>>;
  /** Gender options */
  genders?: Maybe<Array<GenderFilterOption>>;
  /** GoodOnYou options */
  is_good_on_you?: Maybe<Array<GoodOnYouFilterOption>>;
  /** Label options. */
  labels?: Maybe<Array<LabelFilterOption>>;
  /** Language options. */
  languages?: Maybe<Array<FilterOption>>;
  /** Max price based on the current scope. */
  max_price?: Maybe<Scalars['Float']['output']>;
  /** Min price based on the current scope. */
  min_price?: Maybe<Scalars['Float']['output']>;
  /** Occasion options */
  occasions?: Maybe<Array<GeneralFilterOption>>;
  /** Pattern options */
  patterns?: Maybe<Array<GeneralFilterOption>>;
  /** Price band for the current filter scope. */
  price_bands?: Maybe<Array<BandFilterOption>>;
  /** Sizes options. */
  sizes?: Maybe<Array<SizeFilterOption>>;
  /** Style options */
  styles?: Maybe<Array<GeneralFilterOption>>;
};

export type B2BSignUpPayload = {
  __typename?: 'B2BSignUpPayload';
  /** User's company address */
  company_address: Scalars['String']['output'];
  /** User's company name */
  company_name: Scalars['String']['output'];
  /** email address */
  email: Scalars['String']['output'];
  /** State of the user, 0 or 1 */
  is_active_user: Scalars['Int']['output'];
  /** User's company country locations (Netherlands, Italy) */
  located_in: Scalars['String']['output'];
  /** ot_accounts ID for segment flow */
  member_id: Scalars['ID']['output'];
  /** If it is a new signup */
  new_signup: Scalars['Boolean']['output'];
  /** if user accepted promo terms or no. */
  opt_in: Scalars['Boolean']['output'];
  /** User's phone number */
  phone_number: Scalars['String']['output'];
  /** User id. For vendor login, this could be vendor id which is assigned to each vendor. */
  user_id: Scalars['ID']['output'];
  /** Jason web token to use with other secured end points */
  user_token: UserToken;
  /** User's company vat number */
  vat_number: Scalars['String']['output'];
  /** User's company website */
  website: Scalars['String']['output'];
};

/** B2bDealWithBiddingMeta type */
export type B2bDealWitBiddingMeta = {
  __typename?: 'B2bDealWitBiddingMeta';
  total_results?: Maybe<Scalars['Int']['output']>;
};

/** B2bDealWithBidding type */
export type B2bDealWithBidding = {
  __typename?: 'B2bDealWithBidding';
  bidding?: Maybe<DealBidding>;
  brand_name: Scalars['String']['output'];
  expected_price: Scalars['String']['output'];
  shop_id: Scalars['String']['output'];
  stock: Scalars['Int']['output'];
  styles: Scalars['Int']['output'];
  vendor_id: Scalars['String']['output'];
  warehouse: WarehouseInfo;
};

/** B2bDealWithBiddingResponse type */
export type B2bDealWithBiddingResponse = {
  __typename?: 'B2bDealWithBiddingResponse';
  deals: Array<Maybe<B2bDealWithBidding>>;
  meta?: Maybe<B2bDealWitBiddingMeta>;
};

/** Category section */
export type B2bProductOverviewCategorySection = {
  __typename?: 'B2bProductOverviewCategorySection';
  /** Category type */
  category: Scalars['String']['output'];
  /** Stock */
  stock: Scalars['Int']['output'];
  /** Styles */
  styles: Scalars['Int']['output'];
};

/** Gender section */
export type B2bProductOverviewGenderSection = {
  __typename?: 'B2bProductOverviewGenderSection';
  /** Gender type */
  gender: Scalars['String']['output'];
  /** Stock */
  stock: Scalars['Int']['output'];
  /** Styles */
  styles: Scalars['Int']['output'];
};

/** Season section */
export type B2bProductOverviewSeasonSection = {
  __typename?: 'B2bProductOverviewSeasonSection';
  /** Season type */
  season: Scalars['String']['output'];
  /** Stock */
  stock: Scalars['Int']['output'];
  /** Styles */
  styles: Scalars['Int']['output'];
};

/** Total section */
export type B2bProductOverviewTotalsSection = {
  __typename?: 'B2bProductOverviewTotalsSection';
  /** Total stock */
  total_stock?: Maybe<Scalars['Int']['output']>;
  /** Total styles */
  total_styles?: Maybe<Scalars['Int']['output']>;
};

/** Year section */
export type B2bProductOverviewYearSection = {
  __typename?: 'B2bProductOverviewYearSection';
  /** Stock */
  stock: Scalars['Int']['output'];
  /** Styles */
  styles: Scalars['Int']['output'];
  /** Year type */
  year: Scalars['Int']['output'];
};

/** BandFilterOption Type. price bands. */
export type BandFilterOption = Node & {
  __typename?: 'BandFilterOption';
  /** Number of products under this price band. */
  count: Scalars['Int']['output'];
  /** Lower boundy. */
  from?: Maybe<Scalars['Float']['output']>;
  /** id of the price band. */
  id: Scalars['ID']['output'];
  /** Upper boundry. */
  to?: Maybe<Scalars['Float']['output']>;
};

/** BiddingFilter input */
export type BiddingFilter = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** BillingAddress type */
export type BillingAddress = {
  __typename?: 'BillingAddress';
  additionalAddressInfo?: Maybe<Scalars['String']['output']>;
  additionalStreetInfo?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  houseAddition?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
};

/** Brand Interface. */
export type Brand = {
  /** Brand id. */
  id: Scalars['ID']['output'];
  /** Brand name. */
  name: Scalars['String']['output'];
};

/** Filtering options available for report. Refer API Doc for more details */
export type BrandB2bDealsFilter = {
  /** Should be coupled with offset */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Should be coupled with limit */
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Filtering options available for report. Refer API Doc for more details */
export type BrandB2bProductsReportFilter = {
  /** Should be coupled with offset */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Should be coupled with limit */
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** EditCarousel type */
export type BrandCarousel = {
  __typename?: 'BrandCarousel';
  /** List of carousel items */
  items: Array<SummaryBrand>;
  /** Position */
  position: Scalars['Int']['output'];
  /** Title of the carousel. */
  title: Scalars['String']['output'];
  /** View all url of the carousel. */
  view_all_url?: Maybe<Scalars['String']['output']>;
};

export type BrandCertification = {
  __typename?: 'BrandCertification';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

/** BrandConfig Type. */
export type BrandConfig = Brand & {
  __typename?: 'BrandConfig';
  /** First order made date. */
  first_order_date?: Maybe<Scalars['String']['output']>;
  /** Brand id. */
  id: Scalars['ID']['output'];
  /** Brand invoice address. */
  invoice_address?: Maybe<Scalars['String']['output']>;
  /** Brand name. */
  name: Scalars['String']['output'];
  /** Otrium brand id */
  ot_brand_id?: Maybe<Scalars['String']['output']>;
  /** Otrium vendor ID */
  ot_vendor_id?: Maybe<Scalars['String']['output']>;
  /** smart pricing flag. */
  smart_pricing_enabled?: Maybe<Scalars['Boolean']['output']>;
  /** Vendor currency */
  vendor_currency: Scalars['String']['output'];
};

/** Result for the query */
export type BrandConsciousRatingResult = {
  __typename?: 'BrandConsciousRatingResult';
  /** animalScore */
  animalScore?: Maybe<Scalars['Int']['output']>;
  /** brandName */
  brandName: Scalars['String']['output'];
  /** consciousness */
  consciousness: Scalars['Boolean']['output'];
  /** environmentScore */
  environmentScore?: Maybe<Scalars['Int']['output']>;
  /** isShown */
  isShown: Scalars['Boolean']['output'];
  /** labourScore */
  labourScore?: Maybe<Scalars['Int']['output']>;
  /** overallScore */
  overallScore?: Maybe<Scalars['Int']['output']>;
};

export type BrandData = {
  __typename?: 'BrandData';
  cardDisplayType?: Maybe<CardDisplayType>;
  gated?: Maybe<Scalars['Boolean']['output']>;
  image: Scalars['String']['output'];
  labels: Array<BrandLabel>;
  link: Scalars['String']['output'];
  linkCTA?: Maybe<Scalars['String']['output']>;
  subtitle: Scalars['String']['output'];
  timerEndDate?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

/** To keep the relationship between brand and discrete promotion */
export type BrandDiscretePromotion = {
  __typename?: 'BrandDiscretePromotion';
  /** brand id */
  brand_id: Scalars['Int']['output'];
  /** AI brand discrete promotion id */
  brand_promo_id: Scalars['ID']['output'];
  /** created date time */
  created_at: BrandDiscretePromotionCreatedAt;
  /** Discrete promotion id */
  discrete_promo_id: Scalars['Int']['output'];
  /** Discrete promotion type */
  discrete_promotion: DiscretePromotion;
  /** Last updated date time */
  updated_at?: Maybe<BrandDiscretePromotionUpdatedAt>;
};

/** Created at type */
export type BrandDiscretePromotionCreatedAt = Timestamp & {
  __typename?: 'BrandDiscretePromotionCreatedAt';
  date: Scalars['String']['output'];
};

/** Updated at type */
export type BrandDiscretePromotionUpdatedAt = Timestamp & {
  __typename?: 'BrandDiscretePromotionUpdatedAt';
  date?: Maybe<Scalars['String']['output']>;
};

/** To keep the relationship between brand and country */
export type BrandDomain = {
  __typename?: 'BrandDomain';
  /** AI brand domain id */
  brand_domain_id: Scalars['ID']['output'];
  /** brand id */
  brand_id: Scalars['Int']['output'];
  /** Country Domain type */
  country_domain: CountryDomain;
  /** Country domain id */
  country_domain_id: Scalars['Int']['output'];
  /** created date time */
  created_at: BrandDomainCreatedAt;
  /** Last updated date time */
  updated_at?: Maybe<BrandDomainUpdatedAt>;
};

/** Brand domain created at type */
export type BrandDomainCreatedAt = Timestamp & {
  __typename?: 'BrandDomainCreatedAt';
  date: Scalars['String']['output'];
};

/** Brand domain updated at type */
export type BrandDomainUpdatedAt = Timestamp & {
  __typename?: 'BrandDomainUpdatedAt';
  date?: Maybe<Scalars['String']['output']>;
};

export type BrandDropDownloadUrl = {
  __typename?: 'BrandDropDownloadUrl';
  /** url field holds the link to download file. */
  url: Scalars['String']['output'];
};

export type BrandDropDownloadUrlInput = {
  /** Name of the file to be downloaded. */
  file_name: Scalars['String']['input'];
};

export type BrandDropInput = {
  /** Starting point of the next result set. */
  next_continuation_token?: InputMaybe<Scalars['String']['input']>;
};

/** Drop report data */
export type BrandDropReport = {
  __typename?: 'BrandDropReport';
  /** Eg: 2024-03-08 */
  date: Scalars['String']['output'];
  /** S3 Signed URL to download the report */
  download_url: Scalars['String']['output'];
  drop_number: Scalars['Int']['output'];
  po_number: Scalars['String']['output'];
  /** Eg: "98.64" */
  report_variance: Scalars['String']['output'];
};

/** Request drop reports of a vendor */
export type BrandDropReportsInput = {
  ot_vendor_id: Scalars['String']['input'];
};

export type BrandDropUploadUrlInput = {
  /** Name of the file to be uploaded. */
  file_name: Scalars['String']['input'];
  /** id of the vendor. */
  vendor_id: Scalars['Int']['input'];
};

/** BrandFilter Input. */
export type BrandFilter = {
  /** list of brand ids. */
  brand_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** list of category ids. */
  category_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Number of elements to retrieve. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Starting index of the element to return. */
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** BrandFilterOption Type. */
export type BrandFilterOption = Node & {
  __typename?: 'BrandFilterOption';
  /** Number of product under this brand. */
  count: Scalars['Int']['output'];
  /** Id of the brand. */
  id: Scalars['ID']['output'];
  /** Name of the brand */
  name: Scalars['String']['output'];
};

/**
 * Brand Gate
 * This field will be responsible for
 * containing all gate related information
 * under brands / products in CP side
 */
export type BrandGate = {
  __typename?: 'BrandGate';
  level?: Maybe<BrandGateLevelInCustomerPlatform>;
};

/** Brand Gate Level for client apps in customer platform */
export enum BrandGateLevelInCustomerPlatform {
  FullyGated = 'fully_gated',
  NonGated = 'non_gated',
  PartiallyGated = 'partially_gated'
}

/** Filtering options */
export type BrandGeoRevenueFilter = {
  /** From date (Y-m-d) */
  from_date: Scalars['String']['input'];
  /** To date (Y-m-d) */
  to_date: Scalars['String']['input'];
};

/** Result for the query */
export type BrandGeoRevenueResult = {
  __typename?: 'BrandGeoRevenueResult';
  /** geoCode */
  geoCode: Scalars['String']['output'];
  /** grossQuantitySold */
  grossQuantitySold: Scalars['Int']['output'];
  /** grossQuantitySoldChange */
  grossQuantitySoldChange: Scalars['Float']['output'];
  /** grossSalesChange */
  grossSalesChange: Scalars['Float']['output'];
  /** grossSalesExcVat */
  grossSalesExcVat: Scalars['Float']['output'];
  /** grossSalesIncVat */
  grossSalesIncVat: Scalars['Float']['output'];
  /** netQuantitySold */
  netQuantitySold: Scalars['Int']['output'];
  /** netQuantitySoldChange */
  netQuantitySoldChange: Scalars['Float']['output'];
  /** netSalesChange */
  netSalesChange: Scalars['Float']['output'];
  /** netSalesExcVat */
  netSalesExcVat: Scalars['Float']['output'];
  /** netSalesIncVat */
  netSalesIncVat: Scalars['Float']['output'];
  /** percentageQuantityRevenue */
  percentageQuantityRevenue: Scalars['Float']['output'];
  /** percentageSalesRevenue */
  percentageSalesRevenue: Scalars['Float']['output'];
};

export type BrandGoodOnYouInfo = {
  __typename?: 'BrandGoodOnYouInfo';
  certifications: Array<BrandCertification>;
  pillars: BrandGoodOnYouPillars;
  score_meta: Scalars['String']['output'];
  scores: BrandGoodOnYouScores;
};

export type BrandGoodOnYouPillars = {
  __typename?: 'BrandGoodOnYouPillars';
  animals: Scalars['Boolean']['output'];
  overall: Scalars['Boolean']['output'];
  people: Scalars['Boolean']['output'];
  planet: Scalars['Boolean']['output'];
};

export type BrandGoodOnYouScores = {
  __typename?: 'BrandGoodOnYouScores';
  animals: Scalars['Int']['output'];
  overall: Scalars['Int']['output'];
  people: Scalars['Int']['output'];
  planet: Scalars['Int']['output'];
};

export type BrandImage = {
  source?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type BrandInvoiceReportInput = {
  /** invoice id to download */
  invoice_id: Scalars['Int']['input'];
  /** type of the file to download. Refer API end point document to identify available types. You can read it from here [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/317882369/API+endpoints#GET-Vendor-Invoice-Files-Export) */
  type: Scalars['String']['input'];
};

/** This contains labels for a single brand. This might not configured in the system & therefore optional atm. */
export type BrandLabel = {
  __typename?: 'BrandLabel';
  /** Color of the label background */
  background_color?: Maybe<Scalars['String']['output']>;
  /** color code eg: #D0021B */
  color: Scalars['String']['output'];
  /** Label priority. */
  priority: Scalars['Int']['output'];
  /** Promotion section */
  promotion?: Maybe<Promotion>;
  /** Color of the label text */
  text_color?: Maybe<Scalars['String']['output']>;
  /** Labels type. */
  type: Scalars['String']['output'];
  /** Label value. */
  value: Scalars['String']['output'];
};

export type BrandLabelSection = {
  __typename?: 'BrandLabelSection';
  color: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  promotion?: Maybe<Promotion>;
  /** Text color.Will appear for themed_label type */
  text_color?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type BrandPreference = {
  __typename?: 'BrandPreference';
  id: Scalars['ID']['output'];
  is_selected: Scalars['Boolean']['output'];
  logo: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

/** BrandProduct Type. */
export type BrandProduct = Product & {
  __typename?: 'BrandProduct';
  /** @deprecated Use `CatalogProduct` type. */
  available_sizes: Scalars['String']['output'];
  /** @deprecated Use `CatalogProduct` type. */
  brandName: Scalars['String']['output'];
  /** @deprecated Use `CatalogProduct` type. */
  id: Scalars['ID']['output'];
  /** @deprecated Use `CatalogProduct` type. */
  labels?: Maybe<Array<ProductLabel>>;
  /** @deprecated Use `CatalogProduct` type. */
  name: Scalars['String']['output'];
  /** @deprecated Use `CatalogProduct` type. */
  price: Scalars['Float']['output'];
  /** @deprecated Use `CatalogProduct` type. */
  regular_price: Scalars['Float']['output'];
  /** @deprecated Use `CatalogProduct` type. */
  slug: Scalars['String']['output'];
  /** @deprecated Use `CatalogProduct` type. */
  thumbnail: Scalars['String']['output'];
};

/** input type for the brand product visuals source mutation */
export type BrandProductVisualsLogInput = {
  /** Woo brand id */
  brand_id: Scalars['ID']['input'];
  /** Drop no of the visuals. */
  drop_no?: InputMaybe<Scalars['String']['input']>;
  /** String based Image names including format */
  image_names?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Oware brand id to override */
  ot_brand_id?: InputMaybe<Scalars['String']['input']>;
  /** Image Source type */
  source_type?: InputMaybe<ProductImageSourceTypes>;
  /** Woo Vendor id */
  vendor_id: Scalars['ID']['input'];
};

/** Response type of the brand product visuals log mutation */
export type BrandProductVisualsLogResponse = {
  __typename?: 'BrandProductVisualsLogResponse';
  message: Scalars['String']['output'];
};

/** input type for the brand product visuals validate query */
export type BrandProductVisualsValidateInput = {
  /** Woo brand id */
  brand_id: Scalars['ID']['input'];
  /** Drop no of the visuals. */
  drop_no?: InputMaybe<Scalars['String']['input']>;
  /** Comma separated image names to be validated against products */
  image_names?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Oware brand id to override */
  ot_brand_id?: InputMaybe<Scalars['String']['input']>;
  /** Woo Vendor id */
  vendor_id: Scalars['ID']['input'];
};

/** Response type of the brand product visuals validation query. File format should be presented */
export type BrandProductVisualsValidateResponse = {
  __typename?: 'BrandProductVisualsValidateResponse';
  failed_image_names?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * BrandProductsFilter Type.
 * #Please don't use this BrandProductsFilter input anywhere. In the future we plan to remove this.
 */
export type BrandProductsFilter = {
  /** Age ids as array. */
  age_ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** List of category ids as array. */
  category_ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Number of products to return. */
  limit: Scalars['Int']['input'];
  /** Starting index of the products to return. */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Order by options. */
  orderby?: InputMaybe<Scalars['String']['input']>;
  /** Price range. */
  price_range?: InputMaybe<Scalars['String']['input']>;
  /** Shop type. */
  shop_type?: InputMaybe<Scalars['String']['input']>;
  /** List of category slugs as array. */
  size_slugs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Taxonomy */
  taxonomy?: InputMaybe<Scalars['String']['input']>;
  /** Term id */
  term_id?: InputMaybe<Scalars['String']['input']>;
  /** User id. */
  user_id?: InputMaybe<Scalars['ID']['input']>;
};

/** Filtering options */
export type BrandProductsPerformanceFilter = {
  /** Condition. Possible values: ASC, DESC */
  condition: Scalars['String']['input'];
  /** From date (Y-m-d) */
  from_date: Scalars['String']['input'];
  /** Limit */
  limit: Scalars['Int']['input'];
  /** Sort by. Possible values: date, productID, quantitySold, netSalesIncVat, netSalesExcVat, grossSalesIncVat, grossSalesExcVat, netUsdSalesIncVat, netUsdSalesExcVat, grossUsdSalesIncVat, grossUsdSalesExcVat */
  sort_by: Scalars['String']['input'];
  /** To date (Y-m-d) */
  to_date: Scalars['String']['input'];
};

/** Result for the query */
export type BrandProductsPerformanceResult = {
  __typename?: 'BrandProductsPerformanceResult';
  /** grossQuantitySold */
  grossQuantitySold: Scalars['Int']['output'];
  /** grossSalesExcVat */
  grossSalesExcVat: Scalars['Float']['output'];
  /** grossSalesIncVat */
  grossSalesIncVat: Scalars['Float']['output'];
  /** netQuantitySold */
  netQuantitySold: Scalars['Int']['output'];
  /** netSalesExcVat */
  netSalesExcVat: Scalars['Float']['output'];
  /** netSalesIncVat */
  netSalesIncVat: Scalars['Float']['output'];
  /** productID */
  productID: Scalars['Int']['output'];
  /** productImage */
  productImage?: Maybe<Scalars['String']['output']>;
  /** productName */
  productName: Scalars['String']['output'];
};

/** Filtering options */
export type BrandProductsReproductionFilter = {
  /** From date (Y-m-d) */
  from_date: Scalars['String']['input'];
  /** To date (Y-m-d) */
  to_date: Scalars['String']['input'];
};

/** Result for the query */
export type BrandProductsReproductionResult = {
  __typename?: 'BrandProductsReproductionResult';
  /** Collection type */
  collectionType: Scalars['String']['output'];
  /** Discount sold */
  discountSold: Scalars['Float']['output'];
  /** Previous discount sold */
  previousDiscountSold: Scalars['Float']['output'];
  /** Previous quantity stock */
  previousQuantityStock: Scalars['Float']['output'];
  /** Previous retail value */
  previousRetailValue: Scalars['Float']['output'];
  /** Quantity stock */
  quantityStock: Scalars['Float']['output'];
  /** Retail value */
  retailValue: Scalars['Float']['output'];
};

export type BrandReportByUserIdFilterInput = {
  /** Must be a boolean. Please refer Reporting MC API for more info. */
  b_choice_stock_only?: InputMaybe<Scalars['Boolean']['input']>;
  /** Current inventory. Please refer Reporting MC API for more info. */
  current_inventory?: InputMaybe<Scalars['String']['input']>;
  /** brand report filter by end date */
  end: Scalars['String']['input'];
  /** Fields to include. Please refer Reporting MC API for more info. */
  fields_to_include?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Must be an array. Please refer Reporting MC API for more info. */
  product_statuses?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Must be a string. Please refer Reporting MC API for more info. */
  sell_through_type?: InputMaybe<Scalars['String']['input']>;
  /** brand report filter by start date */
  start: Scalars['String']['input'];
  /**
   * brand report type
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/317882369/API+endpoints#GET-Vendor-Exports)
   */
  type: Scalars['String']['input'];
};

/** Filtering options */
export type BrandReturnedProductsFilter = {
  /** From date (Y-m-d) */
  from_date: Scalars['String']['input'];
  /** Limit */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** To date (Y-m-d) */
  to_date: Scalars['String']['input'];
};

/** Result for the query */
export type BrandReturnedProductsResult = {
  __typename?: 'BrandReturnedProductsResult';
  /** productId */
  productId: Scalars['Int']['output'];
  /** productImage */
  productImage?: Maybe<Scalars['String']['output']>;
  /** productName */
  productName: Scalars['String']['output'];
  /** returnRateAmount */
  returnRateAmount: Scalars['Float']['output'];
  /** returnRateQuantity */
  returnRateQuantity: Scalars['Float']['output'];
  /** returnedQuantity */
  returnedQuantity: Scalars['Int']['output'];
  /** totalRefunded */
  totalRefunded: Scalars['Float']['output'];
};

export type BrandSeo = {
  __typename?: 'BrandSEO';
  leftColumnHTML?: Maybe<Scalars['String']['output']>;
  rightColumnHTML?: Maybe<Scalars['String']['output']>;
};

export type BrandSaleByUserIdFilterInput = {
  /** end date for report */
  end: Scalars['String']['input'];
  /** start date for report */
  start: Scalars['String']['input'];
};

/** Brand Sale Type. In Use for Otrium Hub */
export enum BrandSaleType {
  FlashSale = 'FLASH_SALE',
  Regular = 'REGULAR'
}

/** Result of the brand settings update */
export type BrandSettingsUpdateResult = {
  __typename?: 'BrandSettingsUpdateResult';
  result: Scalars['String']['output'];
};

export type BrandShop = BrandShopInterface & {
  __typename?: 'BrandShop';
  /** Related brand object. */
  brand: BrandsListSingleBrand;
  /** brand id. Each brand shop associate with a brand. */
  brand_id: Scalars['Int']['output'];
  /** brand shop id */
  brand_shop_id: Scalars['ID']['output'];
  /** Associated brand shop type. Each shop has associated with a type */
  brand_shop_type?: Maybe<BrandShopType>;
  /** brand shop type id. One brand shop is associated with a single type. So One brand can have only one shop for a single type */
  brand_shop_type_id: Scalars['Int']['output'];
  /** Created datetime object. */
  created_at: BrandShopCreatedAt;
  /** last updated datetime object. */
  updated_at?: Maybe<BrandShopUpdatedAt>;
};

export type BrandShopCreatedAt = Timestamp & {
  __typename?: 'BrandShopCreatedAt';
  date: Scalars['String']['output'];
};

/** Get Shop type query input filter. This is optional */
export type BrandShopFilter = {
  /** filter using brand shop type id */
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  /** Filter by brand_ids. You need to pass brand ids as a comma separated value. eg: (1,2,3,4,5) */
  brand_ids?: InputMaybe<Scalars['String']['input']>;
  /** filter using brand shop type id */
  brand_shop_type_id?: InputMaybe<Scalars['Int']['input']>;
  /** Filter by shop type name. */
  shop_type?: InputMaybe<Scalars['String']['input']>;
};

/** BrandShop Interface. */
export type BrandShopInterface = {
  /** brand id. Each brand shop associate with a brand. */
  brand_id: Scalars['Int']['output'];
  /** brand shop id */
  brand_shop_id: Scalars['ID']['output'];
  /** brand shop type id. One brand shop is associated with a single type. So One brand can have only one shop for a single type */
  brand_shop_type_id: Scalars['Int']['output'];
  /** Created datetime object. */
  created_at: BrandShopCreatedAt;
  /** last updated datetime object. */
  updated_at?: Maybe<BrandShopUpdatedAt>;
};

export type BrandShopPricingAgreement = BrandShopPricingAgreementInterface & {
  __typename?: 'BrandShopPricingAgreement';
  /** agreement id.- unique id for each agreement. Use this value to update request */
  agreement_id: Scalars['ID']['output'];
  /** Average discount of the pricing agreement. */
  average_discount: Scalars['Float']['output'];
  /** Related brand shop object. You can find the relevant brand by dive into this object */
  brand_shop: BrandShop;
  /** related brand shop id. All pricing agreements assign to a shop. */
  brand_shop_id: Scalars['Int']['output'];
  /** Created datetime object. */
  created_at: BrandShopPricingAgreementCreatedAt;
  /** Duration for the agreement. (Days count) */
  duration: Scalars['Int']['output'];
  /** type of the agreement Currently available values are 'FirstPeriod', 'ProfitShop', 'ClearanceShop' */
  type: Scalars['String']['output'];
  /** last updated datetime object. */
  updated_at?: Maybe<BrandShopPricingAgreementUpdatedAt>;
};

export type BrandShopPricingAgreementCreatedAt = Timestamp & {
  __typename?: 'BrandShopPricingAgreementCreatedAt';
  date: Scalars['String']['output'];
};

/** Get Shop pricingAgreement query input filter. This is optional */
export type BrandShopPricingAgreementFilter = {
  /** filter using user pricingAgreement id */
  agreement_id?: InputMaybe<Scalars['Int']['input']>;
  /** Filter by shop id. One shop could have multiple pricingAgreements */
  brand_shop_id?: InputMaybe<Scalars['Int']['input']>;
  /** Filter by type. ('FirstPeriod', 'ProfitShop', 'ClearanceShop') */
  type?: InputMaybe<Scalars['String']['input']>;
  /** Filter by woo id */
  universal_id?: InputMaybe<Scalars['Int']['input']>;
  /** Filter by woo vendor/user id */
  universal_vendor_id?: InputMaybe<Scalars['Int']['input']>;
};

/** BrandShopPricingAgreement Interface. */
export type BrandShopPricingAgreementInterface = {
  /** agreement id.- unique id for each agreement. Use this value to update request */
  agreement_id: Scalars['ID']['output'];
  /** Avarage discount of the pricing agreement. */
  average_discount: Scalars['Float']['output'];
  /** related brand shop id. All pricing agreements assign to a shop. */
  brand_shop_id: Scalars['Int']['output'];
  /** Created datetime object. */
  created_at: BrandShopPricingAgreementCreatedAt;
  /** Duration for the agreement. (Days count) */
  duration: Scalars['Int']['output'];
  /** type of the agreement */
  type: Scalars['String']['output'];
  /** last updated datetime object. */
  updated_at?: Maybe<BrandShopPricingAgreementUpdatedAt>;
};

export type BrandShopPricingAgreementUpdatedAt = Timestamp & {
  __typename?: 'BrandShopPricingAgreementUpdatedAt';
  date?: Maybe<Scalars['String']['output']>;
};

export type BrandShopPricingSetting = BrandShopPricingSettingInterface & {
  __typename?: 'BrandShopPricingSetting';
  /** related brand shop id. All pricing settings assign to a shop. */
  brand_shop_id: Scalars['Int']['output'];
  /** coupons of the pricingSetting. */
  coupons: Scalars['Boolean']['output'];
  /** Created datetime object. */
  created_at: BrandShopPricingSettingCreatedAt;
  /** emotional cap of the pricing setting. */
  emotional_cap: Scalars['Float']['output'];
  /** price matching value for selected pricing setting */
  price_matching: Scalars['Boolean']['output'];
  /** pricing strategy value for selected pricing setting */
  pricing_strategy: Scalars['String']['output'];
  /** setting id.- unique id for each pricing setting. Use this value to update request */
  setting_id: Scalars['ID']['output'];
  /** last updated datetime object. */
  updated_at?: Maybe<BrandShopPricingSettingUpdatedAt>;
};

export type BrandShopPricingSettingCreatedAt = Timestamp & {
  __typename?: 'BrandShopPricingSettingCreatedAt';
  date: Scalars['String']['output'];
};

/** Get Shop pricingSetting query input filter. This is optional */
export type BrandShopPricingSettingFilter = {
  /** Filter by shop id. One shop could have multiple pricingSettings */
  brand_shop_id?: InputMaybe<Scalars['Int']['input']>;
  /** Filter by pricing strategy type. ('Manual', 'Smart', 'Custom') */
  pricing_strategy?: InputMaybe<Scalars['String']['input']>;
  /** filter using user pricingSetting id */
  setting_id?: InputMaybe<Scalars['Int']['input']>;
  /** Filter by woo vendor/user id */
  universal_vendor_id?: InputMaybe<Scalars['Int']['input']>;
};

/** BrandShopPricingSetting Interface. */
export type BrandShopPricingSettingInterface = {
  /** related brand shop id. All pricing settings assign to a shop. */
  brand_shop_id: Scalars['Int']['output'];
  /** coupons of the pricingSetting. */
  coupons: Scalars['Boolean']['output'];
  /** Created datetime object. */
  created_at: BrandShopPricingSettingCreatedAt;
  /** emotional cap of the pricing setting. */
  emotional_cap: Scalars['Float']['output'];
  /** price matching value for selected pricing setting */
  price_matching: Scalars['Boolean']['output'];
  /** pricing strategy value for selected pricing setting */
  pricing_strategy: Scalars['String']['output'];
  /** setting id.- unique id for each pricing setting. Use this value to update request */
  setting_id: Scalars['ID']['output'];
  /** last updated datetime object. */
  updated_at?: Maybe<BrandShopPricingSettingUpdatedAt>;
};

export type BrandShopPricingSettingUpdatedAt = Timestamp & {
  __typename?: 'BrandShopPricingSettingUpdatedAt';
  date?: Maybe<Scalars['String']['output']>;
};

export type BrandShopTarget = BrandShopTargetInterface & {
  __typename?: 'BrandShopTarget';
  /** related brand shop id. All targets assign to a shop. */
  brand_shop_id: Scalars['Int']['output'];
  /** Created datetime object. */
  created_at: BrandShopTargetCreatedAt;
  /** end date of the target period. */
  end_date: Scalars['String']['output'];
  /** gmv value for selected target period */
  gmv: Scalars['Float']['output'];
  /** retail value for selected target period */
  retail_value: Scalars['Float']['output'];
  /** return rate of the target. */
  return_rate?: Maybe<Scalars['Float']['output']>;
  /** sell through value of the target */
  sell_through?: Maybe<Scalars['Float']['output']>;
  /** start date of the target period. */
  start_date: Scalars['String']['output'];
  /** target id. */
  target_id: Scalars['ID']['output'];
  /** last updated datetime object. */
  updated_at?: Maybe<BrandShopTargetUpdatedAt>;
};

export type BrandShopTargetCreatedAt = Timestamp & {
  __typename?: 'BrandShopTargetCreatedAt';
  date: Scalars['String']['output'];
};

/** Get Shop target query input filter. This is optional */
export type BrandShopTargetFilter = {
  /** Filter by brand_ids. You need to pass brand ids as a comma separated value. eg: (1,2,3,4,5) */
  brand_ids?: InputMaybe<Scalars['String']['input']>;
  /** Filter by shop id. One shop could have multiple targets */
  brand_shop_id?: InputMaybe<Scalars['Int']['input']>;
  /** filter using user target id */
  target_id?: InputMaybe<Scalars['Int']['input']>;
};

/** BrandShopTarget Interface. */
export type BrandShopTargetInterface = {
  /** related brand shop id. All targets assign to a shop. */
  brand_shop_id: Scalars['Int']['output'];
  /** Created datetime object. */
  created_at: BrandShopTargetCreatedAt;
  /** end date of the target period. */
  end_date: Scalars['String']['output'];
  /** gmv value for selected target period */
  gmv: Scalars['Float']['output'];
  /** retail value for selected target period */
  retail_value: Scalars['Float']['output'];
  /** return rate of the target. */
  return_rate?: Maybe<Scalars['Float']['output']>;
  /** sell through value of the target */
  sell_through?: Maybe<Scalars['Float']['output']>;
  /** start date of the target period. */
  start_date: Scalars['String']['output'];
  /** target id. */
  target_id: Scalars['ID']['output'];
  /** last updated datetime object. */
  updated_at?: Maybe<BrandShopTargetUpdatedAt>;
};

export type BrandShopTargetUpdatedAt = Timestamp & {
  __typename?: 'BrandShopTargetUpdatedAt';
  date?: Maybe<Scalars['String']['output']>;
};

export type BrandShopType = BrandShopTypeInterface & {
  __typename?: 'BrandShopType';
  /** Human readable name for each brand shop type. This is unique for each */
  brand_shop_type: Scalars['String']['output'];
  /** brand shop type id. */
  brand_shop_type_id: Scalars['ID']['output'];
  /** Created datetime object. */
  created_at: BrandShopTypeCreatedAt;
  /** last updated datetime object. */
  updated_at?: Maybe<BrandShopTypeUpdatedAt>;
};

export type BrandShopTypeCreatedAt = Timestamp & {
  __typename?: 'BrandShopTypeCreatedAt';
  date: Scalars['String']['output'];
};

/** Get Shop type query input filter. This is optional */
export type BrandShopTypeFilter = {
  /** Filter by shop type name. */
  brand_shop_type?: InputMaybe<Scalars['String']['input']>;
  /** filter using brand shop type id */
  brand_shop_type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** BrandShopType Interface. */
export type BrandShopTypeInterface = {
  /** Human readable name for each brand shop type. This is unique for each */
  brand_shop_type: Scalars['String']['output'];
  /** brand shop type id. */
  brand_shop_type_id: Scalars['ID']['output'];
  /** Created datetime object. */
  created_at: BrandShopTypeCreatedAt;
  /** last updated datetime object. */
  updated_at?: Maybe<BrandShopTypeUpdatedAt>;
};

export type BrandShopTypeUpdatedAt = Timestamp & {
  __typename?: 'BrandShopTypeUpdatedAt';
  date?: Maybe<Scalars['String']['output']>;
};

export type BrandShopUpdatedAt = Timestamp & {
  __typename?: 'BrandShopUpdatedAt';
  date?: Maybe<Scalars['String']['output']>;
};

export type BrandSizeGuide = {
  __typename?: 'BrandSizeGuide';
  gender: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type BrandType = BrandTypeInterface & {
  __typename?: 'BrandType';
  /** Brand type id. */
  brand_type_id: Scalars['ID']['output'];
  /** Created datetime object. */
  created_at: BrandTypeCreatedAt;
  /** Brand type name. This behaves similar to a slug. No spaces allowed. This is usefull when it comes to filtering by a human readable word */
  type_identifier: Scalars['String']['output'];
  /** Brand type name. */
  type_name: Scalars['String']['output'];
  /** last updated datetime object. */
  updated_at?: Maybe<BrandTypeUpdatedAt>;
};

export type BrandTypeCreatedAt = Timestamp & {
  __typename?: 'BrandTypeCreatedAt';
  date: Scalars['String']['output'];
};

/** get Brand types query input filter. This is optional */
export type BrandTypeFilter = {
  /** Use brand_id if you want only the brand type of a given brand. */
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  /** filter using brand type id */
  brand_type_id?: InputMaybe<Scalars['Int']['input']>;
  /** filter using the brand type identifier */
  type_identifier?: InputMaybe<Scalars['String']['input']>;
  /** filter using the brand type name */
  type_name?: InputMaybe<Scalars['String']['input']>;
};

/** BrandType Interface. */
export type BrandTypeInterface = {
  /** Brand type id. */
  brand_type_id: Scalars['ID']['output'];
  /** Created datetime object. */
  created_at: BrandTypeCreatedAt;
  /** Brand type name. This behaves similar to a slug. No spaces allowed. This is usefull when it comes to filtering by a human readable word */
  type_identifier: Scalars['String']['output'];
  /** Brand type name. */
  type_name: Scalars['String']['output'];
  /** last updated datetime object. */
  updated_at?: Maybe<BrandTypeUpdatedAt>;
};

export type BrandTypeUpdatedAt = Timestamp & {
  __typename?: 'BrandTypeUpdatedAt';
  date?: Maybe<Scalars['String']['output']>;
};

/** Brand Types. */
export enum BrandTypes {
  Conscious = 'conscious',
  Designer = 'designer',
  Popular = 'popular'
}

export type BrandUser = BrandUserInterface & {
  __typename?: 'BrandUser';
  /** Brand object based on the brand id */
  brand?: Maybe<BrandsListSingleBrand>;
  /** Brand id */
  brand_id?: Maybe<Scalars['Int']['output']>;
  /** Brand to user id. - auto generated id for the relation */
  brand_to_user_id: Scalars['ID']['output'];
  /** Created datetime object. */
  created_at: BrandUserCreatedAt;
  /** Priority id - to maintain the relation between user and it's priority with the relevant brand */
  priority_id?: Maybe<Scalars['Int']['output']>;
  /** User role id - to maintain the relation between user and it's role with the relevant brand */
  role_id: Scalars['Int']['output'];
  /** last updated datetime object. */
  updated_at?: Maybe<BrandUserUpdatedAt>;
  /** User's id */
  user_id: Scalars['Int']['output'];
  /** User Role object based on the user role id */
  user_role: BrandUserRole;
};

export type BrandUserCreatedAt = Timestamp & {
  __typename?: 'BrandUserCreatedAt';
  date: Scalars['String']['output'];
};

/** get Brand types query input filter. This is optional */
export type BrandUserFilter = {
  /** Use brand_id if you want to filter for a single brand */
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  /** filter using Auto generated brand to user id. */
  brand_to_user_id?: InputMaybe<Scalars['Int']['input']>;
  /** filter using user id */
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** BrandUser Interface. */
export type BrandUserInterface = {
  /** Brand id */
  brand_id?: Maybe<Scalars['Int']['output']>;
  /** Brand to user id. - auto generated id for the relation */
  brand_to_user_id: Scalars['ID']['output'];
  /** Created datetime object. */
  created_at: BrandUserCreatedAt;
  /** Priority id - to maintain the relation between user and it's priority with the relevant brand */
  priority_id?: Maybe<Scalars['Int']['output']>;
  /** User role id - to maintain the relation between user and it's role with the relevant brand */
  role_id: Scalars['Int']['output'];
  /** last updated datetime object. */
  updated_at?: Maybe<BrandUserUpdatedAt>;
  /** User's id */
  user_id: Scalars['Int']['output'];
};

export type BrandUserPriority = BrandUserPriorityInterface & {
  __typename?: 'BrandUserPriority';
  /** Created datetime object. */
  created_at: BrandUserPriorityCreatedAt;
  /** User priority name. */
  priority_name?: Maybe<Scalars['String']['output']>;
  /** last updated datetime object. */
  updated_at?: Maybe<BrandUserPriorityUpdatedAt>;
  /** User priority id. */
  user_priority_id: Scalars['ID']['output'];
};

export type BrandUserPriorityCreatedAt = Timestamp & {
  __typename?: 'BrandUserPriorityCreatedAt';
  date: Scalars['String']['output'];
};

/** Get Brand user priorities query input filter. This is optional */
export type BrandUserPriorityFilter = {
  /** filter using the user priority name */
  priority_name?: InputMaybe<Scalars['String']['input']>;
  /** filter using user priority id */
  user_priority_id?: InputMaybe<Scalars['Int']['input']>;
};

/** BrandUserPriority Interface. */
export type BrandUserPriorityInterface = {
  /** Created datetime object. */
  created_at: BrandUserPriorityCreatedAt;
  /** User priority name. */
  priority_name?: Maybe<Scalars['String']['output']>;
  /** last updated datetime object. */
  updated_at?: Maybe<BrandUserPriorityUpdatedAt>;
  /** User priority id. */
  user_priority_id: Scalars['ID']['output'];
};

export type BrandUserPriorityUpdatedAt = Timestamp & {
  __typename?: 'BrandUserPriorityUpdatedAt';
  date?: Maybe<Scalars['String']['output']>;
};

export type BrandUserRole = BrandUserRoleInterface & {
  __typename?: 'BrandUserRole';
  /** Created datetime object. */
  created_at: BrandUserRoleCreatedAt;
  /** User role id. */
  role_id: Scalars['ID']['output'];
  /** User role identifier. This behaves similar to a slug. No spaces allowed. This is usefull when it comes to filtering by a human readable word */
  role_identifier: Scalars['String']['output'];
  /** User role name. */
  role_name: Scalars['String']['output'];
  /** last updated datetime object. */
  updated_at?: Maybe<BrandUserRoleUpdatedAt>;
};

export type BrandUserRoleCreatedAt = Timestamp & {
  __typename?: 'BrandUserRoleCreatedAt';
  date: Scalars['String']['output'];
};

/** Get Brand user roles query input filter. This is optional */
export type BrandUserRoleFilter = {
  /** filter using user role id */
  role_id?: InputMaybe<Scalars['Int']['input']>;
  /** filter using the user role identifier */
  role_identifier?: InputMaybe<Scalars['String']['input']>;
  /** filter using the user role name */
  role_name?: InputMaybe<Scalars['String']['input']>;
};

/** BrandUserRole Interface. */
export type BrandUserRoleInterface = {
  /** Created datetime object. */
  created_at: BrandUserRoleCreatedAt;
  /** User role id. */
  role_id: Scalars['ID']['output'];
  /** User role identifier. This behaves similar to a slug. No spaces allowed. This is usefull when it comes to filtering by a human readable word */
  role_identifier: Scalars['String']['output'];
  /** User role name. */
  role_name: Scalars['String']['output'];
  /** last updated datetime object. */
  updated_at?: Maybe<BrandUserRoleUpdatedAt>;
};

export type BrandUserRoleUpdatedAt = Timestamp & {
  __typename?: 'BrandUserRoleUpdatedAt';
  date?: Maybe<Scalars['String']['output']>;
};

export type BrandUserUpdatedAt = Timestamp & {
  __typename?: 'BrandUserUpdatedAt';
  date?: Maybe<Scalars['String']['output']>;
};

/** Brand VAS Invoice File containing file url */
export type BrandVasInvoiceFile = {
  __typename?: 'BrandVASInvoiceFile';
  /** invoiceFile */
  invoiceFile: Scalars['String']['output'];
};

/** Brand VAS Invoices containing invoices result section and meta data */
export type BrandVasInvoices = {
  __typename?: 'BrandVASInvoices';
  /** Meta */
  meta: BrandVasInvoicesMeta;
  /** Result */
  result: Array<Maybe<BrandVasInvoicesResult>>;
};

/** Filtering options */
export type BrandVasInvoicesFilter = {
  /** Limit */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Starting index of the invoices to return */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Condition. Possible values: ASC, DESC */
  sort: Scalars['String']['input'];
  /** Sort by. Possible values: invoiceId, invoiceDate, totalInvoiced */
  sortBy: Scalars['String']['input'];
};

/** Meta */
export type BrandVasInvoicesMeta = {
  __typename?: 'BrandVASInvoicesMeta';
  count: Scalars['Int']['output'];
};

/** Result for the query */
export type BrandVasInvoicesResult = {
  __typename?: 'BrandVASInvoicesResult';
  /** invoiceDate */
  invoiceDate?: Maybe<Scalars['String']['output']>;
  /** invoiceId */
  invoiceId: Scalars['Int']['output'];
  /** totalInvoiced */
  totalInvoiced?: Maybe<Scalars['String']['output']>;
};

export type BrandVisualInput = {
  /** Starting point of the next result set. */
  next_continuation_token?: InputMaybe<Scalars['String']['input']>;
};

export type BrandVisualUploadData = {
  __typename?: 'BrandVisualUploadData';
  /** Name of the brand visual */
  new_visual_name_with_extension: Scalars['String']['output'];
  /** Signed URL to upload the brand visual */
  signed_url: Scalars['String']['output'];
  /** Visual URL */
  visual_url: Scalars['String']['output'];
};

export type BrandVisualUploadDataInput = {
  /** Name of the file to be uploaded. */
  brand_visual_name: Scalars['String']['input'];
  /** UUID of the brand */
  ot_brand_id: Scalars['String']['input'];
};

export type BrandVisualUploadUrlInput = {
  /** Name of the file to be uploaded. */
  file_name: Scalars['String']['input'];
  /** Otrium brand UUID */
  ot_brand_id: Scalars['String']['input'];
  /**
   * Woo Vendor ID
   * @deprecated(reason: "Use ot_brand_id instead")
   */
  vendor_id?: InputMaybe<Scalars['String']['input']>;
};

export type Brands = WithPosition & {
  __typename?: 'Brands';
  data: Array<SummaryBrand>;
  link: Scalars['String']['output'];
  position: Scalars['Int']['output'];
};

/** Get Brand user priorities query input filter. This is optional */
export type BrandsListFilter = {
  /** filter using the brand id - This is AI id which is unique inside the brand MC. You also should use this id for other related objects. */
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  /** filter using the brand name */
  brand_name?: InputMaybe<Scalars['String']['input']>;
  /** filter using brand type id */
  brand_type_id?: InputMaybe<Scalars['Int']['input']>;
  /** filter using  the mapping id of woo system */
  universal_id?: InputMaybe<Scalars['Int']['input']>;
};

export type BrandsListSingleBrand = Brand & {
  __typename?: 'BrandsListSingleBrand';
  /** Allowed brand domains */
  allowed_domains: Array<BrandDomain>;
  /** Email address of a brand */
  brand_contact_email?: Maybe<Scalars['String']['output']>;
  /** Associated brand type */
  brand_type?: Maybe<BrandType>;
  /** Associated brand type id */
  brand_type_id?: Maybe<Scalars['Int']['output']>;
  /** Created datetime object. */
  created_at: BrandsListSingleBrandCreatedAt;
  /** Discrete Promotions */
  discrete_promotions: Array<BrandDiscretePromotion>;
  /** Google shopping */
  google_shopping?: Maybe<Scalars['String']['output']>;
  /** Brand id of 'MC' This is NOT THE WOO ID. */
  id: Scalars['ID']['output'];
  /** Brand name. */
  name: Scalars['String']['output'];
  /** Mapping key of pip drive system. This will be a unique id for each brand in MC */
  pipe_drive_id?: Maybe<Scalars['Int']['output']>;
  /** last updated datetime object. */
  updated_at?: Maybe<BrandsListSingleBrandUpdatedAt>;
  /** Vat no value of brand */
  vat_no?: Maybe<Scalars['String']['output']>;
  /** Mapping key of woo system. This will be a unique id for each brand in MC */
  woo_id?: Maybe<Scalars['Int']['output']>;
  /** Mapping key of relevant vendor id on woo system */
  woo_vendor_id?: Maybe<Scalars['Int']['output']>;
};

/** Single brand created at */
export type BrandsListSingleBrandCreatedAt = Timestamp & {
  __typename?: 'BrandsListSingleBrandCreatedAt';
  date: Scalars['String']['output'];
};

/** Single brand updated at */
export type BrandsListSingleBrandUpdatedAt = Timestamp & {
  __typename?: 'BrandsListSingleBrandUpdatedAt';
  date?: Maybe<Scalars['String']['output']>;
};

/** BrowserInfo input type */
export type BrowserInfo = {
  /** The color depth of the shopper's browser in bits per pixel. This should be obtained by using the browser's `screen.colorDepth` property. Accepted values: 1, 4, 8, 15, 16, 24, 30, 32 or 48 bit color depth */
  color_depth?: InputMaybe<Scalars['Int']['input']>;
  /** Boolean value indicating if the shopper's browser is able to execute Java */
  java_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The `navigator.language` value of the shopper's browser (as defined in IETF BCP 47) */
  language?: InputMaybe<Scalars['String']['input']>;
  /** The total height of the shopper's device screen in pixels */
  screen_height?: InputMaybe<Scalars['Int']['input']>;
  /** The total width of the shopper's device screen in pixels */
  screen_width?: InputMaybe<Scalars['Int']['input']>;
  /** Time difference between UTC time and the shopper's browser local time, in minutes */
  timezone_offset?: InputMaybe<Scalars['Int']['input']>;
  /** The user agent value of the shopper's browser */
  user_agent: Scalars['String']['input'];
};

export enum CardDisplayType {
  Default = 'DEFAULT',
  FlashSale = 'FLASH_SALE'
}

export type CardSection = {
  __typename?: 'CardSection';
  data: Array<BrandData>;
  type: Scalars['String']['output'];
};

export type CardsSection = WithPosition & {
  __typename?: 'CardsSection';
  data: Array<BrandData>;
  position: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

/** Map of carousels to their respective recommendation type. */
export type CarouselRecommendTypeInput = {
  cart_cross_sell_carousel?: InputMaybe<Scalars['String']['input']>;
  homepage_new_in_carousel?: InputMaybe<Scalars['String']['input']>;
  pdp_bestsellers_carousel?: InputMaybe<Scalars['String']['input']>;
  pdp_last_chance_carousel?: InputMaybe<Scalars['String']['input']>;
  pdp_similar_items_carousel?: InputMaybe<Scalars['String']['input']>;
  pdp_trending_items_carousel?: InputMaybe<Scalars['String']['input']>;
};

/** Response type of the carousel */
export type CarouselResult = {
  __typename?: 'CarouselResult';
  /** List of Brand carousels will be return here. */
  brand_carousels: Array<BrandCarousel>;
  /** List of Edit carousels will be return here. */
  edit_carousels: Array<EditCarousel>;
};

export type CartCarousel = {
  __typename?: 'CartCarousel';
  /** Flag to determine add to cart button should be shown or not. */
  allow_add_to_cart: Scalars['Boolean']['output'];
  /** id of the carousel. */
  id: Scalars['String']['output'];
  /** ot_product_id of cart product which this carousel belongs to */
  ot_product_id?: Maybe<Scalars['String']['output']>;
  /** List of product return from this carousel. */
  products?: Maybe<Array<CatalogProduct>>;
  /** Response source type. */
  response_source_type?: Maybe<Scalars['String']['output']>;
  /** If target type is DEEP_LINK full url will be provided. */
  target_link?: Maybe<Scalars['String']['output']>;
  /** Target type of the target link. */
  target_type?: Maybe<CartCarouselTargetType>;
  /** Translated title of the carousel. */
  title: Scalars['String']['output'];
};

/** CartCarouselInputs type. */
export type CartCarouselInputs = {
  /** Requested recommend types per carousel */
  carousel_recommend_type?: InputMaybe<CarouselRecommendTypeInput>;
  /** Number of products to be return */
  limit: Scalars['Int']['input'];
  /** List of ot_product_ids to get similar product carousels for */
  ot_product_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Shop type either men,women or kids */
  shop_type: ProductGender;
};

export enum CartCarouselTargetType {
  DeepLink = 'DEEP_LINK'
}

/** CartCarouselsResult type. */
export type CartCarouselsResult = {
  __typename?: 'CartCarouselsResult';
  /** List of carousel. */
  carousels?: Maybe<Array<CartCarousel>>;
};

/** Cart query input type. */
export type CartQueryInput = {
  /** Persist warnings. */
  persist_warning?: InputMaybe<Scalars['Boolean']['input']>;
  user_id: Scalars['String']['input'];
};

/**
 * `Deprecated` - Use increaseCart mutation instead
 * Cart Summary Type.
 */
export type CartSummary = {
  __typename?: 'CartSummary';
  /** Summary of cart variations. */
  cart?: Maybe<Array<Maybe<CartSummarySingleVariation>>>;
  /** Cart ID */
  cart_id: Scalars['ID']['output'];
};

/** Cart Summary - Single variation details. */
export type CartSummarySingleVariation = {
  __typename?: 'CartSummarySingleVariation';
  /** Quantity */
  quantity: Scalars['Int']['output'];
  /** variant id of the product. */
  variation_id: Scalars['ID']['output'];
};

/** CatalogBrand Type. */
export type CatalogBrand = Brand & {
  __typename?: 'CatalogBrand';
  /** brand sale type either REGULAR or FLASH_SALE */
  brand_sale_type?: Maybe<BrandSaleType>;
  /** Designer brand flag */
  designer_brand: Scalars['Boolean']['output'];
  /** GoodOnYou pillars */
  good_on_you_pillars: Array<Scalars['String']['output']>;
  /** Brand id. */
  id: Scalars['ID']['output'];
  /** Is brand GoodOnYou */
  is_good_on_you: Scalars['Boolean']['output'];
  /** Brand name */
  name: Scalars['String']['output'];
};

/** CatelogCategory Type. Default type for category/catelog. */
export type CatalogCategory = {
  __typename?: 'CatalogCategory';
  /** ID of the category. */
  id: Scalars['ID']['output'];
  /** Name of the category. */
  name: Scalars['String']['output'];
  /** Parent id of the category */
  parent?: Maybe<Scalars['String']['output']>;
  /** Slug of the category. */
  slug: Scalars['String']['output'];
};

/** CatalogCategoryWithEnglishName Type. */
export type CatalogCategoryWithEnglishName = {
  __typename?: 'CatalogCategoryWithEnglishName';
  /** ID of the category. */
  id: Scalars['ID']['output'];
  /** Name of the category. */
  name: Scalars['String']['output'];
  /** Name of the category in english. */
  name_en: Scalars['String']['output'];
  /** Parent id of the category */
  parent?: Maybe<Scalars['String']['output']>;
  /** Slug of the category. */
  slug: Scalars['String']['output'];
};

/** CatalogProduct Type. */
export type CatalogProduct = Node & Product & {
  __typename?: 'CatalogProduct';
  /** Available size of the products. */
  available_sizes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Available size of the products as string. */
  available_sizes_label?: Maybe<Scalars['String']['output']>;
  /** Brand details of the product. */
  brand: CatalogBrand;
  /** Currency type: value changes based on language header */
  currency: ProductCurrency;
  /** Brand Gate */
  gate?: Maybe<BrandGate>;
  /** Product id. */
  id: Scalars['ID']['output'];
  /** Is product personalised */
  is_personalised?: Maybe<Scalars['Boolean']['output']>;
  /** Labels details of the products. */
  labels?: Maybe<Array<ProductLabel>>;
  /** Name of the product. */
  name: Scalars['String']['output'];
  /** Price of the product. If `Accept-Language` header is `en-uk` price valude is in GBP. else Euro. */
  price: Scalars['Float']['output'];
  /** Product discount */
  product_discount?: Maybe<Scalars['Float']['output']>;
  /** Product kids genders */
  product_kids_gender?: Maybe<Array<Scalars['String']['output']>>;
  /** Product shop type */
  product_shop_type?: Maybe<Array<Scalars['String']['output']>>;
  /** Regular price of the product. If `Accept-Language` header is `en-uk` price valude is in GBP. else Euro. */
  regular_price: Scalars['Float']['output'];
  /** Slug of the product. */
  slug: Scalars['String']['output'];
  /** Thumbnails of the product. */
  thumbnail: Scalars['String']['output'];
  /** Assigned category ids. */
  translated_category_ids?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Assigned Category names */
  translated_category_names?: Maybe<Array<Scalars['String']['output']>>;
  /** Product URL */
  url?: Maybe<Scalars['String']['output']>;
  /**
   * if product exist in user wishlist, return wishlist item ID of the user otherwise null.
   * @deprecated Decided to go with userWishlistMeta.
   */
  user_wishlist_item_id?: Maybe<Scalars['String']['output']>;
};

/** CatalogProductFilter Type. */
export type CatalogProductFilter = {
  /** Ages. List of ages slug. */
  ages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /**
   * Brand ids. CSV string support here.\
   * **deprecated - Use `brand_ids_list` field.**
   */
  brand_ids?: InputMaybe<Scalars['String']['input']>;
  /** Brand ids list as array. */
  brand_ids_list?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Category ids as array. */
  category_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /**
   * category_slug. Web work with category slug and tree building is slightly different than mobile. Providing categor_ slug as parameter
   * will change the nodes return from CategoryFilterOption
   */
  category_slug?: InputMaybe<Scalars['String']['input']>;
  /** Colors code array. Ex: `BLK`, `DNM` */
  colors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Use this filter to fitler products based on department. ex: `designer`, `popular` etc. */
  department?: InputMaybe<Scalars['String']['input']>;
  /** Exclude set of product ids from the result. */
  exclude_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Genders. Enum `men`, `women`, `kids` */
  genders?: InputMaybe<ProductGender>;
  /** Use this filter to filter products based on it's brands good on you flag */
  is_good_on_you?: InputMaybe<GoodOnYouFilter>;
  /** Label Enum. Ex: `NEW_IN`, `NEW_DISCOUNT`, `HOT_DEALS`, `LAST_CHANCE` */
  label?: InputMaybe<ProductLabelFilter>;
  /**
   * Languages. Either one of `nl`, `en`, `de`, `fr`, `en-uk`\
   * **deprecated - Use `Accept-Language` header to set language.**
   */
  languages?: InputMaybe<Scalars['String']['input']>;
  /** Max price. */
  max_price?: InputMaybe<Scalars['Float']['input']>;
  /** Min Price. */
  min_price?: InputMaybe<Scalars['Float']['input']>;
  /** Use this filter to filter products based on style. */
  occasions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Use this filter to filter products based on pattern. */
  patterns?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Sizes slug array. */
  sizes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** source_category_id param is introduced to handle category scope. It will affect availableFilters.categories only now. */
  source_category_id?: InputMaybe<Scalars['ID']['input']>;
  /** Use this filter to filter products based on style. */
  styles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type Categories = WithPosition & {
  __typename?: 'Categories';
  data: Array<CategoryData>;
  link: Scalars['String']['output'];
  position: Scalars['Int']['output'];
};

export type CategoryData = {
  __typename?: 'CategoryData';
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  thumbnail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** CategoryFilter Input. */
export type CategoryFilter = {
  /** Age ids array */
  age_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** List of brand ids. */
  brand_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** List of category ids. */
  category_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Number of categories to return. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Starting index of the category to return. */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Eg: popular */
  orderby?: InputMaybe<Scalars['String']['input']>;
  /** Price range */
  price_range?: InputMaybe<Scalars['String']['input']>;
  /** Shop type filter */
  shop_type?: InputMaybe<Scalars['String']['input']>;
  /** Size ids array input */
  size_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** user's id */
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** CategoryFilterOption Type. */
export type CategoryFilterOption = Node & {
  __typename?: 'CategoryFilterOption';
  /** Number of product available of this option. */
  count: Scalars['Int']['output'];
  /** Category id. */
  id: Scalars['ID']['output'];
  /** Category name. */
  name: Scalars['String']['output'];
  /** Parent id of the category. */
  parent?: Maybe<Scalars['String']['output']>;
  /**
   * Parent id of the category.
   * @deprecated Use "parent" field.
   */
  parent_id?: Maybe<Scalars['Int']['output']>;
  /** Slug of the category. */
  slug: Scalars['String']['output'];
};

export type CategoryFilterOptionOrder = {
  __typename?: 'CategoryFilterOptionOrder';
  /** Sort order of the category */
  order: Scalars['Float']['output'];
  /** Slug of the category */
  slug: Scalars['String']['output'];
};

/** Collection Interface. */
export type Collection = {
  /** Items. */
  items: Array<Maybe<Node>>;
  /** Total number. */
  total: Scalars['Int']['output'];
};

/** ColorFilterOptions Type. */
export type ColorFilterOption = Node & {
  __typename?: 'ColorFilterOption';
  /** Number of products under this color. */
  count: Scalars['Int']['output'];
  /** Hex color code. */
  hex?: Maybe<Scalars['String']['output']>;
  /** Icon of the color if exist. */
  icon?: Maybe<Scalars['String']['output']>;
  /** id of the color. */
  id: Scalars['ID']['output'];
  /** Name of the color. */
  name: Scalars['String']['output'];
};

/** CommercialSettings of the vendor */
export type CommercialSettings = {
  __typename?: 'CommercialSettings';
  /** Active CommercialSettings of the vendor. It will return null if there are no any value default or temporary setup yet */
  active: ActiveCommercialSettings;
  /** Default CommercialSettings of the vendor. It will return null if there is no value setup yet */
  default: DefaultCommercialSettings;
  /** Temporary CommercialSettings of the vendor. It will return null if there is no value setup yet */
  temporary?: Maybe<TemporaryCommercialSettings>;
};

export type CommissionModel = FlatCommissionModel | TieredItemPriceCommissionModel | TieredRevenueCommissionModel;

/** To make sure that any commission model has a type and a specification defined */
export type CommissionModelI = {
  specification: CommissionModelSpecification;
  type: Scalars['String']['output'];
};

/** Possible types of commission models */
export enum CommissionModelInputType {
  Flat = 'FLAT',
  TieredItemPrice = 'TIERED_ITEM_PRICE',
  TieredRevenue = 'TIERED_REVENUE'
}

export type CommissionModelSpecification = {
  rates: Array<RateSpecification>;
};

export type CommissionSpecification = {
  __typename?: 'CommissionSpecification';
  bracket_total: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  max_amount?: Maybe<Scalars['Float']['output']>;
  min_amount: Scalars['Float']['output'];
  rate: Scalars['Float']['output'];
  updated_at: Scalars['String']['output'];
  vendor_configuration_id: Scalars['ID']['output'];
};

export type CommissionStructureBracket = {
  __typename?: 'CommissionStructureBracket';
  bracket_total: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  payment_specification_id: Scalars['ID']['output'];
  rate: Scalars['Float']['output'];
  updated_at: Scalars['String']['output'];
};

export type CompleteMultipartUploadInput = {
  /** Name of the file that has been uploaded. */
  file_name: Scalars['String']['input'];
  /** Otrium brand UUID */
  ot_brand_id?: InputMaybe<Scalars['String']['input']>;
  /** The array of completed parts that have been uploaded. */
  parts?: InputMaybe<Array<CompletedPart>>;
  /** The upload id of the file that has been uploaded. */
  upload_id: Scalars['String']['input'];
  /** Deprecated - Use ot_brand_id instead */
  vendor_id?: InputMaybe<Scalars['String']['input']>;
};

export type CompleteMultipartUploadResult = {
  __typename?: 'CompleteMultipartUploadResult';
  /** The result of the action. */
  result: Scalars['String']['output'];
};

export type CompletedPart = {
  /** Entity tag returned when the part was uploaded. */
  e_tag: Scalars['String']['input'];
  /** Part number that identifies the part. This is a positive integer between 1 and 10,000. */
  part_number: Scalars['Int']['input'];
};

export type ConsciousPage = {
  __typename?: 'ConsciousPage';
  brands?: Maybe<Brands>;
  cards?: Maybe<Array<GenericCardsSection>>;
  meetBrand?: Maybe<MeetBrand>;
  productBlocks?: Maybe<Array<ProductBlocks>>;
  promotionBanners?: Maybe<PromotionBannerSection>;
};

/** DesignerPageFilter Input. */
export type ConsciousPageFilter = {
  /** product limit */
  product_limit: Scalars['Int']['input'];
};

export type CountryData = {
  __typename?: 'CountryData';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Country Domain Type */
export type CountryDomain = {
  __typename?: 'CountryDomain';
  /** Country domain id */
  country_domain_id: Scalars['ID']['output'];
  /** Country name */
  country_name: Scalars['String']['output'];
  /** Country domain created at */
  created_at: CountryDomainCreatedAt;
  /** Domain name */
  domain_name: Scalars['String']['output'];
  /** Country domain updated at */
  updated_at?: Maybe<CountryDomainUpdatedAt>;
};

/** Country Domain created at type */
export type CountryDomainCreatedAt = Timestamp & {
  __typename?: 'CountryDomainCreatedAt';
  date: Scalars['String']['output'];
};

/** Country Domain updated at type */
export type CountryDomainUpdatedAt = Timestamp & {
  __typename?: 'CountryDomainUpdatedAt';
  date?: Maybe<Scalars['String']['output']>;
};

/** Input of the createAdhocSubInvoice mutation */
export type CreateAdhocSubInvoiceInput = {
  /** Manual note amount in EUR */
  amount?: InputMaybe<Scalars['Float']['input']>;
  /** Description of the manual note reason */
  description: Scalars['String']['input'];
  /** Id of the invoice to create a manual note for */
  id: Scalars['String']['input'];
  /** Type of invoice that will be created */
  invoiceType: InvoiceType;
  /** List of previous child invoice IDs to correct */
  selectedInvoiceIds: Array<Scalars['String']['input']>;
  transactionType: AdhocSubInvoiceType;
};

/** Result of the createAdhocSubInvoice mutation */
export type CreateAdhocSubInvoiceResult = {
  __typename?: 'CreateAdhocSubInvoiceResult';
  /** Response code. */
  code: Scalars['String']['output'];
  /** Response message. */
  message: Scalars['String']['output'];
};

/** Input of the createAmendments mutation */
export type CreateAmendmentInput = {
  /** Amendment amount in EUR */
  amount: Scalars['Float']['input'];
  /** Description of the amendment */
  description: Scalars['String']['input'];
  /** Id of the invoice to create an amendment for */
  id: Scalars['String']['input'];
};

/** Result of the createAmendments mutation */
export type CreateAmendmentsResult = {
  __typename?: 'CreateAmendmentsResult';
  /** Response code. */
  code: Scalars['String']['output'];
  /** Response message. */
  message: Scalars['String']['output'];
};

export type CreateBrandInput = {
  /** Brand name */
  brand_name: Scalars['String']['input'];
  /** Shop display name */
  shop_display_name: Scalars['String']['input'];
};

export type CreateBrandMutationResult = {
  __typename?: 'CreateBrandMutationResult';
  /** Response status. */
  status?: Maybe<Scalars['String']['output']>;
  /** Response code. */
  status_code?: Maybe<Scalars['String']['output']>;
};

/** The different type of currencies in which the vendors can get invoiced. */
export enum Currency {
  Eur = 'EUR',
  Gbp = 'GBP',
  Pln = 'PLN',
  Usd = 'USD'
}

/** Dam about popup */
export type DamAboutPopupImage = BrandImage & {
  __typename?: 'DamAboutPopupImage';
  /** About popup image source */
  source?: Maybe<Scalars['String']['output']>;
  /** About popup image URL */
  url: Scalars['String']['output'];
};

/** Dam about popup input */
export type DamAboutPopupImageInput = {
  /** About popup image source */
  source?: InputMaybe<Scalars['String']['input']>;
  /** About popup image URL */
  url: Scalars['String']['input'];
};

export type DamAssignedProductAttribute = {
  __typename?: 'DamAssignedProductAttribute';
  /** Attribute id */
  attribute_id: Scalars['Int']['output'];
  /** Attribute name */
  attribute_name: Scalars['String']['output'];
  /** Attribute value */
  attribute_value?: Maybe<Scalars['String']['output']>;
  /** id */
  id: Scalars['Int']['output'];
  /** probability */
  probability?: Maybe<Scalars['Float']['output']>;
  /** state */
  state?: Maybe<Scalars['String']['output']>;
};

/** Default type of the Dam brand instance */
export type DamBrand = {
  __typename?: 'DamBrand';
  /** about brand text per geo */
  about_brand_texts: Array<DamBrandAboutBrandText>;
  /** brand about popup image */
  about_popup_image?: Maybe<DamAboutPopupImage>;
  /** archived */
  archived?: Maybe<Scalars['Boolean']['output']>;
  /** Brand categories */
  brand_categories: Array<DamBrandCategory>;
  /** brand name */
  brand_name: Scalars['String']['output'];
  /** Brand Sale type either REGULAR or FLASH_SALE */
  brand_sale_type: BrandSaleType;
  /** brand slug */
  brand_slug?: Maybe<Scalars['String']['output']>;
  /** churned */
  churned?: Maybe<Scalars['Boolean']['output']>;
  /** brand cover images */
  cover_images: Array<DamBrandCoverImage>;
  /** Brand descriptions */
  description: Array<DamBrandDescription>;
  /** Brand discount labels */
  discount_label: Array<DamBrandDiscountLabel>;
  gate_level: GateLevel;
  /**
   * brand hidden domains
   * deprecated
   * @deprecated Use hidden_stores instead
   */
  hidden_domains: Array<Scalars['String']['output']>;
  /** brand hidden stores */
  hidden_stores: Array<Scalars['String']['output']>;
  /** Dam internal system id */
  id: Scalars['String']['output'];
  /** brand regular images */
  images: Array<DamBrandImage>;
  /** brand logo image */
  logo_image?: Maybe<DamBrandLogoImage>;
  /** Otrium brand UUID */
  ot_brand_id?: Maybe<Scalars['String']['output']>;
  /** otrium brand id */
  otrium_brand_id?: Maybe<Scalars['String']['output']>;
  /** brand overlay logo images */
  overlay_logo_images: Array<DamBrandOverlayLogoImage>;
  /** Product categories */
  product_categories: Array<DamCategory>;
  /** shop type kids */
  shop_type_kids?: Maybe<Scalars['Boolean']['output']>;
  /** shop type men */
  shop_type_men?: Maybe<Scalars['Boolean']['output']>;
  /** shop type women */
  shop_type_women?: Maybe<Scalars['Boolean']['output']>;
  /** size chart text per gender */
  size_chart_texts: Array<DamBrandSizeChartText>;
  /** Brand store publish details */
  store_publish_details: Array<DamBrandStorePublishDetails>;
  /** woo brand id */
  woo_brand_id?: Maybe<Scalars['Int']['output']>;
};

/** Dam brand about text */
export type DamBrandAboutBrandText = {
  __typename?: 'DamBrandAboutBrandText';
  /** brand about brand geo */
  geo: Scalars['String']['output'];
  /** brand about brand text */
  text?: Maybe<Scalars['String']['output']>;
};

/** Input parameters of the Dam brand update mutation */
export type DamBrandAboutBrandTextInput = {
  /** brand about brand geo */
  geo: Scalars['String']['input'];
  /** brand about brand text */
  text?: InputMaybe<Scalars['String']['input']>;
};

/** Default type of the Dam brand category instance */
export type DamBrandCategory = {
  __typename?: 'DamBrandCategory';
  /** Dam internal uuid */
  id: Scalars['String']['output'];
  /** brand category name */
  name: Scalars['String']['output'];
};

/** Result of brand category list request */
export type DamBrandCategoryList = {
  __typename?: 'DamBrandCategoryList';
  brand_categories: Array<DamBrandCategory>;
};

/** Dam brand cover image */
export type DamBrandCoverImage = BrandImage & {
  __typename?: 'DamBrandCoverImage';
  /** Brand cover image shop type */
  shop_type: Scalars['String']['output'];
  /** Brand cover image source */
  source?: Maybe<Scalars['String']['output']>;
  /** Brand cover image store_key */
  store_key: Scalars['String']['output'];
  /** Brand cover image URL */
  url: Scalars['String']['output'];
};

/** Dam brand cover image input */
export type DamBrandCoverImageInput = {
  /** Brand cover image shop type */
  shop_type: Scalars['String']['input'];
  /** Brand cover image source */
  source?: InputMaybe<Scalars['String']['input']>;
  /** Brand cover image store key */
  store_key: Scalars['String']['input'];
  /** Brand cover image URL */
  url: Scalars['String']['input'];
};

/** Dam brand update description */
export type DamBrandDescription = {
  __typename?: 'DamBrandDescription';
  /** brand description language code - en,nl */
  lang_code: Scalars['String']['output'];
  /** brand description text */
  text?: Maybe<Scalars['String']['output']>;
};

/** Input parameters of the Dam brand update mutation */
export type DamBrandDescriptionInput = {
  /** brand description language code - en,nl */
  lang_code: Scalars['String']['input'];
  /** brand description text */
  text?: InputMaybe<Scalars['String']['input']>;
};

/** Dam brand discount label */
export type DamBrandDiscountLabel = {
  __typename?: 'DamBrandDiscountLabel';
  /** brand discount label geo */
  gender: Scalars['String']['output'];
  /** brand discount label text */
  text?: Maybe<Scalars['String']['output']>;
};

/** Input parameters of the Dam brand update mutation */
export type DamBrandDiscountLabelInput = {
  /** brand discount label geo */
  gender: Scalars['String']['input'];
  /** brand discount label text */
  text?: InputMaybe<Scalars['String']['input']>;
};

/** Dam brand image */
export type DamBrandImage = BrandImage & {
  __typename?: 'DamBrandImage';
  /** Brand image device */
  device: Scalars['String']['output'];
  /** Brand image shop type */
  shop_type: Scalars['String']['output'];
  /** Brand image source */
  source?: Maybe<Scalars['String']['output']>;
  /** Brand image store_key */
  store_key: Scalars['String']['output'];
  /** Brand image URL */
  url: Scalars['String']['output'];
};

/** Dam brand image input */
export type DamBrandImageInput = {
  /** Brand image device */
  device: Scalars['String']['input'];
  /** Brand image shop type */
  shop_type: Scalars['String']['input'];
  /** Brand image source */
  source?: InputMaybe<Scalars['String']['input']>;
  /** Brand image store key */
  store_key: Scalars['String']['input'];
  /** Brand image URL */
  url: Scalars['String']['input'];
};

/** Input parameters of the Dam Product images updating mutation */
export type DamBrandImagesUpdateInput = {
  /** Brand about popup object */
  about_popup_image?: InputMaybe<DamAboutPopupImageInput>;
  /** Brand images */
  brand_images?: InputMaybe<Array<DamBrandImageInput>>;
  /** Brand cover images */
  cover_images?: InputMaybe<Array<DamBrandCoverImageInput>>;
  /** Brand logo object */
  logo_image?: InputMaybe<DamBrandLogoImageInput>;
  /** Brand overlay logos */
  overlay_logo_images?: InputMaybe<Array<DamBrandOverlayLogoImageInput>>;
};

/** Result of brand images updating request */
export type DamBrandImagesUpdateResult = {
  __typename?: 'DamBrandImagesUpdateResult';
  message: Scalars['String']['output'];
};

/** Dam brand logo */
export type DamBrandLogoImage = BrandImage & {
  __typename?: 'DamBrandLogoImage';
  /** Logo image source */
  source?: Maybe<Scalars['String']['output']>;
  /** Logo image URL */
  url: Scalars['String']['output'];
};

/** Dam brand logo input */
export type DamBrandLogoImageInput = {
  /** Logo image source */
  source?: InputMaybe<Scalars['String']['input']>;
  /** Logo image URL */
  url: Scalars['String']['input'];
};

/** Dam brand overlay logo */
export type DamBrandOverlayLogoImage = BrandImage & {
  __typename?: 'DamBrandOverlayLogoImage';
  /** Logo image size */
  size: Scalars['String']['output'];
  /** Logo image source */
  source?: Maybe<Scalars['String']['output']>;
  /** Logo image URL */
  url: Scalars['String']['output'];
};

/** Dam brand overlay logo input */
export type DamBrandOverlayLogoImageInput = {
  /** Logo image size */
  size: Scalars['String']['input'];
  /** Logo image source */
  source?: InputMaybe<Scalars['String']['input']>;
  /** Logo image URL */
  url: Scalars['String']['input'];
};

/** Input parameters of the Dam Brand Search Query */
export type DamBrandSearchInput = {
  /** limit */
  limit: Scalars['Int']['input'];
  /** offset */
  offset: Scalars['Int']['input'];
  /** Dam internal brand Id */
  search_value?: InputMaybe<Scalars['String']['input']>;
};

/** Result of brand search request */
export type DamBrandSearchResult = {
  __typename?: 'DamBrandSearchResult';
  /** Number of brands available for the search */
  brand_count: Scalars['Int']['output'];
  /** Dam brands */
  brands: Array<DamBrand>;
};

/** Dam brand size chart text */
export type DamBrandSizeChartText = {
  __typename?: 'DamBrandSizeChartText';
  /** brand size chart geo */
  gender: Scalars['String']['output'];
  /** brand size chart text */
  text?: Maybe<Scalars['String']['output']>;
};

/** Input parameters of the Dam brand update mutation */
export type DamBrandSizeChartTextInput = {
  /** brand size chart geo */
  gender: Scalars['String']['input'];
  /** brand size chart text */
  text?: InputMaybe<Scalars['String']['input']>;
};

/** Dam store publish details */
export type DamBrandStorePublishDetails = {
  __typename?: 'DamBrandStorePublishDetails';
  /** Publish date */
  publish_date?: Maybe<Scalars['String']['output']>;
  /** Store key */
  store_key: Scalars['String']['output'];
};

/** Input parameters of the Dam store publish details */
export type DamBrandStorePublishDetailsInput = {
  /** Publish date */
  publish_date?: InputMaybe<Scalars['String']['input']>;
  /** Store key */
  store_key: Scalars['String']['input'];
};

/** Default type of the Dam category instance */
export type DamCategory = {
  __typename?: 'DamCategory';
  /** Dam internal id */
  id: Scalars['String']['output'];
  /** whether the category a part of brand categories */
  is_brand_category: Scalars['Boolean']['output'];
  /** category name */
  name?: Maybe<Scalars['String']['output']>;
  /** parent category ID */
  parent_category_id?: Maybe<Scalars['String']['output']>;
  /** category slug */
  slug?: Maybe<Scalars['String']['output']>;
  /** translations */
  translations?: Maybe<Array<Maybe<DamCategoryTranslation>>>;
  /** woo category id */
  woo_category_id?: Maybe<Scalars['Int']['output']>;
};

/** Default type of the Dam brand instance */
export type DamCategoryTranslation = {
  __typename?: 'DamCategoryTranslation';
  /** Dam System id */
  id: Scalars['String']['output'];
  /** locale */
  locale?: Maybe<Scalars['String']['output']>;
  /** category name */
  name?: Maybe<Scalars['String']['output']>;
  /** translated category slug */
  slug?: Maybe<Scalars['String']['output']>;
};

/** Results of dam defect report query */
export type DamDefectReportData = {
  __typename?: 'DamDefectReportData';
  /** dam defect report data */
  data?: Maybe<Scalars['String']['output']>;
};

/** Input parameters of the Dam defect report */
export type DamDefectReportInput = {
  /** drop */
  drop: Scalars['String']['input'];
};

/**
 * Inputs will get convert to the following format
 * {"banCode":"egcode", "originalVisualName":"xxxxxxxx.jpeg","uploadSource":"pipeline"}
 */
export type DamFinalVisualUploadUrlInput = {
  /** Ban code of the product @deprecated(reason: "Use product_uuid instead") */
  ban_code?: InputMaybe<Scalars['String']['input']>;
  /** Brand UUID of the product  */
  brand_uuid: Scalars['String']['input'];
  /** Name of the file to be uploaded. */
  file_name: Scalars['String']['input'];
  /** Product UUID of the product */
  product_uuid: Scalars['String']['input'];
  /** Upload source */
  upload_source: Scalars['String']['input'];
};

/** Response type of the dam visual upload URL request */
export type DamFinalVisualUploadUrlResponse = {
  __typename?: 'DamFinalVisualUploadUrlResponse';
  /** New visual Name including the extension */
  new_visual_name_with_extension: Scalars['String']['output'];
  /** Signed URL to upload */
  signed_url: Scalars['String']['output'];
  /** Visual URL (Eg: https://lookbook-staging.otrium.com/wp-content/uploads/2022/12/abc.jpg */
  visual_url: Scalars['String']['output'];
};

/**
 * Inputs will get convert to the following format
 * {"brandUUID":"egUUDID", "originalVisualName":"xxxxxxxx.jpeg","uploadSource":"pipeline", "visualType": "CROPPED"}
 */
export type DamManualVisualUploadUrlInput = {
  /** Brand UUID of the product */
  brand_uuid: Scalars['String']['input'];
  /** drop no */
  drop_no?: InputMaybe<Scalars['String']['input']>;
  /** Name of the file to be uploaded. */
  file_name: Scalars['String']['input'];
  /** Upload source */
  upload_source: Scalars['String']['input'];
  /** Visual type */
  visual_type: Scalars['String']['input'];
};

/** Response type of the dam original/manual upload URL request */
export type DamManualVisualUploadUrlResponse = {
  __typename?: 'DamManualVisualUploadUrlResponse';
  /** Signed URL to upload */
  signed_url: Scalars['String']['output'];
};

/** Mapped Size Object in Dam */
export type DamMappedSize = {
  __typename?: 'DamMappedSize';
  /** Mapped Size ID - UUID */
  id: Scalars['ID']['output'];
  /** Mapped size title/name */
  mapped_size: Scalars['String']['output'];
  /** Mapped size slug */
  mapped_size_slug: Scalars['String']['output'];
};

/** Dam Mapped Size Mapping type which holds the Otrium size mapping configuration */
export type DamMappedSizeMapping = {
  __typename?: 'DamMappedSizeMapping';
  /** Mapped size mapping ID - UUID */
  id: Scalars['ID']['output'];
  /** Mapped lowest product category ID */
  lowest_category_node: Scalars['String']['output'];
  /** Mapped Size UUID */
  mapped_size_id: Scalars['String']['output'];
  /** size schema slug */
  size_schema_slug: Scalars['String']['output'];
  /** size slug */
  size_slug: Scalars['String']['output'];
};

/** Input parameters of Dam Mapped Size Mapping query */
export type DamMappedSizeMappingInput = {
  /** Number of rows to fetch */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Mapped lowest product category ID */
  lowest_category_node?: InputMaybe<Scalars['String']['input']>;
  /** Mapped Size UUID */
  mapped_size_id?: InputMaybe<Scalars['String']['input']>;
  /** Number of rows to skip */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** size schema slug */
  size_schema_slug?: InputMaybe<Scalars['String']['input']>;
  /** size slug */
  size_slug?: InputMaybe<Scalars['String']['input']>;
};

/** Default type of the Dam product instance */
export type DamProduct = {
  __typename?: 'DamProduct';
  /** Product ban */
  ban?: Maybe<Scalars['String']['output']>;
  /** Brand instance from DAM */
  brand?: Maybe<DamBrand>;
  /** Product categories */
  categories?: Maybe<Array<DamCategory>>;
  /** string contains the hierarchy of categories excluding the root category */
  category_string?: Maybe<Scalars['String']['output']>;
  /** Product color code */
  color_code?: Maybe<Scalars['String']['output']>;
  /** Product color name */
  color_name?: Maybe<Scalars['String']['output']>;
  /** defect id (dam internal id) */
  defect_status?: Maybe<DefectStatus>;
  /** Product description */
  description?: Maybe<Scalars['String']['output']>;
  /** Product drop */
  drop?: Maybe<Scalars['String']['output']>;
  /** Product ean codes */
  eans?: Maybe<Array<Scalars['String']['output']>>;
  /** Product filter color */
  filter_color?: Maybe<Scalars['String']['output']>;
  /** Product gender */
  gender?: Maybe<Scalars['String']['output']>;
  /** Dam System id */
  id: Scalars['String']['output'];
  /** Product's attribute pipeline status */
  is_pipeline_attribute_done?: Maybe<Scalars['Boolean']['output']>;
  /** Product's category pipeline status */
  is_pipeline_category_done?: Maybe<Scalars['Boolean']['output']>;
  /** Product's QA pipeline status */
  is_pipeline_qa_done?: Maybe<Scalars['Boolean']['output']>;
  /** Product's visual pipeline status */
  is_pipeline_visual_done?: Maybe<Scalars['Boolean']['output']>;
  /** Otrium product UUID */
  ot_product_id: Scalars['String']['output'];
  /** Product product status */
  product_status?: Maybe<Scalars['String']['output']>;
  /** Product season */
  season?: Maybe<Scalars['String']['output']>;
  /** Product sku */
  sku?: Maybe<Scalars['String']['output']>;
  /** Product slug */
  slug?: Maybe<Scalars['String']['output']>;
  /** Product title / name */
  title?: Maybe<Scalars['String']['output']>;
  /** Product translations */
  translations?: Maybe<Array<DamProductTranslation>>;
  /** Product variants */
  variants?: Maybe<Array<DamProductVariant>>;
  /** Vendor instance from DAM */
  vendor?: Maybe<DamVendor>;
  /** Product visuals */
  visuals?: Maybe<Array<DamProductVisual>>;
  /** Woo Product id */
  woo_product_id?: Maybe<Scalars['Int']['output']>;
};

/** single dam product attibute */
export type DamProductAttribute = {
  __typename?: 'DamProductAttribute';
  /** attribute id from oware */
  attribute_id: Scalars['Int']['output'];
  /** attribute name */
  attribute_name: Scalars['String']['output'];
  /** attribute values */
  attribute_values?: Maybe<Array<DamProductAttributeValue>>;
};

/** single attribute value object */
export type DamProductAttributeValue = {
  __typename?: 'DamProductAttributeValue';
  /** value id */
  id: Scalars['Int']['output'];
  /** attribute value title */
  value: Scalars['String']['output'];
};

/** Results of dam product attributes list query */
export type DamProductAttributesList = {
  __typename?: 'DamProductAttributesList';
  /** attributes array list */
  attributes: Array<DamProductAttribute>;
};

/** Input parameters of the product category list Query */
export type DamProductCategoryListInput = {
  /** is brand category */
  is_brand_category?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Result of product search request */
export type DamProductSearch = {
  __typename?: 'DamProductSearch';
  products: Array<DamProduct>;
};

/** Input parameters of the Dam Product Search Query */
export type DamProductSearchInput = {
  /** Product ban code */
  ban?: InputMaybe<Scalars['String']['input']>;
  /** Product drop no */
  drop_no?: InputMaybe<Scalars['String']['input']>;
  /** Product ean code */
  ean_code?: InputMaybe<Scalars['String']['input']>;
  /** Search limit */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Search offset */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Product Otrium UUID */
  ot_product_id?: InputMaybe<Scalars['String']['input']>;
  /** Product ID of the product(Dam system id) */
  product_id?: InputMaybe<Scalars['String']['input']>;
  /** Product name */
  product_name?: InputMaybe<Scalars['String']['input']>;
  /** Product slug */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** woo product id */
  woo_product_id?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * deprecated(reason: "Feature has been removed and replaced with size mapping.")
 * Results of size mapping retrieving query
 */
export type DamProductSizeMappingData = {
  __typename?: 'DamProductSizeMappingData';
  container_size?: Maybe<Scalars['String']['output']>;
  container_size_slug?: Maybe<Scalars['String']['output']>;
  size: Scalars['String']['output'];
  size_slug: Scalars['String']['output'];
};

/**
 * deprecated(reason: "Feature has been removed and replaced with size mapping.")
 * Results of size mapping upload query
 */
export type DamProductSizeMappingFileSignedUrlData = {
  __typename?: 'DamProductSizeMappingFileSignedUrlData';
  /** File name to upload */
  file_name: Scalars['String']['output'];
  /** Signed url to upload the file */
  signed_url: Scalars['String']['output'];
};

/**
 * deprecated(reason: "Feature has been removed and replaced with size mapping.")
 * Result type of damProductSizeMappingFileUpload mutation
 */
export type DamProductSizeMappingFileUploadResult = {
  __typename?: 'DamProductSizeMappingFileUploadResult';
  /** success message */
  message: Scalars['String']['output'];
};

/** Results of size schema query */
export type DamProductSizeSchemaList = {
  __typename?: 'DamProductSizeSchemaList';
  /** Size schema name */
  name: Scalars['String']['output'];
  /** slug of the size schema name */
  slug?: Maybe<Scalars['String']['output']>;
};

/** Response type of the dam prodct status query */
export type DamProductStatus = {
  __typename?: 'DamProductStatus';
  /** Details of the product */
  details: DamProductStatusDetails;
  /** Valid status :true/false */
  isValid: Scalars['Boolean']['output'];
  /** URL to update the product */
  url: Scalars['String']['output'];
};

export type DamProductStatusDetails = {
  __typename?: 'DamProductStatusDetails';
  /** Categories count */
  categoriesCount: Scalars['Int']['output'];
  /** Defect reason */
  defectReason?: Maybe<Scalars['String']['output']>;
  /** Gender type */
  gender?: Maybe<Scalars['String']['output']>;
  /** Is brand published on given domain */
  isBrandPublished: Scalars['Boolean']['output'];
  /** Is in defect */
  isDefect: Scalars['Boolean']['output'];
  /** Visuals count */
  visualsCount: Scalars['Int']['output'];
};

/** Default type of the Dam product translation instance */
export type DamProductTranslation = {
  __typename?: 'DamProductTranslation';
  /** Dam Product care description */
  care_instructions_description?: Maybe<Scalars['String']['output']>;
  /** Dam Product description */
  description?: Maybe<Scalars['String']['output']>;
  /** Dam System id */
  id: Scalars['String']['output'];
  /** Dam Product translation locale */
  locale?: Maybe<Scalars['String']['output']>;
  /** Dam Product material description */
  material_description?: Maybe<Scalars['String']['output']>;
  /** Dam Product name */
  name?: Maybe<Scalars['String']['output']>;
  /** Dam Product size and fit description description */
  size_and_fit_description?: Maybe<Scalars['String']['output']>;
};

/** Default type of the Dam product translation input */
export type DamProductTranslationInput = {
  /** Dam Product care description */
  care_instructions_description?: InputMaybe<Scalars['String']['input']>;
  /** Dam Product description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Dam Product translation locale */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Dam Product material description */
  material_description?: InputMaybe<Scalars['String']['input']>;
  /** Dam Product name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Dam Product size and fit description description */
  size_and_fit_description?: InputMaybe<Scalars['String']['input']>;
};

export type DamProductVariant = {
  __typename?: 'DamProductVariant';
  /** Dam Product variant color */
  color?: Maybe<DamProductVariantColor>;
  /** Dam Product variant ean */
  ean?: Maybe<Scalars['String']['output']>;
  /** Dam Product variant size */
  size?: Maybe<DamProductVariantSize>;
  size_mappings: Array<Maybe<DamSizeMapping>>;
  /** Dam Product variant sku */
  sku?: Maybe<Scalars['String']['output']>;
};

export type DamProductVariantColor = {
  __typename?: 'DamProductVariantColor';
  /** Variant color's id */
  id: Scalars['String']['output'];
  /** Variant color's oware id */
  oware_id?: Maybe<Scalars['Int']['output']>;
  /** Variant color's value */
  value: Scalars['String']['output'];
};

export type DamProductVariantSize = {
  __typename?: 'DamProductVariantSize';
  /** Variant size's id */
  id: Scalars['String']['output'];
  /** Variant size's oware id */
  oware_id?: Maybe<Scalars['Int']['output']>;
  /** Variant size's value */
  value: Scalars['String']['output'];
};

/** Default type of the Dam product visual instance */
export type DamProductVisual = {
  __typename?: 'DamProductVisual';
  /** Dam System id */
  id: Scalars['String']['output'];
  /** order/sort */
  order: Scalars['Int']['output'];
  /** Visual URL */
  url: Scalars['String']['output'];
};

/** Type for Dam visual source */
export type DamProductVisualSource = {
  __typename?: 'DamProductVisualSource';
  /** Percentage value in float */
  percentage: Scalars['Float']['output'];
  /** type of the source eg: fashion-cloud */
  type: Scalars['String']['output'];
};

/** Input parameters of the Dam drop publish mutation */
export type DamPublishDropInput = {
  /** Drop no */
  drop?: InputMaybe<Scalars['String']['input']>;
  /** product_ids exist in QA pipeline */
  product_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /**
   * @deprecated (We removed an option for DAM to publish products)
   * publish date (This can be null if hte date is intended to set manually)
   */
  publish_date?: InputMaybe<Scalars['String']['input']>;
};

/** Result type of damPublishDrop mutation */
export type DamPublishDropResult = {
  __typename?: 'DamPublishDropResult';
  /** success message */
  message: Scalars['String']['output'];
};

/** Input parameters of the Dam single product attributes Query */
export type DamSingleProductAttributesInput = {
  /** Product ID of the product(Dam system id) */
  product_id?: InputMaybe<Scalars['String']['input']>;
};

/** Results of dam single product attributes list query */
export type DamSingleProductAttributesList = {
  __typename?: 'DamSingleProductAttributesList';
  /** attributes array list */
  attributes: Array<DamAssignedProductAttribute>;
};

/** Dam Size Mapping type which holds the Otrium size mapping configuration */
export type DamSizeMapping = {
  __typename?: 'DamSizeMapping';
  /** size mapping ID - UUID */
  id: Scalars['ID']['output'];
  /** Mapped lowest product category ID */
  lowest_category_node: Scalars['String']['output'];
  /** Mapped Size Object */
  mapped_size: DamMappedSize;
  /**
   * size schema slug
   * Eg: EU
   */
  size_schema_slug: Scalars['String']['output'];
  /** basic size slug */
  size_slug: Scalars['String']['output'];
};

/**
 * deprecated(reason: "Feature has been removed and replaced with size mapping.")
 * Results of dam size mapping report query
 */
export type DamSizeMappingReportData = {
  __typename?: 'DamSizeMappingReportData';
  /** dam size mapping report data */
  data?: Maybe<Scalars['String']['output']>;
};

export type DamSizeMappingReportUrlData = {
  __typename?: 'DamSizeMappingReportUrlData';
  /** dam size mapping report url */
  data?: Maybe<Scalars['String']['output']>;
};

export type DamUpdateProductAttributesInput = {
  /** Attibutes list as an array */
  attributes?: InputMaybe<Array<InputMaybe<DamUpdateProductAttributesSingleType>>>;
  /** product id (DAM internal id) */
  product_id: Scalars['String']['input'];
};

/** Result type of DamUpdateProductAttributes mutation */
export type DamUpdateProductAttributesResult = {
  __typename?: 'DamUpdateProductAttributesResult';
  /** success message */
  message: Scalars['String']['output'];
};

/** Single attribute input of dam update product attibutes mutation */
export type DamUpdateProductAttributesSingleType = {
  /** attribute id */
  attribute_id: Scalars['Int']['input'];
  /** attribute value id */
  attribute_value_id: Scalars['Int']['input'];
};

/** Default type of the Dam vendor instance */
export type DamVendor = {
  __typename?: 'DamVendor';
  /** Dam System id */
  id: Scalars['String']['output'];
  /** vendor name */
  vendor_name?: Maybe<Scalars['String']['output']>;
  /** woo vendor id */
  woo_vendor_id?: Maybe<Scalars['Int']['output']>;
};

export type DamVisualUploadUrlInput = {
  /** Name of the file to be uploaded. */
  file_name: Scalars['String']['input'];
};

export type DatesResult = {
  __typename?: 'DatesResult';
  /** date */
  date?: Maybe<Scalars['String']['output']>;
  /** timezone */
  timezone?: Maybe<Scalars['String']['output']>;
  /** timezone_type */
  timezone_type?: Maybe<Scalars['Int']['output']>;
};

/** DealBidding type, contains the bidding information of a deal */
export type DealBidding = {
  __typename?: 'DealBidding';
  comments?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  price_bid?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  user_name?: Maybe<Scalars['String']['output']>;
  vendor_id: Scalars['String']['output'];
  vendor_name: Scalars['String']['output'];
};

export type DefaultCommercialSettings = {
  __typename?: 'DefaultCommercialSettings';
  /** Default commission model. Can be null until it is set */
  commission_model?: Maybe<CommissionModel>;
  /** Default fulfillment services. Can be null until it is set */
  fulfillment_services?: Maybe<FulfillmentServicesModel>;
  /** Default promotional discount (or the Platform Discount). Can't be null. It is set to disable at vendor creation */
  promotional_discount: VendorPromotionalDiscountModel;
  /** Default value added services. Can be null until it is set */
  value_added_services?: Maybe<VendorDefaultValueAddedServicesModel>;
};

/** type of the defect status */
export type DefectStatus = {
  __typename?: 'DefectStatus';
  /** defect status System id */
  id: Scalars['String']['output'];
};

export type DeliveryOptionsContent = {
  __typename?: 'DeliveryOptionsContent';
  delivery_services?: Maybe<Array<DeliveryServices>>;
};

export type DeliveryServices = {
  __typename?: 'DeliveryServices';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  pudos?: Maybe<Array<PudosData>>;
  title: Scalars['String']['output'];
};

/**
 * Input fields for the request
 * gender : "Gender type."
 */
export type DesignerCategoriesFilter = {
  gender: Scalars['String']['input'];
};

/** Designer category type */
export type DesignerCategory = {
  __typename?: 'DesignerCategory';
  /** Curated or not */
  curated: Scalars['Boolean']['output'];
  /** Category id - cat_id in rest API */
  id: Scalars['ID']['output'];
  /** Category name - title in rest API */
  name: Scalars['String']['output'];
  /** Category Slug */
  slug: Scalars['String']['output'];
  /** Category thumbnail */
  thumbnail: Scalars['String']['output'];
  /** Category URL */
  url: Scalars['String']['output'];
};

export type DesignerHomepage = {
  __typename?: 'DesignerHomepage';
  brands?: Maybe<Brands>;
  cards?: Maybe<Array<CardsSection>>;
  categories?: Maybe<Categories>;
  /** @deprecated Use the `promotionBanners` field. */
  header?: Maybe<Header>;
  /** @deprecated Use the `promotionBanners` field. */
  heroSlider?: Maybe<HeroSlider>;
  /** @deprecated Use `cards` type. */
  inspirationalSections?: Maybe<Array<InspirationalSections>>;
  meetBrand?: Maybe<MeetBrand>;
  productBlocks?: Maybe<Array<ProductBlocks>>;
  promotionBanners?: Maybe<PromotionBannerSection>;
};

/** DesignerPageFilter Input. */
export type DesignerPageFilter = {
  /** product limit */
  product_limit: Scalars['Int']['input'];
};

export type DiscountTimerBar = {
  __typename?: 'DiscountTimerBar';
  message?: Maybe<AlertBar>;
};

/** Discrete Promo Type */
export type DiscretePromotion = {
  __typename?: 'DiscretePromotion';
  /** Discrete promo created at */
  created_at: DiscretePromotionCreatedAt;
  /** Discrete promo id */
  discrete_promo_id: Scalars['ID']['output'];
  /** Discrete promo name */
  discrete_promo_name: Scalars['String']['output'];
  /** Discrete promo updated at */
  updated_at?: Maybe<DiscretePromotionUpdatedAt>;
};

/** Discrete Promotion created at type */
export type DiscretePromotionCreatedAt = Timestamp & {
  __typename?: 'DiscretePromotionCreatedAt';
  date: Scalars['String']['output'];
};

/** Discrete Promotion updated at type */
export type DiscretePromotionUpdatedAt = Timestamp & {
  __typename?: 'DiscretePromotionUpdatedAt';
  date?: Maybe<Scalars['String']['output']>;
};

/** Download Legacy Document */
export type DownloadLegacyDocument = {
  __typename?: 'DownloadLegacyDocument';
  /** file url */
  file: Scalars['String']['output'];
};

export type Drop = {
  __typename?: 'Drop';
  /** Name of the file. */
  file_name: Scalars['String']['output'];
  /** Size of the file. */
  size?: Maybe<Scalars['Int']['output']>;
};

export type DropCollection = {
  __typename?: 'DropCollection';
  /** has_more result flag. */
  has_more: Scalars['Boolean']['output'];
  /** List of visuals. */
  items?: Maybe<Array<Drop>>;
  /** To obtaine next set of files you have to pass this value as `next_continuation_token` in `getBrandVisual` query. */
  next_continuation_token?: Maybe<Scalars['String']['output']>;
};

/** Edit Type. */
export type Edit = {
  __typename?: 'Edit';
  /** Id of the edit. */
  id: Scalars['ID']['output'];
  /** Image of the edit. */
  image: Scalars['String']['output'];
  /** Name of the edit. */
  name: Scalars['String']['output'];
  /** Link of the edit */
  url: Scalars['String']['output'];
};

/** EditCarousel type */
export type EditCarousel = {
  __typename?: 'EditCarousel';
  /** List of carousel items */
  items: Array<Edit>;
  /** Position */
  position: Scalars['Int']['output'];
  /** Title of the carousel. */
  title: Scalars['String']['output'];
  /** View all url of the carousel. */
  view_all_url?: Maybe<Scalars['String']['output']>;
};

export type ExceptionSpecificationInput = {
  end_date: Scalars['String']['input'];
  start_date: Scalars['String']['input'];
};

/** Filtering options */
export type ExportInsightReportFilter = {
  /** format */
  format: Scalars['String']['input'];
  /** From date (Y-m-d) */
  from_date: Scalars['String']['input'];
  /** To date (Y-m-d) */
  to_date: Scalars['String']['input'];
};

/** Result for the query */
export type ExportInsightReportResult = {
  __typename?: 'ExportInsightReportResult';
  /** format */
  format: Scalars['String']['output'];
  /** output */
  output: Scalars['String']['output'];
};

/** Filtering options */
export type ExportInventoryReportFilter = {
  /** date */
  date?: InputMaybe<Scalars['String']['input']>;
  /** format */
  format: Scalars['String']['input'];
};

/** Result for the query */
export type ExportInventoryReportResult = {
  __typename?: 'ExportInventoryReportResult';
  /** output */
  output: Scalars['String']['output'];
};

/** Filtering options */
export type ExportSalesReportFilter = {
  /** format */
  format: Scalars['String']['input'];
  /** From date (Y-m-d) */
  from_date: Scalars['String']['input'];
  /** To date (Y-m-d) */
  to_date: Scalars['String']['input'];
};

/** Result for the query */
export type ExportSalesReportResult = {
  __typename?: 'ExportSalesReportResult';
  /** output */
  output: Scalars['String']['output'];
};

/** VAS lines that failed to be processed */
export type FailedVasLine = {
  __typename?: 'FailedVASLine';
  /** vendor id of the failed VAS line */
  customerInternalId: Scalars['String']['output'];
  /** Row number of the failed VAS line */
  rowNo: Scalars['Int']['output'];
};

export type FieldsData = {
  __typename?: 'FieldsData';
  code: Scalars['String']['output'];
  is_required?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  options?: Maybe<Array<OptionsData>>;
  regex?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type FilterForCollectionInput = {
  ages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  brands?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cato_cat?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  collectionId: Scalars['ID']['input'];
  colors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  goodOnYou?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['String']['input']>;
  max_price?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  min_price?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  patterns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sizes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  styles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** FilterOption Type. */
export type FilterOption = Node & {
  __typename?: 'FilterOption';
  /** Number of products available for this option. */
  count: Scalars['Int']['output'];
  /** Id of the filter option */
  id: Scalars['ID']['output'];
};

export type FilterProductLabelGroup = {
  __typename?: 'FilterProductLabelGroup';
  labels?: Maybe<Array<Maybe<ProductLabel>>>;
  slug: Scalars['String']['output'];
};

export type FilterProductLabelInputGroup = {
  labels?: InputMaybe<Array<InputMaybe<ProductLabelInput>>>;
  slug: Scalars['String']['input'];
};

export type FilterProductLabelResult = {
  __typename?: 'FilterProductLabelResult';
  items?: Maybe<Array<Maybe<FilterProductLabelGroup>>>;
};

/** Input of the finalizeInvoices mutation */
export type FinalizeInvoicesInput = {
  /** Id of the invoice to be finalized */
  invoice_id: Scalars['String']['input'];
};

/** Result of the finalizeInvoices mutation */
export type FinalizeInvoicesResult = {
  __typename?: 'FinalizeInvoicesResult';
  /** Success message of the mutation */
  message: Scalars['String']['output'];
  /** Amount of invoices that successfully triggered the finalization process */
  successfulInvoiceCounts: Scalars['Int']['output'];
  /** Invoice ids that successfully triggered the finalization process */
  successfulInvoiceIds: Array<Scalars['String']['output']>;
};

export type FlatCommissionModel = CommissionModelI & {
  __typename?: 'FlatCommissionModel';
  specification: FlatCommissionModelSpecification;
  type: Scalars['String']['output'];
};

export type FlatCommissionModelSpecification = CommissionModelSpecification & {
  __typename?: 'FlatCommissionModelSpecification';
  rate: Scalars['Float']['output'];
  rates: Array<RateSpecification>;
};

export type FlatCommissionModelSpecificationInput = {
  rate: Scalars['String']['input'];
};

/** FollowBrandMutationResult Type */
export type FollowBrandMutationResult = {
  __typename?: 'FollowBrandMutationResult';
  /** Response code. */
  code: Scalars['String']['output'];
  /** Mutation data. */
  data?: Maybe<FollowBrandMutationResultDataParam>;
  /** Response message. */
  message: Scalars['String']['output'];
};

/** FollowBrandMutationResultDataParam Type. */
export type FollowBrandMutationResultDataParam = {
  __typename?: 'FollowBrandMutationResultDataParam';
  followed?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

/** FollowedBrand Type. */
export type FollowedBrand = {
  __typename?: 'FollowedBrand';
  /** Brand id. */
  id: Scalars['ID']['output'];
};

export type FulfillmentRatesSpecification = {
  __typename?: 'FulfillmentRatesSpecification';
  handling_fee?: Maybe<Scalars['Float']['output']>;
  order_fee?: Maybe<Scalars['Float']['output']>;
  return_fee?: Maybe<Scalars['Float']['output']>;
  storage_fee?: Maybe<Scalars['Float']['output']>;
};

export type FulfillmentServicesModel = {
  __typename?: 'FulfillmentServicesModel';
  rates?: Maybe<FulfillmentRatesSpecification>;
  state: FulfillmentServicesState;
};

export enum FulfillmentServicesState {
  Custom = 'custom',
  Global = 'global',
  Off = 'off'
}

/**
 * Brand Gate Level.
 * In Use for Otrium Hub/Dam
 */
export enum GateLevel {
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3'
}

export type Gender = {
  __typename?: 'Gender';
  /** Brand size guide */
  brand_size_guide: Scalars['String']['output'];
  /** Coming soon */
  coming_soon: Scalars['Boolean']['output'];
  /** Cover image */
  cover_image: Scalars['String']['output'];
  /** Discount */
  discount: Scalars['String']['output'];
  /** Header image */
  header_image: Scalars['String']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  /** Labels */
  labels?: Maybe<Array<BrandLabel>>;
  /** Promotion section */
  promotion?: Maybe<Promotion>;
  /** Sales landing url */
  sales_landing?: Maybe<Scalars['String']['output']>;
};

/** GenderFilterOptions Type. */
export type GenderFilterOption = Node & {
  __typename?: 'GenderFilterOption';
  /** Number of products under this gender. */
  count: Scalars['Int']['output'];
  /** id of the gender. */
  id: Scalars['ID']['output'];
  /** Name of the gender. */
  name: Scalars['String']['output'];
};

/** GeneralFilterOptions Type. */
export type GeneralFilterOption = Node & {
  __typename?: 'GeneralFilterOption';
  /** Number of products under this gender. */
  count: Scalars['Int']['output'];
  /** id of the gender. */
  id: Scalars['ID']['output'];
  /** Name of the gender. */
  name: Scalars['String']['output'];
};

export type GeneralTimestamp = Timestamp & {
  __typename?: 'GeneralTimestamp';
  date: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  timezone_type: Scalars['Int']['output'];
};

export type GenericBlockData = {
  __typename?: 'GenericBlockData';
  image: Scalars['String']['output'];
  link: Scalars['String']['output'];
  linkCTA?: Maybe<Scalars['String']['output']>;
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GenericCardsSection = WithPosition & {
  __typename?: 'GenericCardsSection';
  data: Array<GenericBlockData>;
  position: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type GenericSection = {
  __typename?: 'GenericSection';
  alert_banners: AlertBannerSection;
  cards: CardSection;
  conscious_shops: ShopSection;
  designer_stores: ShopSection;
  featured_shop: ShopSection;
  image_banners: ImageBannerSection;
  just_in_placeholders: JustInPlaceholdersSection;
  just_in_shops: ShopSection;
  popular_categories: PopularCategorySection;
  popular_shops: ShopSection;
  promotion_banners?: Maybe<PromotionBannerSection>;
  refer_friend: ReferFriendSection;
};

export type GetMembersResult = {
  __typename?: 'GetMembersResult';
  /** access type */
  access_type?: Maybe<Scalars['String']['output']>;
  /** active */
  active?: Maybe<Scalars['Boolean']['output']>;
  /** created_at */
  created_at?: Maybe<DatesResult>;
  /** email */
  email?: Maybe<Scalars['String']['output']>;
  /** last_access */
  last_access?: Maybe<DatesResult>;
  /** nickname */
  nickname?: Maybe<Scalars['String']['output']>;
};

export type GetMultipartUploadSignedUrlsInput = {
  /** The amount of parts to be uploaded. */
  amount_of_parts: Scalars['Int']['input'];
  /** Name of the file to be uploaded. */
  file_name: Scalars['String']['input'];
  /** Otrium brand UUID */
  ot_brand_id?: InputMaybe<Scalars['String']['input']>;
  /** Deprecated - Use ot_brand_id instead */
  vendor_id?: InputMaybe<Scalars['String']['input']>;
};

export type GetPartnerInvitesB2BMembers = {
  __typename?: 'GetPartnerInvitesB2BMembers';
  /** Members */
  members: Array<Maybe<GetMembersResult>>;
  /** If with_load_more is true and there are more records, next_token will have a value; otherwise, it will be null. */
  next_token?: Maybe<Scalars['String']['output']>;
};

/** Global Flat Commission Model rate */
export type GlobalFlatRate = {
  __typename?: 'GlobalFlatRate';
  max_percentage: Scalars['Float']['output'];
  min_percentage: Scalars['Float']['output'];
};

export type GlobalFulfillmentRateSpecification = {
  __typename?: 'GlobalFulfillmentRateSpecification';
  rates: FulfillmentRatesSpecification;
  warehouse_region: Scalars['String']['output'];
};

/** Single Global Otrium Fulfillment Services fee */
export type GlobalOfSfee = {
  __typename?: 'GlobalOFSfee';
  /** Fee amount excluding tax */
  fee_amount_exc_tax: Scalars['Float']['output'];
  /** Fee amount including tax */
  fee_amount_inc_tax: Scalars['Float']['output'];
  /** Level at which the fee is applied */
  level: OfsFeeLevel;
  /** Name of the OFS fee */
  title: Scalars['String']['output'];
};

/** Global Tiered Item Price Commission Model rate */
export type GlobalTieredItemPriceRate = {
  __typename?: 'GlobalTieredItemPriceRate';
  max_amount?: Maybe<Scalars['Float']['output']>;
  max_percentage: Scalars['Float']['output'];
  min_amount: Scalars['Float']['output'];
  min_percentage: Scalars['Float']['output'];
};

export type GlobalTieredRevenueTypeRateSpecification = {
  __typename?: 'GlobalTieredRevenueTypeRateSpecification';
  rates: Array<RateSpecification>;
  type: Scalars['String']['output'];
  type_human_readable: Scalars['String']['output'];
};

/** GoodOnYou filters enum */
export enum GoodOnYouFilter {
  All = 'ALL',
  ConsciousOnly = 'CONSCIOUS_ONLY'
}

/** GoodOnYou Filter option */
export type GoodOnYouFilterOption = Node & {
  __typename?: 'GoodOnYouFilterOption';
  /** Number of product available of this option. */
  count: Scalars['Int']['output'];
  /** ID used in query. */
  id: Scalars['ID']['output'];
  /** Filter name. */
  name: Scalars['String']['output'];
};

export type Header = WithPosition & {
  __typename?: 'Header';
  position: Scalars['Int']['output'];
  shop_by_category: Scalars['String']['output'];
  shop_by_designer: Scalars['String']['output'];
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type HeroSlider = WithPosition & {
  __typename?: 'HeroSlider';
  desktopImages: Array<SliderImage>;
  images: Array<SliderImage>;
  position: Scalars['Int']['output'];
};

/** HomePageBrands Type */
export type HomePageBrands = {
  __typename?: 'HomePageBrands';
  /** Featured Brands. */
  featured?: Maybe<Array<Maybe<SummaryBrand>>>;
  /** Popular Brands. */
  popular?: Maybe<Array<Maybe<SummaryBrand>>>;
};

export type HomePageSingleBrand = {
  __typename?: 'HomePageSingleBrand';
  brand_sale_type?: Maybe<Scalars['String']['output']>;
  cover_image: Scalars['String']['output'];
  designer_brand?: Maybe<Scalars['Boolean']['output']>;
  discount: Scalars['String']['output'];
  /** Brand Gate */
  gate?: Maybe<BrandGate>;
  genders?: Maybe<Array<Scalars['String']['output']>>;
  good_on_you_pillars: Array<Scalars['String']['output']>;
  /** term_id in API call */
  id: Scalars['ID']['output'];
  image: Scalars['String']['output'];
  is_coming_soon?: Maybe<Scalars['Boolean']['output']>;
  is_gold: Scalars['Boolean']['output'];
  is_good_on_you: Scalars['Boolean']['output'];
  labels?: Maybe<Array<HomePageSingleBrandLabel>>;
  last_product_update?: Maybe<Scalars['String']['output']>;
  logo: Scalars['String']['output'];
  logo_svg: Scalars['String']['output'];
  name: Scalars['String']['output'];
  overlay_logo: Scalars['String']['output'];
  sales_landing?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  subtitle: Scalars['String']['output'];
  /**
   * Wishlist item id of the user.
   * @deprecated Decided to go with userWishlistMeta.
   */
  user_wishlist_item_id?: Maybe<Scalars['String']['output']>;
};

export type HomePageSingleBrandLabel = {
  __typename?: 'HomePageSingleBrandLabel';
  color: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  promotion?: Maybe<Promotion>;
  /** Text color.Will appear for themed_label type */
  text_color?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Homepage = {
  __typename?: 'Homepage';
  ActionBlocks?: Maybe<Array<HomepageActionBlockSection>>;
  AlertBanners?: Maybe<Array<HomepageAlertBannerSection>>;
  Cards?: Maybe<Array<CardsSection>>;
  Categories?: Maybe<Array<HomepageCategorySection>>;
  Departments?: Maybe<Array<HomepageDepartmentSection>>;
  DynamicAlertBanners?: Maybe<Array<HomepageDynamicAlertBannerSection>>;
  ImageBanners?: Maybe<Array<ImageBannersSection>>;
  /** @deprecated Use `Cards` type. */
  InspirationalSections?: Maybe<Array<InspirationalSections>>;
  PopularCategories?: Maybe<Array<HomepagePopularCategorySection>>;
  /** @deprecated Use the `PromotionBanners` field. */
  PromoBanners?: Maybe<Array<HomepagePromoBannerSection>>;
  PromotionBanners?: Maybe<PromotionBannerSection>;
  ReferFriend?: Maybe<Array<HomepageReferFriendSection>>;
  Shops?: Maybe<Array<HomepageShopSection>>;
  TemporaryOffers?: Maybe<Array<HomepageTemporaryOfferSection>>;
  productBlocks?: Maybe<Array<HomepageProductBlocksSection>>;
};


export type HomepageProductBlocksArgs = {
  carousel_recommend_type?: InputMaybe<CarouselRecommendTypeInput>;
  labels: Array<ProductLabelFilter>;
  limit: Scalars['Int']['input'];
};

/** @deprecated(reason: "Migrated to `InspirationalSections") */
export type HomepageActionBlockSection = {
  __typename?: 'HomepageActionBlockSection';
  data: HomepageActionBlockSectionData;
  position: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

/** @deprecated(reason: "Migrated to `InspirationalSections") */
export type HomepageActionBlockSectionData = {
  __typename?: 'HomepageActionBlockSectionData';
  button_link: Scalars['String']['output'];
  button_title: Scalars['String']['output'];
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  end_date: Scalars['String']['output'];
  gated?: Maybe<Scalars['Boolean']['output']>;
  image: Scalars['String']['output'];
  platform?: Maybe<Scalars['String']['output']>;
  start_date: Scalars['String']['output'];
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type HomepageAlertBannerData = {
  __typename?: 'HomepageAlertBannerData';
  bg_color: Scalars['String']['output'];
  content: Scalars['String']['output'];
  link: Scalars['String']['output'];
  text_color: Scalars['String']['output'];
};

export type HomepageAlertBannerSection = {
  __typename?: 'HomepageAlertBannerSection';
  data: HomepageAlertBannerData;
  position: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

/** Brand in homepage -> category -> product  Type. */
export type HomepageCategoryProductBrand = Brand & {
  __typename?: 'HomepageCategoryProductBrand';
  /** Designer brand flag */
  designer_brand: Scalars['Boolean']['output'];
  /** Brand id. */
  id: Scalars['ID']['output'];
  /** Brand name */
  name: Scalars['String']['output'];
  /** Brand slug */
  slug: Scalars['String']['output'];
};

/** Type used to reflect extra currencies section in homapage category product */
export type HomepageCategoryProductCurrency = {
  __typename?: 'HomepageCategoryProductCurrency';
  /** String ID */
  id: Scalars['ID']['output'];
  /** Price */
  price: Scalars['String']['output'];
  /** Regular Price */
  regular_price: Scalars['String']['output'];
};

export type HomepageCategorySection = {
  __typename?: 'HomepageCategorySection';
  data: HomepageCategorySectionData;
  position: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type HomepageCategorySectionData = {
  __typename?: 'HomepageCategorySectionData';
  banner_image: Scalars['String']['output'];
  banner_image_mobile: Scalars['String']['output'];
  curated: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  products?: Maybe<Array<HomepageSingleCategoryProduct>>;
  slug: Scalars['String']['output'];
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type HomepageDepartmentSection = {
  __typename?: 'HomepageDepartmentSection';
  data: HomepageDepartmentSectionData;
  position: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type HomepageDepartmentSectionData = {
  __typename?: 'HomepageDepartmentSectionData';
  brands?: Maybe<Array<HomePageSingleBrand>>;
  department_slug: Scalars['String']['output'];
  department_subtitle: Scalars['String']['output'];
  department_title: Scalars['String']['output'];
};

export type HomepageDynamicAlertBannerSection = {
  __typename?: 'HomepageDynamicAlertBannerSection';
  data?: Maybe<Array<HomepageAlertBannerData>>;
  position: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type HomepageFilter = {
  include_gold?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HomepagePopularCategoryData = {
  __typename?: 'HomepagePopularCategoryData';
  curated: Scalars['Boolean']['output'];
  /** cat_id in rest API */
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  thumbnail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type HomepagePopularCategorySection = {
  __typename?: 'HomepagePopularCategorySection';
  data: Array<HomepagePopularCategoryData>;
  gated?: Maybe<Scalars['Boolean']['output']>;
  position: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type HomepageProductBlocksSection = {
  __typename?: 'HomepageProductBlocksSection';
  data: HomepageProductBlocksSectionnData;
  position: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type HomepageProductBlocksSectionnData = {
  __typename?: 'HomepageProductBlocksSectionnData';
  is_enabled: Scalars['Boolean']['output'];
  personalised: Scalars['Boolean']['output'];
  products: Array<CatalogProduct>;
  response_source_type?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type HomepagePromoBannerCategory = {
  __typename?: 'HomepagePromoBannerCategory';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type HomepagePromoBannerData = {
  __typename?: 'HomepagePromoBannerData';
  category?: Maybe<HomepagePromoBannerCategory>;
  description?: Maybe<Scalars['String']['output']>;
  desktop_image?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  mobile_image: Scalars['String']['output'];
  mobile_image_height?: Maybe<Scalars['Int']['output']>;
  mobile_image_width?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type HomepagePromoBannerSection = {
  __typename?: 'HomepagePromoBannerSection';
  data: HomepagePromoBannerData;
  position: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type HomepageReferFriendData = {
  __typename?: 'HomepageReferFriendData';
  action_button: Scalars['String']['output'];
  description: Scalars['String']['output'];
  image: Scalars['String']['output'];
  link: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type HomepageReferFriendSection = {
  __typename?: 'HomepageReferFriendSection';
  data: HomepageReferFriendData;
  position: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type HomepageShopSection = {
  __typename?: 'HomepageShopSection';
  data: Array<HomePageSingleBrand>;
  position: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type HomepageSingleCategoryProduct = {
  __typename?: 'HomepageSingleCategoryProduct';
  brand: HomepageCategoryProductBrand;
  brand_sale_type?: Maybe<Scalars['String']['output']>;
  /** Product categories */
  categories?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  currencies?: Maybe<Array<HomepageCategoryProductCurrency>>;
  currency: ProductCurrency;
  /** Brand Gate */
  gate?: Maybe<BrandGate>;
  guid: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Scalars['String']['output']>>;
  /** Defines if product personalised */
  is_personalised?: Maybe<Scalars['Boolean']['output']>;
  /** Labels of the product. */
  labels?: Maybe<Array<ProductLabel>>;
  name: Scalars['String']['output'];
  post_title: Scalars['String']['output'];
  /** @deprecated Due to type difference. Use `price_float` field. */
  price: Scalars['String']['output'];
  price_float: Scalars['Float']['output'];
  /** Product discount */
  product_discount?: Maybe<Scalars['Float']['output']>;
  product_image_gallery?: Maybe<Scalars['String']['output']>;
  /** Product kids gender */
  product_kids_gender?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Product shop type */
  product_shop_type?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** @deprecated Due to type difference. Use `regular_price_float` field. */
  regular_price: Scalars['String']['output'];
  regular_price_float: Scalars['Float']['output'];
  /** @deprecated Due to type difference. Use `sale_price_float` field. */
  sale_price: Scalars['String']['output'];
  sale_price_float: Scalars['Float']['output'];
  /** Product available sizes */
  sizes_available?: Maybe<Scalars['String']['output']>;
  sku: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  status: Scalars['String']['output'];
  stock_status: Scalars['String']['output'];
  /** Thumbnail of the product */
  thumbnail: Scalars['String']['output'];
  /** Product translated category ids */
  translated_category_ids?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Product translated category names */
  translated_category_names?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Product url */
  url?: Maybe<Scalars['String']['output']>;
  /**
   * Wishlist item id of the user.
   * @deprecated Decided to go with userWishlistMeta.
   */
  user_wishlist_item_id?: Maybe<Scalars['String']['output']>;
};

export type HomepageTemporaryOfferData = {
  __typename?: 'HomepageTemporaryOfferData';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  image: Scalars['String']['output'];
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type HomepageTemporaryOfferSection = {
  __typename?: 'HomepageTemporaryOfferSection';
  data: HomepageTemporaryOfferData;
  position: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type HomepageV2 = {
  __typename?: 'HomepageV2';
  for_you_shops: ShopSection;
  generic_sections: GenericSection;
  positioning: Array<PositioningSection>;
  storefronts?: Maybe<Array<StorefrontSection>>;
};


export type HomepageV2For_You_ShopsArgs = {
  shopType: ShopType;
};


export type HomepageV2Generic_SectionsArgs = {
  shopType: ShopType;
};


export type HomepageV2StorefrontsArgs = {
  shopType: ShopType;
};

export type ImageBannerData = {
  __typename?: 'ImageBannerData';
  block_uuid: Scalars['String']['output'];
  display_type: Scalars['String']['output'];
  end_date: Scalars['String']['output'];
  gated?: Maybe<Scalars['Boolean']['output']>;
  image_desktop: Scalars['String']['output'];
  image_mobile: Scalars['String']['output'];
  link: Scalars['String']['output'];
  platform: Scalars['String']['output'];
  start_date: Scalars['String']['output'];
  subtitle: Scalars['String']['output'];
  subtitle_color: Scalars['String']['output'];
  title: Scalars['String']['output'];
  title_color: Scalars['String']['output'];
};

export type ImageBannerSection = {
  __typename?: 'ImageBannerSection';
  data: Array<ImageBannersData>;
  type: Scalars['String']['output'];
};

export type ImageBannersData = {
  __typename?: 'ImageBannersData';
  image_desktop: Scalars['String']['output'];
  image_mobile: Scalars['String']['output'];
  link: Scalars['String']['output'];
  platform: Scalars['String']['output'];
  subtitle: Scalars['String']['output'];
  subtitle_color: Scalars['String']['output'];
  title: Scalars['String']['output'];
  title_color: Scalars['String']['output'];
};

export type ImageBannersSection = {
  __typename?: 'ImageBannersSection';
  data: Array<ImageBannerData>;
  position: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type InspirationalSections = WithPosition & {
  __typename?: 'InspirationalSections';
  data: Array<BrandData>;
  position: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type InvoiceCollection = {
  __typename?: 'InvoiceCollection';
  data: Array<PaymentSpecification>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total_results: Scalars['Int']['output'];
};

/** Result of the getInvoiceCombinedPDFDownloadURL mutation */
export type InvoiceCombinedPdfDownloadUrlResult = {
  __typename?: 'InvoiceCombinedPDFDownloadURLResult';
  /** Generated S3 Bucket file name */
  fileName: Scalars['String']['output'];
  /** Url used for the 2nd step, to process the download */
  fullURL: Scalars['String']['output'];
  /** Url used for the combined PDF file download */
  signedURL: Scalars['String']['output'];
};

export type InvoiceOfsCalculation = {
  __typename?: 'InvoiceOFSCalculation';
  created_at?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  invoice_id?: Maybe<Scalars['ID']['output']>;
  period_in_days?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  total_after_corrections?: Maybe<Scalars['Float']['output']>;
  total_after_tax?: Maybe<Scalars['Float']['output']>;
  total_items_handled_qty?: Maybe<Scalars['Float']['output']>;
  total_items_handled_value?: Maybe<Scalars['Float']['output']>;
  total_items_returned_qty?: Maybe<Scalars['Float']['output']>;
  total_items_returned_value?: Maybe<Scalars['Float']['output']>;
  total_items_shipped_qty?: Maybe<Scalars['Float']['output']>;
  total_items_shipped_value?: Maybe<Scalars['Float']['output']>;
  total_items_stored_amount?: Maybe<Scalars['Float']['output']>;
  total_items_stored_qty?: Maybe<Scalars['Float']['output']>;
  total_tax?: Maybe<Scalars['Float']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
};

/**
 * The different statuses that the invoice can be in.
 * OPEN → the invoice period is ongoing
 * TO_INVOICE → the invoice period is closed, but the invoice hasn’t been invoiced yet
 * FINALIZING → invoice is in process of being finalized
 * FINALIZED → invoice has been finalized
 * ERROR → invoice has an error, so it can’t be finalized
 * REALLOCATED → invoice has been reallocated to a different vendor
 */
export enum InvoiceStatus {
  Error = 'ERROR',
  Finalized = 'FINALIZED',
  Finalizing = 'FINALIZING',
  Open = 'OPEN',
  Reallocated = 'REALLOCATED',
  ToInvoice = 'TO_INVOICE'
}

export type InvoiceSummaryByVendorCollection = {
  __typename?: 'InvoiceSummaryByVendorCollection';
  data: Array<PaymentSpecificationSummaryByVendor>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total_results: Scalars['Int']['output'];
};

export type InvoiceSummaryCollection = {
  __typename?: 'InvoiceSummaryCollection';
  data: Array<PaymentSpecificationSummary>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total_results: Scalars['Int']['output'];
};

/** Total invoices currently in specific statuses */
export type InvoiceTotalsByStatus = {
  __typename?: 'InvoiceTotalsByStatus';
  ERROR: Scalars['Int']['output'];
  FINALIZED: Scalars['Int']['output'];
  FINALIZING: Scalars['Int']['output'];
  OPEN: Scalars['Int']['output'];
  TO_INVOICE: Scalars['Int']['output'];
};

/** Result of the getInvoiceTransactionReportDownloadURL mutation */
export type InvoiceTransactionReportDownloadUrlResult = {
  __typename?: 'InvoiceTransactionReportDownloadURLResult';
  /** Generated S3 Bucket file name */
  fileName: Scalars['String']['output'];
  /** Url used for the 2nd step, to process the download */
  fullURL: Scalars['String']['output'];
  /** Url used for the Transaction Report file download */
  signedURL: Scalars['String']['output'];
};

/** Possible types of invoice documents */
export enum InvoiceType {
  AdHocCreditNote = 'AD_HOC_CREDIT_NOTE',
  AdHocDebitNote = 'AD_HOC_DEBIT_NOTE',
  AdHocSelfBillDebitNote = 'AD_HOC_SELF_BILL_DEBIT_NOTE',
  CreditNote = 'CREDIT_NOTE',
  Ofs = 'OFS',
  SelfBill = 'SELF_BILL',
  Vas = 'VAS'
}

export type InvoiceVasCalculation = {
  __typename?: 'InvoiceVASCalculation';
  charge_type_id: Scalars['ID']['output'];
  charge_type_name: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoice_id: Scalars['ID']['output'];
  memo: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  updated_at: Scalars['String']['output'];
};

/** The different type of currencies in which the vendors can get invoiced. */
export enum InvoicingCurrency {
  Eur = 'EUR',
  Usd = 'USD'
}

/** The different frequencies how often the vendors can get invoiced. */
export enum InvoicingFrequency {
  BiWeekly = 'bi_weekly',
  Monthly = 'monthly',
  Weekly = 'weekly'
}

/** Filtering options */
export type ItemReturnReportFilter = {
  /** From date (Y-m-d) */
  from_date: Scalars['String']['input'];
  /** To date (Y-m-d) */
  to_date: Scalars['String']['input'];
};

/** Result for the query */
export type ItemReturnReportResult = {
  __typename?: 'ItemReturnReportResult';
  /** date */
  date: Scalars['String']['output'];
  /** percentageOfItems */
  percentageOfItems: Scalars['Float']['output'];
  /** percentageOfSales */
  percentageOfSales: Scalars['Float']['output'];
  /** percentageOfSalesUsd */
  percentageOfSalesUsd: Scalars['Float']['output'];
};

export type JustInPlaceholdersData = {
  __typename?: 'JustInPlaceholdersData';
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type JustInPlaceholdersSection = {
  __typename?: 'JustInPlaceholdersSection';
  data: JustInPlaceholdersData;
  type: Scalars['String']['output'];
};

/** LabelFilterOption Type. */
export type LabelFilterOption = Node & {
  __typename?: 'LabelFilterOption';
  /** Number of products under this label. */
  count: Scalars['Int']['output'];
  /** id of the label. */
  id: Scalars['ID']['output'];
  /** Name of the label. */
  name: Scalars['String']['output'];
};

export type ManualAmendment = {
  __typename?: 'ManualAmendment';
  description: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type MeetBrand = WithPosition & {
  __typename?: 'MeetBrand';
  data: BrandData;
  position: Scalars['Int']['output'];
  products: Array<CatalogProduct>;
};

export type MetaData = {
  /** origin location of the request such as footer or checkout... */
  origin: Origin;
};

export type Miscellaneous = {
  __typename?: 'Miscellaneous';
  checkoutMessage: Scalars['String']['output'];
  footer: Array<Scalars['String']['output']>;
  header: Array<Scalars['String']['output']>;
  pdp: PdpData;
  welcomePopup: WelcomePopup;
};

/** Input type for the move brand images */
export type MoveBrandImageInput = {
  /** brand image name */
  brand_image_name: Scalars['String']['input'];
};

/** Response type of the brand product visuals log mutation */
export type MoveBrandImageResponse = {
  __typename?: 'MoveBrandImageResponse';
  image_url: Scalars['String']['output'];
};

export type MultipartUploadSignedUrls = {
  __typename?: 'MultipartUploadSignedUrls';
  /** The id of the file upload. */
  upload_id: Scalars['String']['output'];
  /** The url for each part that needs to be uploaded. */
  urls?: Maybe<Array<Scalars['String']['output']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Aborts the multipart upload.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/920551439/Visual-Bank+Microservice+API+Endpoints#Abort-Multipart-Upload)
   */
  abortMultipartUpload?: Maybe<AbortMultipartUploadResult>;
  /**
   * Use this mutation to obtain a the JWT by passing the code
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/357269507/Auth+Microservice+API+Endpoints#Accept-Invitation)
   */
  acceptInvitationB2B?: Maybe<TokenResultB2B>;
  /**
   * Use this mutation to add a coupon to a cart. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/720306200/Cart+Rest+API#PUT-Add-Coupon)
   */
  addCouponToCart?: Maybe<SingleCart>;
  /** Add any number of products to a collection */
  addProductsToCollection?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Add any number of products to a storefront */
  addProductsToStorefront?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Add item into user wishlist. */
  addToWishlist?: Maybe<WishlitItemBasicPayload>;
  /**
   * Use this mutation to obtain a JWT by providing an apple token.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/953057290/Apple+Login#POST-Apple-Login)
   */
  appleUserLogin?: Maybe<AuthPayload>;
  /** Use this mutation to obtain a JWT by providing an apple token. */
  appleUserLoginV2?: Maybe<UserToken>;
  /**
   * Auto crops product visuals - Image Auto Crop API
   * [Read More](https://otrimage-staging.otrium.com/doc#operations-Image-post_api_v1_images_auto_crop)
   * @deprecated(reason: "Feature has been removed from the insights dashboard")
   */
  autoCropBrandProductVisuals?: Maybe<AutoCropBrandProductVisualsResult>;
  /**
   * This mutation is used to register a B2B user using an email.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/2440298540/POST+Sign-Up+for+B2B+Stock+Buyer)
   */
  b2bUserRegister?: Maybe<B2BSignUpPayload>;
  /**
   * Create a b2b brand bid. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1477246977/Bidding+API#POST-Bid)
   */
  brandB2bBid?: Maybe<SingleBidCreateResult>;
  /**
   * Mutation to request Otrium gateway access by passing the woo vendor id using the BRAND MC.
   * This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More about the query](https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Request-API-gateway-access)
   * @deprecated(reason: "Feature has been removed from the insights dashboard")
   */
  brandRequestApiGatewayAccess?: Maybe<BrandRequestApiGatewayAccessResult>;
  /** `Deprecated` - Use increaseCart mutation instead */
  cart?: Maybe<CartSummary>;
  /** Marked vendor as churned. */
  churnVendor?: Maybe<VendorChurnPayload>;
  /**
   * Completes the multipart upload.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/920551439/Visual-Bank+Microservice+API+Endpoints#Complete-Multipart-Upload)
   */
  completeMultipartUpload?: Maybe<CompleteMultipartUploadResult>;
  /** Confirming a users new password with the help of an extra authentication check in the form of a temporary recovery confirmation code */
  confirmPasswordRecovery: UserPasswordRecoveryResult;
  /** This mutation is used to create a new ad-hoc sub invoice for the provided invoice id */
  createAdhocSubInvoice: CreateAdhocSubInvoiceResult;
  /** This mutation is used to create a new amendment for each of the provided invoice ids */
  createAmendments: CreateAmendmentsResult;
  /**
   * This mutation can be used to create a brand. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://galactic-shuttle-7879.postman.co/workspace/Otrium-Brand-Experience-(B2B)-W~08e47e1d-9a05-41a0-815e-886338e18659/request/22856815-79dacf9e-90ac-4663-9c0e-af4c6642a3cd?ctx=documentation)
   */
  createBrand?: Maybe<CreateBrandMutationResult>;
  /** Create token payment */
  createTokenPayment: TokenPaymentResponse;
  createVendor?: Maybe<VendorCreatePayload>;
  /**
   * deprecated(reason: "Feature has been removed and replaced with size mapping.")
   * This mutation is used to upload new dam size mapping file using a sign url
   * [Read More](https://otrium.atlassian.net/wiki/spaces/DP/pages/20405354503/Sizing+Containers)
   */
  damProductSizeMappingFileUpload: DamProductSizeMappingFileUploadResult;
  /** This mutation is used to publish the products in a drop */
  damPublishDrop: DamPublishDropResult;
  /**
   * This mutation is used to fetch update assigned pixyle attributes from DAM system and oware
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OWARE/pages/1798439030/Accept+attribute+by+IDs).
   */
  damUpdateProductAttributes: DamUpdateProductAttributesResult;
  /**
   * Use this mutation to decrease a cart item. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/720306200/Cart+Rest+API#PUT-Decrease-Cart-Item)
   */
  decreaseCartItem?: Maybe<SingleCart>;
  /**
   * Use this mutation to delete a cart item. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/720306200/Cart+Rest+API#PUT-Delete-Cart-Item)
   */
  deleteCartItem?: Maybe<SingleCart>;
  /**
   * Use this mutation to delete a coupon in a cart. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/720306200/Cart+Endpoints#PUT-Delete-Coupon)
   */
  deleteCouponFromCart?: Maybe<SingleCart>;
  /**
   * Use this mutation to delete warnings from a cart. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/720306200/Cart+Endpoints#POST-Delete-Warnings)
   */
  deleteWarningsFromCart?: Maybe<SingleCart>;
  /** Delete item from user wishlist. */
  deleteWishlistItem?: Maybe<WishlitItemBasicPayload>;
  /** Use this mutation to obtain a new JWT by passing Auth0 access_token. */
  exchangeAuth0TokenB2C?: Maybe<UserToken>;
  /**
   * Use this mutation to obtain a JWT by passing woo token.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/766607361/Token+exchange+-+agreed+flow)
   */
  exchangeToken?: Maybe<AuthPayload>;
  /**
   * Use this mutation to obtain a JWT by providing a facebook token.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/953057283/Facebook+Login)
   */
  facebookUserLogin?: Maybe<AuthPayload>;
  /** Use this mutation to obtain a JWT by providing a facebook token. */
  facebookUserLoginV2?: Maybe<UserToken>;
  /** Use this mutation to obtain a the FarEye JWT used for accessing the return portal */
  fareyeToken?: Maybe<TokenResultFareye>;
  /**
   * This mutation is used to trigger the async invoice finalization process for the provided invoice ids
   * [Postman request path](https://galactic-shuttle-7879.postman.co/workspace/Invoicing-Team~6c5e89cf-cc7a-4146-998a-ededf4b9c0ec/request/31783742-bad0a8bd-d558-4d13-888a-b945266447bc?ctx=documentation)
   */
  finalizeInvoices: FinalizeInvoicesResult;
  /**
   * This mutation can be used to follow a brand. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/317882369/API+endpoints#POST-Brand-Follow)
   */
  followBrand?: Maybe<FollowBrandMutationResult>;
  /**
   * Use this mutation get redirect Url
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/357269507/Auth+Microservice+API+Endpoints#Login-URL)
   */
  getLoginUrlB2B?: Maybe<RedirectUrlResult>;
  /** Use this mutation get cognito hosted login and logout urls for oHub */
  getLoginUrlOHub?: Maybe<RedirectUrlResult>;
  /**
   * Use this mutation to obtain a the JWT by passing the code
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/357269507/Auth+Microservice+API+Endpoints#Get-Token.1)
   */
  getTokenWithCodeB2B?: Maybe<TokenResultB2B>;
  /** Use this mutation to obtain a the OHub JWT by passing the code */
  getTokenWithCodeOHub?: Maybe<TokenResultOHub>;
  /**
   * Use this mutation to obtain a JWT by providing a google token.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/953024515/Google+Login#POST-Google-Login)
   */
  googleUserLogin?: Maybe<AuthPayload>;
  /** Use this mutation to obtain a JWT by providing a google token. */
  googleUserLoginV2?: Maybe<UserToken>;
  /**
   * Use this mutation to obtain a JWT for guest.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/2381185106/POST+Guest+Login)
   */
  guestLogin?: Maybe<AuthPayload>;
  /** Use this V2 mutation to obtain a JWT for guest. */
  guestLoginV2?: Maybe<UserToken>;
  /**
   * Use this mutation to increase a cart item. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/720306200/Cart+Rest+API#PUT-Increase-Cart-Item)
   */
  increaseCartItem?: Maybe<SingleCart>;
  /** This mutation can be used to log inventory uploads to brand microservice */
  inventoryUploadLog?: Maybe<InventoryUploadLogResult>;
  /**
   * Store product visual logs - Otrimage Image Source API
   * [Read More](https://otrimage-staging.otrium.com/doc#image-source)
   * @deprecated(reason: "Process is being automated using s3 events.")
   */
  logBrandProductVisuals?: Maybe<BrandProductVisualsLogResponse>;
  /**
   * @deprecated(reason: "Use getBrandVisualUploadData instead")
   * Store product visual logs - Otrimage Image Source API
   * [Read More](https://otrimage-staging.otrium.com/doc#image-source)
   */
  moveBrandImage?: Maybe<MoveBrandImageResponse>;
  /**
   * This mutation is used to move a single product to a defect state.
   * Move product to a defect state in both DAM system and Oware
   */
  moveProductToDefect: ProductMoveToDefectResult;
  /** Use this mutation to obtain a JWT token for oHub. */
  oHubUserLogin?: Maybe<UserToken>;
  /** Update opt in value based on the email and return the updated JWT */
  optIn: OptInResult;
  /** This mutation provide facility to delete existing partner. */
  partnerDeleteB2B?: Maybe<PartnerDeleteB2BResult>;
  /** This mutation provide facility to edit existing partner. */
  partnerEditB2B?: Maybe<SinglePartner>;
  /**
   * This mutation can be used to invite partners to Partner Platform. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Partner-Invite-B2B)
   */
  partnerInviteB2B?: Maybe<PartnerInviteB2BResult>;
  /** Payment Session */
  paymentSession: PaymentSessionResponse;
  personalization?: Maybe<PersonalizationPayload>;
  /**
   * This mutation is used to process VAS invoices that are already uploaded to the S3 bucket
   * [Postman request path](https://galactic-shuttle-7879.postman.co/workspace/Invoicing-Team~6c5e89cf-cc7a-4146-998a-ededf4b9c0ec/request/31783742-53906bd1-c22d-4796-9c5a-236cd28e1f64?ctx=documentation)
   */
  processVASInvoices: ProcessVasInvoicesResult;
  pspApplePaySession: PspApplePaySessionResponse;
  pspPaymentCreateTransaction: PspPaymentCreateTransactionResponse;
  /** PSP Payment Methods */
  pspPaymentMethods: PspPaymentMethodsResponse;
  /**
   * Use this mutation to obtain a new JWT by passing expired JWT.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/766607361/Token+exchange+-+agreed+flow)
   */
  refreshToken?: Maybe<RefreshTokenResult>;
  /**
   * Use this mutation to obtain a refreshed JWT
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/357269507/Auth+Microservice+API+Endpoints#Refresh-Token)
   */
  refreshTokenB2B?: Maybe<TokenResultB2B>;
  /** Use this mutation to obtain a new JWT by passing the existing JWT. */
  refreshTokenV2?: Maybe<UserToken>;
  /**
   * This mutation is used to remove a single visual from a product (DAM system)
   * Remove visual from both DAM system and Oware
   */
  removeProductVisual: RemoveProductVisualResult;
  /**
   * This mutation is used to remove multiple visuals from products (DAM system)
   * Remove visuals from both DAM system and Oware
   */
  removeProductVisuals: RemoveProductVisualsResult;
  /**
   * Mutation to rest a brand's API key via Brand-MC.
   * This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   */
  resetBrandApiKey?: Maybe<ApiKeyResult>;
  /** Segment Identify Call */
  segmentIdentify?: Maybe<SegmentIdentifyResult>;
  /** Add any number of products to a collection */
  setFilterForCollection?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Use this mutation to login a user based on the Woo shop_token, will return a JWT. */
  shopTokenLogin?: Maybe<UserToken>;
  /**
   * Use this mutation to have soft login access
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1473413139/POST+Soft+Login)
   */
  softLogin?: Maybe<SoftLoginPayload>;
  /** Submit payment details */
  submitPaymentDetails: SubmitPaymentDetailsResponse;
  /** This mutation is used to sync multiple brand in the DAM system */
  syncDamBrands: SyncDamBrandsResponse;
  /** This mutation is used to sync multiple products in the DAM system */
  syncDamProducts: SyncDamProductsResponse;
  terminateVendorTemporaryCommercialSettings?: Maybe<VendorTerminateTemporaryCommercialSettingsPayload>;
  /** Updates account data with given JWT. */
  updateAccount?: Maybe<UpdateAccount>;
  /**
   * Update a b2b brand bid. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1477246977/Bidding+API#PUT-Bid)
   */
  updateBrandB2bBid?: Maybe<SingleBidUpdateResult>;
  /**
   * Update Restricted Territories by vendor. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Update-Restricted-Territories)
   */
  updateBrandB2bRestrictedTerritories?: Maybe<RestrictedTerritoriesUpdateResult>;
  /**
   * This mutation can be used to inform brand managers for necessary updates in a brand. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Update-brand-settings)
   */
  updateBrandSettings?: Maybe<BrandSettingsUpdateResult>;
  /** This mutation is used to update a brand */
  updateDamBrand: UpdateDamBrandResponse;
  /** This mutation is used to update brand images */
  updateDamBrandImages: DamBrandImagesUpdateResult;
  /** This mutation is used to update a product by id in the DAM system */
  updateDamProduct: UpdateDamProductResponse;
  /** This mutation is used to update multiple products by id in the DAM system */
  updateDamProducts: UpdateDamProductsResponse;
  /**
   * This endpoint can use to update User opt_in property.. This endpoint is protected by JWT. You should set valid JWT that can be obtained from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/317882369/API+endpoints#PUT-Update-User)
   */
  updateUser: UpdateUser;
  /**
   * This endpoint will allow you to update user notification settings. This endpoint is protected by JWT. You should set valid JWT that can be obtained from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1278607361/POST+App+Notification#POST-App-Notification)
   */
  updateUserAppNotificationPreferences: UserPreferences;
  /** This enpoint is to update user email with password verification. This endpoint is protected by JWT. You should set valid JWT that can be obtained from userLogin as a header `Authorization: Bearer <JWT>` */
  updateUserEmail: UserEmailUpdateResult;
  /** This endpoint is to update user profile data. This endpoint is protected by JWT. You should set valid JWT that can be obtained from userLogin as a header `Authorization: Bearer <JWT>` */
  updateUserProfile: UserProfile;
  /**
   * This endpoint will allow you to update user's shop preferences settings. This endpoint is protected by JWT. You should set valid JWT that can be obtained from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1343225881/POST+Shop+Pref#POST-Shop-Pref)
   */
  updateUserShopPreferences: UpdateUserShopPreferencesResult;
  updateVendorBasicDetails?: Maybe<VendorBasicDetailsUpdatePayload>;
  updateVendorBillingDetails?: Maybe<VendorBillingDetailsUpdatePayload>;
  /** Update vendor commercial settings. */
  updateVendorDefaultCommercialSettings?: Maybe<VendorDefaultCommercialSettingsUpdateResponse>;
  /** @deprecated(reason: "use updateVendorDefaultCommercialSettings") */
  updateVendorDefaultCommissionModel?: Maybe<VendorDefaultCommissionModelUpdatePayload>;
  /** @deprecated(reason: "use updateVendorDefaultCommercialSettings") */
  updateVendorDefaultValueAddedServices?: Maybe<VendorDefaultValueAddedServicesUpdatePayload>;
  /** @deprecated(reason: "use updateVendorDefaultCommercialSettings") */
  updateVendorFulfillmentServiceRates?: Maybe<VendorFulfillmentRatesSpecificationUpdatePayload>;
  updateVendorTemporaryCommercialSettings?: Maybe<VendorTemporaryCommercialSettingsUpdateResponse>;
  /** @deprecated(reason: "use updateVendorTemporaryCommercialSettings") */
  updateVendorTemporaryCommissionModel?: Maybe<VendorTemporaryCommissionModelUpdatePayload>;
  /** @deprecated(reason: "use updateVendorTemporaryCommercialSettings") */
  updateVendorTemporaryFulfillmentServiceRates?: Maybe<VendorTemporaryFulfillmentServicesUpdatePayload>;
  /** @deprecated(reason: "use updateVendorTemporaryCommercialSettings") */
  updateVendorTemporaryValueAddedServices?: Maybe<VendorTemporaryValueAddedServicesUpdatePayload>;
  /**
   * This mutation is used to upload a visual to the dam system
   * Should use along with getDamVisualUploadUrl as the 2nd step
   */
  uploadProductVisuals: UploadProductVisualsResult;
  /**
   * Use this mutation to obtain a JWT. `grant_type` can be `guest` or `personal`. To obtain a guest JWT pass `grant_type` as `guest`.
   * To obtain personal JWT use grant type as `personal` with `email` and `password`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1163132943/POST+Log+In)
   */
  userLogin?: Maybe<AuthPayload>;
  /** Use this mutation to obtain a JWT token with all the user information from Auth0. */
  userLoginV2?: Maybe<UserToken>;
  /** User Password Recovery email query */
  userPasswordRecovery: UserPasswordRecoveryResult;
  /**
   * This mutation is used to register a user using an email.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1162772524/POST+Sign+Up)
   */
  userRegister?: Maybe<SignUpPayload>;
};


export type MutationAbortMultipartUploadArgs = {
  input: AbortMultipartUploadInput;
};


export type MutationAcceptInvitationB2BArgs = {
  invitation_id: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationAddCouponToCartArgs = {
  coupon_code: Scalars['String']['input'];
  persist_warning?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationAddProductsToCollectionArgs = {
  collectionId: Scalars['ID']['input'];
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type MutationAddProductsToStorefrontArgs = {
  collectionId: Scalars['ID']['input'];
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type MutationAddToWishlistArgs = {
  input?: InputMaybe<WishlistItemCreateInput>;
};


export type MutationAppleUserLoginArgs = {
  identity_token: Scalars['String']['input'];
};


export type MutationAppleUserLoginV2Args = {
  identity_token: Scalars['String']['input'];
};


export type MutationAutoCropBrandProductVisualsArgs = {
  input: AutoCropBrandProductVisualsInput;
};


export type MutationB2bUserRegisterArgs = {
  company_address: Scalars['String']['input'];
  company_name: Scalars['String']['input'];
  email: Scalars['String']['input'];
  located_in: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone_number: Scalars['String']['input'];
  source: Scalars['String']['input'];
  source_type: Scalars['String']['input'];
  vat_number: Scalars['String']['input'];
  website: Scalars['String']['input'];
};


export type MutationBrandB2bBidArgs = {
  comments?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  quantity: Scalars['Int']['input'];
  vendor_id: Scalars['ID']['input'];
};


export type MutationBrandRequestApiGatewayAccessArgs = {
  woo_vendor_id: Scalars['ID']['input'];
};


export type MutationCartArgs = {
  product_id: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
  variation_id: Scalars['ID']['input'];
};


export type MutationChurnVendorArgs = {
  input?: InputMaybe<VendorChurnInput>;
};


export type MutationCompleteMultipartUploadArgs = {
  input: CompleteMultipartUploadInput;
};


export type MutationConfirmPasswordRecoveryArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  verify_password?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateAdhocSubInvoiceArgs = {
  input: CreateAdhocSubInvoiceInput;
};


export type MutationCreateAmendmentsArgs = {
  input: Array<CreateAmendmentInput>;
};


export type MutationCreateBrandArgs = {
  input: CreateBrandInput;
};


export type MutationCreateTokenPaymentArgs = {
  data: TokenPaymentInput;
};


export type MutationCreateVendorArgs = {
  input?: InputMaybe<VendorCreateInput>;
};


export type MutationDamPublishDropArgs = {
  input: DamPublishDropInput;
};


export type MutationDamUpdateProductAttributesArgs = {
  input: DamUpdateProductAttributesInput;
};


export type MutationDecreaseCartItemArgs = {
  persist_warning?: InputMaybe<Scalars['Boolean']['input']>;
  variation_id: Scalars['ID']['input'];
};


export type MutationDeleteCartItemArgs = {
  persist_warning?: InputMaybe<Scalars['Boolean']['input']>;
  variation_id: Scalars['ID']['input'];
};


export type MutationDeleteCouponFromCartArgs = {
  coupon_code: Scalars['String']['input'];
  persist_warning?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationDeleteWishlistItemArgs = {
  input?: InputMaybe<WishlistItemDeleteInput>;
};


export type MutationExchangeAuth0TokenB2CArgs = {
  access_token: Scalars['String']['input'];
  source_type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationExchangeTokenArgs = {
  woo_token: Scalars['String']['input'];
};


export type MutationFacebookUserLoginArgs = {
  fb_token: Scalars['String']['input'];
};


export type MutationFacebookUserLoginV2Args = {
  fb_token: Scalars['String']['input'];
};


export type MutationFareyeTokenArgs = {
  order_list: Scalars['String']['input'];
  order_name: Scalars['String']['input'];
};


export type MutationFinalizeInvoicesArgs = {
  input: Array<FinalizeInvoicesInput>;
};


export type MutationFollowBrandArgs = {
  brand_id: Scalars['Int']['input'];
  following?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationGetLoginUrlB2BArgs = {
  invitation_id?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['String']['input']>;
  redirectUrl: Scalars['String']['input'];
};


export type MutationGetLoginUrlOHubArgs = {
  redirectUrl: Scalars['String']['input'];
};


export type MutationGetTokenWithCodeB2BArgs = {
  code: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
};


export type MutationGetTokenWithCodeOHubArgs = {
  code: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
};


export type MutationGoogleUserLoginArgs = {
  google_token: Scalars['String']['input'];
};


export type MutationGoogleUserLoginV2Args = {
  google_token: Scalars['String']['input'];
};


export type MutationGuestLoginArgs = {
  source_type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGuestLoginV2Args = {
  source_type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationIncreaseCartItemArgs = {
  persist_warning?: InputMaybe<Scalars['Boolean']['input']>;
  variation_id: Scalars['ID']['input'];
};


export type MutationInventoryUploadLogArgs = {
  brand_id: Scalars['Int']['input'];
  brand_name: Scalars['String']['input'];
  drop_number: Scalars['Int']['input'];
  user_email: Scalars['String']['input'];
  user_id: Scalars['Int']['input'];
};


export type MutationLogBrandProductVisualsArgs = {
  input: BrandProductVisualsLogInput;
};


export type MutationMoveBrandImageArgs = {
  input: MoveBrandImageInput;
};


export type MutationMoveProductToDefectArgs = {
  input?: InputMaybe<Array<ProductMoveToDefectInput>>;
};


export type MutationOHubUserLoginArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};


export type MutationOptInArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<MetaData>;
  segment_anonymous_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPartnerDeleteB2BArgs = {
  input: PartnerDeleteInput;
};


export type MutationPartnerEditB2BArgs = {
  input: PartnerEditInput;
};


export type MutationPartnerInviteB2BArgs = {
  auth0_organization_id: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPaymentSessionArgs = {
  data: PaymentSessionInput;
};


export type MutationProcessVasInvoicesArgs = {
  input: ProcessVasInvoicesInput;
};


export type MutationPspApplePaySessionArgs = {
  data: PspApplePaySessionInput;
};


export type MutationPspPaymentCreateTransactionArgs = {
  data: PspPaymentCreateTransactionInput;
};


export type MutationPspPaymentMethodsArgs = {
  data: PspPaymentMethodsInput;
};


export type MutationRefreshTokenArgs = {
  jwt: Scalars['String']['input'];
};


export type MutationRefreshTokenB2BArgs = {
  auth0_organisation_id?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};


export type MutationRefreshTokenV2Args = {
  token: Scalars['String']['input'];
};


export type MutationRemoveProductVisualArgs = {
  input: RemoveProductVisualInput;
};


export type MutationRemoveProductVisualsArgs = {
  input: RemoveProductVisualsInput;
};


export type MutationResetBrandApiKeyArgs = {
  ot_brand_id: Scalars['String']['input'];
};


export type MutationSegmentIdentifyArgs = {
  data: SegmentIdentifyInput;
};


export type MutationSetFilterForCollectionArgs = {
  input: FilterForCollectionInput;
};


export type MutationSoftLoginArgs = {
  email: Scalars['String']['input'];
  shop_preference?: InputMaybe<Array<Scalars['String']['input']>>;
  source_type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSubmitPaymentDetailsArgs = {
  data: SubmitPaymentDetailsInput;
};


export type MutationSyncDamBrandsArgs = {
  input: SyncDamBrandsInput;
};


export type MutationSyncDamProductsArgs = {
  input: SyncDamProductsInput;
};


export type MutationTerminateVendorTemporaryCommercialSettingsArgs = {
  input: VendorTerminateTemporaryCommercialSettingsInput;
};


export type MutationUpdateAccountArgs = {
  input?: InputMaybe<UpdateAccountInput>;
};


export type MutationUpdateBrandB2bBidArgs = {
  bid_id: Scalars['ID']['input'];
  comments?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateBrandB2bRestrictedTerritoriesArgs = {
  countries: Array<InputMaybe<RestrictedTerritoryInput>>;
  universalVendorId: Scalars['String']['input'];
};


export type MutationUpdateBrandSettingsArgs = {
  country_domains?: InputMaybe<Array<Scalars['String']['input']>>;
  coupons?: InputMaybe<Scalars['String']['input']>;
  discreet_promotions?: InputMaybe<Scalars['String']['input']>;
  google_shopping?: InputMaybe<Scalars['String']['input']>;
  pricing_strategy?: InputMaybe<Scalars['String']['input']>;
  universal_vendor_id: Scalars['Int']['input'];
};


export type MutationUpdateDamBrandArgs = {
  input: UpdateDamBrandInput;
};


export type MutationUpdateDamBrandImagesArgs = {
  brandId: Scalars['String']['input'];
  input: DamBrandImagesUpdateInput;
};


export type MutationUpdateDamProductArgs = {
  input: UpdateDamProductInput;
};


export type MutationUpdateDamProductsArgs = {
  input?: InputMaybe<Array<UpdateDamProductInput>>;
};


export type MutationUpdateUserArgs = {
  opt_in?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateUserAppNotificationPreferencesArgs = {
  app_notification: Scalars['Boolean']['input'];
};


export type MutationUpdateUserEmailArgs = {
  email: Scalars['String']['input'];
  new_email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationUpdateUserProfileArgs = {
  billing_address?: InputMaybe<UpdateBillingAddress>;
  birthdate?: InputMaybe<Scalars['String']['input']>;
  family_name?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  given_name?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shipping_address?: InputMaybe<UpdateShippingAddress>;
  user_pref?: InputMaybe<UpdateUserPref>;
};


export type MutationUpdateUserShopPreferencesArgs = {
  pref_kids?: InputMaybe<Scalars['Boolean']['input']>;
  pref_men?: InputMaybe<Scalars['Boolean']['input']>;
  pref_women?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateVendorBasicDetailsArgs = {
  input?: InputMaybe<VendorBasicDetailsUpdateInput>;
};


export type MutationUpdateVendorBillingDetailsArgs = {
  input?: InputMaybe<VendorBillingDetailsUpdateInput>;
};


export type MutationUpdateVendorDefaultCommercialSettingsArgs = {
  input: VendorDefaultCommercialSettingsUpdateInput;
};


export type MutationUpdateVendorDefaultCommissionModelArgs = {
  input?: InputMaybe<VendorDefaultCommissionModelUpdateInput>;
};


export type MutationUpdateVendorDefaultValueAddedServicesArgs = {
  input?: InputMaybe<VendorDefaultValueAddedServicesUpdateInput>;
};


export type MutationUpdateVendorFulfillmentServiceRatesArgs = {
  input?: InputMaybe<VendorFulfillmentRatesSpecificationInput>;
};


export type MutationUpdateVendorTemporaryCommercialSettingsArgs = {
  input: VendorTemporaryCommercialSettingsUpdateInput;
};


export type MutationUpdateVendorTemporaryCommissionModelArgs = {
  input?: InputMaybe<VendorTemporaryCommissionModelUpdateInput>;
};


export type MutationUpdateVendorTemporaryFulfillmentServiceRatesArgs = {
  input?: InputMaybe<VendorTemporaryFulfillmentServicesUpdateInput>;
};


export type MutationUpdateVendorTemporaryValueAddedServicesArgs = {
  input?: InputMaybe<VendorTemporaryValueAddedServicesUpdateInput>;
};


export type MutationUploadProductVisualsArgs = {
  input: UploadProductVisualsInput;
};


export type MutationUserLoginArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  grant_type: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  remember?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserLoginV2Args = {
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserPasswordRecoveryArgs = {
  email: Scalars['String']['input'];
};


export type MutationUserRegisterArgs = {
  email: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  pref_kids?: InputMaybe<Scalars['Boolean']['input']>;
  pref_men?: InputMaybe<Scalars['Boolean']['input']>;
  pref_women?: InputMaybe<Scalars['Boolean']['input']>;
  source: Scalars['String']['input'];
  source_type: Scalars['String']['input'];
};

export type NavigationItems = {
  __typename?: 'NavigationItems';
  navigation_links: Array<NavigationLinksData>;
  top_level_categories: Array<TopLevelCategoriesData>;
};

export type NavigationLinksData = {
  __typename?: 'NavigationLinksData';
  colour: Scalars['String']['output'];
  link: Scalars['String']['output'];
  section: Scalars['Int']['output'];
  show_on_top: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  title_en: Scalars['String']['output'];
};

/** Filtering options */
export type NetSalesReportFilter = {
  /** From date (Y-m-d) */
  from_date: Scalars['String']['input'];
  /** To date */
  grid: Scalars['Int']['input'];
  /** To date (Y-m-d) */
  to_date: Scalars['String']['input'];
};

/** Result for the query */
export type NetSalesReportResult = {
  __typename?: 'NetSalesReportResult';
  /** Sub results */
  data: Array<Maybe<NetSalesReportSubResult>>;
  /** grossQuantityPeriodPercentage */
  grossQuantityPeriodPercentage: Scalars['Float']['output'];
  /** grossQuantitySoldTotal */
  grossQuantitySoldTotal: Scalars['Int']['output'];
  /** grossSalesExcTotal */
  grossSalesExcTotal: Scalars['Float']['output'];
  /** grossSalesIncTotal */
  grossSalesIncTotal: Scalars['Float']['output'];
  /** grossSalesPeriodPercentage */
  grossSalesPeriodPercentage: Scalars['Float']['output'];
  /** netQuantityPeriodPercentage */
  netQuantityPeriodPercentage: Scalars['Float']['output'];
  /** netQuantitySoldTotal */
  netQuantitySoldTotal: Scalars['Int']['output'];
  /** netSalesExcTotal */
  netSalesExcTotal: Scalars['Float']['output'];
  /** netSalesIncTotal */
  netSalesIncTotal: Scalars['Float']['output'];
  /** netSalesPeriodPercentage */
  netSalesPeriodPercentage: Scalars['Float']['output'];
};

/** Sub result for the query */
export type NetSalesReportSubResult = {
  __typename?: 'NetSalesReportSubResult';
  /** Date */
  date: Scalars['String']['output'];
  /** End Date */
  endDate?: Maybe<Scalars['String']['output']>;
  /** grossQuantitySold */
  grossQuantitySold: Scalars['Int']['output'];
  /** grossSalesExcVat */
  grossSalesExcVat: Scalars['Float']['output'];
  /** grossSalesIncVat */
  grossSalesIncVat: Scalars['Float']['output'];
  /** netQuantitySold */
  netQuantitySold: Scalars['Int']['output'];
  /** netSalesExcVat */
  netSalesExcVat: Scalars['Float']['output'];
  /** netSalesIncVat */
  netSalesIncVat: Scalars['Float']['output'];
  /** previousGrossQuantitySold */
  previousGrossQuantitySold: Scalars['Int']['output'];
  /** previousGrossSalesExcVat */
  previousGrossSalesExcVat: Scalars['Float']['output'];
  /** previousGrossSalesIncVat */
  previousGrossSalesIncVat: Scalars['Float']['output'];
  /** previousNetQuantitySold */
  previousNetQuantitySold: Scalars['Int']['output'];
  /** previousNetSalesExcVat */
  previousNetSalesExcVat: Scalars['Float']['output'];
  /** previousNetSalesIncVat */
  previousNetSalesIncVat: Scalars['Float']['output'];
};

/** Node Interface */
export type Node = {
  /** Id. */
  id: Scalars['ID']['output'];
};

/** Possible levels owhere a fee can be applied */
export enum OfsFeeLevel {
  Item = 'ITEM',
  Order = 'ORDER'
}

export type OffFulfillmentServicesModel = {
  __typename?: 'OffFulfillmentServicesModel';
  state: FulfillmentServicesState;
};

export type OnboardingMetadata = {
  __typename?: 'OnboardingMetadata';
  show_onboarding: Scalars['Boolean']['output'];
  stage?: Maybe<OnboardingStage>;
  status: OnboardingStatus;
  tries?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['Int']['output']>;
};

export enum OnboardingStage {
  BrandPreference = 'brand_preference',
  CategoryPreference = 'category_preference',
  ShopPreference = 'shop_preference'
}

/** OnboardingMetadata Type. */
export enum OnboardingStatus {
  Complete = 'complete',
  Incomplete = 'incomplete'
}

export type OptInResult = {
  __typename?: 'OptInResult';
  /** JWT token */
  token?: Maybe<Scalars['String']['output']>;
};

export type OptionsData = {
  __typename?: 'OptionsData';
  option: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Shipping details of the cart */
export type OrderSummaryShippingDetails = {
  __typename?: 'OrderSummaryShippingDetails';
  /** Free delivery minimum Amount */
  free_min_amount: Scalars['String']['output'];
  /** Price */
  price: Scalars['String']['output'];
};

export enum Origin {
  Checkout = 'CHECKOUT',
  Footer = 'FOOTER',
  GhostWelcomeoptin = 'GHOST_WELCOMEOPTIN',
  OptinPopup = 'OPTIN_POPUP',
  Sustainability = 'SUSTAINABILITY'
}

export type PageAnchorListComponent = {
  __typename?: 'PageAnchorListComponent';
  anchor_title?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/**
 * This holds all the details about assigned language. One page is always linked with a single language object.
 * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/368541826/Pages+Micro-service)
 */
export type PageAvailableLanguage = {
  __typename?: 'PageAvailableLanguage';
  /** unique id of the language */
  id: Scalars['ID']['output'];
  /** language code - unique */
  lang_code: Scalars['String']['output'];
  /** description */
  lang_description: Scalars['String']['output'];
};

export type PageComponentCenteredTextWithBackgroundImage = {
  __typename?: 'PageComponentCenteredTextWithBackgroundImage';
  background_image?: Maybe<PageImage>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  link?: Maybe<PageLinkComponent>;
  title?: Maybe<Scalars['String']['output']>;
};

export type PageComponentCenteredTextWithButton = {
  __typename?: 'PageComponentCenteredTextWithButton';
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  link?: Maybe<PageLinkComponent>;
  title?: Maybe<Scalars['String']['output']>;
};

export type PageComponentCenteredTitleWithBackgroundImage = {
  __typename?: 'PageComponentCenteredTitleWithBackgroundImage';
  id: Scalars['ID']['output'];
  image?: Maybe<PageImage>;
  index: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
};

export type PageComponentIconsList = {
  __typename?: 'PageComponentIconsList';
  icons?: Maybe<Array<Maybe<PageIconWithLabelComponent>>>;
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
};

export type PageComponentImageWithTextColumn = {
  __typename?: 'PageComponentImageWithTextColumn';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<PageImage>;
  index: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type PageComponentImageWithTextNButtonColumn = {
  __typename?: 'PageComponentImageWithTextNButtonColumn';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<PageImage>;
  index: Scalars['Int']['output'];
  link?: Maybe<PageLinkComponent>;
  title?: Maybe<Scalars['String']['output']>;
};

export type PageComponentLinksWithAnchors = {
  __typename?: 'PageComponentLinksWithAnchors';
  anchor_list?: Maybe<Array<Maybe<PageAnchorListComponent>>>;
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
};

export type PageComponentTextWithInfoList = {
  __typename?: 'PageComponentTextWithInfoList';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  link?: Maybe<PageLinkComponent>;
  list?: Maybe<Array<Maybe<PageInfoListComponent>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type PageComponentTextWithLoginButtons = {
  __typename?: 'PageComponentTextWithLoginButtons';
  description?: Maybe<Scalars['String']['output']>;
  helper_text?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  links?: Maybe<Array<Maybe<PageLinkComponent>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type PageComponentTextWithSlider = {
  __typename?: 'PageComponentTextWithSlider';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  slider_type?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Page types. */
export enum PageContentType {
  Brand = 'brand',
  Category = 'category',
  Product = 'product'
}

/**
 * Holds all te details of a single page
 * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/368541826/Pages+Micro-service)
 */
export type PageDetailComponent = {
  __typename?: 'PageDetailComponent';
  /** page created date */
  created_at: Scalars['String']['output'];
  /** Auto generated page detail id */
  id: Scalars['ID']['output'];
  /** page language object. This holds the assigned language details for each page */
  language: PageAvailableLanguage;
  /** name of the page */
  name: Scalars['String']['output'];
  /** page type to determine the type of the page */
  type: PageType;
  /** page updated date */
  updated_at?: Maybe<Scalars['String']['output']>;
  /** page version to support backward compatibility. */
  version: PageVersion;
};

export type PageDetailInputFilter = {
  page_type: Scalars['String']['input'];
  page_version: Scalars['String']['input'];
};

export type PageIconWithLabelComponent = {
  __typename?: 'PageIconWithLabelComponent';
  id: Scalars['ID']['output'];
  image?: Maybe<PageImage>;
  label?: Maybe<Scalars['String']['output']>;
};

export type PageImage = {
  __typename?: 'PageImage';
  alternativeText?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  formats?: Maybe<Array<PageImageFormat>>;
  height: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  width: Scalars['Int']['output'];
};

export type PageImageFormat = {
  __typename?: 'PageImageFormat';
  ext: Scalars['String']['output'];
  format_type: Scalars['String']['output'];
  hash: Scalars['String']['output'];
  mime: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type PageInfoListComponent = {
  __typename?: 'PageInfoListComponent';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type PageInputFilter = {
  language?: InputMaybe<PageLanguageInputFilter>;
  page: PageDetailInputFilter;
};

export type PageLanguageInputFilter = {
  language_code?: InputMaybe<Scalars['String']['input']>;
};

/**
 * This holds a link component which has it's own title,url and a unique identifier to make special functionalities
 * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/368541826/Pages+Micro-service)
 */
export type PageLinkComponent = {
  __typename?: 'PageLinkComponent';
  id: Scalars['ID']['output'];
  link_title?: Maybe<Scalars['String']['output']>;
  link_url?: Maybe<Scalars['String']['output']>;
  unique_identifier?: Maybe<Scalars['String']['output']>;
};

/**
 * This contains the type of each page. Use below link to get all available types
 * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/368541826/Pages+Micro-service)
 */
export type PageType = {
  __typename?: 'PageType';
  type: Scalars['String']['output'];
  type_id: Scalars['String']['output'];
};

/**
 * This keeps all details regarding the page version.
 * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/368541826/Pages+Micro-service)
 */
export type PageVersion = {
  __typename?: 'PageVersion';
  version_details?: Maybe<Scalars['String']['output']>;
  version_id: Scalars['String']['output'];
};

export type PartnerByEmailInput = {
  /** Email address of the partner to be deleted. */
  email: Scalars['String']['input'];
};

/** Result of the partner delete B2B mutation */
export type PartnerDeleteB2BResult = {
  __typename?: 'PartnerDeleteB2BResult';
  /** Result */
  result?: Maybe<Scalars['String']['output']>;
};

export type PartnerDeleteInput = {
  /** Email address of the partner to be deleted. */
  email: Scalars['String']['input'];
};

export type PartnerEditInput = {
  /** Email address of the partner. It uses as identifier. This won't be modified by the operation */
  email: Scalars['String']['input'];
  /** Name of the partner */
  name: Scalars['String']['input'];
  /** List of ot_vendor_id user has to be assigned. */
  organization_ids: Array<Scalars['String']['input']>;
};

/** Result of the partner invite B2B mutation */
export type PartnerInviteB2BResult = {
  __typename?: 'PartnerInviteB2BResult';
  /** Result */
  result?: Maybe<Scalars['String']['output']>;
};

export type PaymentAndShippingData = {
  __typename?: 'PaymentAndShippingData';
  copy: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum PaymentChannel {
  Android = 'android',
  Ios = 'ios',
  Web = 'web'
}

export enum PaymentMethod {
  ApplePay = 'applePay',
  GooglePay = 'googlePay'
}

/** Payment Session mutation input type. */
export type PaymentSessionInput = {
  /** Cart ID */
  cart_id: Scalars['String']['input'];
  /** Cart version */
  cart_version: Scalars['Int']['input'];
  /** Channel */
  channel: PaymentChannel;
  /** Return URL */
  return_url: Scalars['String']['input'];
  /** Shopper Email */
  shopper_email?: InputMaybe<Scalars['String']['input']>;
  /** The token obtained when initializing the SDK (required for iOS and Android, not for web) */
  token?: InputMaybe<Scalars['String']['input']>;
};

/** Payment Session response type. */
export type PaymentSessionResponse = {
  __typename?: 'PaymentSessionResponse';
  /** Cart ID */
  cart_id: Scalars['String']['output'];
  /** Cart Version */
  cart_version: Scalars['Int']['output'];
  /** Session ID */
  id: Scalars['String']['output'];
  /** Session Data */
  session_data: Scalars['String']['output'];
};

/** Payment specification for an invoice boundle type */
export type PaymentSpecification = {
  __typename?: 'PaymentSpecification';
  commission_structure_brackets: Array<CommissionStructureBracket>;
  /** Date that the invoice was created */
  created_at: Scalars['String']['output'];
  /** Unique ID for every invoice */
  id: Scalars['ID']['output'];
  /** Date that the invoice has been invoiced */
  invoice_date?: Maybe<Scalars['String']['output']>;
  /** Human readable unique ID for every invoice */
  invoice_number: Scalars['String']['output'];
  invoice_ofs_calculation: InvoiceOfsCalculation;
  invoice_vas_calculations: Array<InvoiceVasCalculation>;
  manual_amendments: Array<ManualAmendment>;
  manual_credit_note_invoice_numbers: Array<Scalars['String']['output']>;
  manual_credit_note_line_invoice_numbers: Array<Scalars['String']['output']>;
  manual_debit_note_invoice_numbers: Array<Scalars['String']['output']>;
  manual_self_bill_debit_note_invoice_numbers: Array<Scalars['String']['output']>;
  pdf_url: Scalars['String']['output'];
  /** End of the invoice period */
  period_end: Scalars['String']['output'];
  /** Start of the invoice period */
  period_start: Scalars['String']['output'];
  status: InvoiceStatus;
  sum_of_all_net_payments: Scalars['Float']['output'];
  sum_of_all_payments: Scalars['Float']['output'];
  sum_of_all_tax: Scalars['Float']['output'];
  total_amount_of_goods_returned_qty: Scalars['Float']['output'];
  total_amount_of_goods_returned_value: Scalars['Float']['output'];
  total_amount_of_goods_sold_qty: Scalars['Float']['output'];
  total_amount_of_goods_sold_value: Scalars['Float']['output'];
  total_commission_charged: Scalars['Float']['output'];
  total_commission_prior_invoice_period: Scalars['Float']['output'];
  total_credit_note: Scalars['Float']['output'];
  total_goods_sold_after_returns_qty: Scalars['Float']['output'];
  total_goods_sold_after_returns_value: Scalars['Float']['output'];
  total_manual_credit_note: Scalars['Float']['output'];
  total_manual_credit_note_line: Scalars['Float']['output'];
  total_manual_debit_note: Scalars['Float']['output'];
  total_manual_self_bill_debit_note: Scalars['Float']['output'];
  total_net_payment_credit_note: Scalars['Float']['output'];
  total_net_payment_ofs_invoice: Scalars['Float']['output'];
  total_net_payment_self_bill: Scalars['Float']['output'];
  total_net_payment_vas_invoice: Scalars['Float']['output'];
  total_ofs_invoice: Scalars['Float']['output'];
  total_platform_discount_prior_invoice_period: Scalars['Float']['output'];
  total_platform_promotion_discount: Scalars['Float']['output'];
  total_returned_qty_prior_invoice_period: Scalars['Float']['output'];
  total_returned_value_prior_invoice_period: Scalars['Float']['output'];
  total_self_bill: Scalars['Float']['output'];
  total_vas_invoice: Scalars['Float']['output'];
  transaction_url: Scalars['String']['output'];
  updated_at: Scalars['String']['output'];
  vendor_configuration: VendorConfigurationAtInvoiceTime;
  vendor_id: Scalars['ID']['output'];
};

/** Payment specification summary for an invoice listing */
export type PaymentSpecificationSummary = {
  __typename?: 'PaymentSpecificationSummary';
  brand_name: Scalars['String']['output'];
  commission_model: CommissionModelInputType;
  commission_tier?: Maybe<Scalars['String']['output']>;
  /** Unique ID for every invoice */
  id: Scalars['ID']['output'];
  /** Date that the invoice has been invoiced */
  invoice_date?: Maybe<Scalars['String']['output']>;
  invoice_interval: InvoicingFrequency;
  /** Human readable unique ID for every invoice */
  invoice_number: Scalars['String']['output'];
  /** End of the invoice period */
  period_end: Scalars['String']['output'];
  /** Start of the invoice period */
  period_start: Scalars['String']['output'];
  status: InvoiceStatus;
  total_sum_of_all_payments: Scalars['Float']['output'];
  vendor_currency?: Maybe<Currency>;
  vendor_id: Scalars['ID']['output'];
  vendor_name: Scalars['String']['output'];
  vendor_ofs_enabled: Scalars['Boolean']['output'];
  vendor_vas_enabled: Scalars['Boolean']['output'];
  wholesale_vendor: Scalars['Boolean']['output'];
};

/** Payment specification summary for an invoice listing */
export type PaymentSpecificationSummaryByVendor = {
  __typename?: 'PaymentSpecificationSummaryByVendor';
  /** Unique ID for every invoice */
  id: Scalars['ID']['output'];
  /** Date that the invoice has been invoiced */
  invoice_date?: Maybe<Scalars['String']['output']>;
  /** Human readable unique ID for every invoice */
  invoice_number: Scalars['String']['output'];
  period_end: Scalars['String']['output'];
  status: InvoiceStatus;
  total_sum_of_all_payments: Scalars['Float']['output'];
  vendor_configuration: VendorConfigurationAtInvoiceTime;
  vendor_id: Scalars['ID']['output'];
};

export type PdpCarousel = {
  __typename?: 'PdpCarousel';
  id: Scalars['String']['output'];
  products?: Maybe<Array<CatalogProduct>>;
  response_source_type?: Maybe<Scalars['String']['output']>;
  target_link?: Maybe<Scalars['String']['output']>;
  target_type?: Maybe<PdpCarouselTargetType>;
  title: Scalars['String']['output'];
};

export enum PdpCarouselTargetType {
  CollectionPlp = 'COLLECTION_PLP',
  WishlistProduct = 'WISHLIST_PRODUCT'
}

export type PdpCarouselsResult = {
  __typename?: 'PdpCarouselsResult';
  carousels?: Maybe<Array<PdpCarousel>>;
};

export type PdpData = {
  __typename?: 'PdpData';
  delivery: Scalars['String']['output'];
  otriumOffers: Array<Scalars['String']['output']>;
  paymentAndShipping: Array<PaymentAndShippingData>;
  returns: Scalars['String']['output'];
  shipping: Scalars['String']['output'];
};

export type Personalization = {
  __typename?: 'Personalization';
  brandPreferences: Array<BrandPreference>;
  shopPreferences: Array<ShopPreference>;
};

export type PersonalizationPayload = {
  __typename?: 'PersonalizationPayload';
  updateBrandPreferences: Array<BrandPreference>;
  /** @deprecated(reason: "Please use updateShopPreferencesV2 to get the correct token including correct gender") */
  updateShopPreferences: Array<ShopPreference>;
  updateShopPreferencesV2: UpdateShopPreferencesV2Result;
};


export type PersonalizationPayloadUpdateBrandPreferencesArgs = {
  preferences: Array<Scalars['String']['input']>;
};


export type PersonalizationPayloadUpdateShopPreferencesArgs = {
  preferences: Array<Scalars['String']['input']>;
};


export type PersonalizationPayloadUpdateShopPreferencesV2Args = {
  preferences: Array<Scalars['String']['input']>;
};

/** Ping Type. */
export type Ping = {
  __typename?: 'Ping';
  /** Response code. */
  code?: Maybe<Scalars['Int']['output']>;
  /** Response message. */
  message?: Maybe<Scalars['String']['output']>;
};

/** Result of pipeline product list request */
export type PipelineProductList = {
  __typename?: 'PipelineProductList';
  product_count: Scalars['Int']['output'];
  products: Array<DamProduct>;
};

/** Input parameters of the Pipeline product list Query */
export type PipelineProductListInput = {
  /** Product drop no */
  drop_no?: InputMaybe<Scalars['String']['input']>;
  /** limit for pagination */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** offset to skip records for pagination */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pipeline type */
  pipeline_type: Scalars['String']['input'];
};

/**
 * Input fields for the request
 * gender : "Gender type. Find more details on available types - [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/317882369/API+endpoints#GET-Categories-Popular)"
 */
export type PopularCategoriesFilter = {
  gender: Scalars['String']['input'];
};

/** Popular category type */
export type PopularCategory = {
  __typename?: 'PopularCategory';
  /** Curated or not */
  curated: Scalars['Boolean']['output'];
  /** Category id - cat_id in rest API */
  id: Scalars['ID']['output'];
  /** Category name - title in rest API */
  name: Scalars['String']['output'];
  /** Category Slug */
  slug: Scalars['String']['output'];
  /** Category thumbnail */
  thumbnail: Scalars['String']['output'];
  /** Category URL */
  url: Scalars['String']['output'];
};

export type PopularCategoryData = {
  __typename?: 'PopularCategoryData';
  curated: Scalars['Boolean']['output'];
  /** cat_id in rest API */
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  thumbnail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type PopularCategorySection = {
  __typename?: 'PopularCategorySection';
  data: Array<PopularCategoryData>;
  type: Scalars['String']['output'];
};

export type PositioningSection = {
  __typename?: 'PositioningSection';
  /** component_path is relative to the homepage v2 query result */
  component_path: Scalars['String']['output'];
  position: Scalars['Int']['output'];
};

/** Input of the processVASInvoices mutation */
export type ProcessVasInvoicesInput = {
  /** Type of invoice that will be processed */
  invoiceType: InvoiceType;
  /** URL of the uploaded VAS invoice file that will be processed */
  uploadUrl: Scalars['String']['input'];
};

/** Result of the processVASInvoices mutation */
export type ProcessVasInvoicesResult = {
  __typename?: 'ProcessVASInvoicesResult';
  /** Response code. */
  code: Scalars['String']['output'];
  /** VAS lines that were successfully processed */
  createdVasLines: Array<Scalars['String']['output']>;
  /** VAS lines that failed to be processed */
  failedVasLines: Array<FailedVasLine>;
  /** Response message. */
  message: Scalars['String']['output'];
};

/** Product interface. */
export type Product = {
  /** Product id. */
  id: Scalars['ID']['output'];
  /** Name of the product. */
  name: Scalars['String']['output'];
  /** Price of the product. */
  price: Scalars['Float']['output'];
  /** Regular price of the product. */
  regular_price: Scalars['Float']['output'];
};

export type ProductBlockHeader = {
  __typename?: 'ProductBlockHeader';
  link: Scalars['String']['output'];
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ProductBlocks = WithPosition & {
  __typename?: 'ProductBlocks';
  header: ProductBlockHeader;
  position: Scalars['Int']['output'];
  products: Array<CatalogProduct>;
  type: Scalars['String']['output'];
};

/** ProductBrand Type. */
export type ProductBrand = {
  __typename?: 'ProductBrand';
  /** Brand sale type which indicates if the brand is on flash sale or regular sale */
  brand_sale_type?: Maybe<BrandSaleType>;
  /** Cover image of the brand */
  cover_image?: Maybe<Scalars['String']['output']>;
  /** Designer brand */
  designer_brand: Scalars['Boolean']['output'];
  /** Valid genders for this branc */
  genders?: Maybe<Array<Scalars['String']['output']>>;
  good_on_you_info?: Maybe<BrandGoodOnYouInfo>;
  /** GoodOnYou pillars */
  good_on_you_pillars: Array<Scalars['String']['output']>;
  /** Id of the product brand. */
  id: Scalars['ID']['output'];
  /** Brand image */
  image?: Maybe<Scalars['String']['output']>;
  /** Is brand GoodOnYou */
  is_good_on_you: Scalars['Boolean']['output'];
  /** Logo of the brand */
  logo: Scalars['String']['output'];
  /** Name of the product brand. */
  name: Scalars['String']['output'];
  /** Slug of the product brand. */
  slug: Scalars['String']['output'];
};

/** ProductCurrency */
export type ProductCurrency = {
  __typename?: 'ProductCurrency';
  /** code of the currency */
  code: Scalars['String']['output'];
  /** Position of the symbol */
  position: Scalars['String']['output'];
  /** Symbol of the currency */
  symbol: Scalars['String']['output'];
};

/** Single product defect status type. */
export type ProductDefectStatus = {
  __typename?: 'ProductDefectStatus';
  /** Defect code */
  defect_code: Scalars['Int']['output'];
  /** description of the defect status */
  description?: Maybe<Scalars['String']['output']>;
  /** internal defect ID */
  id: Scalars['String']['output'];
};

/** ProductFiltersOptionOrder type. */
export type ProductFiltersOptionOrder = {
  __typename?: 'ProductFiltersOptionOrder';
  /** Category options sort order */
  category: Array<CategoryFilterOptionOrder>;
};

/** ProductGender Enum. */
export enum ProductGender {
  Kids = 'kids',
  Men = 'men',
  Women = 'women'
}

/** Product Image Source Types. */
export enum ProductImageSourceTypes {
  /** If source is fashion cloud */
  Fc = 'FC',
  /** If source is looklet */
  Looklet = 'LOOKLET',
  /** If source is manual */
  Manual = 'MANUAL',
  /** If source is photography uploads */
  Photography = 'PHOTOGRAPHY',
  /** If source is partner platform */
  Pp = 'PP',
  /** If source is visual API */
  VisualApi = 'VISUAL_API'
}

/** ProductLabel Type. */
export type ProductLabel = {
  __typename?: 'ProductLabel';
  /** Color of the label background */
  background_color?: Maybe<Scalars['String']['output']>;
  /** Label color string. */
  color: Scalars['String']['output'];
  /** ISO 8601 String representing when this label stops showing */
  expires_at?: Maybe<Scalars['String']['output']>;
  /** Unix timestamp representing when this label stops showing */
  expires_at_timestamp?: Maybe<Scalars['UnixTimestamp']['output']>;
  /** Label priority string. */
  priority: Scalars['Int']['output'];
  /** ISO 8601 String representing when this label starts showing */
  starts_at?: Maybe<Scalars['String']['output']>;
  /** Unix timestamp representing when this label starts showing */
  starts_at_timestamp?: Maybe<Scalars['UnixTimestamp']['output']>;
  /** Color of the label text */
  text_color?: Maybe<Scalars['String']['output']>;
  /** Label type string. */
  type: Scalars['String']['output'];
  /** Label value string. */
  value: Scalars['String']['output'];
};

/** ProductLabelFilter Enum. */
export enum ProductLabelFilter {
  All = 'ALL',
  HotDeals = 'HOT_DEALS',
  Icons = 'ICONS',
  LastChance = 'LAST_CHANCE',
  NewDiscount = 'NEW_DISCOUNT',
  NewIn = 'NEW_IN'
}

/** ProductLabel Type. */
export type ProductLabelInput = {
  /** Color of the label background */
  background_color?: InputMaybe<Scalars['String']['input']>;
  /** Label color string. */
  color: Scalars['String']['input'];
  /** ISO 8601 String representing when this label stops showing */
  expires_at?: InputMaybe<Scalars['String']['input']>;
  /** Unix timestamp representing when this label stops showing */
  expires_at_timestamp?: InputMaybe<Scalars['UnixTimestamp']['input']>;
  /** Label priority string. */
  priority: Scalars['Int']['input'];
  /** ISO 8601 String representing when this label starts showing */
  starts_at?: InputMaybe<Scalars['String']['input']>;
  /** Unix timestamp representing when this label starts showing */
  starts_at_timestamp?: InputMaybe<Scalars['UnixTimestamp']['input']>;
  /** Color of the label text */
  text_color?: InputMaybe<Scalars['String']['input']>;
  /** Label type string. */
  type: Scalars['String']['input'];
  /** Label value string. */
  value: Scalars['String']['input'];
};

/** Input parameters of the removeProductVisual mutation */
export type ProductMoveToDefectInput = {
  /** Reason for disapproval */
  product_defect_id: Scalars['String']['input'];
  /** Product ID of the product */
  product_id: Scalars['String']['input'];
};

export type ProductMoveToDefectResult = {
  __typename?: 'ProductMoveToDefectResult';
  /** success/failure */
  code: Scalars['String']['output'];
  /** success/failure status of each product */
  data?: Maybe<ProductMoveToDefectResultData>;
  /** success message */
  message?: Maybe<Scalars['String']['output']>;
};

export type ProductMoveToDefectResultData = {
  __typename?: 'ProductMoveToDefectResultData';
  failures?: Maybe<Array<Maybe<ProductMoveToDefectResultDetail>>>;
  successes?: Maybe<Array<Maybe<ProductMoveToDefectResultDetail>>>;
};

export type ProductMoveToDefectResultDetail = {
  __typename?: 'ProductMoveToDefectResultDetail';
  /** Target DAM internal Product defect id of failed/succeeded product */
  product_defect_id: Scalars['String']['output'];
  /** DAM internal Product id of failed/succeeded product */
  product_id: Scalars['String']['output'];
  /** Reason for a failure/ success if available */
  reason?: Maybe<Scalars['String']['output']>;
};

/** Default type of the Dam product size instance */
export type ProductSize = {
  __typename?: 'ProductSize';
  /** Size slug */
  slug: Scalars['String']['output'];
  /** Size value to display */
  value: Scalars['String']['output'];
};

/** ProductSummaryBrand Type. */
export type ProductSummaryBrand = Brand & {
  __typename?: 'ProductSummaryBrand';
  /** About sales desciption details. */
  about_sale_description: Array<Maybe<AboutSaleDescription>>;
  /** About sales image. */
  about_sale_image?: Maybe<Scalars['String']['output']>;
  /** Brand Description */
  brand_description: Scalars['String']['output'];
  /** Brand id. */
  id: Scalars['ID']['output'];
  /** Brand logo. */
  logo: Scalars['String']['output'];
  /** Brand name. */
  name: Scalars['String']['output'];
};

/** ProductVariation Type. */
export type ProductVariation = {
  __typename?: 'ProductVariation';
  /** Variation id */
  id: Scalars['ID']['output'];
  /** Regular price. */
  regular_price: Scalars['Float']['output'];
  /** Sales price. */
  sale_price?: Maybe<Scalars['Float']['output']>;
  /** Size */
  size?: Maybe<Scalars['String']['output']>;
  /** Stock quantity. */
  stock_quantity: Scalars['Int']['output'];
};

/** Single promotion */
export type Promotion = {
  __typename?: 'Promotion';
  /** Promotion background color */
  promo_background_color?: Maybe<Scalars['String']['output']>;
  /** Promotion code */
  promo_code?: Maybe<Scalars['String']['output']>;
  /** Promotion display type */
  promo_display_type?: Maybe<PromotionDisplayType>;
  /** Promotion end date */
  promo_end_date?: Maybe<Scalars['String']['output']>;
  /** Promotion highlight color */
  promo_highlight_color?: Maybe<Scalars['String']['output']>;
  /** Desktop image for promotion */
  promo_image_desktop?: Maybe<Scalars['String']['output']>;
  /** Mobile image for promotion */
  promo_image_mobile?: Maybe<Scalars['String']['output']>;
  /** Promotion message */
  promo_message?: Maybe<Scalars['String']['output']>;
  /** Promotion message url */
  promo_message_url?: Maybe<Scalars['String']['output']>;
  /** Promotion name */
  promo_name?: Maybe<Scalars['String']['output']>;
  /** Promotion start date */
  promo_start_date?: Maybe<Scalars['String']['output']>;
  /** Promotion text color */
  promo_text_color?: Maybe<Scalars['String']['output']>;
  /** Promotion url for kids - might not available for all queries (added based on categoryBySlug query) */
  promo_url_kids?: Maybe<Scalars['String']['output']>;
  /** Promotion url for men - might not available for all queries (added based on categoryBySlug query) */
  promo_url_men?: Maybe<Scalars['String']['output']>;
  /** Promotion url for women - might not available for all queries (added based on categoryBySlug query) */
  promo_url_women?: Maybe<Scalars['String']['output']>;
};

export type PromotionBannerData = {
  __typename?: 'PromotionBannerData';
  background_colour?: Maybe<Scalars['String']['output']>;
  banner_link?: Maybe<Scalars['String']['output']>;
  button_colour: Scalars['String']['output'];
  button_title: Scalars['String']['output'];
  condition_text?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  desktop_image: Scalars['String']['output'];
  end_date?: Maybe<Scalars['String']['output']>;
  gated?: Maybe<Scalars['Boolean']['output']>;
  hide_cta_button?: Maybe<Scalars['Boolean']['output']>;
  mobile_image: Scalars['String']['output'];
  mobile_image_height?: Maybe<Scalars['Int']['output']>;
  mobile_image_width?: Maybe<Scalars['Int']['output']>;
  promo_code?: Maybe<Scalars['String']['output']>;
  promo_code_colour?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['String']['output']>;
  tablet_image: Scalars['String']['output'];
  tablet_image_height?: Maybe<Scalars['Int']['output']>;
  tablet_image_width?: Maybe<Scalars['Int']['output']>;
  text_colour: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type PromotionBannerSection = WithPosition & {
  __typename?: 'PromotionBannerSection';
  data: Array<PromotionBannerData>;
  position: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export enum PromotionDisplayType {
  Default = 'DEFAULT',
  FlashSale = 'FLASH_SALE',
  LightningDeal = 'LIGHTNING_DEAL'
}

/** Promotion Type Enum. */
export enum PromotionType {
  Brand = 'brand',
  Category = 'category',
  Product = 'product'
}

export type PromotionalDiscount = {
  /** Discount percentage */
  percentage?: Maybe<Scalars['Float']['output']>;
  /** VendorPromotionalDiscountState either enabled or disabled */
  state: VendorPromotionalDiscountState;
};

export type PspApplePaySessionInput = {
  /** Domain where Apple Pay is initialised */
  domain: Scalars['String']['input'];
  /** Payment ID of the current payment */
  payment_id: Scalars['String']['input'];
  /** Validation URL coming from Apple */
  validation_url: Scalars['String']['input'];
};

export type PspApplePaySessionResponse = {
  __typename?: 'PspApplePaySessionResponse';
  /** Payment ID of the adjusted payment */
  payment_id: Scalars['String']['output'];
  /** Apple Pay related payment session */
  payment_session: Scalars['String']['output'];
};

export type PspPaymentCreateTransactionInput = {
  /** Apple Pay Payment Token in case the payment is being paid with Apple Pay */
  apple_pay_token?: InputMaybe<Scalars['String']['input']>;
  /** Card Token in case the payment is being paid with creditcard */
  card_token?: InputMaybe<Scalars['String']['input']>;
  /** Cart ID of the current cart */
  cart_id: Scalars['String']['input'];
  /** Payment ID of any previously created payments (to fetch payment methods) */
  payment_id: Scalars['String']['input'];
  /** Payment method the customer would like to pay with */
  payment_method: Scalars['String']['input'];
  /** Return URL to send the user towards to */
  return_url: Scalars['String']['input'];
};

export type PspPaymentCreateTransactionResponse = {
  __typename?: 'PspPaymentCreateTransactionResponse';
  /** Checkout URL from the PSP */
  checkout_url?: Maybe<Scalars['String']['output']>;
  /** Payment ID of the adjusted payment */
  payment_id: Scalars['String']['output'];
};

export type PspPaymentMethod = {
  __typename?: 'PspPaymentMethod';
  /** Description of the Payment Method */
  description: Scalars['String']['output'];
  /** ID of the Payment Method */
  id: Scalars['String']['output'];
  /** Icon of the Payment Method */
  image: Scalars['String']['output'];
};

export type PspPaymentMethodsInput = {
  /** ID of the cart the Payment needs to be attached to */
  cart_id: Scalars['String']['input'];
  /** Latest version of the cart */
  cart_version: Scalars['Int']['input'];
  /** Reference where the request is coming from */
  channel?: InputMaybe<Scalars['String']['input']>;
  /** Comma separated list of wallets to be included and supported from the frontend. Supported values: 'applepay' */
  include_wallets?: InputMaybe<Scalars['String']['input']>;
  /** PSP to create this payment for. Supported values: 'mollie' */
  psp?: InputMaybe<Scalars['String']['input']>;
};

export type PspPaymentMethodsResponse = {
  __typename?: 'PspPaymentMethodsResponse';
  /** ID of the payment session */
  payment_id: Scalars['String']['output'];
  /** Payment methods that are available for this specific shopping cart and country */
  payment_methods?: Maybe<Array<PspPaymentMethod>>;
};

export type PspPaymentState = {
  __typename?: 'PspPaymentState';
  /** Payment ID of the current payment */
  id: Scalars['String']['output'];
  /** Payment method that has been used for this particular payment */
  method?: Maybe<Scalars['String']['output']>;
  /** CT State of the payment (and its transaction): Success, Failure, Open */
  state: Scalars['String']['output'];
};

export type Pudo = {
  __typename?: 'Pudo';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type PudosData = {
  __typename?: 'PudosData';
  address: Scalars['String']['output'];
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  /**
   * Can be used to retrieve data from woo REST /v1/alert-bars?shop_type={type} end point.
   * >Cache ttl - 900
   */
  alertBar: AlertBars;
  /**
   * Get Brand by brand ID
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1091371009/GET+Brand+by+ID+Slug#GET-Brand-by-ID).
   * >Cache ttl - 900
   */
  brand?: Maybe<SingleBrand>;
  /**
   * Returns API gateway details of a brand using BRAND MC.
   * This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More about the query](https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Get-brand-API-gateway-details)
   * @deprecated(reason: "Feature has been removed from the insights dashboard")
   */
  brandApiGatewayDetails?: Maybe<SingleBrandApiGatewayDetails>;
  /**
   * Returns brand API keys as known to Brand MC. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * Input filter on ot_brand_id is required.
   */
  brandApiKeys?: Maybe<Array<Maybe<ApiKey>>>;
  /**
   * Get B2B marketplace auction pdf report url. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Get-B2B-marketplace-Auction-Pdf-Report)
   */
  brandB2bAuctionPdf?: Maybe<SingleB2bAuctionPdfReport>;
  /**
   * Get B2B bid by bid id. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1477246977/Bidding+API#GET-Single-Bid)
   */
  brandB2bBidById?: Maybe<SingleB2bBrandBid>;
  /**
   * Get B2B bids. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1477246977/Bidding+API#GET-Bids-By-User-ID)
   */
  brandB2bBids?: Maybe<Array<SingleB2bBrandBid>>;
  /**
   * Get B2B bids by vendor id. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1477246977/Bidding+API#Request)
   */
  brandB2bBidsByVendor?: Maybe<Array<SingleB2bBrandBid>>;
  /**
   * Get B2B marketplace deals as json. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Get-B2B-marketplace-Deals)
   */
  brandB2bDeals?: Maybe<SingleB2bDeals>;
  /**
   * Get B2B deals with bidding by status. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1477246977/Bidding+API#Request)
   */
  brandB2bDealsWithBidding: B2bDealWithBiddingResponse;
  /**
   * Get B2B pending bid by vendor id. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1477246977/Bidding+API#GET-Single-Pending-Bid)
   */
  brandB2bPendingBidByVendor: SingleB2bBrandPendingBid;
  /**
   * Get B2B marketplace product images report as json. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Get-B2B-marketplace-Product-Images-Report)
   */
  brandB2bProductImagesReport?: Maybe<SingleB2bProductImagesReport>;
  /**
   * Get B2B marketplace products overview json. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Get-B2B-marketplace-products-overview)
   */
  brandB2bProductsOverviewReport?: Maybe<SingleB2bProductsOverviewReport>;
  /**
   * Get B2B marketplace products as json. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Get-B2B-marketplace-products)
   */
  brandB2bProductsReport?: Maybe<SingleB2bProductsReport>;
  /**
   * Get Restricted Territories by vendor. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Get-Restricted-Territories)
   */
  brandB2bRestrictedTerritories?: Maybe<RestrictedTerritoriesResponse>;
  /**
   * Get B2B marketplace size overview as json. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Get-B2B-marketplace-Size-Overview)
   */
  brandB2bSizeOverview?: Maybe<SingleB2bSizeOverview>;
  /**
   * Get brand by product slug
   * >Cache ttl - 900
   */
  brandByProductSlug?: Maybe<ProductSummaryBrand>;
  /**
   * Returns a single brand using BRAND MC. This is completely different source than other available brand related queries atm. In future, all brands will be migrated toward this MC. Refer below link to understand more about this. [Brand MC - Read more ](https://otrium.atlassian.net/wiki/spaces/MIC/pages/542244901/Brand+Microservice)
   * This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More about the query](https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Get-brands-by-vendor-id)
   */
  brandByWooVendorId?: Maybe<BrandsListSingleBrand>;
  /**
   * Returns vendor information. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1162772512/GET+Vendor)
   */
  brandConfig?: Maybe<BrandConfig>;
  /**
   * Get conscious Rating Report. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Get-Good-On-You)
   */
  brandConsciousRating?: Maybe<BrandConsciousRatingResult>;
  /**
   * @deprecated(reason: "Feature has been removed from Oware")
   * Developed to send a drop report email to relevant vendors. Solely created for Oware & limited to oware users.
   * [Read More about the query](https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Send-drop-report-email)
   */
  brandDropReportEmail?: Maybe<SingleBrandDropReportEmailResult>;
  /**
   * Request drop reports of a vendor from Oware
   * [REST API] (https://galactic-shuttle-7879.postman.co/workspace/Otrium-Brand-Experience-(B2B)-W~08e47e1d-9a05-41a0-815e-886338e18659/request/8864210-a4791e08-86a1-4454-8c23-908fdc839aff?active-environment=5b350554-f38a-4f26-ac6d-de91e8d2cea7)
   */
  brandDropReports?: Maybe<Array<BrandDropReport>>;
  /**
   * Get Brand Geo Revenue. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Get-B2B-marketplace-Brand-Geo-Revenue)
   */
  brandGeoRevenue?: Maybe<Array<Maybe<BrandGeoRevenueResult>>>;
  /**
   * Returns vendor invoice pdf file based on invoice ID. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as header `Authorization: Bearer <JWT>`
   * All details about input parameters can be found using below link. It contains all details about the underlying rest API
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/317882369/API+endpoints#GET-Vendor-Invoice-Files-Export)
   */
  brandInvoiceReport?: Maybe<SingleBrandInvoiceReport>;
  /**
   * Returns vendor invoice data. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/317882369/API+endpoints#GET-Vendor)
   */
  brandInvoices?: Maybe<Array<Maybe<SingleBrandInvoice>>>;
  /**
   * Get B2B marketplace return rate dashboard url. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Get-Return-Rate-Dashboard)
   */
  brandItemReturnReport?: Maybe<Array<Maybe<ItemReturnReportResult>>>;
  /**
   * Returns vendor invoice data combined per month. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/317882369/API+endpoints#GET-Vendor-Invoices-per-month)
   */
  brandMonthlyInvoices?: Maybe<Array<Maybe<SingleBrandMonthlyInvoice>>>;
  /**
   * Sends notification email to partnership manager about brand intention to drop more items.. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/317882369/API+endpoints#GET-Plan-Drop)
   */
  brandPlanDrop?: Maybe<SingleBrandPlanDrop>;
  /**
   * `Deprecated` use `products` query.
   * @deprecated Use `products` query.
   */
  brandProducts?: Maybe<Array<Maybe<BrandProduct>>>;
  /**
   * Get Brand Products Performance. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Get-B2B-marketplace-Brand-Products-Performance)
   */
  brandProductsPerformance?: Maybe<Array<Maybe<BrandProductsPerformanceResult>>>;
  /**
   * Get Brand Products Reproduction. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Get-B2B-marketplace-Brand-Products-Reproduction)
   */
  brandProductsReproduction?: Maybe<Array<Maybe<BrandProductsReproductionResult>>>;
  /**
   * Returns vendor information as csv. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/317882369/API+endpoints#GET-Vendor-Exports)
   */
  brandReport?: Maybe<SingleBrandReport>;
  /**
   * Get B2B return rate by category report. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Get-Return-rate-by-category)
   */
  brandReturnRateByCategory?: Maybe<ReturnRateByCategoryResult>;
  /**
   * Get B2B return rate by geo revenue. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Get-Return-Rate-Geo-Revenue)
   */
  brandReturnRateGeoRevenue?: Maybe<Array<Maybe<ReturnRateGeoRevenueResult>>>;
  /**
   * Get B2B Return Dashboard return reason report. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#%5BhardBreak%5DGet-Return-Reason)
   */
  brandReturnReasonReport?: Maybe<Array<Maybe<ReturnReasonReportResult>>>;
  /**
   * Get B2B marketplace return rate scorecard report. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Get-Return-Rate-Scorecard)
   */
  brandReturnScorecardReport?: Maybe<ReturnScorecardResult>;
  /**
   * Get Returned Products Report. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Get-Returned-Products-Report)
   */
  brandReturnedProducts?: Maybe<Array<Maybe<BrandReturnedProductsResult>>>;
  /**
   * Can be used to retrieve data from woo REST /v2/brand_seo end point.
   * >Cache ttl - 900
   */
  brandSEO: BrandSeo;
  /**
   * Returns vendor information as csv. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * Data is fetching from reporting mc which connected to the data ware house.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Brand-Sales-Overview)
   */
  brandSale?: Maybe<SingleBrandSale>;
  /**
   * Returns brand shop pricing agreement information using the brand MC. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * Input filter is optional.
   * [Read More]( https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Get-shop-pricing-agreements)
   */
  brandShopPricingAgreements?: Maybe<Array<Maybe<BrandShopPricingAgreement>>>;
  /**
   * Returns brand shop pricing settings information using the brand MC. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * Input filter is optional.
   * [Read More]( https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Get-shop-pricing-settings)
   */
  brandShopPricingSettings?: Maybe<Array<Maybe<BrandShopPricingSetting>>>;
  /**
   * Returns brand shop targets information using the brand MC. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * Input filter is optional.
   * [Read More]( https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Get-shop-targets)
   */
  brandShopTargets?: Maybe<Array<Maybe<BrandShopTarget>>>;
  /**
   * Returns brand shop type information using the brand MC. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * Input filter is optional.
   * [Read More]( https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Get-shop-types)
   */
  brandShopTypes?: Maybe<Array<Maybe<BrandShopType>>>;
  /**
   * Returns brand shop information using the brand MC. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * Input filter is optional.
   * [Read More]( https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Get-shops)
   */
  brandShops?: Maybe<Array<Maybe<BrandShop>>>;
  /**
   * Get brand size guide by brand slug
   * >Cache ttl - 900
   */
  brandSizeGuideBySlug?: Maybe<Array<Maybe<BrandSizeGuide>>>;
  /**
   * Returns vendor stock overview data as json. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Brand-Stock-Overview)
   */
  brandStockOverview?: Maybe<SingleBrandStockOverview>;
  /**
   * Returns brand type information using brand MC. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * Input filter is optional.
   * [Read More]( https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Get-brand-types)
   */
  brandTypes?: Maybe<Array<Maybe<BrandType>>>;
  /**
   * Returns brand user priority information using the brand MC. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * Input filter is optional.
   * [Read More]( https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Get-user-priorities)
   */
  brandUserPriorities?: Maybe<Array<Maybe<BrandUserPriority>>>;
  /**
   * Returns brand user role information using the brand MC. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * Input filter is optional.
   * [Read More]( https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Get-user-roles)
   */
  brandUserRoles?: Maybe<Array<Maybe<BrandUserRole>>>;
  /**
   * Returns brand users with their role and priority information using brand MC. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * Input filter is optional.
   * [Read More]( https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Get-brand-users)
   */
  brandUsers?: Maybe<Array<Maybe<BrandUser>>>;
  /**
   * Get Brand VAS Invoice File. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Download-Vas-Invoice)
   */
  brandVASInvoiceFile?: Maybe<BrandVasInvoiceFile>;
  /**
   * Get Brand VAS Invoices. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Brand-Vas-Invoice)
   */
  brandVASInvoices?: Maybe<BrandVasInvoices>;
  /**
   * Returns all brands using BRAND MC. This is completely different source than other available brand related queries atm. In future, all brands will be migrated toward this MC. Refer below link to understand more about this. [Brand MC - Read more ](https://otrium.atlassian.net/wiki/spaces/MIC/pages/542244901/Brand+Microservice)
   * [Read More about the query](https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Get-brands).
   */
  brandsList?: Maybe<Array<Maybe<BrandsListSingleBrand>>>;
  /**
   * Returns Cart details by user id.. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/720306200/API+Cart+Rest#Request)
   */
  cart?: Maybe<SingleCart>;
  /** Returns cart page/screen product carousels */
  cartCarousels: CartCarouselsResult;
  /**
   * Returns catalog brand by filters.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/317882369/API+endpoints#POST-Catalog-Filters-Type)
   */
  catalogBrands?: Maybe<Array<Maybe<CatalogBrand>>>;
  /**
   * `Deprecated` use `products` query.
   * @deprecated Use `products` query.
   */
  catalogProducts?: Maybe<Array<Maybe<CatalogProduct>>>;
  /**
   * Returns categories by filters.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/317882369/API+endpoints#POST-Catalog-Filters-Type)
   */
  categories?: Maybe<Array<Maybe<CatalogCategory>>>;
  /**
   * Returns categories by filters with english translations.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/317882369/API+endpoints#POST-Catalog-Filters-Type)
   */
  categoriesWithEnglishTranslations?: Maybe<Array<Maybe<CatalogCategoryWithEnglishName>>>;
  /**
   * Returns a single category by it's slug.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1014104077/Category+by+Slug#GET-Category-by-Slug)
   * >Cache ttl - 900
   */
  categoryBySlug?: Maybe<SingleCategory>;
  /**
   * Can be used to retrieve data from content microservice REST /v1/content/conscious_page end point.
   * >Cache ttl - 900
   */
  consciousPage: ConsciousPage;
  /**
   * Returns all country domains from brand MC.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Get-Country-Domains)
   */
  countryDomains: Array<CountryDomain>;
  /** This query is used to return a brand by id. */
  damBrandById: DamBrand;
  /** This query is used to return all brand categories. */
  damBrandCategories: DamBrandCategoryList;
  /** This query is used to fetch dam Defect Report. */
  damDefectReport: DamDefectReportData;
  /**
   * Final product visual upload request.
   * This will return a signed URL for FE to upload the visual.
   */
  damFinalVisualUploadUrl: DamFinalVisualUploadUrlResponse;
  /**
   * Original/Cropped product visual manual upload request.
   * This will return a signed URL for Dam FE to upload the visual.
   */
  damManualVisualUploadUrl: DamManualVisualUploadUrlResponse;
  /** This query is used to fetch all mapped size mappings */
  damMappedSizeMapping?: Maybe<Array<Maybe<DamMappedSizeMapping>>>;
  /**
   * This query is used to fetch all pixyle attributes from DAM system
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OWARE/pages/1809088561/Get+attribute+mapped+values).
   */
  damProductAttributes: DamProductAttributesList;
  /**
   * This query is used to return a product by id.
   * Currently used for the dam system
   */
  damProductById: DamProduct;
  /** This query is used to fetch all categories with an optional filter for brand categories */
  damProductCategoryList: DamProductCategoryList;
  /**
   * deprecated(reason: "Feature has been removed and replaced with size mapping.")
   * This query is used to fetch product size mapping information
   * [Read More](https://otrium.atlassian.net/wiki/spaces/DP/pages/20405354503/Sizing+Containers)
   */
  damProductSizeMapping: Array<DamProductSizeMappingData>;
  /**
   * deprecated(reason: "Feature has been removed and replaced with size mapping.")
   * This query is used to fetch a sign url for dam size mapping file
   * [Read More](https://otrium.atlassian.net/wiki/spaces/DP/pages/20405354503/Sizing+Containers)
   */
  damProductSizeMappingFileUploadSignedUrl: DamProductSizeMappingFileSignedUrlData;
  /** This query is used to fetch all distinct size schemas based on names and slugs */
  damProductSizeSchema?: Maybe<Array<Maybe<DamProductSizeSchemaList>>>;
  /** This query is used to return a distinct list of product sizes */
  damProductSizes: Array<ProductSize>;
  /**
   * This query is used to return a product by id.
   * Currently used for the dam system
   */
  damProductStatus: DamProductStatus;
  /**
   * This query is used to fetch assigned pixyle attributes from DAM system
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OWARE/pages/1730740231/Get+product+attributes).
   */
  damSingleProductAttributes: DamSingleProductAttributesList;
  /**
   * deprecated(reason: "Feature has been removed and replaced with size mapping.")
   * This query is used to fetch dam size mapping Report.
   */
  damSizeMappingReport: DamSizeMappingReportData;
  /** This query is used to fetch dam size mapping report url. */
  damSizeMappingReportUrl: DamSizeMappingReportUrlData;
  /**
   * This query use to show empty search overly carousels
   * >Cache ttl - 10800
   */
  defaultSearchCarousels?: Maybe<CarouselResult>;
  deliveryOptions?: Maybe<DeliveryOptionsContent>;
  /**
   * Return designer categories by gender.
   * >Cache ttl - 900
   */
  designerCategories?: Maybe<Array<DesignerCategory>>;
  /**
   * Can be used to retrieve data from content microservice REST /v1/content/designer_homepage end point.
   * >Cache ttl - 900
   */
  designerHomepage: DesignerHomepage;
  discountTimerBar?: Maybe<DiscountTimerBar>;
  /**
   * Return legacy document url
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1100152871/GET+Lead+Time+message)
   */
  downloadLegacyDocument?: Maybe<DownloadLegacyDocument>;
  /**
   * Export insight Report. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Export-Insight-Report)
   */
  exportInsightReport?: Maybe<ExportInsightReportResult>;
  /**
   * Export inventory Report. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Export-Inventory-Data-Report)
   */
  exportInventoryReport?: Maybe<ExportInventoryReportResult>;
  /**
   * Export sales Report. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Export-Sales-Report)
   */
  exportSalesReport?: Maybe<ExportSalesReportResult>;
  /** This query is used to get all product defect statuses. */
  fetchProductDefectStatuses?: Maybe<Array<Maybe<ProductDefectStatus>>>;
  /** Filter product labels based on expire dates and sorting order */
  filterProductLabels?: Maybe<FilterProductLabelResult>;
  /**
   * Returns followed brands. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/317882369/API+endpoints#GET-Brand-Follow).
   */
  followedBrands?: Maybe<Array<Maybe<FollowedBrand>>>;
  /**
   * Returns the brand drops.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/920551439/Visual-Bank+Microservice+API+Endpoints#Get-Drops)
   */
  getBrandDrop?: Maybe<DropCollection>;
  /**
   * Returns download url of the brand drop.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/920551439/Visual-Bank+Microservice+API+Endpoints#Get-Drop-Download-Url)
   */
  getBrandDropDownloadUrl?: Maybe<BrandDropDownloadUrl>;
  /**
   * Returns signed url to upload the brand drop.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/920551439/Visual-Bank+Microservice+API+Endpoints#Get-Drop-Signed-Url)
   */
  getBrandDropUploadUrl?: Maybe<SignedUrl>;
  /**
   * Returns the brand visuals.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/920551439/Visual-Bank+Microservice+API+Endpoints#Get-Visuals)
   */
  getBrandVisual?: Maybe<VisualCollection>;
  /**
   * Returns signed url & name to upload dam - brand visuals.
   * [Read More](https://otrium.atlassian.net/browse/DM-664)
   */
  getBrandVisualUploadData?: Maybe<BrandVisualUploadData>;
  /**
   * Returns signed url to upload brand visual.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/920551439/Visual-Bank+Microservice+API+Endpoints#Get-signed-url)
   */
  getBrandVisualUploadUrl?: Maybe<SignedUrl>;
  /**
   * @deprecated Use getBrandVisualUploadData instead.
   * Returns signed url to upload dam/pipeline visual.
   * [Read More](https://otrium.atlassian.net/browse/DAM-864)
   */
  getDamVisualUploadUrl?: Maybe<SignedUrl>;
  /**
   * Initiates the multipart upload and returns the signed urls needed for the upload.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/920551439/Visual-Bank+Microservice+API+Endpoints#Get-Multipart-Upload-Signed-URLs)
   */
  getMultipartUploadSignedUrls?: Maybe<MultipartUploadSignedUrls>;
  /**
   * Get partner invites B2B list on Partner Platform. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Get-Partner-Invites-B2B)
   */
  getPartnerInvitesB2B?: Maybe<GetPartnerInvitesB2BMembers>;
  /** Returns SEO details for a given page url slug */
  getSeoContent?: Maybe<SeoQueryResponse>;
  /**
   * Returns a signed url in order to upload VAS Invoices
   * This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Postman request path](https://galactic-shuttle-7879.postman.co/workspace/Invoicing-Team~6c5e89cf-cc7a-4146-998a-ededf4b9c0ec/request/31783742-abcbe024-251f-4241-aa4d-ccb2b176eb56?ctx=documentation)
   */
  getVASInvoiceUploadUrl: VasInvoiceUploadUrlResult;
  /**
   * Returns the global flat commission model rates
   * This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   */
  globalFlatRate: GlobalFlatRate;
  globalFulfillmentRates?: Maybe<Array<GlobalFulfillmentRateSpecification>>;
  /**
   * Returns the global Otrium Fulfillment Services fees that can be charged
   * This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   */
  globalOFSfees: Array<GlobalOfSfee>;
  /**
   * Returns the global Tiered Item Price commission model rates
   * This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   */
  globalTieredItemPriceRates: Array<GlobalTieredItemPriceRate>;
  globalTieredRevenueTypeRates?: Maybe<Array<GlobalTieredRevenueTypeRateSpecification>>;
  /**
   * Returns featured and popular shops.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/317882369/API+endpoints#GET-Homepage-(deprecated)).
   */
  homePageBrands?: Maybe<HomePageBrands>;
  /**
   * Can be used to retrieve data from woo REST /v2/homepage{gender} end point.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1163067438/GET+Homepage).
   * >Cache ttl - 900
   * @deprecated use homepageV2
   */
  homepage: Homepage;
  /** >Cache ttl - 900 */
  homepageV2: HomepageV2;
  /**
   * Returns a signed url in order to download an Invoices' Combined PDF
   * This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Postman request path](https://galactic-shuttle-7879.postman.co/workspace/Invoicing-Team~6c5e89cf-cc7a-4146-998a-ededf4b9c0ec/request/21018010-dbac6487-93ee-49b8-af05-a20b599f8877?ctx=documentation)
   */
  invoiceCombinedPDFDownloadURL: InvoiceCombinedPdfDownloadUrlResult;
  /**
   * Returns total invoices by status from Commerce Engine (invoicing MC).
   * This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Postman request path](https://galactic-shuttle-7879.postman.co/workspace/Invoicing-Team~6c5e89cf-cc7a-4146-998a-ededf4b9c0ec/request/31783742-95e43450-6ed3-4b1a-aaa2-7de1751dfda2?ctx=documentation)
   */
  invoiceTotalsByStatus?: Maybe<InvoiceTotalsByStatus>;
  /**
   * Returns a signed url in order to download an Invoices' Transaction Report
   * This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Postman request path](https://galactic-shuttle-7879.postman.co/workspace/Invoicing-Team~6c5e89cf-cc7a-4146-998a-ededf4b9c0ec/request/21018010-514ea3aa-3a24-43dd-ae82-4cd56e136fc1?ctx=documentation)
   */
  invoiceTransactionReportDownloadURL: InvoiceTransactionReportDownloadUrlResult;
  /**
   * Returns invoices from Commerce Engine (invoicing MC).
   * This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More about the query](https://www.notion.so/otrium/Invoicing-2-0-8c64d73734e74944b8a934c7c2ec9977)
   * [Postman request path](https://galactic-shuttle-7879.postman.co/workspace/Invoicing-Team~6c5e89cf-cc7a-4146-998a-ededf4b9c0ec/request/21018010-90e5a948-07ef-43a4-9e0f-27c0c9045d66)
   */
  invoices?: Maybe<InvoiceCollection>;
  /**
   * Returns invoices summary for listing from Commerce Engine (invoicing MC).
   * This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Postman request path](https://galactic-shuttle-7879.postman.co/workspace/Invoicing-Team~6c5e89cf-cc7a-4146-998a-ededf4b9c0ec/request/31783742-711ab8ca-be40-46df-8c6a-8d8a2de02c7d?action=share&creator=31783742&ctx=documentation&active-environment=21018010-d29b2b1d-7c2b-4329-8b1d-9858313a716d)
   */
  invoicesSummary?: Maybe<InvoiceSummaryCollection>;
  /**
   * Returns invoices summary for listing from Commerce Engine (invoicing MC), by vendor id.
   * This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   */
  invoicesSummaryByVendor?: Maybe<InvoiceSummaryByVendorCollection>;
  /**
   * This query is used to return dam pipeline main dashboard data.
   * Currently used for the dam system
   */
  mainDashboard: PipelineMainDashboardResult;
  /**
   * Can be used to retrieve data from woo REST /v2/miscellaneous end point.
   * >Cache ttl - 900
   */
  miscellaneous: Miscellaneous;
  /**
   * Can be used to retrieve data from woo REST /v2/navigationItems{gender} end point.
   * >Cache ttl - 900
   */
  navigationItems: NavigationItems;
  /**
   * Get Net Sales Report. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   *  [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Get-B2B-marketplace-Net-Sales-Report)
   */
  netSalesReport?: Maybe<NetSalesReportResult>;
  /**
   * Returns ot_vendor_id from woo_vendor_id.
   * This endpoint requires a valid JWT.
   */
  otVendorIdFromWooId?: Maybe<VendorOtVendorId>;
  /**
   * Use this query to get content for Otrium static pages. This endpoint is public and not protected by any token.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/368541826/Pages+Micro-service)
   */
  page?: Maybe<SinglePage>;
  /** Get Partner details by email */
  partnerByEmail?: Maybe<SinglePartner>;
  /**
   * Returns payment specification of a single invoice bundle from Commerce Engine (invoicing MC).
   * This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Postman request path](https://galactic-shuttle-7879.postman.co/workspace/Invoicing-Team~6c5e89cf-cc7a-4146-998a-ededf4b9c0ec/request/31783742-922a68ae-08db-4b72-a236-d24ff19427a4?ctx=documentation)
   */
  paymentSpecification: PaymentSpecification;
  pdpCarousels?: Maybe<PdpCarouselsResult>;
  /**
   * Can be used to retrieve data from woo REST /v1/personalization/:type end point.
   * >Cache ttl - 900
   */
  personalization?: Maybe<Personalization>;
  /** Health check query */
  ping?: Maybe<Ping>;
  /** Ping to check if authorized for collections */
  pingStorefrontCollections?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   * This query is used to return dam pipeline attribute dashboard data.
   * Currently used for the dam system
   */
  pipelineAttributeDashboard: PipelineAttributeDashboardResult;
  /**
   * This query is used to return dam pipeline category dashboard data.
   * Currently used for the dam system
   */
  pipelineCategoryDashboard: PipelineCategoryDashboardResult;
  /**
   * This query is used to return dam pipeline defect dashboard data.
   * Currently used for the dam system
   */
  pipelineDefectDashboard: PipelineDefectDashboardResult;
  /**
   * `Deprecated` use `mainDashboard` query.
   * @deprecated Use `mainDashboard` query.
   */
  pipelineMainDashboard: PipelineMainDashboardResult;
  /** This query is used to find products from DAM system for a given pipeline and drop no */
  pipelineProductList: PipelineProductList;
  /**
   * This query is used to return dam pipeline qa dashboard data.
   * Currently used for the dam system
   */
  pipelineQADashboard: PipelineQaDashboardResult;
  /**
   * This query is used to return dam pipeline visual dashboard data.
   * Currently used for the dam system
   */
  pipelineVisualDashboard: PipelineVisualDashboardResult;
  /**
   * Get popular brand by gender. Gender can be either one of men,women,kids
   * >Cache ttl - 900
   */
  popularBrands?: Maybe<Array<Maybe<SummaryBrand>>>;
  /**
   * Return popular categories by gender.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/317882369/API+endpoints#GET-Categories-Popular)
   * >Cache ttl - 900
   */
  popularCategories?: Maybe<Array<PopularCategory>>;
  /**
   * Use this query to get the product details by slug.
   * >Cache ttl - 900
   */
  productBySlug?: Maybe<SingleProduct>;
  /**
   * Return PLP  product filter options order.
   * `Accept-Language` header is use to influence the language for this query.
   */
  productFiltersOptionOrder?: Maybe<ProductFiltersOptionOrder>;
  /** This query is used to get a promotion by type. */
  promotion: Promotion;
  /** Returns the state of the current payment */
  pspPaymentState?: Maybe<PspPaymentState>;
  /**
   * Retrieve saleslanding by slug.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1394573315/GET+Saleslanding)
   * >Cache ttl - 900
   */
  salesLanding?: Maybe<SingleSalesLanding>;
  /**
   * Retrieve products by search term. Additional input parameters can be used to fine tune the search results
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/525598736/Search+Microservice+API+Endpoints#Search-Products)
   */
  search?: Maybe<Search>;
  /** Expose nessasary configuration details for the client application to init search client. */
  searchConfigurations?: Maybe<SearchConfiguration>;
  /** Expose nessasary configuration details for the web client. */
  searchConfigurationsWeb?: Maybe<SearchConfiguration>;
  /** This query is used to search brands */
  searchDamBrands: DamBrandSearchResult;
  /**
   * This query is used to find products from DAM system
   * Currently using only for operations & not for b2c
   */
  searchDamProducts: DamProductSearch;
  /**
   * Return most selling categories of the vendors. his endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Get-brand-selling-categories-report)
   */
  sellingCategories: SellingCategory;
  /**
   * Returns most selling products of the vendors. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Get-brand-selling-products-report)
   */
  sellingProducts: SellingProduct;
  /**
   * Return the shipping address fields
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1643610148/Shipping+Address+Form+Fields).
   * >Cache ttl - 900
   */
  shippingFields?: Maybe<ShippingAddressContent>;
  shippingMethodDetails?: Maybe<ShippingMethodDetails>;
  /** Returns the Commercetools Public Store settings */
  storeSettings?: Maybe<StoreSettings>;
  /** Returns collection info */
  storefrontCollection?: Maybe<StorefrontCollectionInfo>;
  /** Returns a list of storefront collections */
  storefrontCollections?: Maybe<StorefrontCollectionList>;
  /**
   * Returns sub invoices by invoice id from Commerce Engine (invoicing MC).
   * This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Postman request path](https://galactic-shuttle-7879.postman.co/workspace/Invoicing-Team~6c5e89cf-cc7a-4146-998a-ededf4b9c0ec/request/31783742-31744ee3-6227-488b-b1d8-99ec1eceeb32?ctx=documentation)
   */
  subInvoices?: Maybe<SubInvoiceCollection>;
  /**
   * Returns sub invoices by vendor id from Commerce Engine (invoicing MC).
   * This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More about the query](https://www.notion.so/otrium/Invoicing-2-0-8c64d73734e74944b8a934c7c2ec9977)
   * [Postman request path](https://galactic-shuttle-7879.postman.co/workspace/Invoicing-Team~6c5e89cf-cc7a-4146-998a-ededf4b9c0ec/request/21018010-90e5a948-07ef-43a4-9e0f-27c0c9045d66)
   */
  subInvoicesByVendor?: Maybe<SubInvoiceByVendorCollection>;
  /**
   * Return top level categories by gender.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/317882369/API+endpoints#GET-Categories-Top-Level)
   * >Cache ttl - 900
   */
  topLevelCategories?: Maybe<TopLevelCategories>;
  /**
   * This endpoint returns all the relevant data related to user preferences.. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/317882369/API+endpoints#GET-User-Preferences)
   */
  userPreferences?: Maybe<UserPreferences>;
  /**
   * This endpoint returns all the relevant data related to user preferences by email. This is public query. and we cannot return same `UserPreferences` as response because woo api unable to return user_id as mandatory field.
   * [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1574207497/GET+User+Preference+by+Email)
   */
  userPreferencesByEmail?: Maybe<UserPreferencesByEmail>;
  /** This endpoint will return all data related to user profile. This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>` */
  userProfile?: Maybe<UserProfile>;
  /** Get meta data of user wishlist. */
  userWishlistMetaData?: Maybe<UserWishlistMeta>;
  /**
   * Validate product visual names against draft products - Image validation API
   * [Read More](https://otrimage-staging.otrium.com/doc#operations-tag-Image_validation)
   */
  validateBrandProductVisuals?: Maybe<BrandProductVisualsValidateResponse>;
  vasChargeTypes?: Maybe<Array<ValueAddedServiceChargeType>>;
  vendor?: Maybe<Vendor>;
  /**
   * Returns logs for a vendor.
   * This endpoint requires a valid JWT.
   */
  vendorLogs?: Maybe<VendorLogsCollection>;
  /**
   * Return all vendors from DWH (reporting MC).
   * This endpoint is protected by JWT. You should set valid JWT that obtain from userLogin as a header `Authorization: Bearer <JWT>`
   * [Read More about the query](https://otrium.atlassian.net/wiki/spaces/MIC/pages/634617918/Brand+Microservice+API+Endpoints#Get-brands-by-vendor-id)
   */
  vendors?: Maybe<Array<SingleVendor>>;
  /**
   * Returns all vendors from Brand microservice.
   * This endpoint requires a valid JWT.
   */
  vendorsV2?: Maybe<VendorCollection>;
  /** Get wishlist brands. */
  wishlistBrands?: Maybe<WishlistBrandCollection>;
  /** Get wishlist products. */
  wishlistProducts?: Maybe<WishlistProductCollection>;
};


export type QueryAlertBarArgs = {
  shop_type: Scalars['String']['input'];
};


export type QueryBrandArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBrandApiGatewayDetailsArgs = {
  woo_vendor_id: Scalars['ID']['input'];
};


export type QueryBrandApiKeysArgs = {
  filter: ApiKeyFilter;
};


export type QueryBrandB2bAuctionPdfArgs = {
  vendor_id: Scalars['ID']['input'];
};


export type QueryBrandB2bBidByIdArgs = {
  bid_id: Scalars['ID']['input'];
};


export type QueryBrandB2bBidsByVendorArgs = {
  vendor_id: Scalars['ID']['input'];
};


export type QueryBrandB2bDealsArgs = {
  filter?: InputMaybe<BrandB2bDealsFilter>;
};


export type QueryBrandB2bDealsWithBiddingArgs = {
  filter?: InputMaybe<BiddingFilter>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBrandB2bPendingBidByVendorArgs = {
  vendor_id: Scalars['ID']['input'];
};


export type QueryBrandB2bProductImagesReportArgs = {
  product_id: Scalars['ID']['input'];
  vendor_id: Scalars['ID']['input'];
};


export type QueryBrandB2bProductsOverviewReportArgs = {
  location?: InputMaybe<Scalars['String']['input']>;
  vendor_id: Scalars['ID']['input'];
};


export type QueryBrandB2bProductsReportArgs = {
  filter?: InputMaybe<BrandB2bProductsReportFilter>;
  vendor_id: Scalars['ID']['input'];
};


export type QueryBrandB2bRestrictedTerritoriesArgs = {
  universalVendorId: Scalars['String']['input'];
};


export type QueryBrandB2bSizeOverviewArgs = {
  product_id: Scalars['ID']['input'];
  vendor_id: Scalars['ID']['input'];
};


export type QueryBrandByProductSlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryBrandByWooVendorIdArgs = {
  woo_vendor_id: Scalars['ID']['input'];
};


export type QueryBrandConfigArgs = {
  user_id: Scalars['ID']['input'];
};


export type QueryBrandConsciousRatingArgs = {
  vendor_id: Scalars['ID']['input'];
};


export type QueryBrandDropReportEmailArgs = {
  asn_quantity: Scalars['Float']['input'];
  brand_universal_vendor_id: Scalars['Int']['input'];
  drop_number: Scalars['Int']['input'];
  drop_report_name: Scalars['String']['input'];
  received_quantity: Scalars['Float']['input'];
  variance: Scalars['Float']['input'];
};


export type QueryBrandDropReportsArgs = {
  input?: InputMaybe<BrandDropReportsInput>;
};


export type QueryBrandGeoRevenueArgs = {
  filter: BrandGeoRevenueFilter;
  vendor_id: Scalars['ID']['input'];
};


export type QueryBrandInvoiceReportArgs = {
  filter: BrandInvoiceReportInput;
  userId: Scalars['ID']['input'];
};


export type QueryBrandInvoicesArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryBrandItemReturnReportArgs = {
  filter: ItemReturnReportFilter;
  vendor_id: Scalars['ID']['input'];
};


export type QueryBrandMonthlyInvoicesArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryBrandPlanDropArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryBrandProductsArgs = {
  brandId: Scalars['ID']['input'];
  filter?: InputMaybe<BrandProductsFilter>;
};


export type QueryBrandProductsPerformanceArgs = {
  filter: BrandProductsPerformanceFilter;
  vendor_id: Scalars['ID']['input'];
};


export type QueryBrandProductsReproductionArgs = {
  filter: BrandProductsReproductionFilter;
  vendor_id: Scalars['ID']['input'];
};


export type QueryBrandReportArgs = {
  filter: BrandReportByUserIdFilterInput;
  userId: Scalars['ID']['input'];
};


export type QueryBrandReturnRateByCategoryArgs = {
  filter: ReturnRateByCategoryFilter;
  vendor_id: Scalars['ID']['input'];
};


export type QueryBrandReturnRateGeoRevenueArgs = {
  filter: ReturnRateGeoRevenueFilter;
  vendor_id: Scalars['ID']['input'];
};


export type QueryBrandReturnReasonReportArgs = {
  filter: ReturnReasonReportFilter;
  vendor_id: Scalars['ID']['input'];
};


export type QueryBrandReturnScorecardReportArgs = {
  filter: ReturnScorecardFilter;
  vendor_id: Scalars['ID']['input'];
};


export type QueryBrandReturnedProductsArgs = {
  filter: BrandReturnedProductsFilter;
  vendor_id: Scalars['ID']['input'];
};


export type QueryBrandSeoArgs = {
  brand_slug: Scalars['String']['input'];
  shop_type: ShopType;
};


export type QueryBrandSaleArgs = {
  filter: BrandSaleByUserIdFilterInput;
  userId: Scalars['ID']['input'];
};


export type QueryBrandShopPricingAgreementsArgs = {
  filter?: InputMaybe<BrandShopPricingAgreementFilter>;
};


export type QueryBrandShopPricingSettingsArgs = {
  filter?: InputMaybe<BrandShopPricingSettingFilter>;
};


export type QueryBrandShopTargetsArgs = {
  filter?: InputMaybe<BrandShopTargetFilter>;
};


export type QueryBrandShopTypesArgs = {
  filter?: InputMaybe<BrandShopTypeFilter>;
};


export type QueryBrandShopsArgs = {
  filter?: InputMaybe<BrandShopFilter>;
};


export type QueryBrandSizeGuideBySlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryBrandStockOverviewArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryBrandTypesArgs = {
  filter?: InputMaybe<BrandTypeFilter>;
};


export type QueryBrandUserPrioritiesArgs = {
  filter?: InputMaybe<BrandUserPriorityFilter>;
};


export type QueryBrandUserRolesArgs = {
  filter?: InputMaybe<BrandUserRoleFilter>;
};


export type QueryBrandUsersArgs = {
  filter?: InputMaybe<BrandUserFilter>;
};


export type QueryBrandVasInvoiceFileArgs = {
  invoice_id: Scalars['Int']['input'];
  vendor_id: Scalars['ID']['input'];
};


export type QueryBrandVasInvoicesArgs = {
  filter: BrandVasInvoicesFilter;
  vendor_id: Scalars['ID']['input'];
};


export type QueryBrandsListArgs = {
  filter?: InputMaybe<BrandsListFilter>;
};


export type QueryCartArgs = {
  filter: CartQueryInput;
};


export type QueryCartCarouselsArgs = {
  inputs: CartCarouselInputs;
};


export type QueryCatalogBrandsArgs = {
  filter?: InputMaybe<BrandFilter>;
};


export type QueryCatalogProductsArgs = {
  filter?: InputMaybe<CatalogProductFilter>;
};


export type QueryCategoriesArgs = {
  filter?: InputMaybe<CategoryFilter>;
};


export type QueryCategoriesWithEnglishTranslationsArgs = {
  filter?: InputMaybe<CategoryFilter>;
};


export type QueryCategoryBySlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryConsciousPageArgs = {
  filter?: InputMaybe<ConsciousPageFilter>;
  shop_type: Scalars['String']['input'];
};


export type QueryDamBrandByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryDamDefectReportArgs = {
  input: DamDefectReportInput;
};


export type QueryDamFinalVisualUploadUrlArgs = {
  input: DamFinalVisualUploadUrlInput;
};


export type QueryDamManualVisualUploadUrlArgs = {
  input: DamManualVisualUploadUrlInput;
};


export type QueryDamMappedSizeMappingArgs = {
  input?: InputMaybe<DamMappedSizeMappingInput>;
};


export type QueryDamProductByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryDamProductCategoryListArgs = {
  input?: InputMaybe<DamProductCategoryListInput>;
};


export type QueryDamProductStatusArgs = {
  ban: Scalars['String']['input'];
  storeKey: Scalars['String']['input'];
};


export type QueryDamSingleProductAttributesArgs = {
  input: DamSingleProductAttributesInput;
};


export type QueryDefaultSearchCarouselsArgs = {
  shop_type: ProductGender;
};


export type QueryDeliveryOptionsArgs = {
  country_code?: InputMaybe<Scalars['String']['input']>;
  internal_use?: InputMaybe<Scalars['Boolean']['input']>;
  post_code?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDesignerCategoriesArgs = {
  filter: DesignerCategoriesFilter;
};


export type QueryDesignerHomepageArgs = {
  filter?: InputMaybe<DesignerPageFilter>;
  shop_type: Scalars['String']['input'];
};


export type QueryDiscountTimerBarArgs = {
  shop_type?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDownloadLegacyDocumentArgs = {
  fileName: Scalars['String']['input'];
};


export type QueryExportInsightReportArgs = {
  filter: ExportInsightReportFilter;
  vendor_id: Scalars['ID']['input'];
};


export type QueryExportInventoryReportArgs = {
  filter: ExportInventoryReportFilter;
  vendor_id: Scalars['ID']['input'];
};


export type QueryExportSalesReportArgs = {
  filter: ExportSalesReportFilter;
  vendor_id: Scalars['ID']['input'];
};


export type QueryFilterProductLabelsArgs = {
  items?: InputMaybe<Array<InputMaybe<FilterProductLabelInputGroup>>>;
  page_content_type?: InputMaybe<PageContentType>;
};


export type QueryGetBrandDropArgs = {
  input?: InputMaybe<BrandDropInput>;
};


export type QueryGetBrandDropDownloadUrlArgs = {
  input: BrandDropDownloadUrlInput;
};


export type QueryGetBrandDropUploadUrlArgs = {
  input: BrandDropUploadUrlInput;
};


export type QueryGetBrandVisualArgs = {
  input?: InputMaybe<BrandVisualInput>;
};


export type QueryGetBrandVisualUploadDataArgs = {
  input: BrandVisualUploadDataInput;
};


export type QueryGetBrandVisualUploadUrlArgs = {
  input: BrandVisualUploadUrlInput;
};


export type QueryGetDamVisualUploadUrlArgs = {
  input: DamVisualUploadUrlInput;
};


export type QueryGetMultipartUploadSignedUrlsArgs = {
  input: GetMultipartUploadSignedUrlsInput;
};


export type QueryGetPartnerInvitesB2BArgs = {
  auth0_organization_id: Scalars['String']['input'];
  next_token?: InputMaybe<Scalars['String']['input']>;
  with_load_more?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetSeoContentArgs = {
  filter: SeoQueryInput;
};


export type QueryGetVasInvoiceUploadUrlArgs = {
  fileName: Scalars['String']['input'];
  invoiceType: InvoiceType;
};


export type QueryHomePageBrandsArgs = {
  category: Scalars['String']['input'];
};


export type QueryHomepageArgs = {
  filter?: InputMaybe<HomepageFilter>;
  gender: Scalars['String']['input'];
};


export type QueryInvoiceCombinedPdfDownloadUrlArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInvoiceTransactionReportDownloadUrlArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInvoicesArgs = {
  invoiceStatus?: InputMaybe<Array<InvoiceStatus>>;
  invoiceType?: InputMaybe<Array<InvoiceType>>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInvoicesSummaryArgs = {
  invoiceStatus?: InputMaybe<Array<InvoiceStatus>>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryInvoicesSummaryByVendorArgs = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  vendor_id: Scalars['ID']['input'];
};


export type QueryMainDashboardArgs = {
  input: PipelineMainDashboardInput;
};


export type QueryNavigationItemsArgs = {
  shop_type: Scalars['String']['input'];
};


export type QueryNetSalesReportArgs = {
  filter: NetSalesReportFilter;
  vendor_id: Scalars['ID']['input'];
};


export type QueryOtVendorIdFromWooIdArgs = {
  woo_vendor_id: Scalars['String']['input'];
};


export type QueryPageArgs = {
  filter: PageInputFilter;
};


export type QueryPartnerByEmailArgs = {
  input: PartnerByEmailInput;
};


export type QueryPaymentSpecificationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPdpCarouselsArgs = {
  carousel_recommend_type?: InputMaybe<CarouselRecommendTypeInput>;
  limit: Scalars['Int']['input'];
  recommend_type?: InputMaybe<Scalars['String']['input']>;
  shop_type: ProductGender;
  slug: Scalars['String']['input'];
};


export type QueryPipelineProductListArgs = {
  input: PipelineProductListInput;
};


export type QueryPopularBrandsArgs = {
  category: Scalars['String']['input'];
  excluded?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPopularCategoriesArgs = {
  filter: PopularCategoriesFilter;
};


export type QueryProductBySlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryPromotionArgs = {
  shop_type: ShopType;
  slug: Scalars['String']['input'];
  type: PromotionType;
};


export type QueryPspPaymentStateArgs = {
  payment_id?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySalesLandingArgs = {
  slug: Scalars['String']['input'];
};


export type QuerySearchArgs = {
  filter: SearchFilter;
};


export type QuerySearchConfigurationsWebArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type QuerySearchDamBrandsArgs = {
  input: DamBrandSearchInput;
};


export type QuerySearchDamProductsArgs = {
  input: DamProductSearchInput;
};


export type QuerySellingCategoriesArgs = {
  filter?: InputMaybe<SellingCategoryFilter>;
  user_id: Scalars['ID']['input'];
};


export type QuerySellingProductsArgs = {
  filter?: InputMaybe<SellingProductFilter>;
  user_id: Scalars['ID']['input'];
};


export type QueryShippingFieldsArgs = {
  country_code?: InputMaybe<Scalars['String']['input']>;
};


export type QueryShippingMethodDetailsArgs = {
  filter: AddressFilter;
};


export type QueryStorefrontCollectionArgs = {
  slug: Scalars['String']['input'];
};


export type QuerySubInvoicesArgs = {
  id: Scalars['ID']['input'];
  invoiceType?: InputMaybe<InvoiceType>;
};


export type QuerySubInvoicesByVendorArgs = {
  invoiceStatus?: InputMaybe<Array<InvoiceStatus>>;
  invoiceType?: InputMaybe<Array<InvoiceType>>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  vendor_id: Scalars['ID']['input'];
};


export type QueryTopLevelCategoriesArgs = {
  filter: TopLevelCategoriesFilter;
};


export type QueryUserPreferencesArgs = {
  shop_type?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserPreferencesByEmailArgs = {
  shop_type?: InputMaybe<Scalars['String']['input']>;
  user_email?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserProfileArgs = {
  email: Scalars['String']['input'];
};


export type QueryValidateBrandProductVisualsArgs = {
  input: BrandProductVisualsValidateInput;
};


export type QueryVendorArgs = {
  date_time?: InputMaybe<Scalars['String']['input']>;
  ot_vendor_id: Scalars['String']['input'];
};


export type QueryVendorLogsArgs = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  ot_vendor_id: Scalars['String']['input'];
};


export type QueryVendorsV2Args = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  search_value?: InputMaybe<Scalars['String']['input']>;
  with_temporary_exception?: InputMaybe<TemporaryCommercialSettingsStateFilter>;
};


export type QueryWishlistBrandsArgs = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  sort?: InputMaybe<WishlistSort>;
};


export type QueryWishlistProductsArgs = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  sort?: InputMaybe<WishlistSort>;
};

export type RateSpecification = {
  __typename?: 'RateSpecification';
  max_amount?: Maybe<Scalars['Float']['output']>;
  min_amount: Scalars['Float']['output'];
  rate: Scalars['Float']['output'];
};

/** Common product collection type for recommendation. */
export type RecommendedProductCollection = {
  __typename?: 'RecommendedProductCollection';
  /** Recommended products. */
  items?: Maybe<Array<CatalogProduct>>;
  /** Total items. */
  total: Scalars['Int']['output'];
};

/** Result of login logout Url */
export type RedirectUrlResult = {
  __typename?: 'RedirectUrlResult';
  /** This loginUrl */
  loginUrl: Scalars['String']['output'];
  /** This logoutUrl */
  logoutUrl: Scalars['String']['output'];
};

export type ReferFriendData = {
  __typename?: 'ReferFriendData';
  image: Scalars['String']['output'];
  link: Scalars['String']['output'];
  linkCTA?: Maybe<Scalars['String']['output']>;
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ReferFriendSection = {
  __typename?: 'ReferFriendSection';
  data?: Maybe<ReferFriendData>;
  type: Scalars['String']['output'];
};

export type RefreshTokenResult = {
  __typename?: 'RefreshTokenResult';
  /** This contains the new JWT */
  new_token: Scalars['String']['output'];
};

/** Used as a general type to link parent,children & siblings categories */
export type RelatedSingleCategory = {
  __typename?: 'RelatedSingleCategory';
  /** category id (term id in woo shop) */
  id: Scalars['ID']['output'];
  /** Category name */
  name: Scalars['String']['output'];
  /** Category slug */
  slug: Scalars['String']['output'];
};

/** Input parameters of the removeProductVisual mutation */
export type RemoveProductVisualInput = {
  /** Product ID of the product(Dam system id) */
  product_id: Scalars['String']['input'];
  /** Visual URL of the product(Full url) */
  visual_url: Scalars['String']['input'];
};

/** Result of product visual remove request */
export type RemoveProductVisualResult = {
  __typename?: 'RemoveProductVisualResult';
  /** success message */
  message: Scalars['String']['output'];
};

/** Input parameters of the removeProductVisual mutation */
export type RemoveProductVisualsInput = {
  /** Drop no  */
  drop_no?: InputMaybe<Scalars['String']['input']>;
  /** Product ID of the product(Dam system id) */
  product_id?: InputMaybe<Scalars['String']['input']>;
};

/** Result of product visual remove request */
export type RemoveProductVisualsResult = {
  __typename?: 'RemoveProductVisualsResult';
  /** success message */
  message: Scalars['String']['output'];
};

/** Response of restricted territories */
export type RestrictedTerritoriesResponse = {
  __typename?: 'RestrictedTerritoriesResponse';
  restrictedTerritories: Array<Maybe<RestrictedTerritory>>;
};

/** Result of restricted territories update */
export type RestrictedTerritoriesUpdateResult = {
  __typename?: 'RestrictedTerritoriesUpdateResult';
  result: Scalars['String']['output'];
};

/** Instance of a single Restricted Territory info */
export type RestrictedTerritory = {
  __typename?: 'RestrictedTerritory';
  /** Two-letter country code defined in ISO 3166-1 */
  countryCode: Scalars['String']['output'];
  /** Country name in English */
  countryName: Scalars['String']['output'];
};

export type RestrictedTerritoryInput = {
  /** Two-letter country code defined in ISO 3166-1 */
  countryCode: Scalars['String']['input'];
  /** Country name in English */
  countryName: Scalars['String']['input'];
};

/** Filtering options */
export type ReturnRateByCategoryFilter = {
  /** Condition. Possible values: ASC, DESC */
  condition: Scalars['String']['input'];
  /** From date (Y-m-d) */
  from_date: Scalars['String']['input'];
  /** Limit */
  limit: Scalars['Int']['input'];
  /** Sort by. Possible values: returnRateBySales, returnRateByQuantity */
  sort_by: Scalars['String']['input'];
  /** To date (Y-m-d) */
  to_date: Scalars['String']['input'];
};

/** Result for the query */
export type ReturnRateByCategoryResult = {
  __typename?: 'ReturnRateByCategoryResult';
  /** KIDS */
  KIDS?: Maybe<Array<Maybe<SingleCategoryResult>>>;
  /** MEN */
  MEN?: Maybe<Array<Maybe<SingleCategoryResult>>>;
  /** WOMEN */
  WOMEN?: Maybe<Array<Maybe<SingleCategoryResult>>>;
};

/** Filtering options */
export type ReturnRateGeoRevenueFilter = {
  /** From date (Y-m-d) */
  from_date: Scalars['String']['input'];
  /** To date (Y-m-d) */
  to_date: Scalars['String']['input'];
};

/** Result for the query */
export type ReturnRateGeoRevenueResult = {
  __typename?: 'ReturnRateGeoRevenueResult';
  /** geoCode */
  geoCode: Scalars['String']['output'];
  /** grossQuantitySold */
  grossQuantitySold: Scalars['Int']['output'];
  /** grossSalesExcVat */
  grossSalesExcVat: Scalars['Float']['output'];
  /** returnGrossQuantitySold */
  returnGrossQuantitySold: Scalars['Int']['output'];
  /** returnGrossSalesExcVat */
  returnGrossSalesExcVat: Scalars['Float']['output'];
  /** returnRateAmount */
  returnRateAmount: Scalars['Float']['output'];
  /** returnRateQuantity */
  returnRateQuantity: Scalars['Float']['output'];
};

/** Filtering options */
export type ReturnReasonReportFilter = {
  /** From date (Y-m-d) */
  from_date: Scalars['String']['input'];
  /** Limit */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** To date (Y-m-d) */
  to_date: Scalars['String']['input'];
};

/** Result for the query */
export type ReturnReasonReportResult = {
  __typename?: 'ReturnReasonReportResult';
  /** percentage */
  percentage: Scalars['Float']['output'];
  /** reasonName */
  reasonName: Scalars['String']['output'];
  /** returnReasonCount */
  returnReasonCount: Scalars['Int']['output'];
  /** row */
  row: Scalars['Int']['output'];
};

/** Filtering options */
export type ReturnScorecardFilter = {
  /** From date (Y-m-d) */
  from_date: Scalars['String']['input'];
  /** To date (Y-m-d) */
  to_date: Scalars['String']['input'];
};

/** Result for the query */
export type ReturnScorecardResult = {
  __typename?: 'ReturnScorecardResult';
  /** grossQuantitySold */
  grossQuantitySold: Scalars['Int']['output'];
  /** grossSalesExcVat */
  grossSalesExcVat: Scalars['Float']['output'];
  /** grossSalesExcVatUsd */
  grossSalesExcVatUsd: Scalars['Float']['output'];
  /** previousGrossQuantitySold */
  previousGrossQuantitySold: Scalars['Int']['output'];
  /** previousGrossSalesExcVat */
  previousGrossSalesExcVat: Scalars['Float']['output'];
  /** previousReturnGrossQuantitySold */
  previousReturnGrossQuantitySold: Scalars['Int']['output'];
  /** previousReturnGrossSalesExcVat */
  previousReturnGrossSalesExcVat: Scalars['Float']['output'];
  /** previousReturnRateAmount */
  previousReturnRateAmount: Scalars['Float']['output'];
  /** previousReturnRateQuantity */
  previousReturnRateQuantity: Scalars['Float']['output'];
  /** returnGrossQuantitySold */
  returnGrossQuantitySold: Scalars['Int']['output'];
  /** returnGrossSalesExcVat */
  returnGrossSalesExcVat: Scalars['Float']['output'];
  /** returnGrossSalesExcVatUsd */
  returnGrossSalesExcVatUsd: Scalars['Float']['output'];
  /** returnRateAmount */
  returnRateAmount: Scalars['Float']['output'];
  /** returnRateQuantity */
  returnRateQuantity: Scalars['Float']['output'];
};

/** SEO page query input */
export type SeoQueryInput = {
  page_url_slug: Scalars['String']['input'];
};

/** SEO page query response type */
export type SeoQueryResponse = {
  __typename?: 'SEOQueryResponse';
  brand?: Maybe<Scalars['String']['output']>;
  canonical_tags?: Maybe<Scalars['String']['output']>;
  meta_description?: Maybe<Scalars['String']['output']>;
  meta_keywords?: Maybe<Scalars['String']['output']>;
  meta_title?: Maybe<Scalars['String']['output']>;
  page_type?: Maybe<Scalars['String']['output']>;
  robots_tag_value?: Maybe<Scalars['String']['output']>;
};

/** Search Type. */
export type Search = {
  __typename?: 'Search';
  /** Search Brands. */
  brands?: Maybe<Array<SearchBrand>>;
  /** Search Categories. */
  categories?: Maybe<Array<SearchCategory>>;
  /** Search Products. */
  products?: Maybe<Array<SearchProduct>>;
};

/** SearchBrand Type. */
export type SearchBrand = Brand & {
  __typename?: 'SearchBrand';
  /** Extra details of the brand. */
  extra: SearchBrandExtra;
  /** Brand id */
  id: Scalars['ID']['output'];
  /** Brand name. */
  name: Scalars['String']['output'];
  /** Brand slug. */
  slug: Scalars['String']['output'];
  /** Brand type. */
  type: Scalars['String']['output'];
};

/** SearchBrandExtra Type. */
export type SearchBrandExtra = {
  __typename?: 'SearchBrandExtra';
  /** Designer brand */
  designer_brand?: Maybe<Scalars['Boolean']['output']>;
  /** Available genders */
  genders?: Maybe<Array<Scalars['String']['output']>>;
  /** Is gold brand */
  is_gold: Scalars['Boolean']['output'];
  /**
   * Wishlist item id of the user.
   * @deprecated Decided to go with userWishlistMeta.
   */
  user_wishlist_item_id?: Maybe<Scalars['String']['output']>;
};

/** SearchCategory Type. */
export type SearchCategory = {
  __typename?: 'SearchCategory';
  /** Extra category details. */
  extra: SearchCategoryExtra;
  /** Id of the Search category. */
  id: Scalars['ID']['output'];
  /** Name of the category. */
  name: Scalars['String']['output'];
  /** Slug of the category. */
  slug: Scalars['String']['output'];
  /** Type of the category. */
  type: Scalars['String']['output'];
};

/** SearchCategoryExtra Type. */
export type SearchCategoryExtra = {
  __typename?: 'SearchCategoryExtra';
  /** Gender of the category */
  gender: Scalars['String']['output'];
  /** Parent path of the category. */
  parent_path?: Maybe<Array<Maybe<SearchCategoryExtraParentPath>>>;
  /** Parent path of the category. */
  parent_path_string?: Maybe<Scalars['String']['output']>;
  /** Parent path string span. */
  parent_path_string_span?: Maybe<Scalars['String']['output']>;
};

/** SearchCategoryExtraParentPath Type. */
export type SearchCategoryExtraParentPath = {
  __typename?: 'SearchCategoryExtraParentPath';
  /** Id of the category. */
  id: Scalars['ID']['output'];
  /** Name of the category. */
  name: Scalars['String']['output'];
};

/** SearchConfiguration Type */
export type SearchConfiguration = {
  __typename?: 'SearchConfiguration';
  /** Search App ID */
  app_id: Scalars['String']['output'];
  /** Search API Key */
  search_api_key: Scalars['String']['output'];
};

/** SearchFilter Input */
export type SearchFilter = {
  /** Gender. `men`, `women`, `kids` */
  gender?: InputMaybe<Scalars['String']['input']>;
  /** Language. */
  lang?: InputMaybe<Scalars['String']['input']>;
  /** Max results to return. */
  max_results?: InputMaybe<Scalars['Int']['input']>;
  /** Query string. */
  q: Scalars['String']['input'];
};

/** SearchProduct Type. */
export type SearchProduct = Product & {
  __typename?: 'SearchProduct';
  /** Extra details of the product. */
  extra: SearchProductExtra;
  /** Id of the product. */
  id: Scalars['ID']['output'];
  /** Name of the product. */
  name: Scalars['String']['output'];
  /** Price of the product. */
  price: Scalars['Float']['output'];
  /** Regular price of the product. */
  regular_price: Scalars['Float']['output'];
  /** Slug of the product. */
  slug: Scalars['String']['output'];
  /** Type of the product. */
  type: Scalars['String']['output'];
};

/** SearchProductExtra Type. */
export type SearchProductExtra = {
  __typename?: 'SearchProductExtra';
  /** Name of the product brand. */
  brand?: Maybe<Scalars['String']['output']>;
  /** List of categories ids. */
  categories?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  currencies?: Maybe<Array<SearchProductExtraCurrency>>;
  /** Currency type */
  currency: ProductCurrency;
  /** Designer brand */
  designer_brand?: Maybe<Scalars['Boolean']['output']>;
  /** Image of the product. */
  image?: Maybe<Scalars['String']['output']>;
  /** Is gold */
  is_gold: Scalars['Boolean']['output'];
  /** Product Labels */
  labels: Array<ProductLabel>;
  /** Product SKU. */
  sku?: Maybe<Scalars['String']['output']>;
  /**
   * Wishlist item id of the user.
   * @deprecated Decided to go with userWishlistMeta.
   */
  user_wishlist_item_id?: Maybe<Scalars['String']['output']>;
};

/** Type used to reflect extra currencies section in search product */
export type SearchProductExtraCurrency = {
  __typename?: 'SearchProductExtraCurrency';
  /** String ID */
  id: Scalars['ID']['output'];
  /** Price */
  price: Scalars['String']['output'];
  /** Regular Price */
  regular_price: Scalars['String']['output'];
};

/** Segment identify mutation input type. */
export type SegmentIdentifyInput = {
  /** Anonymous ID */
  anonymous_id: Scalars['String']['input'];
  /** Shopper Email */
  shopper_email: Scalars['String']['input'];
};

/** Segment identify mutation result type. */
export type SegmentIdentifyResult = {
  __typename?: 'SegmentIdentifyResult';
  /** If it succeeded */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** SellingCategory type. */
export type SellingCategory = {
  __typename?: 'SellingCategory';
  /** Meta details */
  meta: SellingCategoryMeta;
  /** Report */
  report: Array<Maybe<SellingCategoryReport>>;
};

/**
 * SellingCategoryFilter Type.
 * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Get-brand-selling-categories-report)
 */
export type SellingCategoryFilter = {
  /** Days ago */
  days_ago?: InputMaybe<Scalars['Int']['input']>;
  /** Limit response */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Offset */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** segments as array */
  segments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Sort by type */
  sort?: InputMaybe<Scalars['String']['input']>;
  /** Sort by value */
  sort_by?: InputMaybe<Scalars['String']['input']>;
};

export type SellingCategoryMeta = {
  __typename?: 'SellingCategoryMeta';
  /** Total results count */
  total_results: Scalars['Int']['output'];
};

export type SellingCategoryReport = {
  __typename?: 'SellingCategoryReport';
  /** live now */
  live_now: Scalars['Int']['output'];
  /** Low in stock */
  low_in_stock: Scalars['Boolean']['output'];
  /** Most sold size */
  most_sold_size: Scalars['String']['output'];
  /** Name of the category. */
  name?: Maybe<Scalars['String']['output']>;
  /** Net Sale */
  net_sale: Scalars['String']['output'];
  /** Rank of a category */
  nr: Scalars['Int']['output'];
  /** product segment */
  product_segment: Scalars['String']['output'];
  /** Sell through value */
  sell_through: Scalars['String']['output'];
  /** Sold in 7 days */
  sold_7days: Scalars['Int']['output'];
  /** Sold ratio value */
  sold_ratio?: Maybe<Scalars['String']['output']>;
  /** Stock ratio value */
  stock_ratio?: Maybe<Scalars['String']['output']>;
  /** Total sold  type */
  total_sold?: Maybe<Scalars['Int']['output']>;
};

/** SellingProduct type. */
export type SellingProduct = {
  __typename?: 'SellingProduct';
  /** Meta details */
  meta: SellingProductMeta;
  /** Report */
  report: Array<Maybe<SellingProductReport>>;
  /** Selling product data - need to parse this value in consumer application */
  reportData?: Maybe<Scalars['String']['output']>;
};

/**
 * SellingProductFilter Type.
 * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Get-brand-selling-products-report)
 */
export type SellingProductFilter = {
  /** Used to return products that meet only ab-fashion criteria */
  ab_fashion?: InputMaybe<Scalars['Boolean']['input']>;
  /** Days ago */
  days_ago?: InputMaybe<Scalars['Int']['input']>;
  /** Limit response */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Offset */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Used to return products that meet replenishment/re-stock criteria */
  replenishment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Type of the response you want. csv or data */
  response_format?: InputMaybe<Scalars['String']['input']>;
  /** segments as array */
  segments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Sort type */
  sort?: InputMaybe<Scalars['String']['input']>;
  /** Sort by value - Refer API doc for values */
  sort_by?: InputMaybe<Scalars['String']['input']>;
};

export type SellingProductMeta = {
  __typename?: 'SellingProductMeta';
  /** Total results count */
  total_results: Scalars['Int']['output'];
};

export type SellingProductReport = {
  __typename?: 'SellingProductReport';
  /** ab_fashion flag */
  ab_fashion: Scalars['Boolean']['output'];
  /** Absolute sold of the product for the past x days */
  abs_sold: Scalars['Int']['output'];
  /** Current stock value */
  current_stock: Scalars['Int']['output'];
  /** low_in_stock */
  low_in_stock?: Maybe<Scalars['Boolean']['output']>;
  /** Most sold size type */
  most_sold_size?: Maybe<Scalars['String']['output']>;
  /** Name of the product. */
  name?: Maybe<Scalars['String']['output']>;
  /** Net Sales */
  net_sales: Scalars['String']['output'];
  /** Rank of a product */
  nr: Scalars['Int']['output'];
  /** product id */
  product_id: Scalars['Int']['output'];
  /** product segment */
  product_segment: Scalars['String']['output'];
  /** Sell through value */
  sell_through: Scalars['String']['output'];
  /** Sold in last 7 days */
  sold_last_7days: Scalars['Int']['output'];
  /** Sold ratio value */
  sold_ratio?: Maybe<Scalars['String']['output']>;
  /** Stock ratio value */
  stock_ratio?: Maybe<Scalars['String']['output']>;
  /** product url */
  url?: Maybe<Scalars['String']['output']>;
};

/** ShippingAddress type */
export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  additionalAddressInfo?: Maybe<Scalars['String']['output']>;
  additionalStreetInfo?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  houseAddition?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
};

export type ShippingAddressContent = {
  __typename?: 'ShippingAddressContent';
  all_countries?: Maybe<Array<AllowedCountriesData>>;
  allowed_country_codes?: Maybe<Array<Scalars['String']['output']>>;
  billing_fields?: Maybe<Array<FieldsData>>;
  country: CountryData;
  fields?: Maybe<Array<FieldsData>>;
};

export type ShippingMethodDetails = {
  __typename?: 'ShippingMethodDetails';
  fietskoeriers: Scalars['Boolean']['output'];
  pudos?: Maybe<Array<Pudo>>;
};

export type ShopPreference = {
  __typename?: 'ShopPreference';
  desktop_image?: Maybe<Scalars['String']['output']>;
  is_selected: Scalars['Boolean']['output'];
  mobile_image: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type ShopSection = {
  __typename?: 'ShopSection';
  data?: Maybe<Array<SingleBrandSection>>;
  type: Scalars['String']['output'];
};

/** Shop Type Enum. */
export enum ShopType {
  Kids = 'kids',
  Men = 'men',
  Women = 'women'
}

export type SignUpPayload = {
  __typename?: 'SignUpPayload';
  /** email address */
  email: Scalars['String']['output'];
  /** gender type */
  gender: Scalars['String']['output'];
  /** ot_accounts ID for segment flow */
  member_id: Scalars['ID']['output'];
  /** Name of the user */
  name?: Maybe<Scalars['String']['output']>;
  /** On-Boarding metadata of the user */
  onboarding_metadata: OnboardingMetadata;
  /** if user accepted promo terms or no. */
  opt_in: Scalars['Boolean']['output'];
  /** if promo popup showed or not. */
  show_opt_in: Scalars['Boolean']['output'];
  /** User id. For vendor login, this could be vendor id which is assigned to each vendor. */
  user_id: Scalars['ID']['output'];
  /** Jason web token to use with other secured end points */
  user_token: UserToken;
};

export type SignedUrl = {
  __typename?: 'SignedUrl';
  /** url field holds the link to upload file. */
  url: Scalars['String']['output'];
};

/** [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/1343356938/New+Authentication+Flow+-+Request+Shop+Preference#%5BhardBreak%5D2---Log-In-Changes) */
export type SingleAuthWarning = {
  __typename?: 'SingleAuthWarning';
  /** warning code */
  code: Scalars['String']['output'];
  /** warning message */
  message: Scalars['String']['output'];
  /** Warning type */
  type: Scalars['String']['output'];
};

/** Single B2B auction pdf report. */
export type SingleB2bAuctionPdfReport = {
  __typename?: 'SingleB2bAuctionPdfReport';
  /** PDF download url */
  url?: Maybe<Scalars['String']['output']>;
};

/** Single instance of B2b brand bid */
export type SingleB2bBrandBid = {
  __typename?: 'SingleB2bBrandBid';
  /** Comments */
  comments: Scalars['String']['output'];
  /** Created at */
  created_at: Scalars['String']['output'];
  /** Bid ID */
  id: Scalars['ID']['output'];
  /** Price bid */
  price_bid: Scalars['Float']['output'];
  /** Quantity */
  quantity: Scalars['Int']['output'];
  /** Status */
  status: Scalars['String']['output'];
  /** Updated at */
  updated_at: Scalars['String']['output'];
  /** User ID */
  user_id: Scalars['Int']['output'];
  /** User name (Will return for specific requests only) */
  user_name?: Maybe<Scalars['String']['output']>;
  /** Vendor ID */
  vendor_id: Scalars['Int']['output'];
  /** Vendor name (Will return for specific requests only) */
  vendor_name?: Maybe<Scalars['String']['output']>;
};

/** Single instance of B2B brand pending bid */
export type SingleB2bBrandPendingBid = {
  __typename?: 'SingleB2bBrandPendingBid';
  /** Comments */
  comments?: Maybe<Scalars['String']['output']>;
  /** Bid ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Price bid */
  price_bid?: Maybe<Scalars['Float']['output']>;
  /** Quantity */
  quantity?: Maybe<Scalars['Int']['output']>;
  /** User ID */
  user_id?: Maybe<Scalars['Int']['output']>;
  /** Vendor ID */
  vendor_id?: Maybe<Scalars['Int']['output']>;
};

/** Single instance of B2b deals report */
export type SingleB2bDeal = {
  __typename?: 'SingleB2bDeal';
  /** Brand Name */
  brand_name: Scalars['String']['output'];
  /** Shop Id */
  shop_id: Scalars['String']['output'];
  /** Stock */
  stock: Scalars['Int']['output'];
  /** Styles */
  styles: Scalars['Int']['output'];
  /** Vendor ID */
  vendor_id: Scalars['String']['output'];
};

/** Single b2b deals report which contains multiple report sections and meta data for the whole response */
export type SingleB2bDeals = {
  __typename?: 'SingleB2bDeals';
  /** Meta details */
  meta: SingleB2bDealsMeta;
  /** Report */
  report: Array<Maybe<SingleB2bDeal>>;
};

/** Meta section */
export type SingleB2bDealsMeta = {
  __typename?: 'SingleB2bDealsMeta';
  total_results: Scalars['Int']['output'];
};

/** Single instance of B2B product image report */
export type SingleB2bProductImageReport = {
  __typename?: 'SingleB2bProductImageReport';
  /** ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Image URL */
  url?: Maybe<Scalars['String']['output']>;
};

/** Single b2b product images report which contains multiple report sections and meta data for the whole response */
export type SingleB2bProductImagesReport = {
  __typename?: 'SingleB2bProductImagesReport';
  /** Meta details */
  meta: SingleB2bProductImagesReportMeta;
  /** Report */
  report: Array<Maybe<SingleB2bProductImageReport>>;
};

/** Meta section */
export type SingleB2bProductImagesReportMeta = {
  __typename?: 'SingleB2bProductImagesReportMeta';
  /** Total amount of results */
  total_results: Scalars['Int']['output'];
};

/** Single instance of B2b product overview report */
export type SingleB2bProductOverviewReport = {
  __typename?: 'SingleB2bProductOverviewReport';
  /** Category section */
  category?: Maybe<Array<B2bProductOverviewCategorySection>>;
  /** Gender section */
  gender?: Maybe<Array<B2bProductOverviewGenderSection>>;
  /** Season section */
  season?: Maybe<Array<B2bProductOverviewSeasonSection>>;
  /** Totals */
  totals: B2bProductOverviewTotalsSection;
  /** Year section */
  year?: Maybe<Array<B2bProductOverviewYearSection>>;
};

/** Single instance of B2b product report */
export type SingleB2bProductReport = {
  __typename?: 'SingleB2bProductReport';
  /** Product ID */
  product_id?: Maybe<Scalars['Int']['output']>;
  /** Product Name */
  product_name?: Maybe<Scalars['String']['output']>;
  /** Product Variations */
  product_variation?: Maybe<Array<Maybe<SingleB2bProductReportVariation>>>;
  /** Retail Price Live */
  retailpricelive?: Maybe<Scalars['String']['output']>;
  /** Total Stock */
  total_stock?: Maybe<Scalars['Int']['output']>;
  /** Url */
  url?: Maybe<Scalars['String']['output']>;
};

/** Meta section */
export type SingleB2bProductReportMeta = {
  __typename?: 'SingleB2bProductReportMeta';
  total_results: Scalars['Int']['output'];
};

/** Single instance of B2b product report variation */
export type SingleB2bProductReportVariation = {
  __typename?: 'SingleB2bProductReportVariation';
  /** Category */
  category?: Maybe<Scalars['String']['output']>;
  /** Ean */
  ean?: Maybe<Scalars['String']['output']>;
  /** Expected price */
  expected_price?: Maybe<Scalars['String']['output']>;
  /** Gender */
  gender?: Maybe<Scalars['String']['output']>;
  /** Name */
  name?: Maybe<Scalars['String']['output']>;
  /** nr */
  nr?: Maybe<Scalars['Int']['output']>;
  /** product id */
  productid?: Maybe<Scalars['Int']['output']>;
  /** Retail price live */
  retailpricelive?: Maybe<Scalars['String']['output']>;
  /** Season */
  season?: Maybe<Scalars['String']['output']>;
  /** Size */
  size?: Maybe<Scalars['String']['output']>;
  /** Stock */
  stock?: Maybe<Scalars['Int']['output']>;
  /** Url */
  url?: Maybe<Scalars['String']['output']>;
  /** Year */
  year?: Maybe<Scalars['Int']['output']>;
};

/** Single b2b products overview report which contains multiple report sections */
export type SingleB2bProductsOverviewReport = {
  __typename?: 'SingleB2bProductsOverviewReport';
  /** Report */
  report: SingleB2bProductOverviewReport;
};

/** Single b2b products report which contains multiple report sections and meta data for the whole response */
export type SingleB2bProductsReport = {
  __typename?: 'SingleB2bProductsReport';
  /** Meta details */
  meta: SingleB2bProductReportMeta;
  /** Report */
  report: Array<Maybe<SingleB2bProductReport>>;
};

/** Single B2B size overview report. */
export type SingleB2bSizeOverview = {
  __typename?: 'SingleB2bSizeOverview';
  /** Brand Display Name */
  branddisplayname?: Maybe<Scalars['String']['output']>;
  /** Category */
  category?: Maybe<Scalars['String']['output']>;
  /** Gender */
  gender?: Maybe<Scalars['String']['output']>;
  /** Product Name */
  name: Scalars['String']['output'];
  /** Product variations */
  product_variation?: Maybe<Array<Maybe<SingleB2bSizeOverviewProductVariation>>>;
  /** Product ID */
  productid: Scalars['Int']['output'];
  /** Retail Price Live */
  retailpricelive?: Maybe<Scalars['String']['output']>;
  /** URL */
  url?: Maybe<Scalars['String']['output']>;
  /** Year */
  year?: Maybe<Scalars['Int']['output']>;
};

/** The product variations. */
export type SingleB2bSizeOverviewProductVariation = {
  __typename?: 'SingleB2bSizeOverviewProductVariation';
  /** Product Variation ID */
  productvariationid?: Maybe<Scalars['Int']['output']>;
  /** Size */
  size?: Maybe<Scalars['String']['output']>;
  /** Stock */
  stock?: Maybe<Scalars['Int']['output']>;
};

/** Response type of the bid mutation */
export type SingleBidCreateResult = {
  __typename?: 'SingleBidCreateResult';
  /** Response code */
  code: Scalars['String']['output'];
  /** Records inserted */
  records_inserted: Scalars['Int']['output'];
};

export type SingleBidUpdateResult = {
  __typename?: 'SingleBidUpdateResult';
  /** Response code */
  code: Scalars['String']['output'];
  /** Records inserted */
  records_updated: Scalars['Int']['output'];
};

/** SingleBrand Type. */
export type SingleBrand = Brand & {
  __typename?: 'SingleBrand';
  /**
   * About sales description.
   * deprecated
   * @deprecated Use brand_description instead
   */
  about_sale_description: Array<Maybe<AboutSaleDescription>>;
  /** About sale image. */
  about_sale_image?: Maybe<Scalars['String']['output']>;
  /** Brand Description */
  brand_description: Scalars['String']['output'];
  /** Designer brand */
  designer_brand: Scalars['Boolean']['output'];
  /** Gender details. */
  gender?: Maybe<Array<Gender>>;
  /** GoodOnYou pillars */
  good_on_you_pillars: Array<Scalars['String']['output']>;
  /** Hide brand from */
  hide_brand_from?: Maybe<Array<Scalars['String']['output']>>;
  /** Brand icon text. */
  icon_text?: Maybe<Scalars['String']['output']>;
  /**
   * Brand id.
   * Could be term_id/id or brand_id depend on the rest API
   */
  id: Scalars['ID']['output'];
  /** Is Gold */
  is_gold: Scalars['Boolean']['output'];
  /** Is brand GoodOnYou */
  is_good_on_you: Scalars['Boolean']['output'];
  /** Brand logo. */
  logo: Scalars['String']['output'];
  /** Brand SVG logo. */
  logo_svg: Scalars['String']['output'];
  /** Brand name. */
  name: Scalars['String']['output'];
  /** Overlay logo. */
  overlay_logo?: Maybe<Scalars['String']['output']>;
  /** Brand slug. */
  slug: Scalars['String']['output'];
  /** Star rating */
  star_rating?: Maybe<Scalars['String']['output']>;
  /** Subtitle. */
  subtitle?: Maybe<Scalars['String']['output']>;
  /**
   * Wishlist item id of the user.
   * @deprecated Decided to go with userWishlistMeta.
   */
  user_wishlist_item_id?: Maybe<Scalars['String']['output']>;
};

export type SingleBrandAPiGatewayCredentials = {
  __typename?: 'SingleBrandAPiGatewayCredentials';
  key_id?: Maybe<Scalars['String']['output']>;
  key_secret?: Maybe<Scalars['String']['output']>;
};

export type SingleBrandApiGatewayDetails = {
  __typename?: 'SingleBrandApiGatewayDetails';
  api_gateway_enabled_datetime?: Maybe<Scalars['String']['output']>;
  credentials?: Maybe<Array<SingleBrandAPiGatewayCredentials>>;
  is_api_gateway_access_requested: Scalars['Boolean']['output'];
  is_api_gateway_enabled: Scalars['Boolean']['output'];
};

/** @deprecated(reason: "Feature has been removed from Oware") */
export type SingleBrandDropReportEmailResult = {
  __typename?: 'SingleBrandDropReportEmailResult';
  /** Result code : 200 for success result */
  code: Scalars['Int']['output'];
  /** Overall result as a text response */
  message: Scalars['String']['output'];
};

export type SingleBrandInvoice = {
  __typename?: 'SingleBrandInvoice';
  /** invoice date */
  invoice_date: Scalars['String']['output'];
  /** invoice id */
  invoice_id: Scalars['ID']['output'];
  /** invoice number */
  invoice_number: Scalars['Int']['output'];
  /** invoice period end date */
  period_end?: Maybe<Scalars['String']['output']>;
  /** invoice period start date */
  period_start?: Maybe<Scalars['String']['output']>;
  /** invoice status */
  status?: Maybe<Scalars['String']['output']>;
  /** total paid value */
  total_paid?: Maybe<Scalars['String']['output']>;
  /** vendor user id */
  user_id: Scalars['Int']['output'];
};

export type SingleBrandInvoiceReport = {
  __typename?: 'SingleBrandInvoiceReport';
  /** result code */
  code?: Maybe<Scalars['String']['output']>;
  /** invoice file url */
  invoice?: Maybe<SingleFile>;
  /** result message */
  message?: Maybe<Scalars['String']['output']>;
};

export type SingleBrandMonthlyInvoice = {
  __typename?: 'SingleBrandMonthlyInvoice';
  /** invoice period */
  invoice_period?: Maybe<Scalars['String']['output']>;
  /** invoice quantity */
  quantity_invoices?: Maybe<Scalars['Int']['output']>;
  /** invoice total paid value */
  total_paid?: Maybe<Scalars['Float']['output']>;
};

export type SingleBrandPlanDrop = {
  __typename?: 'SingleBrandPlanDrop';
  /** result code */
  code: Scalars['String']['output'];
  /** result */
  result: Scalars['String']['output'];
};

export type SingleBrandReport = {
  __typename?: 'SingleBrandReport';
  /** brand report data - need to parse this value in consumer application */
  reportData?: Maybe<Scalars['String']['output']>;
};

/** For more information - refer https://otrium.atlassian.net/wiki/spaces/MIC/pages/858718209/Reporting+Microservice+API+Endpoints#Brand-Sales-Overview */
export type SingleBrandSale = {
  __typename?: 'SingleBrandSale';
  /** @deprecated(reason: "DWH doesn't have this value yet") */
  first_order_date?: Maybe<Scalars['String']['output']>;
  first_publish_date?: Maybe<Scalars['String']['output']>;
  quantity_variations_sold?: Maybe<Scalars['Float']['output']>;
  refund_quantity_variations?: Maybe<Scalars['Float']['output']>;
  refund_total_value?: Maybe<Scalars['Float']['output']>;
  refund_vendor_payment?: Maybe<Scalars['Float']['output']>;
  returned_percentage?: Maybe<Scalars['Float']['output']>;
  total_live_stock?: Maybe<Scalars['Float']['output']>;
  total_scanned_stock?: Maybe<Scalars['Float']['output']>;
  total_stock_a_choice?: Maybe<Scalars['Float']['output']>;
  total_stock_b_choice?: Maybe<Scalars['Float']['output']>;
  total_value_sold?: Maybe<Scalars['Float']['output']>;
  total_vendor_payment?: Maybe<Scalars['Float']['output']>;
  total_vendor_payment_ex_vat?: Maybe<Scalars['Float']['output']>;
  vendor_payment?: Maybe<Scalars['Float']['output']>;
  vendor_payment_ex_vat?: Maybe<Scalars['Float']['output']>;
};

export type SingleBrandSection = {
  __typename?: 'SingleBrandSection';
  cover_image: Scalars['String']['output'];
  designer_brand?: Maybe<Scalars['Boolean']['output']>;
  discount: Scalars['String']['output'];
  genders?: Maybe<Array<Scalars['String']['output']>>;
  good_on_you_pillars: Array<Scalars['String']['output']>;
  /** term_id in API call */
  id: Scalars['ID']['output'];
  image: Scalars['String']['output'];
  is_coming_soon?: Maybe<Scalars['Boolean']['output']>;
  is_gold: Scalars['Boolean']['output'];
  is_good_on_you: Scalars['Boolean']['output'];
  labels?: Maybe<Array<BrandLabelSection>>;
  last_product_update?: Maybe<Scalars['String']['output']>;
  logo: Scalars['String']['output'];
  logo_svg: Scalars['String']['output'];
  name: Scalars['String']['output'];
  overlay_logo: Scalars['String']['output'];
  sales_landing?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  subtitle: Scalars['String']['output'];
};

export type SingleBrandStockOverview = {
  __typename?: 'SingleBrandStockOverview';
  /** b stock */
  b_stock?: Maybe<Scalars['Int']['output']>;
  /** brand id */
  brand_id?: Maybe<Scalars['ID']['output']>;
  /** issue stock */
  issue_stock?: Maybe<Scalars['Int']['output']>;
  /** issue styles */
  issue_styles?: Maybe<Scalars['Int']['output']>;
  /** live stock */
  live_stock?: Maybe<Scalars['Int']['output']>;
  /** live styles */
  live_styles?: Maybe<Scalars['Int']['output']>;
  /** processing stock */
  processing_stock?: Maybe<Scalars['Int']['output']>;
  /** processing styles */
  processing_styles?: Maybe<Scalars['Int']['output']>;
  /** total stock */
  total_stock?: Maybe<Scalars['Int']['output']>;
  /** total styles */
  total_styles?: Maybe<Scalars['Int']['output']>;
};

/** Cart query single response type. */
export type SingleCart = {
  __typename?: 'SingleCart';
  /** Checkout message */
  checkout_message?: Maybe<SingleCartCheckoutMessage>;
  /** Count of products */
  count: Scalars['Int']['output'];
  /** Details on coupons */
  coupons?: Maybe<Array<SingleCartCoupon>>;
  /** cart id */
  id: Scalars['ID']['output'];
  /** Cart items collection */
  items?: Maybe<Array<SingleCartProductVariation>>;
  /** Cart order summary */
  order_summary?: Maybe<SingleCartOrderSummary>;
  /** Referral credit */
  referral_credit?: Maybe<SingleCartReferralCredit>;
  /** All cart warnings */
  warnings?: Maybe<Array<SingleCartWarning>>;
};

/** Single cart checkout message instance */
export type SingleCartCheckoutMessage = {
  __typename?: 'SingleCartCheckoutMessage';
  /** Is Alert */
  is_alert?: Maybe<Scalars['Boolean']['output']>;
  /** Text */
  text?: Maybe<Scalars['String']['output']>;
};

/** Single cart coupon */
export type SingleCartCoupon = {
  __typename?: 'SingleCartCoupon';
  /** Coupon code */
  code: Scalars['String']['output'];
  /** Currency type */
  currency: ProductCurrency;
  /** Description of the coupon */
  description: Scalars['String']['output'];
  /** Discount of the coupon */
  discount: Scalars['String']['output'];
  /** Type of the coupon */
  type: Scalars['String']['output'];
};

/** Single cart order summary */
export type SingleCartOrderSummary = {
  __typename?: 'SingleCartOrderSummary';
  /** Currency type */
  currency?: Maybe<ProductCurrency>;
  /** Shipping details */
  shipping?: Maybe<OrderSummaryShippingDetails>;
  /** Total amount */
  total_amount?: Maybe<Scalars['String']['output']>;
  /** Total cart items */
  total_items?: Maybe<Scalars['String']['output']>;
};

/** Cart single product variation response type. */
export type SingleCartProductVariation = {
  __typename?: 'SingleCartProductVariation';
  /** Brand */
  brand: SingleCartProductVariationBrand;
  /** Product Category Ids */
  categories?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Currency type */
  currency: ProductCurrency;
  /** Gender */
  gender: Scalars['String']['output'];
  /** Variation Id */
  id: Scalars['ID']['output'];
  /** Image */
  image: Scalars['String']['output'];
  /** Is personalised */
  is_personalised?: Maybe<Scalars['Boolean']['output']>;
  /** Product Labels */
  labels?: Maybe<Array<Maybe<ProductLabel>>>;
  /**
   * Price
   * @deprecated Due to type difference. Use `price_float` field.
   */
  price: Scalars['String']['output'];
  /** Price in float format */
  price_float: Scalars['Float']['output'];
  /** Product Discount */
  product_discount?: Maybe<Scalars['Float']['output']>;
  /** product Id */
  product_id: Scalars['String']['output'];
  /** Product Kids Gender */
  product_kids_gender?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Product Name */
  product_name: Scalars['String']['output'];
  /** Product Shop Type */
  product_shop_type?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Product size */
  product_size?: Maybe<Scalars['String']['output']>;
  /** Product Slug */
  product_slug: Scalars['String']['output'];
  /** Product URL */
  product_url?: Maybe<Scalars['String']['output']>;
  /** Quantity in the cart */
  quantity_in_cart: Scalars['Int']['output'];
  /**
   * Regular Price
   * @deprecated Due to type difference. Use `regular_price_float` field.
   */
  regular_price: Scalars['String']['output'];
  /** Regular price in float format */
  regular_price_float: Scalars['Float']['output'];
  /** Size */
  size: SingleCartProductVariationSize;
  /** Product SKU */
  sku?: Maybe<Scalars['String']['output']>;
  /** Available Stock Count */
  stock_available: Scalars['Int']['output'];
  /** Translated Category Ids */
  translated_category_ids?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Translated Category Ids */
  translated_category_names?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   * Wishlist item id of the user.
   * @deprecated Decided to go with userWishlistMeta.
   */
  user_wishlist_item_id?: Maybe<Scalars['String']['output']>;
  /** Product variant size */
  variant?: Maybe<Scalars['String']['output']>;
};

/** Cart single product variation brand type. */
export type SingleCartProductVariationBrand = {
  __typename?: 'SingleCartProductVariationBrand';
  /** id of the brand */
  id: Scalars['ID']['output'];
  /** Name of the brand */
  name: Scalars['String']['output'];
  /** slug of the brand */
  slug: Scalars['String']['output'];
};

/** Cart single product variation size type. */
export type SingleCartProductVariationSize = {
  __typename?: 'SingleCartProductVariationSize';
  /** Name of the size */
  name: Scalars['String']['output'];
  /** Slug of the size */
  slug: Scalars['String']['output'];
};

/** Single cart referral credit instance */
export type SingleCartReferralCredit = {
  __typename?: 'SingleCartReferralCredit';
  /** Code */
  code: Scalars['String']['output'];
  /** Currency type */
  currency: ProductCurrency;
  /** Description */
  description: Scalars['String']['output'];
  /** Discount */
  discount: Scalars['String']['output'];
  /** Condition:min amount - can use to build conditions like 'Available on orders over €50'  */
  min_cart_total_amount: Scalars['String']['output'];
  /** type */
  type: Scalars['String']['output'];
};

/**
 * Cart warnings block
 * https://otrium.atlassian.net/wiki/spaces/OT/pages/742457347/Cart+Warning+Codes
 */
export type SingleCartWarning = {
  __typename?: 'SingleCartWarning';
  /** warning code */
  code: Scalars['String']['output'];
  /** warning id */
  id: Scalars['String']['output'];
  /** Related item details */
  item_details?: Maybe<SingleCartProductVariation>;
  /** warning message */
  message: Scalars['String']['output'];
  /** Cart warning type */
  type: Scalars['String']['output'];
};

export type SingleCategory = {
  __typename?: 'SingleCategory';
  /**
   * Child categories
   * @deprecated None of the client use this field.
   */
  children?: Maybe<Array<RelatedSingleCategory>>;
  /**
   * Curated or not - is_curated field in API
   * @deprecated Curated categories were replaced with collections.
   */
  curated: Scalars['Boolean']['output'];
  /**
   * Category ID
   * @deprecated Use algolia category index.
   */
  id: Scalars['ID']['output'];
  /**
   * Category name
   * @deprecated Use algolia category index name.
   */
  name: Scalars['String']['output'];
  /**
   * Parent category details (Only if a child)
   * @deprecated None of the client use this field.
   */
  parent?: Maybe<RelatedSingleCategory>;
  /** Promotion section (Will appear only if exists for the category) */
  promotion?: Maybe<Promotion>;
  /**
   * siblings categories
   * @deprecated None of the client use this field.
   */
  siblings?: Maybe<Array<RelatedSingleCategory>>;
  /**
   * Category slug
   * @deprecated Use algolia category index slug.
   */
  slug: Scalars['String']['output'];
  /**
   * Translations set for category
   * @deprecated None of the client use this field.
   */
  translations?: Maybe<Array<SingleCategoryTranslation>>;
};

/** Result of each category */
export type SingleCategoryResult = {
  __typename?: 'SingleCategoryResult';
  /** categoryName */
  categoryName: Scalars['String']['output'];
  /** returnRateByQuantity */
  returnRateByQuantity: Scalars['Float']['output'];
  /** returnRateBySales */
  returnRateBySales: Scalars['Float']['output'];
};

export type SingleCategoryTranslation = {
  __typename?: 'SingleCategoryTranslation';
  /** Category id */
  id: Scalars['ID']['output'];
  /** Category language code */
  lang: Scalars['String']['output'];
  /** Category name */
  name: Scalars['String']['output'];
  /** Category slug */
  slug: Scalars['String']['output'];
};

export type SingleFile = {
  __typename?: 'SingleFile';
  /** url field holds the link to download the requested file. This could be a link from svn or somewhere else & depends on the business logic. */
  url?: Maybe<Scalars['String']['output']>;
};

/**
 * This holds all page objects.
 * [Read More](https://otrium.atlassian.net/wiki/spaces/MIC/pages/368541826/Pages+Micro-service)
 */
export type SinglePage = {
  __typename?: 'SinglePage';
  centered_text_with_background_image?: Maybe<Array<Maybe<PageComponentCenteredTextWithBackgroundImage>>>;
  centered_text_with_button?: Maybe<Array<Maybe<PageComponentCenteredTextWithButton>>>;
  centered_title_with_background_image?: Maybe<Array<Maybe<PageComponentCenteredTitleWithBackgroundImage>>>;
  icons_list?: Maybe<Array<Maybe<PageComponentIconsList>>>;
  image_with_text_column?: Maybe<Array<Maybe<PageComponentImageWithTextColumn>>>;
  image_with_text_n_button_column?: Maybe<Array<Maybe<PageComponentImageWithTextNButtonColumn>>>;
  links_with_anchors?: Maybe<Array<Maybe<PageComponentLinksWithAnchors>>>;
  page_details?: Maybe<PageDetailComponent>;
  text_with_info_list?: Maybe<Array<Maybe<PageComponentTextWithInfoList>>>;
  text_with_login_buttons?: Maybe<Array<Maybe<PageComponentTextWithLoginButtons>>>;
  text_with_slider?: Maybe<Array<Maybe<PageComponentTextWithSlider>>>;
};

/** SinglePartner type */
export type SinglePartner = {
  __typename?: 'SinglePartner';
  /** Email of the partner */
  email: Scalars['String']['output'];
  /** Name of the partner */
  name: Scalars['String']['output'];
  /** List of ot_vendor_id user is assigned. */
  organization_ids: Array<Scalars['String']['output']>;
};

/** SingleProduct Type. */
export type SingleProduct = Product & {
  __typename?: 'SingleProduct';
  /** Brand details of the product. */
  brand: ProductBrand;
  /** Care instructions. */
  care_instructions?: Maybe<Scalars['String']['output']>;
  /** Assigned category ids. */
  categories?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Currency type: value changes based on language header */
  currency: ProductCurrency;
  /** Created date details. */
  date_created: SingleProductCreatedDate;
  /** Updated date details. */
  date_modified?: Maybe<SingleProductModifiedDate>;
  /** Product description. */
  description: Scalars['String']['output'];
  /** Fit and size details. */
  fit_size?: Maybe<Scalars['String']['output']>;
  /** Gender of the product. */
  gender?: Maybe<Scalars['String']['output']>;
  /** Product id. */
  id: Scalars['ID']['output'];
  /** Image of the product. */
  images?: Maybe<Array<Scalars['String']['output']>>;
  /** Flag to determine original prices or auto-converted */
  is_currency_converted: Scalars['Boolean']['output'];
  /** Is product personalised */
  is_personalised?: Maybe<Scalars['Boolean']['output']>;
  /** If product under kids, Sub categories details uder kids. `Girls`, `Boys`. */
  kids_sub_categories?: Maybe<Array<Maybe<CatalogCategory>>>;
  /** Labels of the product. */
  labels?: Maybe<Array<ProductLabel>>;
  /** Lowest price of last 30 days */
  lowest_price?: Maybe<Scalars['Float']['output']>;
  /** Main category ids. */
  main_category_ids?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Material value of the product. */
  material?: Maybe<Scalars['String']['output']>;
  /** Name of the product. */
  name: Scalars['String']['output'];
  /** Payment types section */
  payment_types?: Maybe<Array<SingleProductPaymentTypes>>;
  /** Price of the product. */
  price: Scalars['Float']['output'];
  /** Product discount */
  product_discount?: Maybe<Scalars['Float']['output']>;
  /** Product kids genders */
  product_kids_gender?: Maybe<Array<Scalars['String']['output']>>;
  /** Product shop type */
  product_shop_type?: Maybe<Array<Scalars['String']['output']>>;
  /** Promotion section */
  promotion?: Maybe<Promotion>;
  /** Regular price of the product. */
  regular_price: Scalars['Float']['output'];
  /** Shipping section */
  shipping?: Maybe<SingleProductShipping>;
  /** Shop type of the product. */
  shop_type: Scalars['String']['output'];
  /** Size schema of the product. ex: IT/INT/INCH */
  size_schema?: Maybe<Scalars['String']['output']>;
  /** SKU of the product. */
  sku: Scalars['String']['output'];
  /** Slug of the product. */
  slug: Scalars['String']['output'];
  /** Product status. */
  status: Scalars['String']['output'];
  /** Stock status. */
  stock_status?: Maybe<Scalars['String']['output']>;
  /** Top category section */
  top_category?: Maybe<SingleProductTopCategory>;
  /** Translated Category ids */
  translated_category_ids?: Maybe<Array<Scalars['String']['output']>>;
  /** Translated Category names */
  translated_category_names?: Maybe<Array<Scalars['String']['output']>>;
  /** Product URL */
  url?: Maybe<Scalars['String']['output']>;
  /**
   * Wishlist item id of the user.
   * @deprecated Decided to go with userWishlistMeta.
   */
  user_wishlist_item_id?: Maybe<Scalars['String']['output']>;
  /** Variation details of the product. */
  variations?: Maybe<Array<ProductVariation>>;
  /** Any warnings related to product will appear here */
  warnings?: Maybe<Array<SingleProductWarning>>;
};

/** SingleProductCreatedDate Type. */
export type SingleProductCreatedDate = {
  __typename?: 'SingleProductCreatedDate';
  /** Date string. */
  date: Scalars['String']['output'];
  /** Timezone string. */
  timezone?: Maybe<Scalars['String']['output']>;
  /** Timezone type. */
  timezone_type?: Maybe<Scalars['Int']['output']>;
};

/** SingleProductModifiedDate Type. */
export type SingleProductModifiedDate = {
  __typename?: 'SingleProductModifiedDate';
  /** Date string. */
  date: Scalars['String']['output'];
  /** Timezone string. */
  timezone?: Maybe<Scalars['String']['output']>;
  /** Timezone type. */
  timezone_type?: Maybe<Scalars['Int']['output']>;
};

/** Payment types section */
export type SingleProductPaymentTypes = {
  __typename?: 'SingleProductPaymentTypes';
  /** Text of Shipping */
  image: Scalars['String']['output'];
  /** Name of Payment Method */
  name: Scalars['String']['output'];
};

/** Shipping section */
export type SingleProductShipping = {
  __typename?: 'SingleProductShipping';
  /** Text of Shipping */
  shipping_text: Scalars['String']['output'];
  /** Title of Shipping */
  shipping_title: Scalars['String']['output'];
};

/** Single product - top category section */
export type SingleProductTopCategory = {
  __typename?: 'SingleProductTopCategory';
  /** ID */
  id: Scalars['ID']['output'];
  /** Name */
  name: Scalars['String']['output'];
  /** Slug */
  slug: Scalars['String']['output'];
};

/** Single warning section */
export type SingleProductWarning = {
  __typename?: 'SingleProductWarning';
  /** Code of the warning */
  code: Scalars['String']['output'];
  /** Message of the warning */
  message: Scalars['String']['output'];
};

/** SingleReport interface. */
export type SingleReport = {
  /** CSV string of report data. */
  reportData?: Maybe<Scalars['String']['output']>;
};

/** Single sales landing type. */
export type SingleSalesLanding = {
  __typename?: 'SingleSalesLanding';
  /** Sales landing Data */
  saleslanding_data: SingleSalesLandingData;
  /** Sales landing Meta Data */
  saleslanding_meta_data: SingleSalesLandingMetaData;
  /** Sales landing Type. */
  saleslanding_type: Scalars['String']['output'];
  /** SEO texts */
  seo_texts: SingleSalesLandingSeoTexts;
};

export type SingleSalesLandingData = {
  __typename?: 'SingleSalesLandingData';
  bottom_image: Scalars['String']['output'];
  bottom_image_mobile: Scalars['String']['output'];
  discount_percentage: Scalars['String']['output'];
  discount_text?: Maybe<Scalars['String']['output']>;
  genders?: Maybe<Array<Scalars['String']['output']>>;
  intro_text: Scalars['String']['output'];
  is_coming_soon: Scalars['Boolean']['output'];
  is_noindex?: Maybe<Scalars['Boolean']['output']>;
  logo: Scalars['String']['output'];
  outlet_label?: Maybe<Scalars['String']['output']>;
  slugs_by_gender?: Maybe<SlugsByGender>;
  title: Scalars['String']['output'];
  top_image: Scalars['String']['output'];
  top_image_mobile: Scalars['String']['output'];
  usp_text?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type SingleSalesLandingMetaData = {
  __typename?: 'SingleSalesLandingMetaData';
  linked_taxonomy_id: Scalars['Int']['output'];
  saleslanding_id: Scalars['ID']['output'];
};

export type SingleSalesLandingSeoTexts = {
  __typename?: 'SingleSalesLandingSeoTexts';
  seo_bottom_text: Scalars['String']['output'];
  seo_bottom_text_gate_3_0?: Maybe<Scalars['String']['output']>;
  seo_description: Scalars['String']['output'];
  seo_title: Scalars['String']['output'];
};

export type SingleStorefrontProduct = {
  __typename?: 'SingleStorefrontProduct';
  brand: StorefrontProductBrand;
  /** Product categories */
  categories?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  currencies?: Maybe<Array<StorefrontProductCurrency>>;
  currency: ProductCurrency;
  guid: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Scalars['String']['output']>>;
  /** Defines if product personalised */
  is_personalised?: Maybe<Scalars['Boolean']['output']>;
  /** Labels of the product. */
  labels?: Maybe<Array<ProductLabel>>;
  name: Scalars['String']['output'];
  post_title: Scalars['String']['output'];
  price_float: Scalars['Float']['output'];
  /** Product discount */
  product_discount?: Maybe<Scalars['Float']['output']>;
  product_image_gallery?: Maybe<Scalars['String']['output']>;
  /** Product kids gender */
  product_kids_gender?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Product shop type */
  product_shop_type?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  regular_price_float: Scalars['Float']['output'];
  sale_price_float: Scalars['Float']['output'];
  /** Product available sizes */
  sizes_available?: Maybe<Scalars['String']['output']>;
  sku: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  status: Scalars['String']['output'];
  stock_status: Scalars['String']['output'];
  /** Thumbnail of the product */
  thumbnail: Scalars['String']['output'];
  /** Product translated category ids */
  translated_category_ids?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Product translated category names */
  translated_category_names?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Product url */
  url?: Maybe<Scalars['String']['output']>;
};

/** Single vendor type */
export type SingleVendor = {
  __typename?: 'SingleVendor';
  /** shop/brand id */
  brand_id?: Maybe<Scalars['Int']['output']>;
  /** organisation id */
  organisation_id?: Maybe<Scalars['Int']['output']>;
  /** vendor id */
  vendor_id: Scalars['ID']['output'];
  /** vendor name */
  vendor_name: Scalars['String']['output'];
};

/** SizeFilterOption Type. */
export type SizeFilterOption = Node & {
  __typename?: 'SizeFilterOption';
  /** container_size equivalent of the size. */
  container_size: Scalars['String']['output'];
  /** Number of products under this size. */
  count: Scalars['Int']['output'];
  /** id of the size. */
  id: Scalars['ID']['output'];
  /** Name of the size. */
  name: Scalars['String']['output'];
  /** Slug of the size. */
  slug: Scalars['String']['output'];
};

export type SliderImage = {
  __typename?: 'SliderImage';
  link: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type SlugsByGender = {
  __typename?: 'SlugsByGender';
  boys?: Maybe<Scalars['String']['output']>;
  girls?: Maybe<Scalars['String']['output']>;
  men?: Maybe<Scalars['String']['output']>;
  women?: Maybe<Scalars['String']['output']>;
};

export type SoftLoginPayload = {
  __typename?: 'SoftLoginPayload';
  /** email address */
  email: Scalars['String']['output'];
  /** gender type */
  gender: Scalars['String']['output'];
  /** Language */
  language: Scalars['String']['output'];
  /** ot_accounts ID for segment flow */
  member_id: Scalars['ID']['output'];
  /** New Sign up */
  new_signup: Scalars['Boolean']['output'];
  /** if user accepted promo terms or no. */
  opt_in: Scalars['Boolean']['output'];
  /** List of shop preferences */
  shop_preference?: Maybe<Array<Scalars['String']['output']>>;
  /** JSON web token to use with other secured end points */
  user_token: UserToken;
};

/** Commercetools Store Settings response type. */
export type StoreSettings = {
  __typename?: 'StoreSettings';
  /** Minimal cart subtotal value in cents to enable checkout */
  min_cart_subtotal_price_cent_amount?: Maybe<Scalars['Int']['output']>;
};

/** A collection type for the storefront. */
export type StorefrontCollection = {
  __typename?: 'StorefrontCollection';
  /** ID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** A collection info type for the collection page. */
export type StorefrontCollectionInfo = {
  __typename?: 'StorefrontCollectionInfo';
  desktopImage?: Maybe<Scalars['String']['output']>;
  /** ID */
  id: Scalars['ID']['output'];
  mobileImage?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  plpQuery?: Maybe<Scalars['String']['output']>;
  publishEndDate?: Maybe<Scalars['String']['output']>;
  publishStartDate?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  targetType: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

/** A list of collections for the storefront */
export type StorefrontCollectionList = {
  __typename?: 'StorefrontCollectionList';
  items?: Maybe<Array<StorefrontCollection>>;
  /** Next */
  next?: Maybe<Scalars['String']['output']>;
};

/** Brand in homepage -> storefront -> product  Type. */
export type StorefrontProductBrand = Brand & {
  __typename?: 'StorefrontProductBrand';
  /** Designer brand flag */
  designer_brand: Scalars['Boolean']['output'];
  /** Brand id. */
  id: Scalars['ID']['output'];
  /** Brand name */
  name: Scalars['String']['output'];
  /** Brand slug */
  slug: Scalars['String']['output'];
};

/** Type used to reflect extra currencies section in homepage storefront product */
export type StorefrontProductCurrency = {
  __typename?: 'StorefrontProductCurrency';
  /** String ID */
  id: Scalars['ID']['output'];
  /** Price */
  price: Scalars['String']['output'];
  /** Regular Price */
  regular_price: Scalars['String']['output'];
};

export type StorefrontSection = {
  __typename?: 'StorefrontSection';
  data: StorefrontSectionData;
  type: Scalars['String']['output'];
};

export type StorefrontSectionData = {
  __typename?: 'StorefrontSectionData';
  banner_image: Scalars['String']['output'];
  banner_image_mobile: Scalars['String']['output'];
  curated: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  products?: Maybe<Array<SingleStorefrontProduct>>;
  slug: Scalars['String']['output'];
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** Sub invoice type */
export type SubInvoice = {
  __typename?: 'SubInvoice';
  /** Date that the invoice was created */
  created_at: Scalars['String']['output'];
  /** Description entered by finance on Ad hoc notes. Defaults to null. */
  description?: Maybe<Scalars['String']['output']>;
  /** Unique ID of the invoice */
  id: Scalars['ID']['output'];
  /** Date that the invoice has been invoiced */
  invoice_date?: Maybe<Scalars['String']['output']>;
  /** Human readable unique ID for the sub invoice */
  invoice_number: Scalars['String']['output'];
  invoice_ofs_calculation: InvoiceOfsCalculation;
  invoice_type: InvoiceType;
  invoice_vas_calculations: Array<InvoiceVasCalculation>;
  pdf_url: Scalars['String']['output'];
  /** End of the invoice period */
  period_end: Scalars['String']['output'];
  /** Start of the invoice period */
  period_start: Scalars['String']['output'];
  /** Invoice numbers that are referenced by the sub invoice on ad hoc credit notes */
  referenced_invoice_numbers: Array<Scalars['String']['output']>;
  status: InvoiceStatus;
  /** Optional Sub lines to display on a particular sub invoice. Defaults to empty array. */
  sub_lines: Array<SubInvoiceSecondaryLine>;
  /** Total commission in currency of the invoice */
  total_commission: Scalars['Float']['output'];
  /** Total coupon discount on the invoice in currency of the invoice */
  total_discount: Scalars['Float']['output'];
  /** Total price before coupons in currency of the invoice */
  total_invoice_amount: Scalars['Float']['output'];
  /** total_invoice_amount - total_discount - total_commission */
  total_net_sales: Scalars['Float']['output'];
  /** total_net_sales + total_tax */
  total_paid: Scalars['Float']['output'];
  /** Total tax in local currency */
  total_tax: Scalars['Float']['output'];
  transaction_url: Scalars['String']['output'];
  updated_at: Scalars['String']['output'];
  vendor_configuration: VendorConfigurationAtInvoiceTime;
  vendor_id: Scalars['ID']['output'];
};

/** Collection of sub invoices by vendor id */
export type SubInvoiceByVendorCollection = {
  __typename?: 'SubInvoiceByVendorCollection';
  data: Array<SubInvoice>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total_results: Scalars['Int']['output'];
};

/** Collection of sub invoices */
export type SubInvoiceCollection = {
  __typename?: 'SubInvoiceCollection';
  data: Array<SubInvoice>;
};

export type SubInvoiceSecondaryLine = {
  __typename?: 'SubInvoiceSecondaryLine';
  amount: Scalars['Float']['output'];
  description: Scalars['String']['output'];
};

/**
 * SubmitPaymentDetailsInput
 * Submit your payment results after redirection from payment service
 */
export type SubmitPaymentDetailsInput = {
  /** The result of the redirect as appended to the returnURL */
  redirect_result?: InputMaybe<Scalars['String']['input']>;
  /** Base64-encoded string returned by the Component after the challenge flow */
  three_ds_result?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitPaymentDetailsResponse = {
  __typename?: 'SubmitPaymentDetailsResponse';
  success: Scalars['Boolean']['output'];
};

/** SummaryBrand Type */
export type SummaryBrand = Brand & {
  __typename?: 'SummaryBrand';
  /** Cover Image. */
  cover_image?: Maybe<Scalars['String']['output']>;
  /** Designer brand */
  designer_brand: Scalars['Boolean']['output'];
  /** Discount (popular shop discount). */
  discount?: Maybe<Scalars['String']['output']>;
  /** Genders ex:men,women,kids. */
  genders?: Maybe<Array<Scalars['String']['output']>>;
  /** GoodOnYou pillars */
  good_on_you_pillars: Array<Scalars['String']['output']>;
  /** Header image */
  header_image?: Maybe<Scalars['String']['output']>;
  /** Brand id. */
  id: Scalars['ID']['output'];
  /** Image. */
  image?: Maybe<Scalars['String']['output']>;
  /** is_coming_soon flag. */
  is_coming_soon?: Maybe<Scalars['Boolean']['output']>;
  /** Is brand GoodOnYou */
  is_good_on_you: Scalars['Boolean']['output'];
  /** Labels details */
  labels?: Maybe<Array<BrandLabel>>;
  /**
   * Last product update date time
   * @deprecated There can be scenarios this can be nullable.
   */
  last_product_update: Scalars['String']['output'];
  /** Logo. */
  logo: Scalars['String']['output'];
  /** Brand Logo in SVG. */
  logo_svg: Scalars['String']['output'];
  /** Brand name. */
  name: Scalars['String']['output'];
  /** Overlay logo. */
  overlay_logo?: Maybe<Scalars['String']['output']>;
  /** Sales landing url. */
  sales_landing?: Maybe<Scalars['String']['output']>;
  /** Brand slug. */
  slug: Scalars['String']['output'];
  /** Subtitle. */
  subtitle?: Maybe<Scalars['String']['output']>;
  /**
   * Wishlist item id of the user.
   * @deprecated Decided to go with userWishlistMeta.
   */
  user_wishlist_item_id?: Maybe<Scalars['String']['output']>;
};

/** Input parameters of the Pipeline/DAM brand sync mutation */
export type SyncDamBrandsInput = {
  /** DAM internal ids */
  brand_ids: Array<Scalars['String']['input']>;
};

export type SyncDamBrandsResponse = {
  __typename?: 'SyncDamBrandsResponse';
  /** Brand sync response code */
  code: Scalars['String']['output'];
  /** Brand sync response message */
  message: Scalars['String']['output'];
};

/** Input parameters of the Pipeline/DAM product sync mutation */
export type SyncDamProductsInput = {
  /** DAM internal ids */
  product_ids: Array<Scalars['String']['input']>;
};

export type SyncDamProductsResponse = {
  __typename?: 'SyncDamProductsResponse';
  /** Product sync response code */
  code: Scalars['String']['output'];
  /** Product sync response message */
  message: Scalars['String']['output'];
};

export type TemporaryCommercialSettings = {
  __typename?: 'TemporaryCommercialSettings';
  /** Temporary commission model which is active for a certain period of time */
  commission_model: TemporaryCommissionModel;
  /** Start and end dates of temporary exception periods. */
  exception_period: TemporaryExceptionPeriod;
  /** Temporary fulfillment services which is active for a certain period of time */
  fulfillment_services: TemporaryFulfillmentServicesModel;
  /** Id of temporary commercial setting */
  id: Scalars['ID']['output'];
  /** TemporaryCommercialSettingsState of temporary commercial setting */
  state: TemporaryCommercialSettingsState;
  /** Temporary value added services which is active for a certain period of time */
  value_added_services: TemporaryVendorValueAddedServicesModel;
};

/** Operation type either create,edit or replace */
export enum TemporaryCommercialSettingsOperationType {
  Create = 'create',
  Edit = 'edit',
  Replace = 'replace'
}

/** TemporaryCommercialSettingsState either active,expired or scheduled */
export enum TemporaryCommercialSettingsState {
  Active = 'active',
  Expired = 'expired',
  Scheduled = 'scheduled'
}

/** Filter option either active, expired, scheduled or not_created */
export enum TemporaryCommercialSettingsStateFilter {
  Active = 'active',
  Expired = 'expired',
  NotCreated = 'not_created',
  Scheduled = 'scheduled'
}

export type TemporaryCommissionModel = {
  __typename?: 'TemporaryCommissionModel';
  commission_model?: Maybe<CommissionModel>;
  state: TemporaryCommissionModelState;
};

export enum TemporaryCommissionModelState {
  Custom = 'custom',
  Inherit = 'inherit'
}

export type TemporaryExceptionPeriod = {
  __typename?: 'TemporaryExceptionPeriod';
  end_date?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['String']['output']>;
};

export type TemporaryExceptionSpecification = {
  __typename?: 'TemporaryExceptionSpecification';
  end_date: Scalars['String']['output'];
  start_date: Scalars['String']['output'];
};

export type TemporaryFulfillmentServicesModel = {
  __typename?: 'TemporaryFulfillmentServicesModel';
  rates?: Maybe<FulfillmentRatesSpecification>;
  state: TemporaryFulfillmentServicesState;
  temporary_exception_specification: TemporaryExceptionSpecification;
};

export enum TemporaryFulfillmentServicesState {
  Custom = 'custom',
  Inherit = 'inherit',
  Off = 'off'
}

export type TemporaryVendorValueAddedServicesModel = {
  __typename?: 'TemporaryVendorValueAddedServicesModel';
  enabled_vas_charge_type_ids?: Maybe<Array<Scalars['String']['output']>>;
  state: TemporaryVendorValueAddedServicesState;
};

export enum TemporaryVendorValueAddedServicesState {
  Custom = 'custom',
  Disabled = 'disabled',
  Inherit = 'inherit'
}

export type TieredItemPriceCommissionModel = CommissionModelI & {
  __typename?: 'TieredItemPriceCommissionModel';
  specification: TieredItemPriceCommissionModelSpecification;
  type: Scalars['String']['output'];
};

export type TieredItemPriceCommissionModelSpecification = CommissionModelSpecification & {
  __typename?: 'TieredItemPriceCommissionModelSpecification';
  rates: Array<RateSpecification>;
};

export type TieredItemPriceCommissionModelSpecificationInput = {
  /** Exactly 4 rates are required */
  rates: Array<Scalars['Float']['input']>;
};

export type TieredRevenueCommissionModel = CommissionModelI & {
  __typename?: 'TieredRevenueCommissionModel';
  specification: TieredRevenueCommissionModelSpecification;
  type: Scalars['String']['output'];
};

export type TieredRevenueCommissionModelSpecification = CommissionModelSpecification & {
  __typename?: 'TieredRevenueCommissionModelSpecification';
  rates: Array<RateSpecification>;
  type: Scalars['String']['output'];
};

export type TieredRevenueCommissionModelSpecificationInput = {
  type: Scalars['String']['input'];
};

export type Timestamp = {
  date?: Maybe<Scalars['String']['output']>;
};

/**
 * TokenPaymentInput
 * This mutation allows you to verify GooglePay / ApplePay payments
 */
export type TokenPaymentInput = {
  /** The browser information (Will be used for 3DS flow) */
  browser_info: BrowserInfo;
  /** The ID of cart */
  cart_id: Scalars['String']['input'];
  /** The version of the cart */
  cart_version: Scalars['Int']['input'];
  /** The channel that shopper is using */
  channel: PaymentChannel;
  /** The payment method that user chose */
  payment_method: PaymentMethod;
  /** The URL which payment service must redirect the shopper to (Will be used for 3DS flow) */
  return_url: Scalars['String']['input'];
  /** The token which you received after user paid by Google/Apple Pay */
  token: Scalars['String']['input'];
};

export type TokenPaymentRedirectShopperAction = {
  __typename?: 'TokenPaymentRedirectShopperAction';
  data: TokenPaymentRedirectShopperActionData;
  method: Scalars['String']['output'];
  paymentMethodType: Scalars['String']['output'];
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type TokenPaymentRedirectShopperActionData = {
  __typename?: 'TokenPaymentRedirectShopperActionData';
  MD: Scalars['String']['output'];
  PaReq: Scalars['String']['output'];
  TermUrl: Scalars['String']['output'];
};

/**
 * TokenPaymentResponse
 * The response you can expect from "createTokenPayment" mutation
 */
export type TokenPaymentResponse = {
  __typename?: 'TokenPaymentResponse';
  /**
   * Data you need to handle the redirect for 3DS (Will be used for 3DS flow)
   * This will be null for Authorized payments
   */
  action?: Maybe<TokenPaymentRedirectShopperAction>;
  /** A human-readable description of what happened */
  description: Scalars['String']['output'];
  /** Result code which has been returned by the payment service */
  result_code: TokenPaymentResultCode;
};

export enum TokenPaymentResultCode {
  Authorised = 'Authorised',
  RedirectShopper = 'RedirectShopper'
}

export type TokenResultB2B = {
  __typename?: 'TokenResultB2B';
  /** Access Token */
  access_token: Scalars['String']['output'];
  /** Expire time in seconds */
  expires_in: Scalars['Int']['output'];
  /** Refresh Token */
  refresh_token?: Maybe<Scalars['String']['output']>;
  /** Scopes */
  scope: Scalars['String']['output'];
  /** Token Type */
  token_type: Scalars['String']['output'];
};

export type TokenResultFareye = {
  __typename?: 'TokenResultFareye';
  /** Return Portal Access Token */
  token: Scalars['String']['output'];
};

export type TokenResultOHub = {
  __typename?: 'TokenResultOHub';
  /** Access Token */
  access_token: Scalars['String']['output'];
};

/** Top Level Categories query response */
export type TopLevelCategories = {
  __typename?: 'TopLevelCategories';
  /** @deprecated Not needed by clients */
  hotDeals: TopLevelCategoryHotDeals;
  /** @deprecated Not needed by clients */
  mainCategory?: Maybe<TopLevelMainCatalogCategory>;
  topCategories?: Maybe<Array<CatalogCategory>>;
};

export type TopLevelCategoriesData = {
  __typename?: 'TopLevelCategoriesData';
  category_page_id: Array<Maybe<Scalars['String']['output']>>;
  colour: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  name_en: Scalars['String']['output'];
  /** @deprecated Not used by clients & CMS does not support this parameter */
  parent?: Maybe<Scalars['String']['output']>;
  section: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
};

/**
 * Input fields for the request
 * gender : "Gender type. Find more details on available types - [Read More](https://otrium.atlassian.net/wiki/spaces/OT/pages/317882369/API+endpoints#GET-Categories-Top-Level)"
 */
export type TopLevelCategoriesFilter = {
  gender: Scalars['String']['input'];
};

/**
 * This provides hot deal category
 * All fields are optional due to no data return for some category filters.
 */
export type TopLevelCategoryHotDeals = {
  __typename?: 'TopLevelCategoryHotDeals';
  /** Gender */
  gender?: Maybe<Scalars['String']['output']>;
  /** Id */
  id?: Maybe<Scalars['String']['output']>;
};

/** Main category type of the top level categories query */
export type TopLevelMainCatalogCategory = {
  __typename?: 'TopLevelMainCatalogCategory';
  /** ID of the category. */
  id?: Maybe<Scalars['ID']['output']>;
  /** Language of the category. */
  lang?: Maybe<Scalars['String']['output']>;
  /** Name of the category. */
  name?: Maybe<Scalars['String']['output']>;
  /** Slug of the category. */
  slug?: Maybe<Scalars['String']['output']>;
};

/** Translation Type. */
export type Translation = {
  __typename?: 'Translation';
  /** Translation en. */
  en?: Maybe<Scalars['Int']['output']>;
  /** Translations nl. */
  nl?: Maybe<Scalars['Int']['output']>;
};

/** Default type for return payload of update account object */
export type UpdateAccount = {
  __typename?: 'UpdateAccount';
  /** account email */
  email: Scalars['String']['output'];
  /** language code of the account, like nl */
  language: Scalars['String']['output'];
  /** shop language of the account, like dutch */
  shop_language: Scalars['String']['output'];
};

export type UpdateAccountInput = {
  /** language code of the account, like nl */
  language?: InputMaybe<Scalars['String']['input']>;
};

/** BillingAddress input */
export type UpdateBillingAddress = {
  additionalAddressInfo?: InputMaybe<Scalars['String']['input']>;
  additionalStreetInfo?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  houseAddition?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetName?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Input parameters of the Dam brand update mutation */
export type UpdateDamBrandInput = {
  /** about brand text per geo */
  about_brand_texts?: InputMaybe<Array<DamBrandAboutBrandTextInput>>;
  /** brand categories */
  brand_categories?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Dam internal id */
  brand_id: Scalars['String']['input'];
  /** brand sale type either REGULAR or FLASH_SALE */
  brand_sale_type?: InputMaybe<BrandSaleType>;
  /** brand description */
  description?: InputMaybe<Array<DamBrandDescriptionInput>>;
  /** brand discount label */
  discount_label?: InputMaybe<Array<DamBrandDiscountLabelInput>>;
  gate_level?: InputMaybe<GateLevel>;
  /**
   * hide brand from domains
   * deprecated - Use hidden_stores instead
   */
  hidden_domains?: InputMaybe<Array<Scalars['String']['input']>>;
  /** hide brand from stores */
  hidden_stores?: InputMaybe<Array<Scalars['String']['input']>>;
  /** product categories which are also brand categories */
  product_categories?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Is shop type kids */
  shop_type_kids?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is shop type men */
  shop_type_men?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is shop type women */
  shop_type_women?: InputMaybe<Scalars['Boolean']['input']>;
  /** size chart text per gender */
  size_chart_texts?: InputMaybe<Array<DamBrandSizeChartTextInput>>;
  /** Brand store publish details */
  store_publish_details?: InputMaybe<Array<DamBrandStorePublishDetailsInput>>;
};

export type UpdateDamBrandResponse = {
  __typename?: 'UpdateDamBrandResponse';
  /** Brand update response code */
  code: Scalars['String']['output'];
  /** Brand update response message */
  message: Scalars['String']['output'];
};

/** Input parameters of the Pipeline product update mutation */
export type UpdateDamProductInput = {
  /** Product categories */
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  /** defect id (dam internal id) */
  defect_status?: InputMaybe<Scalars['String']['input']>;
  /** Current pipeline */
  pipeline?: InputMaybe<Scalars['String']['input']>;
  /** Dam internal id */
  product_id: Scalars['String']['input'];
  /** Product translations */
  translations?: InputMaybe<Array<DamProductTranslationInput>>;
  /** Product visuals */
  visuals?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateDamProductResponse = {
  __typename?: 'UpdateDamProductResponse';
  /** Product update response code */
  code: Scalars['String']['output'];
  /** Product update response message */
  message: Scalars['String']['output'];
};

export type UpdateDamProductsResponse = {
  __typename?: 'UpdateDamProductsResponse';
  /** Product update response code */
  code: Scalars['String']['output'];
  /** Additional data related to each executed product */
  data?: Maybe<Array<UpdateDamProductsResponseDetail>>;
  /** Product update response message */
  message: Scalars['String']['output'];
};

export type UpdateDamProductsResponseDetail = {
  __typename?: 'UpdateDamProductsResponseDetail';
  /** dam Product id */
  product_id: Scalars['String']['output'];
  /** Reason if the product update fails */
  reason?: Maybe<Scalars['String']['output']>;
  /** success/fail */
  status: Scalars['String']['output'];
};

/** ShippingAddress input */
export type UpdateShippingAddress = {
  additionalAddressInfo?: InputMaybe<Scalars['String']['input']>;
  additionalStreetInfo?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  houseAddition?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetName?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateShopPreferencesV2Result = {
  __typename?: 'UpdateShopPreferencesV2Result';
  shopPreferences: Array<ShopPreference>;
  token: Scalars['String']['output'];
};

/** Default type for return payload of update user object */
export type UpdateUser = {
  __typename?: 'UpdateUser';
  /** Email */
  email: Scalars['String']['output'];
  /** Gender */
  gender: Scalars['String']['output'];
  /** Opt In */
  opt_in: Scalars['Boolean']['output'];
  /** User ID */
  user_id: Scalars['ID']['output'];
};

/** UserPref input */
export type UpdateUserPref = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserShopPreferencesResult = {
  __typename?: 'UpdateUserShopPreferencesResult';
  last_updated: Scalars['Int']['output'];
  shop_preferences: UserShopPreferences;
  times_requested: Scalars['Int']['output'];
};

export type UploadProductVisualsInput = {
  /** Order/ Sort value */
  order: Scalars['Int']['input'];
  /** Product ID of the product(Dam system id) */
  product_id: Scalars['String']['input'];
  /** Visual name with format (Eg: abc.jpg)  */
  visual_name: Scalars['String']['input'];
};

export type UploadProductVisualsResult = {
  __typename?: 'UploadProductVisualsResult';
  visual: DamProductVisual;
};

/** User email update request - result type */
export type UserEmailUpdateResult = {
  __typename?: 'UserEmailUpdateResult';
  /** Message */
  message: Scalars['String']['output'];
  /** JWT token */
  token?: Maybe<Scalars['String']['output']>;
};

/** User password recovery request - result type */
export type UserPasswordRecoveryResult = {
  __typename?: 'UserPasswordRecoveryResult';
  /** Message */
  message: Scalars['String']['output'];
};

export type UserPref = {
  __typename?: 'UserPref';
  language: Scalars['String']['output'];
};

/** UserPreferences Type. */
export type UserPreferences = {
  __typename?: 'UserPreferences';
  /**
   * Alert bar
   * @deprecated It is no longer applicable after migration
   */
  alert_bar: UserPreferencesAlertBar;
  /**
   * Cart details.
   * @deprecated It is no longer applicable after migration
   */
  app_cart?: Maybe<Array<CartSummarySingleVariation>>;
  /**
   * Cart count.
   * @deprecated It is no longer applicable after migration
   */
  app_cart_count: Scalars['Int']['output'];
  /**
   * Cart ID
   * @deprecated It is no longer applicable after migration
   */
  app_cart_id: Scalars['ID']['output'];
  /** App notifications enabled or not */
  app_notification?: Maybe<Scalars['Boolean']['output']>;
  /** Country. */
  country: Scalars['String']['output'];
  /**
   * user firstname
   * @deprecated It is no longer applicable after migration
   */
  first_name?: Maybe<Scalars['String']['output']>;
  /** Followed Brands list */
  followed_brands?: Maybe<Array<UserPreferencesFollowedBrand>>;
  /**
   * Following ids list
   * @deprecated It is no longer applicable after migration
   */
  following?: Maybe<Array<Scalars['Int']['output']>>;
  /** Gender */
  gender?: Maybe<Scalars['String']['output']>;
  /** logged in status. */
  is_logged_in: Scalars['Boolean']['output'];
  /** language code, like nl */
  language: Scalars['String']['output'];
  /**
   * user lastname
   * @deprecated It is no longer applicable after migration
   */
  last_name?: Maybe<Scalars['String']['output']>;
  /** ot_accounts ID for segment flow */
  member_id: Scalars['ID']['output'];
  /** New signup */
  new_signup: Scalars['Boolean']['output'];
  /**
   * Notifications bar text
   * @deprecated It is no longer applicable after migration
   */
  notification?: Maybe<Scalars['String']['output']>;
  /** On-Boarding metadata of the user */
  onboarding_metadata: OnboardingMetadata;
  /** women > men > kids */
  preferred_shop_type?: Maybe<Scalars['String']['output']>;
  /** Private sale consent status */
  private_sale_consent_status?: Maybe<Scalars['Boolean']['output']>;
  /** shop language, like dutch */
  shop_language: Scalars['String']['output'];
  /** @deprecated It is no longer applicable after migration */
  shopping_cart_count: Scalars['Int']['output'];
  /**
   * Show opt in
   * @deprecated It is no longer applicable after migration
   */
  show_opt_in: Scalars['Boolean']['output'];
  /**
   * user id.
   * @deprecated It is no longer applicable after migration, clients need to be switched to ot_member_id
   */
  user_id: Scalars['ID']['output'];
  /** username */
  user_name: Scalars['String']['output'];
  /**
   * All the brand ids within the current user wishlist.
   * @deprecated Introduce separate query userWishlistMetaData
   */
  wishlist_brand_ids?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * Products count in wishlist.
   * @deprecated Introduce separate query userWishlistMetaData
   */
  wishlist_product_count: Scalars['Int']['output'];
  /**
   * All the product ids within the current user wishlist.
   * @deprecated Introduce separate query userWishlistMetaData
   */
  wishlist_product_ids?: Maybe<Array<Scalars['String']['output']>>;
};

/** Alert bar section in user preferences */
export type UserPreferencesAlertBar = {
  __typename?: 'UserPreferencesAlertBar';
  /** Background color */
  bg_color: Scalars['String']['output'];
  /** Content */
  content: Scalars['String']['output'];
  /** Link */
  link: Scalars['String']['output'];
  /** Text color */
  text_color: Scalars['String']['output'];
};

/** UserPreferencesByEmail. */
export type UserPreferencesByEmail = {
  __typename?: 'UserPreferencesByEmail';
  /** Alert bar */
  alert_bar?: Maybe<UserPreferencesAlertBar>;
  /** Cart details. */
  app_cart?: Maybe<Array<CartSummarySingleVariation>>;
  /** Cart count. */
  app_cart_count?: Maybe<Scalars['Int']['output']>;
  /** Cart ID */
  app_cart_id?: Maybe<Scalars['ID']['output']>;
  /** App notifications enabled or not */
  app_notification?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Cookie Preference
   * @deprecated It is no longer applicable after migration
   */
  cookies_pref?: Maybe<Scalars['Int']['output']>;
  /** Country. */
  country?: Maybe<Scalars['String']['output']>;
  /**
   * Followed Brands list
   * @deprecated It is no longer applicable after migration
   */
  followed_brands?: Maybe<Array<UserPreferencesFollowedBrand>>;
  /**
   * Following ids list
   * @deprecated It is no longer applicable after migration
   */
  following?: Maybe<Array<Scalars['Int']['output']>>;
  /** Gender */
  gender?: Maybe<Scalars['String']['output']>;
  /** Is gold member */
  is_gold_member?: Maybe<Scalars['Boolean']['output']>;
  /** logged in status. */
  is_logged_in?: Maybe<Scalars['Boolean']['output']>;
  /** ot_accounts ID for segment flow */
  member_id?: Maybe<Scalars['ID']['output']>;
  /** New signup */
  new_signup?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Notifications bar text
   * @deprecated It is no longer applicable after migration
   */
  notification?: Maybe<Scalars['String']['output']>;
  /** Notification IDs */
  notification_ids?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /**
   * Platform
   * @deprecated It is no longer applicable after migration
   */
  platform?: Maybe<Scalars['String']['output']>;
  shopping_cart_count?: Maybe<Scalars['Int']['output']>;
  /**
   * Show custom notification
   * @deprecated It is no longer applicable after migration
   */
  show_custom_notification?: Maybe<Scalars['Boolean']['output']>;
  /** Show opt in */
  show_opt_in?: Maybe<Scalars['Boolean']['output']>;
  /** user id. */
  user_id?: Maybe<Scalars['ID']['output']>;
  /**
   * User Name
   * @deprecated It is no longer applicable after migration
   */
  user_name?: Maybe<Scalars['String']['output']>;
};

/** Followed brand type in user preferences object */
export type UserPreferencesFollowedBrand = Brand & {
  __typename?: 'UserPreferencesFollowedBrand';
  /** Woo brand ID */
  id: Scalars['ID']['output'];
  /** Brand name */
  name: Scalars['String']['output'];
  /** Brand slug */
  slug: Scalars['String']['output'];
};

/** UserProfile type */
export type UserProfile = {
  __typename?: 'UserProfile';
  /** billing_address */
  billing_address?: Maybe<BillingAddress>;
  /** birthdate */
  birthdate?: Maybe<Scalars['GraphQLDate']['output']>;
  /** email */
  email: Scalars['String']['output'];
  /** family_name */
  family_name?: Maybe<Scalars['String']['output']>;
  /** gender */
  gender?: Maybe<Scalars['String']['output']>;
  /** given_name */
  given_name?: Maybe<Scalars['String']['output']>;
  /** member_id */
  member_id: Scalars['String']['output'];
  /** name */
  name: Scalars['String']['output'];
  /** shipping_address */
  shipping_address?: Maybe<ShippingAddress>;
  /** JWT token */
  token?: Maybe<Scalars['String']['output']>;
  /** user_id */
  user_id: Scalars['String']['output'];
  /** user_pref */
  user_pref?: Maybe<UserPref>;
  /** user_token */
  user_token: UserToken;
  /** woo_user_id */
  woo_user_id?: Maybe<Scalars['String']['output']>;
};

/** User Shop preferences */
export type UserShopPreferences = {
  __typename?: 'UserShopPreferences';
  /** Shop preferences for kids cat. */
  kids?: Maybe<Scalars['Boolean']['output']>;
  /** Shop preferences for men cat. */
  men?: Maybe<Scalars['Boolean']['output']>;
  /** Shop preferences for women cat. */
  women?: Maybe<Scalars['Boolean']['output']>;
};

/** Auth0 UserToken type */
export type UserToken = {
  __typename?: 'UserToken';
  /** This holds the JWT. */
  token: Scalars['String']['output'];
  token_timestamp: Scalars['String']['output'];
};

/** UserWishlistMeta Type */
export type UserWishlistMeta = {
  __typename?: 'UserWishlistMeta';
  /** All the brand ids within the current user wishlist. */
  wishlist_brand_ids?: Maybe<Array<Scalars['String']['output']>>;
  /** List of brand slugs for the the current user wishlist */
  wishlist_brand_slugs?: Maybe<Array<Scalars['String']['output']>>;
  /** Products count in wishlist. This value depend on `Accept-Language` header. */
  wishlist_product_count: Scalars['Int']['output'];
  /** All the product ids within the current user wishlist. */
  wishlist_product_ids?: Maybe<Array<Scalars['String']['output']>>;
};

/** Result of the getVASInvoiceUploadUrl mutation */
export type VasInvoiceUploadUrlResult = {
  __typename?: 'VASInvoiceUploadUrlResult';
  /** Generated S3 Bucket file name */
  fileName: Scalars['String']['output'];
  /** Url used for the 2nd step, to process the upload */
  fullURL: Scalars['String']['output'];
  /** Url used for the VAS Invoice file upload */
  signedURL: Scalars['String']['output'];
};

export type ValueAddedServiceChargeType = {
  __typename?: 'ValueAddedServiceChargeType';
  charge_type_name: Scalars['String']['output'];
  id: Scalars['String']['output'];
  netsuite_id: Scalars['Int']['output'];
};

export type Vendor = {
  __typename?: 'Vendor';
  billing_details?: Maybe<VendorBillingDetails>;
  brand?: Maybe<VendorAssignedBrand>;
  /** Commercial setting of the vendor */
  commercial_settings: CommercialSettings;
  display_name: Scalars['String']['output'];
  /** Fulfillment services state and rates for per service if available */
  fulfillment_rates?: Maybe<FulfillmentServicesModel>;
  /** Churn State of the vendor. */
  is_churned: Scalars['Boolean']['output'];
  is_wholesale_vendor: Scalars['Boolean']['output'];
  legal_entity_name?: Maybe<Scalars['String']['output']>;
  netsuite_id?: Maybe<Scalars['Int']['output']>;
  ot_brand_id: Scalars['String']['output'];
  ot_vendor_id: Scalars['String']['output'];
  warehouse_region: Scalars['String']['output'];
  woo_vendor_id: Scalars['Int']['output'];
};

export type VendorAssignedBrand = {
  __typename?: 'VendorAssignedBrand';
  brand_name: Scalars['String']['output'];
  ot_brand_id: Scalars['String']['output'];
};

export type VendorBasicDetailsUpdateInput = {
  ot_vendor_id: Scalars['String']['input'];
  vendor_display_name: Scalars['String']['input'];
  vendor_legal_entity_name: Scalars['String']['input'];
};

export type VendorBasicDetailsUpdatePayload = {
  __typename?: 'VendorBasicDetailsUpdatePayload';
  status: Scalars['String']['output'];
  status_code: Scalars['Int']['output'];
};

export type VendorBillingDetails = {
  __typename?: 'VendorBillingDetails';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  invoicing_settings?: Maybe<VendorInvoicingSettings>;
  state?: Maybe<Scalars['String']['output']>;
  vat_information?: Maybe<VendorVatInformation>;
  zip_code: Scalars['String']['output'];
};

export type VendorBillingDetailsUpdateInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  invoicing_settings: VendorInvoicingSettingsInput;
  ot_vendor_id: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  vat_information: VendorVatInformationInput;
  zip_code: Scalars['String']['input'];
};

export type VendorBillingDetailsUpdatePayload = {
  __typename?: 'VendorBillingDetailsUpdatePayload';
  status: Scalars['String']['output'];
  status_code: Scalars['Int']['output'];
};

/** Vendor churn input. */
export type VendorChurnInput = {
  /** Otrium vendor ID. */
  ot_vendor_id: Scalars['String']['input'];
  /** Churn reason. */
  reason: Scalars['String']['input'];
};

export type VendorChurnPayload = {
  __typename?: 'VendorChurnPayload';
  status: Scalars['String']['output'];
  status_code: Scalars['Int']['output'];
};

export type VendorCollection = {
  __typename?: 'VendorCollection';
  results_per_page: Scalars['Int']['output'];
  total_pages: Scalars['Int']['output'];
  total_results: Scalars['Int']['output'];
  vendors?: Maybe<Array<VendorSummary>>;
};

export enum VendorCommercialAggrementType {
  All = 'ALL',
  CommissionModel = 'COMMISSION_MODEL',
  Ofs = 'OFS',
  Vas = 'VAS'
}

/** Vendor configuration for invoices type */
export type VendorConfigurationAtInvoiceTime = {
  __typename?: 'VendorConfigurationAtInvoiceTime';
  address: Scalars['String']['output'];
  brand_name: Scalars['String']['output'];
  city: Scalars['String']['output'];
  coc_number?: Maybe<Scalars['String']['output']>;
  commission_model: CommissionModelInputType;
  commission_specification?: Maybe<Array<CommissionSpecification>>;
  commission_tier?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  date: Scalars['String']['output'];
  dutch_vat_number?: Maybe<Scalars['String']['output']>;
  /**
   * Unique per invoice, every new invoice, the invoicing system will fetch new vendor
   * configurations along with the commission model in case they’re updated
   */
  id: Scalars['ID']['output'];
  invoice_interval: InvoicingFrequency;
  legal_name?: Maybe<Scalars['String']['output']>;
  local_vat_number?: Maybe<Scalars['String']['output']>;
  netsuite_id: Scalars['String']['output'];
  ofs_configuration_state: FulfillmentServicesState;
  period_end: Scalars['String']['output'];
  period_start: Scalars['String']['output'];
  platform_discount_enabled: Scalars['Boolean']['output'];
  platform_discount_rate?: Maybe<Scalars['Float']['output']>;
  temporary_exception: Scalars['Boolean']['output'];
  updated_at: Scalars['String']['output'];
  vas_configuration_state: VendorValueAddedServicesState;
  vat_percentage: Scalars['Float']['output'];
  vendor_currency?: Maybe<Currency>;
  vendor_id: Scalars['ID']['output'];
  /** Name of the vendor */
  vendor_name: Scalars['String']['output'];
  vendor_ofs_configuration: VendorOfsConfigurationAtInvoiceTime;
  vendor_vas_configuration: Array<VendorVasConfigurationAtInvoiceTime>;
  vendor_vas_enabled: Scalars['Boolean']['output'];
  /** Location of the warehouse of the vendor */
  warehouse_location: Scalars['String']['output'];
  wholesale_vendor: Scalars['Boolean']['output'];
  woo_vendor_id: Scalars['ID']['output'];
  zip_code: Scalars['String']['output'];
};

export type VendorCreateInput = {
  ot_brand_id: Scalars['String']['input'];
  vendor_display_name: Scalars['String']['input'];
  vendor_legal_entity_name: Scalars['String']['input'];
  warehouse_region: Scalars['String']['input'];
};

export type VendorCreatePayload = {
  __typename?: 'VendorCreatePayload';
  status: Scalars['String']['output'];
  status_code: Scalars['Int']['output'];
};

/** VendorDefaultCommercialSettings type */
export type VendorDefaultCommercialSettings = {
  __typename?: 'VendorDefaultCommercialSettings';
  /** Otrium vendor id */
  ot_vendor_id: Scalars['String']['output'];
  /** VendorDefaultPromotionalDiscount type */
  vendor_default_promotional_discount?: Maybe<VendorDefaultPromotionalDiscount>;
};

export type VendorDefaultCommercialSettingsUpdateInput = {
  /** Otrium vendor id */
  ot_vendor_id: Scalars['String']['input'];
  /** VendorDefaultCommissionModelUpdateInputV2 input */
  vendor_default_commission_model: VendorDefaultCommissionModelUpdateInputV2;
  /** VendorFulfillmentRatesSpecificationInputV2 input */
  vendor_default_fulfillment_rates: VendorFulfillmentRatesSpecificationInputV2;
  /** VendorDefaultPromotionalDiscountInput input */
  vendor_default_promotional_discount: VendorDefaultPromotionalDiscountInput;
  /** VendorDefaultValueAddedServicesUpdateInputV2 input */
  vendor_default_value_added_services: VendorDefaultValueAddedServicesUpdateInputV2;
};

/** VendorDefaultCommercialSettingsUpdateResponse response */
export type VendorDefaultCommercialSettingsUpdateResponse = {
  __typename?: 'VendorDefaultCommercialSettingsUpdateResponse';
  /** Updated settings VendorDefaultCommercialSettings as response */
  data?: Maybe<VendorDefaultCommercialSettings>;
  /** Operation status */
  status: Scalars['String']['output'];
  /** Operation status code. 200 for success' */
  status_code: Scalars['Int']['output'];
};

export type VendorDefaultCommissionModelUpdateInput = {
  /**
   * Flat commission model specification required if the type is FLAT
   * Union input types aren't supported yet, so we need to specify the type of the input
   */
  flat_commission_specification?: InputMaybe<FlatCommissionModelSpecificationInput>;
  /** Otrium UUID of vendor */
  ot_vendor_id: Scalars['String']['input'];
  /**
   * Tiered item price commission model specification required if the type is TIRED_ITEM_PRICE
   * Union input types aren't supported yet, so we need to specify the type of the input
   */
  tiered_item_price_commission_specification?: InputMaybe<TieredItemPriceCommissionModelSpecificationInput>;
  /**
   * Tiered revenue commission model specification required if the type is TIERED_REVENUE
   * Union input types aren't supported yet, so we need to specify the type of the input
   */
  tiered_revenue_commission_specification?: InputMaybe<TieredRevenueCommissionModelSpecificationInput>;
  /** Type of the commission model - CommissionModelInputType */
  type: CommissionModelInputType;
};

/** VendorDefaultCommissionModelUpdateInputV2 input */
export type VendorDefaultCommissionModelUpdateInputV2 = {
  /**
   * Flat commission model specification required if the type is FLAT
   * Union input types aren't supported yet, so we need to specify the type of the input
   */
  flat_commission_specification?: InputMaybe<FlatCommissionModelSpecificationInput>;
  /**
   * Tiered item price commission model specification required if the type is TIRED_ITEM_PRICE
   * Union input types aren't supported yet, so we need to specify the type of the input
   */
  tiered_item_price_commission_specification?: InputMaybe<TieredItemPriceCommissionModelSpecificationInput>;
  /**
   * Tiered revenue commission model specification required if the type is TIERED_REVENUE
   * Union input types aren't supported yet, so we need to specify the type of the input
   */
  tiered_revenue_commission_specification?: InputMaybe<TieredRevenueCommissionModelSpecificationInput>;
  /** Type of the commission model - CommissionModelInputType */
  type: CommissionModelInputType;
};

export type VendorDefaultCommissionModelUpdatePayload = {
  __typename?: 'VendorDefaultCommissionModelUpdatePayload';
  status: Scalars['String']['output'];
  status_code: Scalars['Int']['output'];
};

/** VendorDefaultPromotionalDiscount type */
export type VendorDefaultPromotionalDiscount = PromotionalDiscount & {
  __typename?: 'VendorDefaultPromotionalDiscount';
  /** Discount percentage */
  percentage?: Maybe<Scalars['Float']['output']>;
  /** VendorPromotionalDiscountState either enabled or disabled */
  state: VendorPromotionalDiscountState;
};

/** VendorDefaultPromotionalDiscountInput input */
export type VendorDefaultPromotionalDiscountInput = {
  /** Discount percentage. Do not include '%' */
  percentage?: InputMaybe<Scalars['Float']['input']>;
  /** State can either be enabled or disabled */
  state: VendorPromotionalDiscountState;
};

export type VendorDefaultValueAddedServicesModel = {
  __typename?: 'VendorDefaultValueAddedServicesModel';
  enabled_vas_charge_type_ids?: Maybe<Array<Scalars['String']['output']>>;
  state: VendorValueAddedServicesState;
};

export type VendorDefaultValueAddedServicesUpdateInput = {
  ot_vendor_id: Scalars['String']['input'];
  state: VendorValueAddedServicesState;
};

/** VendorDefaultValueAddedServicesUpdateInputV2 input */
export type VendorDefaultValueAddedServicesUpdateInputV2 = {
  state: VendorValueAddedServicesState;
};

export type VendorDefaultValueAddedServicesUpdatePayload = {
  __typename?: 'VendorDefaultValueAddedServicesUpdatePayload';
  status: Scalars['String']['output'];
  status_code: Scalars['Int']['output'];
};

export type VendorFulfillmentRatesSpecificationInput = {
  ot_vendor_id: Scalars['String']['input'];
  state: FulfillmentServicesState;
};

/** VendorDefaultCommissionModelUpdateInputV2 input */
export type VendorFulfillmentRatesSpecificationInputV2 = {
  /** FulfillmentServicesState type */
  state: FulfillmentServicesState;
};

export type VendorFulfillmentRatesSpecificationUpdatePayload = {
  __typename?: 'VendorFulfillmentRatesSpecificationUpdatePayload';
  status: Scalars['String']['output'];
  status_code: Scalars['Int']['output'];
};

/** The settings specific to the invoicing of the vendors. */
export type VendorInvoicingSettings = {
  __typename?: 'VendorInvoicingSettings';
  currency?: Maybe<InvoicingCurrency>;
  frequency?: Maybe<InvoicingFrequency>;
};

export type VendorInvoicingSettingsInput = {
  currency: InvoicingCurrency;
  frequency: InvoicingFrequency;
};

export type VendorLog = {
  __typename?: 'VendorLog';
  /** Author of the log event */
  author?: Maybe<Scalars['String']['output']>;
  /** Each changed line in a particular log */
  logEntries: Array<VendorLogEntries>;
  /** Date time of the log event */
  timeStamp: Scalars['String']['output'];
  /** Title of the operation that was performed */
  title: Scalars['String']['output'];
};

export type VendorLogEntries = {
  __typename?: 'VendorLogEntries';
  key: Scalars['String']['output'];
  newValue?: Maybe<Scalars['String']['output']>;
  oldValue?: Maybe<Scalars['String']['output']>;
};

export type VendorLogsCollection = {
  __typename?: 'VendorLogsCollection';
  logs: Array<VendorLog>;
  results_per_page: Scalars['Int']['output'];
  total_pages: Scalars['Int']['output'];
  total_results: Scalars['Int']['output'];
};

/** Vendor OFS configuration for an invoice, at the time of the invoice */
export type VendorOfsConfigurationAtInvoiceTime = {
  __typename?: 'VendorOFSConfigurationAtInvoiceTime';
  enabled: Scalars['Boolean']['output'];
  handling_fee?: Maybe<Scalars['Float']['output']>;
  order_fee?: Maybe<Scalars['Float']['output']>;
  return_fee?: Maybe<Scalars['Float']['output']>;
  storage_fee?: Maybe<Scalars['Float']['output']>;
};

export type VendorOtVendorId = {
  __typename?: 'VendorOtVendorId';
  ot_vendor_id: Scalars['String']['output'];
};

/** VendorPromotionalDiscountModel type */
export type VendorPromotionalDiscountModel = PromotionalDiscount & {
  __typename?: 'VendorPromotionalDiscountModel';
  /** Discount percentage */
  percentage?: Maybe<Scalars['Float']['output']>;
  /** VendorPromotionalDiscountState either enabled or disabled */
  state: VendorPromotionalDiscountState;
};

/** Vendor VendorPromotionalDiscountState enum */
export enum VendorPromotionalDiscountState {
  Disabled = 'disabled',
  Enabled = 'enabled'
}

export type VendorSummary = {
  __typename?: 'VendorSummary';
  /** Billing country of the vendor. */
  billing_country?: Maybe<Scalars['String']['output']>;
  /** Date of the vendor creation. */
  created_at: Scalars['String']['output'];
  /** Display name of the  vendor. */
  display_name: Scalars['String']['output'];
  /** Churn State of the vendor. */
  is_churned: Scalars['Boolean']['output'];
  /** Legal entity of the  vendor. */
  legal_entity_name?: Maybe<Scalars['String']['output']>;
  /** Netsuite ID. */
  netsuite_id?: Maybe<Scalars['Int']['output']>;
  /** Otrium brand ID */
  ot_brand_id: Scalars['String']['output'];
  /** Otrium vendor ID */
  ot_vendor_id: Scalars['String']['output'];
  /** TemporaryExceptionPeriod if vendor has any. */
  temporary_exception_period?: Maybe<TemporaryExceptionPeriod>;
  /** State of the temporary exception created. Either active,expire or scheduled */
  temporary_exception_state?: Maybe<TemporaryCommercialSettingsState>;
  /** Warehouse entity of the  vendor. */
  warehouse_region: Scalars['String']['output'];
  /** Woo vendor ID. */
  woo_vendor_id?: Maybe<Scalars['Int']['output']>;
};

export type VendorTemporaryCommercialSettingsUpdateInput = {
  /** ExceptionSpecificationInput input */
  exception_specification: ExceptionSpecificationInput;
  /**
   * Id of the temporary commercial setting.
   * Optional when creating new one.
   * Required when updating.
   */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Operation type. Either create, edit or replace */
  operation: TemporaryCommercialSettingsOperationType;
  /** Otrium vendor id */
  ot_vendor_id: Scalars['String']['input'];
  /** VendorTemporaryCommissionModelUpdateInput input */
  vendor_temporary_commission_model: VendorTemporaryCommissionModelUpdateInput;
  /** VendorTemporaryFulfillmentServicesUpdateInput input */
  vendor_temporary_fulfillment_rates: VendorTemporaryFulfillmentServicesUpdateInput;
  /** VendorTemporaryValueAddedServicesUpdateInput input */
  vendor_temporary_value_added_services: VendorTemporaryValueAddedServicesUpdateInput;
};

export type VendorTemporaryCommercialSettingsUpdateResponse = {
  __typename?: 'VendorTemporaryCommercialSettingsUpdateResponse';
  /** Operation status */
  status: Scalars['String']['output'];
  /** Operation status code. 200 for success' */
  status_code: Scalars['Int']['output'];
};

export type VendorTemporaryCommissionModelUpdateInput = {
  /**
   * Flat commission model specification required if the type is FLAT
   * Union input types aren't supported yet, so we need to specify the type of the input
   */
  flat_commission_specification?: InputMaybe<FlatCommissionModelSpecificationInput>;
  /** Type of the commission model - TemporaryCommissionModelState */
  state: TemporaryCommissionModelState;
  /**
   * Tiered item price commission model specification required if the type is TIRED_ITEM_PRICE
   * Union input types aren't supported yet, so we need to specify the type of the input
   */
  tiered_item_price_commission_specification?: InputMaybe<TieredItemPriceCommissionModelSpecificationInput>;
  /**
   * Tiered revenue commission model specification required if the type is TIERED_REVENUE
   * Union input types aren't supported yet, so we need to specify the type of the input
   */
  tiered_revenue_commission_specification?: InputMaybe<TieredRevenueCommissionModelSpecificationInput>;
  /** Type of the commission model - CommissionModelInputType */
  type?: InputMaybe<CommissionModelInputType>;
};

export type VendorTemporaryCommissionModelUpdatePayload = {
  __typename?: 'VendorTemporaryCommissionModelUpdatePayload';
  status: Scalars['String']['output'];
  status_code: Scalars['Int']['output'];
};

export type VendorTemporaryFulfillmentServicesCustomStateInput = {
  handling_fee: Scalars['Float']['input'];
  order_fee: Scalars['Float']['input'];
  return_fee: Scalars['Float']['input'];
  storage_fee: Scalars['Float']['input'];
};

export type VendorTemporaryFulfillmentServicesUpdateInput = {
  custom_state_specification?: InputMaybe<VendorTemporaryFulfillmentServicesCustomStateInput>;
  state: TemporaryFulfillmentServicesState;
};

export type VendorTemporaryFulfillmentServicesUpdatePayload = {
  __typename?: 'VendorTemporaryFulfillmentServicesUpdatePayload';
  status: Scalars['String']['output'];
  status_code: Scalars['Int']['output'];
};

export type VendorTemporaryValueAddedServicesUpdateInput = {
  enabled_vas_charge_type_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  state: TemporaryVendorValueAddedServicesState;
};

export type VendorTemporaryValueAddedServicesUpdatePayload = {
  __typename?: 'VendorTemporaryValueAddedServicesUpdatePayload';
  status: Scalars['String']['output'];
  status_code: Scalars['Int']['output'];
};

export type VendorTerminateTemporaryCommercialSettingsInput = {
  /** Id of temporary commercial setting return from the vendor query commercial_settings.temporary.id */
  temporary_commercial_setting_id: Scalars['ID']['input'];
};

export type VendorTerminateTemporaryCommercialSettingsPayload = {
  __typename?: 'VendorTerminateTemporaryCommercialSettingsPayload';
  status: Scalars['String']['output'];
  status_code: Scalars['Int']['output'];
};

/** Vendor VAS configuration for an invoice, at the time of the invoice */
export type VendorVasConfigurationAtInvoiceTime = {
  __typename?: 'VendorVASConfigurationAtInvoiceTime';
  charge_type_id: Scalars['ID']['output'];
  enabled: Scalars['Boolean']['output'];
};

export type VendorValueAddedServicesModel = {
  __typename?: 'VendorValueAddedServicesModel';
  enabled_vas_charge_type_ids?: Maybe<Array<Scalars['String']['output']>>;
  state: VendorValueAddedServicesState;
  /** @deprecated Use vendor.temporary_exception_period */
  temporary_exception_specification?: Maybe<TemporaryExceptionSpecification>;
};

export enum VendorValueAddedServicesState {
  Custom = 'custom',
  Disabled = 'disabled',
  Enabled = 'enabled'
}

export type VendorVatInformation = {
  __typename?: 'VendorVatInformation';
  call_off_stock_exemption?: Maybe<Scalars['Boolean']['output']>;
  /** Netherlands Chamber of Commerce KVK number */
  coc_number?: Maybe<Scalars['String']['output']>;
  dutch_vat_number?: Maybe<Scalars['String']['output']>;
  local_vat_country_code?: Maybe<Scalars['String']['output']>;
  local_vat_number?: Maybe<Scalars['String']['output']>;
};

export type VendorVatInformationInput = {
  call_off_stock_exemption?: InputMaybe<Scalars['Boolean']['input']>;
  /** Netherlands Chamber of Commerce KVK number */
  coc_number?: InputMaybe<Scalars['String']['input']>;
  dutch_vat_number?: InputMaybe<Scalars['String']['input']>;
  local_vat_country_code: Scalars['String']['input'];
  local_vat_number?: InputMaybe<Scalars['String']['input']>;
};

export type Visual = {
  __typename?: 'Visual';
  /** Size of the file. */
  size?: Maybe<Scalars['Int']['output']>;
  /** url of the files. */
  url: Scalars['String']['output'];
};

export type VisualCollection = {
  __typename?: 'VisualCollection';
  /** has_more result flag. */
  has_more: Scalars['Boolean']['output'];
  /** List of visuals. */
  items?: Maybe<Array<Visual>>;
  /** To obtaine next set of files you have to pass this value as `next_continuation_token` in `getBrandVisual` query. */
  next_continuation_token?: Maybe<Scalars['String']['output']>;
};

/** WarehouseInfo type, contains information about warehouse's location */
export type WarehouseInfo = {
  __typename?: 'WarehouseInfo';
  city?: Maybe<Scalars['String']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  location: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type WelcomePopup = {
  __typename?: 'WelcomePopup';
  cta: Scalars['String']['output'];
  desktopImage: Scalars['String']['output'];
  mobileImage: Scalars['String']['output'];
  subTitle: Scalars['String']['output'];
  termsAndConditions: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** WishlistBrand Type */
export type WishlistBrand = Node & {
  __typename?: 'WishlistBrand';
  /** Cover Image. */
  cover_image?: Maybe<Scalars['String']['output']>;
  /** Created timestamp. In ISO 8601 format */
  created_at: Scalars['String']['output'];
  /** Designer brand */
  designer_brand: Scalars['Boolean']['output'];
  /** Discount (popular shop discount). */
  discount?: Maybe<Scalars['String']['output']>;
  /** Genders ex:men,women,kids. */
  genders?: Maybe<Array<Scalars['String']['output']>>;
  /** GoodOnYou pillars */
  good_on_you_pillars: Array<Scalars['String']['output']>;
  /** Brand id. */
  id: Scalars['ID']['output'];
  /** Image. */
  image?: Maybe<Scalars['String']['output']>;
  /** is_coming_soon flag. */
  is_coming_soon?: Maybe<Scalars['Boolean']['output']>;
  /** Is brand GoodOnYou */
  is_good_on_you: Scalars['Boolean']['output'];
  /** Labels details */
  labels?: Maybe<Array<BrandLabel>>;
  /** Logo. */
  logo: Scalars['String']['output'];
  /** Logo svg. */
  logo_svg: Scalars['String']['output'];
  /** Brand name. */
  name: Scalars['String']['output'];
  /** Overlay logo. */
  overlay_logo?: Maybe<Scalars['String']['output']>;
  /** Sales landing url. */
  sales_landing?: Maybe<Scalars['String']['output']>;
  /** Brand slug. */
  slug: Scalars['String']['output'];
  /** Subtitle. */
  subtitle?: Maybe<Scalars['String']['output']>;
  /** wihslist item id of the user. */
  user_wishlist_item_id?: Maybe<Scalars['String']['output']>;
};

export type WishlistBrandCollection = Collection & {
  __typename?: 'WishlistBrandCollection';
  /** Items. */
  items: Array<Maybe<WishlistBrand>>;
  /** Total number. */
  total: Scalars['Int']['output'];
};

export type WishlistItemCreateInput = {
  /** Based on the item_type product/category or brand id. */
  item_id: Scalars['String']['input'];
  /** item_type. Either one of  `product`, `category` or `brand`. */
  item_type: WishlistItemType;
};

export type WishlistItemDeleteInput = {
  /** Based on the item_type product/category or brand id. */
  item_id: Scalars['String']['input'];
  /** item_type. Either one of  `product`, `category` or `brand`. */
  item_type: WishlistItemType;
};

/** WishlistItemFilter Type. */
export type WishlistItemFilter = {
  /** item_type. Either one of  `product`, `category` or `brand`. */
  item_type?: InputMaybe<WishlistItemType>;
};

export enum WishlistItemType {
  Brand = 'brand',
  Category = 'category',
  Product = 'product'
}

/** WishlistProduct Type. */
export type WishlistProduct = Node & {
  __typename?: 'WishlistProduct';
  /** Availability of the product. */
  availability: Scalars['Boolean']['output'];
  /** Available size of the products as string. */
  available_sizes_label?: Maybe<Scalars['String']['output']>;
  /** Brand details of the product. */
  brand: CatalogBrand;
  /** Product categories */
  categories?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Created timestamp. In ISO 8601 format */
  created_at: Scalars['String']['output'];
  /** Currency type: value changes based on language header */
  currency: ProductCurrency;
  /** Product id. */
  id: Scalars['ID']['output'];
  /** Is product personalised */
  is_personalised?: Maybe<Scalars['Boolean']['output']>;
  /** Labels details of the products. */
  labels?: Maybe<Array<ProductLabel>>;
  /** Name of the product. */
  name: Scalars['String']['output'];
  /** Price of the product. If `Accept-Language` header is `en-uk` price valude is in GBP. else Euro. */
  price: Scalars['Float']['output'];
  /** Product discount */
  product_discount?: Maybe<Scalars['Float']['output']>;
  /** Product kids gender */
  product_kids_gender?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Product lang */
  product_lang?: Maybe<Scalars['String']['output']>;
  /** Product shop gender */
  product_shop_type?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Regular price of the product. If `Accept-Language` header is `en-uk` price valude is in GBP. else Euro. */
  regular_price: Scalars['Float']['output'];
  /** Product available sizes */
  sizes_available?: Maybe<Scalars['String']['output']>;
  /** Product sku */
  sku?: Maybe<Scalars['String']['output']>;
  /** Slug of the product. */
  slug: Scalars['String']['output'];
  /** Thumbnails of the product. */
  thumbnail: Scalars['String']['output'];
  /** Product translated category ids */
  translated_category_ids?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Product translated category names */
  translated_category_names?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Product url */
  url?: Maybe<Scalars['String']['output']>;
  /** Wishlist item id of the user. */
  user_wishlist_item_id?: Maybe<Scalars['String']['output']>;
};

export type WishlistProductCollection = Collection & {
  __typename?: 'WishlistProductCollection';
  /** Items. */
  items: Array<Maybe<WishlistProduct>>;
  /** Total number. */
  total: Scalars['Int']['output'];
};

export enum WishlistSort {
  CreatedDateAsc = 'CREATED_DATE_ASC',
  CreatedDateDesc = 'CREATED_DATE_DESC'
}

export type WishlitItemBasicPayload = Node & {
  __typename?: 'WishlitItemBasicPayload';
  /** Created timestamp. In ISO 8601 format */
  created_at: Scalars['String']['output'];
  /** Id. */
  id: Scalars['ID']['output'];
  /** Based of the item_type id of the entity ( product, category or brand). */
  item_id: Scalars['String']['output'];
  /** Item type either one of product,category or brand. */
  item_type: Scalars['String']['output'];
};

export type WithPosition = {
  position?: Maybe<Scalars['Int']['output']>;
};

/** WooToken type */
export type WooToken = {
  __typename?: 'WooToken';
  timestamp: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

/** Result of the Brand MC request. */
export type ApiKeyResult = {
  __typename?: 'apiKeyResult';
  success: Scalars['Boolean']['output'];
};

/** Result of the brand API gateway access request */
export type BrandRequestApiGatewayAccessResult = {
  __typename?: 'brandRequestApiGatewayAccessResult';
  result: Scalars['String']['output'];
};

/** Result of product category list request */
export type DamProductCategoryList = {
  __typename?: 'damProductCategoryList';
  categories: Array<DamCategory>;
};

export type InventoryUploadLogResult = {
  __typename?: 'inventoryUploadLogResult';
  /** Response code. */
  code: Scalars['String']['output'];
  /** Response message. */
  message: Scalars['String']['output'];
};

/** DAM - PIPELINE - Attribute dashboard result type */
export type PipelineAttributeDashboardResult = {
  __typename?: 'pipelineAttributeDashboardResult';
  /** Table data array */
  table_data: Array<PipelineAttributeDashboardTableRow>;
};

/** Single row */
export type PipelineAttributeDashboardTableRow = {
  __typename?: 'pipelineAttributeDashboardTableRow';
  /** Drop no */
  drop: Scalars['String']['output'];
  /** Title */
  title: Scalars['String']['output'];
  /** Total products count */
  total_products_count: Scalars['Int']['output'];
};

/** DAM - PIPELINE - Category dashboard result type */
export type PipelineCategoryDashboardResult = {
  __typename?: 'pipelineCategoryDashboardResult';
  /** Table data array */
  table_data: Array<PipelineCategoryDashboardTableRow>;
};

/** Single row */
export type PipelineCategoryDashboardTableRow = {
  __typename?: 'pipelineCategoryDashboardTableRow';
  /** Drop no */
  drop: Scalars['String']['output'];
  /** Title */
  title: Scalars['String']['output'];
  /** Total products count */
  total_products_count: Scalars['Int']['output'];
};

/** DAM - PIPELINE - defect dashboard result type */
export type PipelineDefectDashboardResult = {
  __typename?: 'pipelineDefectDashboardResult';
  /** Table data array */
  table_data: Array<PipelineDefectDashboardTableRow>;
};

/** Single row */
export type PipelineDefectDashboardTableRow = {
  __typename?: 'pipelineDefectDashboardTableRow';
  /** Drop no */
  drop: Scalars['String']['output'];
  /** This will contain all image source data */
  image_sources: Array<DamProductVisualSource>;
  /** Title */
  title: Scalars['String']['output'];
  /** Total products count */
  total_products_count: Scalars['Int']['output'];
};

export type PipelineMainDashboardInput = {
  /** limit for pagination */
  limit: Scalars['Int']['input'];
  /** skip for pagination */
  offset: Scalars['Int']['input'];
  /** search value */
  search_value?: InputMaybe<Scalars['String']['input']>;
};

/** DAM - PIPELINE - main dashboard result type */
export type PipelineMainDashboardResult = {
  __typename?: 'pipelineMainDashboardResult';
  /** Table data array */
  table_data: Array<PipelineMainDashboardTableRow>;
};

/** Single row */
export type PipelineMainDashboardTableRow = {
  __typename?: 'pipelineMainDashboardTableRow';
  /** todo done pipeline count */
  done_pipeline: Scalars['Int']['output'];
  /** Drop no */
  drop: Scalars['String']['output'];
  /** This will contain all image source data */
  image_sources: Array<DamProductVisualSource>;
  /** Pipeline status */
  pipeline_status: Scalars['String']['output'];
  /** Total products count */
  products_count: Scalars['Int']['output'];
  /** Title */
  title: Scalars['String']['output'];
  /** todo attribute pipeline count */
  todo_attribute_pipeline: Scalars['Int']['output'];
  /** todo category pipeline count */
  todo_category_pipeline: Scalars['Int']['output'];
  /** todo defect pipeline count */
  todo_defect_pipeline: Scalars['Int']['output'];
  /** todo qa pipeline count */
  todo_qa_pipeline: Scalars['Int']['output'];
  /** todo visual pipeline count */
  todo_visual_pipeline: Scalars['Int']['output'];
};

/** DAM - PIPELINE - QA dashboard result type */
export type PipelineQaDashboardResult = {
  __typename?: 'pipelineQADashboardResult';
  /** Table data array */
  table_data: Array<PipelineQaDashboardTableRow>;
};

/** Single row */
export type PipelineQaDashboardTableRow = {
  __typename?: 'pipelineQADashboardTableRow';
  /** Drop no */
  drop: Scalars['String']['output'];
  /** Title */
  title: Scalars['String']['output'];
  /** Total products count */
  total_products_count: Scalars['Int']['output'];
};

/** DAM - PIPELINE - visual dashboard result type */
export type PipelineVisualDashboardResult = {
  __typename?: 'pipelineVisualDashboardResult';
  /** Table data array */
  table_data: Array<PipelineVisualDashboardTableRow>;
};

/** Single row */
export type PipelineVisualDashboardTableRow = {
  __typename?: 'pipelineVisualDashboardTableRow';
  /** Drop no */
  drop: Scalars['String']['output'];
  /** This will contain all image source data */
  image_sources: Array<DamProductVisualSource>;
  /** Total products count */
  products_count: Scalars['Int']['output'];
  /** Total completed products count */
  products_done: Scalars['Int']['output'];
  /** Title */
  title: Scalars['String']['output'];
  /** Total products count */
  total_products_count: Scalars['Int']['output'];
  /** Total visuals count */
  total_visuals_count: Scalars['Int']['output'];
};

export type PlpQuery = {
  __typename?: 'plpQuery';
  ages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  brands?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  cato_cat?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  colors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  goodOnYou?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  label?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  limit?: Maybe<Scalars['String']['output']>;
  max_price?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  min_price?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  orderBy?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  page?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  patterns?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sizes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  styles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};
