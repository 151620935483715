import { useCallback, useContext } from "react";
import { IAssignmentEvent } from "@eppo/js-client-sdk";
import type { Context } from "@segment/analytics-next";
import { User } from "@ht-sdks/events-sdk-js-browser";
import { useSession } from "next-auth/client";
import { SearchState } from "react-instantsearch-core";
import Cookies from "js-cookie";
import { getAnalytics } from "src/utils/getAnalytics";
import { isClient } from "src/utils/isClient";
import { AuthProvider } from "pages/api/auth/getUserTokenFromApi";

import {
  CatalogProduct,
  HomepageSingleCategoryProduct,
  SingleProduct,
  SingleCartProductVariation,
  WishlistProduct,
  ProductVariation,
  SummaryBrand,
} from "src/types/graphql.d";
import { fixFemaleOrMale } from "src/utils/fixFemaleOrMale";
import {
  SEGMENT_EVENTS,
  Filter,
  SegmentProductListMeta,
  SegmentProduct,
  SegmentCatalogProduct,
  SegmentHomepageSingleCategoryProduct,
  getFilters,
  SegmentCouponTracking,
  getFormName,
  SEGMENT_TOGGLE_WISHLIST_TYPE,
  SEGMENT_DATA_UNAVAILABLE,
  SEGMENT_ENGAGEMENT_TIMEOUT,
  SEGMENT_PROMOTION_TYPE,
} from "src/segment";
import {
  AlgoliaSearchProduct,
  AlgoliaSearchSuggestion,
  SuggestionType,
} from "src/types/Algolia";
import {
  Cart,
  LineItem,
  ProductPrice,
  RawProductAttribute,
  AddressInput,
} from "src/types/ctgraphql.d";
import { Iso2LetterLocale, Locale } from "lib/Intl";
import { getCTAttribute } from "src/utils/ct/attributeHelper";
import { formatStandardPrice } from "src/utils/ct/priceHelper";
import { cartCountHelper } from "src/utils/ct/cartCountHelper";
import { getLineItemPrice } from "src/utils/getRegularPrice";
import { useRouter } from "next/router";
import { CarouselItem, CarouselType } from "src/types/Carousels.d";
import { PLPType } from "src/types/PLPType.d";
import { AppCtx, RankingInfo } from "src/contexts/app.context";
import { HomePageType } from "src/types/HomePageType.d";

export type TrackFnReturnType =
  | Promise<void>
  | Promise<Context>
  | Promise<Context[]>;

export type FilterAction = "filter" | "sort" | "next_page";

export enum PromotionEventType {
  click = "click",
  view = "view",
}

type ProductListFilteredProps = {
  algoliaIndex: string | undefined;
  searchState: SearchState;
  queryID: string | undefined;
  meta: SegmentProductListMeta;
  filterAction?: FilterAction;
  filtersApplied?: Filter[];
  sortApplied?: string;
  numPage?: number;
  rankingInfo?: RankingInfo;
};

type productListFilterResetProps = {
  algoliaIndex?: string;
  meta: SegmentProductListMeta;
  queryID?: string;
  rankingInfo?: RankingInfo;
};

export type productListClickedProps = SegmentProductListMeta & {
  rankingInfo?: RankingInfo;
  gatedActionType?: string;
  actionSource?: string;
  redirectTo?: string;
  promo_path?: string;
};

interface SegmentProductListViewedProps {
  algoliaIndex?: string;
  defaultSortBy?: string;
  isPersonalised?: boolean;
  numberOfItems?: number;
  productListMeta: SegmentProductListMeta;
  products: SegmentProduct[];
  queryID?: string;
  rankingInfo?: RankingInfo;
  responseSourceType?: string | null;
  redirectTo?: string;
}

export type SegmentProductClicked = {
  algoliaIndex?: string;
  isPersonalised?: boolean;
  position: number;
  product: (CatalogProduct | HomepageSingleCategoryProduct) & {
    objectID?: string;
    entityID?: string;
  };
  productListMeta: SegmentProductListMeta;
  queryID?: string;
  rankingInfo?: RankingInfo;
  gatedActionType?: string;
  actionSource?: string;
  redirectTo?: string;
  promo_path?: string;
};

export interface SearchResultClickedData {
  query: string | null;
  product: AlgoliaSearchProduct | null;
  brandId?: string | null | undefined;
  suggestion?: string | null;
  productIdPosition?: number | null | undefined;
  categoryIdPosition?: number | null | undefined;
  carouselPosition?: number | null | undefined;
  carouselItemIdPosition?: number | null | undefined;
  brandIdPosition?: number | null;
  brands?: SummaryBrand[] | undefined;
  products?: AlgoliaSearchProduct[] | undefined;
  carouselItems?: CarouselItem[] | undefined;
  suggestions?: AlgoliaSearchSuggestion[] | undefined;
}

export type GatedPopupData = {
  pageName: string;
  actionSource: string;
  carouselType?: string;
  shopType: string;
  productName?: string;
  productId?: string;
  productImageUrl?: string;
  brandName?: string;
  brandId?: string;
  brandImageUrl?: string;
  redirectTo?: string;
  level?: string;
  tab?: string;
};

export interface Promotion {
  creative?: string;
  eventType: PromotionEventType;
  id?: string;
  name?: string | null;
  position?: string;
  path?: string;
  promotionType?: SEGMENT_PROMOTION_TYPE;
}

enum TrackingLocale {
  "en" = "en-EN",
  "de" = "de-DE",
  "fr" = "fr-FR",
  "nl" = "nl-NL",
  "zz" = "en-EN",
  "en-gb" = "en-GB",
  "es-es" = "es-ES",
  "it-it" = "it-IT",
  "en-dk" = "en-DK",
  "en-se" = "en-SE",
  "pl-pl" = "pl-PL",
  "de-at" = "de-AT",
  "fr-be" = "fr-BE",
  "nl-be" = "nl-BE",
  "en-us" = "en-US",
}

enum CheckoutSteps {
  STEP_1 = "Personal Details",
  STEP_2 = "Payment Method",
}

export enum OnboardingSteps {
  BRAND = "brand",
  COMPLETED = "completed",
  ERROR = "error",
  SHOP = "shop",
}

type GeneralObject = Record<string, any>;

type ProductFavouritedOrUnfavouritedProps = {
  currency: string;
  eventType: SEGMENT_TOGGLE_WISHLIST_TYPE;
  isPersonalised?: boolean;
  product: (
    | CatalogProduct
    | HomepageSingleCategoryProduct
    | SingleProduct
    | WishlistProduct
  ) & {
    objectID?: string;
    entityID?: string;
  };
  /** Algolia index */
  indexName?: string;
};

type UserUpdateData = {
  firstName?: string;
  lastName?: string;
  gender?: "female" | "male";
  birthday?: string;
  phoneNumber?: string;
  address?: {
    postalCode?: string;
    country?: string;
    city?: string;
    street?: string;
    state?: string;
  };
};

const PAGE_TYPE: Record<PLPType | HomePageType, string> = {
  [PLPType.BRAND_PLP]: "brand_plp",
  [PLPType.ICONS_PLP]: "icons_plp",
  [PLPType.CATEGORY_PLP]: "category_plp",
  [PLPType.NEW_IN]: "new_in",
  [PLPType.HOT_DEALS]: "hot_deals",
  [PLPType.CLEARANCE_PLP]: "clearance_plp",
  [PLPType.DESIGNER_PLP]: "designer_plp",
  [PLPType.COLLECTIONS_PLP]: "colletions_plp",
  [PLPType.SEARCH_PLP]: "search_plp",
  [PLPType.SHOP_TYPE]: "shop_type",
  [HomePageType.GATED]: "gated_homepage",
} as const;

type AuthBannerData = {
  page_type?: PLPType | HomePageType;
  brand?: string;
  category?: string;
  category_slug?: string;
  shop_type?: string;
};

interface UseSegementReturnType {
  user?: Promise<User>;
  segmentIdentify: (
    additionalOptions: IdentifyAdditionalOptions
  ) => TrackFnReturnType;

  segmentSignedIn: () => TrackFnReturnType;
  segmentSignedOut: () => Promise<void>;
  segmentSignedUp: () => TrackFnReturnType;
  segmentBrandInfoOpened: (data: {
    pageType: string | null | undefined;
    brandName: string | null | undefined;
  }) => TrackFnReturnType;
  segmentBrandConsciousHowWeRateClicked: (data: {
    pageType: string | null | undefined;
    brandName: string | null | undefined;
  }) => TrackFnReturnType;
  brandFavourited: (data: {
    brandName: string | null | undefined;
    brandId: string | null | undefined;
  }) => TrackFnReturnType;
  brandUnfavourited: (data: {
    brandName: string | null | undefined;
    brandId: string | null | undefined;
  }) => TrackFnReturnType;
  brandFavouritedWhenLoggedOut: (data: {
    brandName: string | null | undefined;
    brandId: string | null | undefined;
  }) => TrackFnReturnType;

  productListFilterReset: (
    data: productListFilterResetProps
  ) => TrackFnReturnType;

  productListFiltered: (data: ProductListFilteredProps) => TrackFnReturnType;

  segmentProductListClicked: (
    data: productListClickedProps
  ) => TrackFnReturnType;

  segmentProductListFilterOpened: (filterName: string) => TrackFnReturnType;
  segmentProductListViewed: (
    data: SegmentProductListViewedProps
  ) => TrackFnReturnType;
  segmentCartViewed: (cart: Cart) => TrackFnReturnType;
  segmentCheckoutStarted: (cart: Cart) => TrackFnReturnType;
  segmentProductClicked: (args: SegmentProductClicked) => TrackFnReturnType;

  segmentFirstCheckoutStepCompleted: (
    cartId: string,
    shippingMethodId: string | undefined,
    shippingMethodName: string | undefined,
    email: string | undefined,
    billingAddress: AddressInput,
    shippingAddress?: AddressInput
  ) => TrackFnReturnType;

  segmentSecondCheckoutStepCompleted: (
    cartId: string,
    paymentMethod?: string
  ) => TrackFnReturnType;

  segmentOrderCompleted: (
    cart: Cart,
    isOldCustomer: boolean
  ) => TrackFnReturnType;

  segmentCouponApplied: (
    data: SegmentCouponTracking & { couponDiscountAbsolute: string }
  ) => TrackFnReturnType;
  segmentCouponDenied: (
    data: SegmentCouponTracking & {
      couponErrorCode?: string;
      couponDenied: string;
    }
  ) => TrackFnReturnType;
  segmentCouponEntered: (data: SegmentCouponTracking) => TrackFnReturnType;
  segmentCouponRemoved: (data: SegmentCouponTracking) => TrackFnReturnType;

  segmentFormError: (
    formField: string,
    formErrorMessage: string,
    formName?: string
  ) => TrackFnReturnType;
  segmentFormTracking: (
    formField: string,
    formName?: string
  ) => TrackFnReturnType;
  segmentOnboarding: (onboardingStep: OnboardingSteps) => TrackFnReturnType;
  segmentOptIn: (
    optIn: boolean,
    email: string | null | undefined,
    origin?: string
  ) => Promise<void | Context>;

  segmentPageViewed: (data: PageViewedProps) => TrackFnReturnType;
  segmentPasswordUpdated: () => TrackFnReturnType;
  segmentProductAdded: (
    product: SingleProduct,
    size: string,
    queryID?: string,
    objectID?: string,
    algoliaIndex?: string,
    variationID?: string,
    cartData?: {
      product_count?: number;
      cart_total?: number;
    },
    lineItems?: LineItem[]
  ) => TrackFnReturnType;

  segmentProductFavouritedOrUnfavourited: ({
    currency,
    eventType,
    isPersonalised,
    product,
    indexName,
  }: ProductFavouritedOrUnfavouritedProps) => TrackFnReturnType;

  segmentProductOutOfStock: (
    product: SingleProduct | SingleCartProductVariation,
    size?: string | null
  ) => TrackFnReturnType;
  segmentProductAddedOrRemoved: (
    lineItem: LineItem,
    type: "productAdded" | "productRemoved",
    countryCode: Iso2LetterLocale | null,
    quantity?: number,
    cartData?: {
      product_count?: number;
      cart_total?: number;
    },
    lineItems?: LineItem[]
  ) => TrackFnReturnType;
  segmentProductSizeSelected: (
    product: SingleProduct,
    size?: string | null
  ) => TrackFnReturnType;
  segmentProductSizeSelectorClicked: (
    product: SingleProduct
  ) => TrackFnReturnType;
  segmentProductViewed: (
    product: SingleProduct,
    queryID?: string,
    objectID?: string,
    algoliaIndex?: string
  ) => TrackFnReturnType;
  segmentProductEngaged: (
    product: SingleProduct,
    queryID?: string,
    objectID?: string,
    algoliaIndex?: string
  ) => TrackFnReturnType;
  segmentPromotionClickedOrViewed: (
    data: Promotion & {
      tab?: string;
      gatedActionType?: string;
      shopType?: string;
      brand?: string;
      brandId?: string;
      redirectTo?: string;
    }
  ) => TrackFnReturnType;
  segmentReferAFriendViewed: () => TrackFnReturnType;
  segmentSearched: (
    query: string,
    numBrandResults: number,
    numCategoryResults: number,
    numProductsResults: number
  ) => TrackFnReturnType;

  segmentUserEmailUpdated: () => TrackFnReturnType;
  segmentProductOutOfStockCT: (
    lineItem: LineItem,
    warning: string,
    countryCode: Iso2LetterLocale | null
  ) => TrackFnReturnType;
  segmentDomainChanged: (
    domainName: string,
    callback: () => void
  ) => TrackFnReturnType;
  segmentToasterNotificationDisplayed: (
    promo_name: string | undefined,
    promo_path?: string
  ) => TrackFnReturnType;
  segmentToasterNotificationDismissed: (
    promo_name: string | undefined,
    promo_path?: string
  ) => TrackFnReturnType;
  segmentToasterNotificationClicked: (
    promo_name: string | undefined,
    promo_path?: string
  ) => TrackFnReturnType;
  segmentSearchOverlayOpened: () => TrackFnReturnType;
  segmentSearchOverlayClosed: (
    closedVia: "click x" | "click outside"
  ) => TrackFnReturnType;
  segmentSearchOverlayCarouselsViewed: (
    carousel_type: CarouselType,
    carousel_position?: number
  ) => TrackFnReturnType;
  segmentSearchLinkClicked: (
    type: SuggestionType,
    linkName: string
  ) => TrackFnReturnType;
  segmentSearchRecommendationClicked: (
    search_rec_type: CarouselType,
    search_rec_name: string,
    search_rec_position: number
  ) => TrackFnReturnType;
  segmentViewSearchAllBrands: () => TrackFnReturnType;
  segmentViewSearchResultPage: () => TrackFnReturnType;
  trackForgotPassword: () => TrackFnReturnType;
  segmentPdpInfoBoxOpened: (boxName: string) => TrackFnReturnType;
  segmentUserProfileUpdated: (userData: UserUpdateData) => TrackFnReturnType;
  segmentViewedAuthBanner: (page_name?: string) => TrackFnReturnType;
  segmentAuthBannerSelect: (
    data: AuthBannerData & {
      type: "sign_up" | "sign_in";
    }
  ) => TrackFnReturnType;
  segmentAuthBannerClosed: (
    data: AuthBannerData & {
      popup_type: "sign_up" | "sign_in";
    }
  ) => TrackFnReturnType;
  segmentGuestOptInBannerDismissed: (page_name?: string) => TrackFnReturnType;
  segmentGuestOptInBannerViewed: (page_name?: string) => TrackFnReturnType;
  segmentGuestOptInBannerCtaClick: (page_name?: string) => TrackFnReturnType;
  segmentGuestOptInPopupDismissed: (page_name?: string) => TrackFnReturnType;
  segmentTrackingError: (data: {
    error_type: string;
    error_message: string;
  }) => TrackFnReturnType;
  trackingGooglePayButtonClicked: (card_id: string) => TrackFnReturnType;
  trackingCategoryItemClicked: (
    category_slug: string,
    category_name: string
  ) => TrackFnReturnType;
  eppoRandomizedAssigment: (assigment: IAssignmentEvent) => TrackFnReturnType;
  segmentMenuNavClicked: (
    menuType: string,
    menuClickType: string,
    menuClickName: string,
    menuSectionName?: string
  ) => TrackFnReturnType;
  segmentOptInDeeplinkViewed: () => TrackFnReturnType;
  segmentGatedPopupOpened: (data: GatedPopupData) => TrackFnReturnType;
  segmentGatedPopupLoginClicked: (data: GatedPopupData) => TrackFnReturnType;
  segmentGatedPopupRegisterClicked: (data: GatedPopupData) => TrackFnReturnType;
  segmentGatedPopupClosed: (data: GatedPopupData) => TrackFnReturnType;
}

interface IdentifyAdditionalOptions {
  sign_in_platform?: AuthProvider;
  sign_up_platform?: AuthProvider;
  [key: string]: any;
}

interface TrackingCartProduct {
  brand: unknown;
  brand_id: unknown;
  category: never[];
  category_id: never[];
  image_url: unknown;
  labels: unknown;
  name: unknown;
  price: number;
  product_id: unknown;
  product_size: unknown;
  quantity: number;
  regular_price: number;
  sku?: string | null;
  url: string;
  variant: unknown;
  product_variation: unknown;
}

interface TrackingCartData {
  request_source: "appAndroid" | "appIos" | "webReact";
  cart_id: string;
  currency: string;
  cart_total_products: number;
  cart_total_value: number;
  products: TrackingCartProduct[];
}

interface PageViewedProps {
  pathName: string;
  locale: Locale;
  shopType?: string;
  cartData?: {
    product_count?: number;
    cart_total?: number;
  };
  productAvailable?: boolean;
}

type GA_COOKIES = { [key: string]: string };

const isIOSApp =
  typeof webkit !== "undefined" &&
  typeof webkit.messageHandlers?.track !== "undefined";

const isAndroidApp = typeof segment_webview !== "undefined";

const isNativeApp = isAndroidApp || isIOSApp;

export const requestSource = {
  get request_source() {
    const searchParams = new URLSearchParams(location.search);
    const isFromAndroid = searchParams.get("app") === "android";
    if (isAndroidApp || isFromAndroid) return "appAndroid";
    if (isIOSApp) return "appIos";
    return "webReact";
  },
};

export const localeAdapter = (locale: Locale): TrackingLocale => {
  return TrackingLocale[locale];
};

export const getFilterDiff = (
  filters: Filter[],
  prevFilters: Filter[] | undefined
): Filter[] => {
  if (!prevFilters) return filters;

  return filters.filter((f) => {
    return !prevFilters.find((pf) => {
      if (f.type === "min_price" || f.type === "max_price") {
        return (
          pf.type === f.type && pf.value && f.value && +pf.value === +f.value
        );
      }
      return pf.type === f.type && pf.value === f.value;
    });
  });
};

const getRegularPrice = (
  prices: ProductPrice[] | undefined | null,
  cart: Cart
) => {
  const localPrice = prices?.find(
    (price) => price?.country === cart?.shippingAddress?.country
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const regularPriceInCents = localPrice?.custom?.customFieldsRaw?.find(
    (field) => field.name === "list_price"
  )?.value.centAmount;

  return regularPriceInCents / 100;
};

const getSelectedSize = (attributesRaw: RawProductAttribute[] | undefined) =>
  getCTAttribute("size_value", attributesRaw);

const getTrackingCartProducts = (cart: Cart): TrackingCartProduct[] => {
  return cart?.lineItems.map((item) => {
    const attributesRaw = item.variant?.attributesRaw;
    const prices = item.variant?.prices;

    return {
      brand: getCTAttribute("brand_name", attributesRaw),
      brand_id: getCTAttribute("woo_brand_id", attributesRaw),
      category: [],
      category_id: [],
      image_url: getCTAttribute("main_image", attributesRaw),
      labels: getCTAttribute("labels", attributesRaw),
      name: getCTAttribute("name", attributesRaw),
      price: item.price.value.centAmount / 100,
      product_id: getCTAttribute("woo_product_id", attributesRaw),
      product_size: getSelectedSize(attributesRaw),
      quantity: item.quantity as number,
      regular_price: getRegularPrice(prices, cart),
      sku: item?.variant?.sku,
      url: `/product/${item.productSlug as string}`,
      variant: getSelectedSize(attributesRaw),
      product_variation: getCTAttribute("product_id", attributesRaw),
      // is_personalized: null,
      // product_discount: null,
      // product_kids_gender: null,
      // product_shop_type: null,
    };
  });
};

const getTrackingCartData = (cart: Cart): TrackingCartData => {
  return {
    ...requestSource,
    cart_id: cart.id,
    currency: cart.totalPrice.currencyCode as string,
    cart_total_products: cart?.lineItems?.reduce(
      (partial, item) => +item.quantity + partial,
      0
    ),
    cart_total_value:
      (cart?.taxedPrice?.totalGross || cart?.totalPrice).centAmount / 100,
    products: getTrackingCartProducts(cart),
    // free_shipping_value: null,
  };
};

const getAvailableSizes = (variations?: ProductVariation[]) =>
  (variations ?? []).filter((v) => v.stock_quantity > 0).map((v) => v.size);

const isClientWrapper = <T extends Array<any>, U>(fn: (...args: T) => U) => {
  if (!isClient) return () => new Promise<void>((resolve) => resolve());
  return (...args: T): U => fn(...args);
};

const getOnboardingEventData = (onboardingStep: OnboardingSteps) => {
  switch (onboardingStep) {
    case OnboardingSteps.SHOP:
      return [SEGMENT_EVENTS.personalizationOnboardingStart, "shop_preference"];
    case OnboardingSteps.BRAND:
      return [
        SEGMENT_EVENTS.personalizationOnboardingStage,
        "brand_preference",
      ];
    case OnboardingSteps.COMPLETED:
      return [SEGMENT_EVENTS.personalizationOnboardingCompleted];
    case OnboardingSteps.ERROR:
    default:
      return [SEGMENT_EVENTS.personalizationOnboardingError];
  }
};
const getGenericFilterName = (filterName: string): string => {
  switch (filterName) {
    case "brand.meta":
      return "brands";
    case "color.meta":
      return "colors";
    case "container_sizes.meta":
      return "sizes";
    case "promotion_filter.meta":
      return "promotions";
    case "style.meta":
      return "styles";
    case "pattern.meta":
      return "patterns";
    case "brand.good_on_you_info.meta":
      return "conscious";
    case "cat_slug":
    case "categories.meta":
      return "categories";
    default:
      return filterName;
  }
};

function segmentNativeAppHandler(
  eventName: undefined | "",
  traits: Record<string, any>,
  eventType: "identify"
): Promise<void>;
function segmentNativeAppHandler(
  eventName: string,
  eventParams: Record<string, any>,
  eventType: "track" | "screen"
): Promise<void>;
function segmentNativeAppHandler(
  eventName: any,
  eventParams: Record<string, any>,
  eventType: "identify" | "track" | "screen"
) {
  try {
    if (isAndroidApp) {
      const params = JSON.stringify(eventParams);
      switch (eventType) {
        case "identify":
          segment_webview.identify(params);
          break;
        case "track":
          segment_webview.track(eventName, params);
          break;
        case "screen":
          segment_webview.screen(eventName, params);
          break;
      }
    }

    if (isIOSApp) {
      const json = JSON.stringify({
        messageType: eventType,
        eventName,
        eventParams,
      });
      switch (eventType) {
        case "identify":
          webkit.messageHandlers.identify.postMessage(json);
          break;
        case "track":
          webkit.messageHandlers.track.postMessage(json);
          break;
        case "screen":
          webkit.messageHandlers.screen.postMessage(json);
          break;
      }
    }

    return new Promise<void>((resolve) => resolve());
  } catch (error) {
    console.error(error);
    return new Promise<void>((_, rejected) => rejected(error));
  }
}

export const useSegment = (): UseSegementReturnType => {
  const { locale } = useContext(AppCtx);
  const { analytics, htevents } = getAnalytics();
  let user;

  if (htevents?.user) {
    user = htevents.user();
  }

  const [session] = useSession();
  const { asPath: path } = useRouter();

  const sendSegmentTrackEvent = useCallback(
    (
      eventName: SEGMENT_EVENTS,
      properties: GeneralObject,
      callback?: () => void
    ) => {
      if (isNativeApp) {
        return new Promise<void>((resolve) => {
          segmentNativeAppHandler(eventName, properties, "track").finally(() =>
            resolve()
          );
        });
      } else {
        window.dataLayer.push({
          event: eventName,
          ...properties,
        });
        void htevents?.track(
          eventName,
          properties,
          {
            context: { locale: localeAdapter(locale) },
          },
          callback
        );
        return analytics.track(
          eventName,
          properties,
          {
            context: { locale: localeAdapter(locale) },
          },
          callback
        );
      }
    },
    [analytics, htevents, locale]
  );

  const segmentIdentify = isClientWrapper(
    (additionalOptions: IdentifyAdditionalOptions) => {
      if (!session?.user?.email)
        return new Promise<void>((resolve) => resolve());

      const { member_id, email, gender } = session?.user;
      const { sign_in_platform, sign_up_platform } = additionalOptions;

      const traits = {
        email,
        // TODO: Delete temporary fix in https://otrium.atlassian.net/browse/TT-2482
        gender: fixFemaleOrMale(gender),
        ...(member_id ? { user_id_type: "member" } : {}),
        ...requestSource,
        ...additionalOptions,
        sign_in_platform:
          sign_in_platform === AuthProvider.CREDENTIALS
            ? "email"
            : sign_in_platform,
        sign_up_platform:
          sign_up_platform === AuthProvider.CREDENTIALS_REGISTER
            ? "email"
            : sign_up_platform,
      };

      void htevents.identify(member_id, traits, {
        context: { locale: localeAdapter(locale) },
      });

      return analytics.identify(member_id, traits, {
        context: { locale: localeAdapter(locale) },
      });
    }
  );

  const segmentSignedIn = isClientWrapper(() => {
    if (!session?.user) return new Promise<void>((resolve) => resolve());

    const { platform, email } = session.user;

    return sendSegmentTrackEvent(SEGMENT_EVENTS.signedIn, {
      ...requestSource,
      sign_in_platform: platform,
      email,
    });
  });

  const segmentSignedUp = isClientWrapper(() => {
    if (!session?.user) return new Promise<void>((resolve) => resolve());

    const { platform, email } = session.user;

    return sendSegmentTrackEvent(SEGMENT_EVENTS.signedUp, {
      ...requestSource,
      sign_up_platform: platform,
      email,
    });
  });

  const segmentSignedOut = isClientWrapper(() => {
    return new Promise<void>((resolve) => {
      void sendSegmentTrackEvent(
        SEGMENT_EVENTS.signedOut,
        requestSource,
        () => {
          void analytics.reset();
          resolve();
        }
      );
    });
  });

  const segmentBrandInfoOpened = isClientWrapper(
    ({
      pageType,
      brandName,
    }: {
      pageType: string | null | undefined;
      brandName: string | null | undefined;
    }) =>
      sendSegmentTrackEvent(SEGMENT_EVENTS.brandInfoOpened, {
        ...requestSource,
        brand_name: brandName,
        page_type: pageType,
      })
  );

  const segmentBrandConsciousHowWeRateClicked = isClientWrapper(
    ({
      pageType,
      brandName,
    }: {
      pageType: string | null | undefined;
      brandName: string | null | undefined;
    }) =>
      sendSegmentTrackEvent(SEGMENT_EVENTS.brandConsciousHowWeRateClicked, {
        ...requestSource,
        brand_name: brandName,
        page_type: pageType,
      })
  );

  const brandFavourited = isClientWrapper(
    ({
      brandName,
      brandId,
    }: {
      brandName: string | null | undefined;
      brandId: string | null | undefined;
    }) =>
      sendSegmentTrackEvent(SEGMENT_EVENTS.brandFavourited, {
        ...requestSource,
        brand_name: brandName,
        brand_id: brandId,
        email: session?.user?.email,
      })
  );

  const brandUnfavourited = isClientWrapper(
    ({
      brandName,
      brandId,
    }: {
      brandName: string | null | undefined;
      brandId: string | null | undefined;
    }) =>
      sendSegmentTrackEvent(SEGMENT_EVENTS.brandUnfavourited, {
        ...requestSource,
        brand_name: brandName,
        brand_id: brandId,
        email: session?.user?.email,
      })
  );

  const brandFavouritedWhenLoggedOut = isClientWrapper(
    ({
      brandName,
      brandId,
    }: {
      brandName: string | null | undefined;
      brandId: string | null | undefined;
    }) =>
      sendSegmentTrackEvent(SEGMENT_EVENTS.brandFavouritedWhenLoggedOut, {
        ...requestSource,
        brand_name: brandName,
        brand_id: brandId,
        email: session?.user?.email,
      })
  );

  const productListFiltered = isClientWrapper(
    ({
      algoliaIndex,
      meta,
      queryID,
      searchState,
      filterAction,
      filtersApplied,
      sortApplied,
      numPage,
      rankingInfo,
    }: ProductListFilteredProps) => {
      const filters = getFilters(searchState);
      const { abTestID, abTestVariantID, indexUsed } = rankingInfo || {};

      return sendSegmentTrackEvent(SEGMENT_EVENTS.productListFiltered, {
        ...requestSource,
        index: algoliaIndex,
        filters,
        list_format: meta.listFormat,
        list_id: meta.listId,
        list_type: meta.listType,
        path,
        queryID,
        shop_type: meta.shopType,
        sorts: searchState?.sortBy,
        filter_action: filterAction,
        filters_applied: filtersApplied,
        sort_applied: sortApplied,
        num_page: numPage,
        ab_test_id: abTestID,
        ab_test_variant_id: abTestVariantID,
        index_used: indexUsed,
      });
    }
  );

  const productListFilterReset = isClientWrapper(
    ({
      algoliaIndex,
      meta,
      queryID,
      rankingInfo,
    }: productListFilterResetProps) => {
      const { listFormat, listId, listType, shopType } = meta;
      const { abTestID, abTestVariantID, indexUsed } = rankingInfo || {};

      return sendSegmentTrackEvent(SEGMENT_EVENTS.productListFiltersReset, {
        ...requestSource,
        index: algoliaIndex,
        list_format: listFormat,
        list_id: listId,
        list_type: listType,
        path,
        queryID,
        ab_test_id: abTestID,
        ab_test_variant_id: abTestVariantID,
        index_used: indexUsed,
        shop_type: shopType,
      });
    }
  );

  const segmentProductClicked = isClientWrapper(
    ({
      algoliaIndex,
      isPersonalised,
      position,
      product,
      productListMeta: { listFormat, listId, listType, shopType },
      queryID,
      rankingInfo,
      gatedActionType,
      actionSource,
      redirectTo,
      promo_path,
    }: SegmentProductClicked) => {
      const {
        price: catalogProductPrice,
        regular_price,
        available_sizes,
      } = product as SegmentCatalogProduct;

      const { price_float, regular_price_float, sizes_available } =
        product as SegmentHomepageSingleCategoryProduct;

      const price = catalogProductPrice || price_float;
      const regularPrice = regular_price || regular_price_float;
      const sizesAvailable = available_sizes || sizes_available || [];
      const { abTestID, abTestVariantID, indexUsed } = rankingInfo || {};
      const pathValue = gatedActionType ? { promo_path } : { path };

      return sendSegmentTrackEvent(
        (gatedActionType
          ? SEGMENT_EVENTS.gatedPopupRedirected
          : SEGMENT_EVENTS.productClicked) as SEGMENT_EVENTS,
        {
          ...requestSource,
          brand: product.brand.name,
          brand_id: product.brand.id,
          category_id: product.translated_category_ids,
          category: product.translated_category_names,
          image_url: product.thumbnail,
          index: algoliaIndex,
          is_personalised: isPersonalised || product.is_personalised,
          labels: (product.labels || []).map(({ value }) => value),
          list_format: listFormat,
          list_id: listId,
          list_type: listType,
          name: product.name,
          objectID: product.objectID || product.entityID,
          ...pathValue,
          position,
          price,
          product_discount:
            product?.product_discount ||
            Math.round((regularPrice - price + Number.EPSILON) * 100) / 100,
          product_id: product.id,
          product_kids_gender: product.product_shop_type?.includes("kids")
            ? product.product_kids_gender
            : undefined,
          product_shop_type: product.product_shop_type,
          queryID,
          regular_price: regularPrice,
          shop_type: shopType,
          sizes_available: sizesAvailable,
          url: `/product/${product.slug}`,
          ab_test_id: abTestID,
          ab_test_variant_id: abTestVariantID,
          index_used: indexUsed,
          gated_action_type: gatedActionType,
          gate_level: product.gate?.level || null,
          action_source: actionSource,
          redirect_to: redirectTo,
        }
      );
    }
  );

  const segmentProductListClicked = isClientWrapper(
    ({
      listFormat,
      listId,
      listType,
      shopType,
      rankingInfo,
      gatedActionType,
      actionSource,
      redirectTo,
      promo_path,
    }: productListClickedProps) => {
      const { abTestID, abTestVariantID, indexUsed } = rankingInfo || {};
      const pathValue = gatedActionType ? { promo_path } : { path };

      return sendSegmentTrackEvent(
        (gatedActionType
          ? SEGMENT_EVENTS.gatedPopupRedirected
          : SEGMENT_EVENTS.productListClicked) as SEGMENT_EVENTS,
        {
          ...requestSource,
          list_id: listId,
          list_type: listType,
          list_format: listFormat,
          ...pathValue,
          ab_test_id: abTestID,
          ab_test_variant_id: abTestVariantID,
          index_used: indexUsed,
          gated_action_type: gatedActionType,
          shop_type: shopType,
          action_source: actionSource,
          redirect_to: redirectTo,
        }
      );
    }
  );

  const segmentProductListViewed = isClientWrapper(
    ({
      algoliaIndex,
      defaultSortBy,
      isPersonalised,
      numberOfItems,
      productListMeta: { listFormat, listId, listType, shopType },
      products,
      queryID,
      rankingInfo,
      responseSourceType,
    }: SegmentProductListViewedProps) => {
      const { abTestID, abTestVariantID, indexUsed } = rankingInfo || {};

      return sendSegmentTrackEvent(SEGMENT_EVENTS.productListViewed, {
        ...requestSource,
        default_sort_by: defaultSortBy,
        index: algoliaIndex,
        list_format: listFormat,
        list_id: listId,
        list_type: listType,
        num_items: numberOfItems,
        queryID,
        shop_type: shopType,
        path,
        ab_test_id: abTestID,
        ab_test_variant_id: abTestVariantID,
        index_used: indexUsed,
        response_source_type: responseSourceType,
        products: products?.map((item) => {
          const {
            price: catalogProductPrice,
            regular_price,
            available_sizes,
          } = item as SegmentCatalogProduct;

          const { price_float, regular_price_float, sizes_available } =
            item as SegmentHomepageSingleCategoryProduct;

          const price = catalogProductPrice || price_float;
          const regularPrice = regular_price || regular_price_float;
          const sizesAvailable = available_sizes || sizes_available;

          return {
            brand: item.brand.name,
            brand_id: item.brand.id,
            category: item.translated_category_names,
            category_id: item.translated_category_ids,
            image_url: item.thumbnail,
            is_personalised: isPersonalised || item.is_personalised,
            labels: (item.labels || []).map(({ value }) => value),
            name: item.name,
            objectID: item.objectID || item.entityID,
            position: item.position,
            price,
            product_discount:
              item?.product_discount ||
              Math.round((regularPrice - price + Number.EPSILON) * 100) / 100,
            product_id: item.id,
            product_kids_gender: item.product_shop_type?.includes("kids")
              ? item.product_kids_gender
              : undefined,
            product_shop_type: item.product_shop_type,
            regular_price: regularPrice,
            sizes_available: sizesAvailable,
            url: `/product/${item.slug}`,
          };
        }),
      });
    }
  );

  const segmentCartViewed = isClientWrapper((cart: Cart) => {
    return sendSegmentTrackEvent(
      SEGMENT_EVENTS.cartViewed,
      getTrackingCartData(cart)
    );
  });

  const segmentCheckoutStarted = isClientWrapper((cart: Cart) => {
    return sendSegmentTrackEvent(
      SEGMENT_EVENTS.checkoutStarted,
      getTrackingCartData(cart)
    );
  });

  const segmentFirstCheckoutStepCompleted = isClientWrapper(
    async (
      cartId: string,
      shippingMethodId: string | undefined,
      shippingMethodName: string | undefined,
      email: string | undefined,
      billingAddress: AddressInput,
      shippingAddress?: AddressInput
    ) => {
      const properties = {
        ...requestSource,
        cart_id: cartId,
        step: 1,
        step_name: CheckoutSteps.STEP_1,
        shipping_method: shippingMethodName,
        shipping_method_id: shippingMethodId,
      };

      await sendSegmentTrackEvent(
        SEGMENT_EVENTS.checkoutStepCompleted,
        properties
      );

      const getAllGACookies = (): GA_COOKIES => {
        // Regex to match _ga_ cookies with their Measurement ID suffixes
        const gaCookieRegex = /^_ga_([A-Z0-9]+)$/i;

        return document.cookie
          .split(";")
          .reduce<GA_COOKIES>((ga_cookies, cookieString) => {
            const [cookieKey, cookieValue] = cookieString.trim().split("=");

            const isGACookie = cookieKey.match(gaCookieRegex);

            if (isGACookie && cookieValue) {
              const parameterKey = `cookie_google_ga_${isGACookie[1]}`;
              ga_cookies[parameterKey] = cookieValue;
            }

            return ga_cookies;
          }, {});
      };

      const data: GeneralObject = {
        email,
        cookie_google_ga: Cookies.get("_ga"),
        ...getAllGACookies(),
        cookie_facebook_fbp: Cookies.get("_fbp"),
        first_name: billingAddress.firstName,
        last_name: billingAddress.lastName,
        address: {
          postal_code: billingAddress.postalCode,
          country: billingAddress.country,
          city: billingAddress.city,
          street:
            billingAddress.country === "NL"
              ? billingAddress.streetNumber
              : `${billingAddress.streetNumber || ""} ${
                  billingAddress.streetName || ""
                } ${billingAddress.additionalStreetInfo || ""}`,
          phone_number: billingAddress.phone,
        },
      };

      if (shippingAddress) {
        data.shipping_address = {
          postal_code: shippingAddress.postalCode,
          country: shippingAddress.country,
          city: shippingAddress.city,
          street:
            shippingAddress.country === "NL"
              ? shippingAddress.streetNumber
              : `${shippingAddress.streetNumber || ""} ${
                  shippingAddress.streetName || ""
                } ${shippingAddress.additionalStreetInfo || ""}`,
          phone_number: shippingAddress.phone,
        };
      }
      if (isNativeApp) {
        return segmentNativeAppHandler(undefined, data, "identify");
      } else {
        const args = session?.user?.member_id
          ? { member_id: session.user.member_id, ...data }
          : { ...data };

        await segmentIdentify(args);
      }
    }
  );

  const segmentSecondCheckoutStepCompleted = isClientWrapper(
    (cartId: string, paymentMethod?: string) => {
      const properties = {
        ...requestSource,
        cart_id: cartId,
        step: 2,
        step_name: CheckoutSteps.STEP_2,
        payment_method: paymentMethod,
        // payment_bank: null,
      };

      return sendSegmentTrackEvent(
        SEGMENT_EVENTS.checkoutStepCompleted,
        properties
      );
    }
  );

  const segmentOrderCompleted = isClientWrapper(
    (cart: Cart, isOldCustomer: boolean) => {
      const properties = {
        ...requestSource,
        cart_id: cart.id,
        currency: cart.totalPrice.currencyCode as string,
        shipping: formatStandardPrice(cart.shippingInfo?.price),
        tax:
          cart.taxedPrice?.totalTax !== undefined &&
          cart.taxedPrice?.totalTax !== null
            ? formatStandardPrice(cart.taxedPrice?.totalTax)
            : null,
        totalPrice: formatStandardPrice(cart.taxedPrice?.totalGross),
        products: getTrackingCartProducts(cart),
        customer_type: isOldCustomer ? "old customer" : "new customer",
        // affiliation: null,
        // checkout_id: null,
        // coupon: null,
        // discount: null,
        // index: null,
        // order_id: null,
        // queryID: null,
        // revenue: null,
        // subTotal: null,
        // transactional_cost: null,
      };

      return sendSegmentTrackEvent(SEGMENT_EVENTS.orderCompleted, properties);
    }
  );

  const segmentCouponApplied = isClientWrapper(
    ({
      cartTotalProducts,
      cartValue,
      couponCode,
      couponDiscountAbsolute,
      currency,
      couponID,
    }: SegmentCouponTracking & { couponDiscountAbsolute: string }) =>
      sendSegmentTrackEvent(SEGMENT_EVENTS.couponApplied, {
        ...requestSource,
        coupon_id: couponID,
        coupon_code: couponCode,
        coupon_discount_absolute: couponDiscountAbsolute,
        currency,
        cart_value: cartValue,
        cart_total_products: cartTotalProducts,
      })
  );

  const segmentCouponDenied = isClientWrapper(
    ({
      couponCode,
      couponErrorCode,
      currency,
      cartValue,
      cartTotalProducts,
      couponDenied,
    }: SegmentCouponTracking & {
      couponErrorCode?: string;
      couponDenied: string;
    }) =>
      sendSegmentTrackEvent(SEGMENT_EVENTS.couponDenied, {
        ...requestSource,
        coupon_code: couponCode,
        coupon_error_code: couponErrorCode,
        currency,
        coupon_message: couponDenied,
        cart_value: cartValue,
        cart_total_products: cartTotalProducts,
      })
  );

  const segmentCouponEntered = isClientWrapper(
    ({
      couponCode,
      currency,
      cartValue,
      cartTotalProducts,
    }: SegmentCouponTracking) =>
      sendSegmentTrackEvent(SEGMENT_EVENTS.couponEntered, {
        ...requestSource,
        coupon_code: couponCode.toLowerCase(),
        currency,
        cart_value: cartValue,
        cart_total_products: cartTotalProducts,
      })
  );

  const segmentCouponRemoved = isClientWrapper(
    ({
      couponCode,
      currency,
      cartValue,
      cartTotalProducts,
      couponID,
    }: SegmentCouponTracking) =>
      sendSegmentTrackEvent(SEGMENT_EVENTS.couponRemoved, {
        ...requestSource,
        coupon_id: couponID,
        coupon_code: couponCode,
        currency,
        cart_value: cartValue,
        cart_total_products: cartTotalProducts,
      })
  );

  const segmentFormError = isClientWrapper(
    (formField: string, formErrorMessage: string, formName?: string) => {
      const eventName = SEGMENT_EVENTS.formError;

      const params = {
        form_name: formName || getFormName(formField),
        form_field: formField,
        form_error_message: formErrorMessage,
        form_error_type: formErrorMessage,
      };

      if (isNativeApp) {
        return segmentNativeAppHandler(eventName, params, "track");
      } else {
        return sendSegmentTrackEvent(eventName, {
          ...requestSource,
          ...params,
        });
      }
    }
  );

  const segmentFormTracking = isClientWrapper(
    (formField: string, formName?: string) => {
      const eventName = SEGMENT_EVENTS.formTracking;

      const params = {
        form_name: formName || getFormName(formField),
        form_field: formField,
      };

      if (isNativeApp) {
        return segmentNativeAppHandler(eventName, params, "track");
      } else {
        return sendSegmentTrackEvent(eventName, {
          ...requestSource,
          ...params,
        });
      }
    }
  );

  const segmentOnboarding = isClientWrapper(
    (onboardingStep: OnboardingSteps) => {
      const [name, stage] = getOnboardingEventData(onboardingStep);

      return sendSegmentTrackEvent(name as SEGMENT_EVENTS, {
        ...requestSource,
        stage,
      });
    }
  );

  const segmentOptIn = isClientWrapper(
    async (
      optIn: boolean,
      email: string | null | undefined,
      origin?: string
    ) => {
      const traits = {
        email: email ?? undefined,
        optIn,
      };
      if (isNativeApp) {
        await segmentNativeAppHandler(undefined, traits, "identify");
      } else {
        await segmentIdentify(traits);
      }

      const track = await sendSegmentTrackEvent(SEGMENT_EVENTS.optIn, {
        ...requestSource,
        optIn,
        email,
        metadata: {
          origin,
        },
      });

      return track;
    }
  );

  const segmentPageViewed = isClientWrapper(
    ({
      pathName,
      locale,
      shopType,
      cartData,
      productAvailable,
    }: PageViewedProps) => {
      const properties = {
        locale,
        shop_type: shopType,
        cart_total_products: cartData?.product_count,
        cart_value: cartData?.cart_total,
        ...(typeof productAvailable !== "undefined" && {
          product_page_message: productAvailable
            ? "product available"
            : "product no longer available",
        }),
        ...requestSource,
      };

      if (isNativeApp) {
        // NOTE: Temporary fix until the page title can be mapped to the URL
        const screenName =
          pathName.length > 100 ? pathName.slice(0, 100) : pathName;
        return segmentNativeAppHandler(screenName, properties, "screen");
      } else {
        void htevents.page(pathName, properties, {
          context: { locale: localeAdapter(locale) },
        });

        window.dataLayer.push({
          event: "Loaded a page",
          ...properties,
        });
        return analytics.page(pathName, properties, {
          context: { locale: localeAdapter(locale) },
        });
      }
    }
  );

  const segmentPasswordUpdated = isClientWrapper(() => {
    const params = requestSource;

    if (isNativeApp) {
      return segmentNativeAppHandler(
        SEGMENT_EVENTS.passwordUpdated,
        params,
        "track"
      );
    } else {
      return sendSegmentTrackEvent(SEGMENT_EVENTS.passwordUpdated, params);
    }
  });

  const segmentProductAdded = isClientWrapper(
    (
      product: SingleProduct,
      size: string,
      queryID?: string,
      objectID?: string,
      algoliaIndex?: string,
      variationID?: string,
      cartData?: {
        product_count?: number;
        cart_total?: number;
      },
      lineItems?: LineItem[]
    ) =>
      sendSegmentTrackEvent(SEGMENT_EVENTS.productAdded, {
        ...requestSource,
        brand: product.brand.name,
        brand_id: product.brand.id,
        category: product?.translated_category_names || [],
        category_id: product?.translated_category_ids || [],
        currency: product.currency.code,
        image_url: product.images?.[0],
        index: algoliaIndex,
        is_personalised: product.is_personalised,
        labels: (product.labels || []).map(({ value }) => value),
        name: product.name,
        objectID,
        price: product.price,
        product_discount: product.product_discount,
        product_id: product.id,
        product_kids_gender: product.product_shop_type?.includes("kids")
          ? product.product_kids_gender
          : undefined,
        product_shop_type: product.product_shop_type,
        product_size: size,
        quantity: 1,
        queryID,
        regular_price: product.regular_price,
        sku: product.sku,
        url: `product/${product.slug}`,
        variant: size,
        product_variation: variationID,
        gender: product.gender,
        cart_total_products: cartData?.product_count,
        cart_value: cartData?.cart_total,
        prod_sequence: cartCountHelper(lineItems),
      })
  );

  const segmentProductAddedOrRemoved = isClientWrapper(
    (
      lineItem: LineItem,
      type: "productAdded" | "productRemoved",
      countryCode: Iso2LetterLocale | null,
      quantity?: number,
      cartData?: {
        product_count?: number;
        cart_total?: number;
      },
      lineItems?: LineItem[]
    ) => {
      const attributesRaw = lineItem.variant?.attributesRaw;

      const selectedSize = getCTAttribute("size_value", attributesRaw);

      const getRegularPrice = (prices: ProductPrice[] | undefined | null) => {
        const localPrice = getLineItemPrice(prices, countryCode);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const regularPriceInCents = localPrice?.custom?.customFieldsRaw?.find(
          (field) => field.name === "list_price"
        )?.value.centAmount as number;

        return regularPriceInCents / 100;
      };

      return sendSegmentTrackEvent(SEGMENT_EVENTS[type], {
        ...requestSource,
        brand: getCTAttribute("brand_name", attributesRaw),
        brand_id: getCTAttribute("woo_brand_id", attributesRaw),
        // cart_total_products: null,
        // cart_total_value: null,
        category: [],
        category_id: [],
        currency: lineItem.price.value.currencyCode as string,
        // free_shipping_value: null,
        image_url: getCTAttribute("main_image", attributesRaw),
        // is_personalized: null,
        labels: getCTAttribute("labels", attributesRaw),
        name: getCTAttribute("name", attributesRaw),
        price: lineItem.price.value.centAmount / 100,
        // product_discount: null,
        product_id: getCTAttribute("woo_product_id", attributesRaw),
        // product_kids_gender: null,
        // product_shop_type: null,
        product_size: selectedSize,
        quantity: (quantity || lineItem.quantity) as number,
        regular_price: getRegularPrice(lineItem?.variant?.prices),
        sku: lineItem?.variant?.sku,
        url: `product/${lineItem.productSlug as string}`,
        variant: selectedSize,
        cart_total_products: cartData?.product_count,
        cart_value: cartData?.cart_total,
        ...(SEGMENT_EVENTS[type] === SEGMENT_EVENTS.productAdded && {
          prod_sequence: cartCountHelper(lineItems),
        }),
      });
    }
  );

  const segmentProductFavouritedOrUnfavourited = isClientWrapper(
    ({
      currency,
      eventType,
      isPersonalised,
      product,
      indexName,
    }: ProductFavouritedOrUnfavouritedProps) => {
      const { images, variations } = product as SingleProduct;

      const { thumbnail } = product as
        | CatalogProduct
        | HomepageSingleCategoryProduct
        | WishlistProduct;

      const { available_sizes } = product as CatalogProduct;

      const { sizes_available } = product as
        | HomepageSingleCategoryProduct
        | WishlistProduct;

      const sizesAvailable =
        available_sizes ||
        sizes_available ||
        (variations || []).map((variation) => variation.size);

      const imageUrl = images ? images[0] : thumbnail;

      const { price: catalogProductPrice, regular_price } = product as
        | CatalogProduct
        | SingleProduct
        | WishlistProduct;

      const { gender } = product as SingleProduct;

      const {
        price_float: homepageSingleCategoryProductPrice,
        regular_price_float: homepageSingleCategoryProductRegularPrice,
      } = product as HomepageSingleCategoryProduct;

      const price = catalogProductPrice || homepageSingleCategoryProductPrice;
      const regularPrice =
        regular_price || homepageSingleCategoryProductRegularPrice;

      return sendSegmentTrackEvent(
        eventType === SEGMENT_TOGGLE_WISHLIST_TYPE.favouritedWhenLoggedOut
          ? SEGMENT_EVENTS.productFavouritedWhenLoggedOut
          : eventType === SEGMENT_TOGGLE_WISHLIST_TYPE.favourited
          ? SEGMENT_EVENTS.productFavourited
          : SEGMENT_EVENTS.productUnfavourited,
        {
          ...requestSource,
          brand: product.brand.name,
          brand_id: product.brand.id,
          category: product.translated_category_names,
          category_id: product.translated_category_ids,
          currency,
          image_url: imageUrl,
          is_personalised: isPersonalised || product.is_personalised,
          labels: (product.labels || []).map(({ value }) => value),
          name: product.name,
          price,
          product_discount: product?.product_discount,
          product_id: product.id,
          product_kids_gender: product.product_shop_type?.includes("kids")
            ? product.product_kids_gender
            : undefined,
          product_shop_type: product.product_shop_type,
          regular_price: regularPrice,
          sizes_available: sizesAvailable,
          slug: product.slug,
          url: `/product/${product.slug}`,
          objectID: product.objectID || product.entityID,
          index: indexName,
          gender,
          email: session?.user?.email,
        }
      );
    }
  );

  const segmentProductListFilterOpened = isClientWrapper((filterName: string) =>
    sendSegmentTrackEvent(SEGMENT_EVENTS.productListFilterOpened, {
      filter_type_open: getGenericFilterName(filterName),
    })
  );

  const segmentProductOutOfStock = isClientWrapper(
    (
      product: SingleProduct | SingleCartProductVariation,
      size?: string | null
    ) => {
      const selectedSize = size || SEGMENT_DATA_UNAVAILABLE;

      const {
        image,
        price_float,
        product_name,
        product_slug,
        quantity_in_cart,
        regular_price_float,
      } = product as SingleCartProductVariation;

      const { images, name, price, regular_price, slug } =
        product as SingleProduct;

      const imageURL = image || images?.[0] || SEGMENT_DATA_UNAVAILABLE;
      const productName = product_name || name || SEGMENT_DATA_UNAVAILABLE;
      const productSlug = product_slug || slug || SEGMENT_DATA_UNAVAILABLE;
      const productPrice = price_float || price || SEGMENT_DATA_UNAVAILABLE;
      const regularPrice =
        regular_price_float || regular_price || SEGMENT_DATA_UNAVAILABLE;

      return sendSegmentTrackEvent(SEGMENT_EVENTS.productOutOfStock, {
        ...requestSource,
        brand: product.brand.name,
        brand_id: product.brand.id,
        category: product?.translated_category_names || [],
        category_id: product?.translated_category_ids || [],
        currency: product.currency.code,
        image_url: imageURL,
        is_personalised: product.is_personalised,
        labels: (product?.labels || []).map((label) => label?.value),
        name: productName,
        price: productPrice,
        product_discount: product.product_discount,
        product_id: product.id,
        product_kids_gender: product.product_shop_type?.includes("kids")
          ? product.product_kids_gender
          : undefined,
        product_shop_type: product.product_shop_type,
        product_size: selectedSize,
        quantity: quantity_in_cart,
        regular_price: regularPrice,
        sku: product.sku,
        url: `product/${productSlug}`,
        variant: selectedSize,
        gender: product.gender,
      });
    }
  );

  const segmentProductSizeSelected = isClientWrapper(
    (product: SingleProduct, size?: string | null) => {
      const selectedSize = size || SEGMENT_DATA_UNAVAILABLE;

      const sizesAvailable = (product.variations || [])
        .filter((variation) => variation.stock_quantity > 0)
        .map((val) => val.size);

      return sendSegmentTrackEvent(SEGMENT_EVENTS.productSizeSelected, {
        ...requestSource,
        brand: product.brand.name,
        brand_id: product.brand.id,
        category: product?.translated_category_names || [],
        category_id: product?.translated_category_ids || [],
        currency: product.currency.code,
        image_url: product.images?.[0],
        is_personalised: product.is_personalised,
        labels: (product.labels || []).map(({ value }) => value),
        name: product.name,
        price: product.price,
        product_discount: product.product_discount,
        product_id: product.id,
        product_kids_gender: product.product_shop_type?.includes("kids")
          ? product.product_kids_gender
          : undefined,
        product_shop_type: product.product_shop_type,
        product_size: selectedSize,
        regular_price: product.regular_price,
        sizes_available: sizesAvailable,
        sku: product.sku,
        url: `product/${product.slug}`,
        variant: selectedSize,
        gender: product.gender,
      });
    }
  );

  const segmentProductSizeSelectorClicked = isClientWrapper(
    (product: SingleProduct) => {
      const sizesAvailable = (product.variations || [])
        .filter((variation) => variation.stock_quantity > 0)
        .map((val) => val.size);

      return sendSegmentTrackEvent(SEGMENT_EVENTS.productSizeSelectorClicked, {
        ...requestSource,
        brand: product.brand.name,
        brand_id: product.brand.id,
        category: product?.translated_category_names || [],
        category_id: product?.translated_category_ids || [],
        currency: product.currency.code,
        image_url: product.images?.[0],
        labels: (product.labels || []).map(({ value }) => value),
        name: product.name,
        price: product.price,
        product_discount: product.product_discount,
        product_id: product.id,
        product_kids_gender: product.product_shop_type?.includes("kids")
          ? product.product_kids_gender
          : undefined,
        product_shop_type: product.product_shop_type,
        regular_price: product.regular_price,
        sizes_available: sizesAvailable,
        url: `product/${product.slug}`,
        gender: product.gender,
      });
    }
  );

  const segmentProductViewed = isClientWrapper(
    (
      product: SingleProduct,
      queryID?: string,
      objectID?: string,
      algoliaIndex?: string
    ) =>
      sendSegmentTrackEvent(SEGMENT_EVENTS.productViewed, {
        ...requestSource,
        brand: product.brand.name,
        brand_id: product.brand.id,
        category: product?.translated_category_names || [],
        category_id: product?.translated_category_ids || [],
        currency: product?.currency?.code,
        image_url: product.images?.[0],
        index: algoliaIndex,
        is_personalised: product.is_personalised,
        labels: (product.labels || []).map(({ value }) => value),
        name: product.name,
        objectID,
        price: product.price,
        product_discount: product.product_discount,
        product_id: product.id,
        product_kids_gender: product.product_shop_type?.includes("kids")
          ? product.product_kids_gender
          : undefined,
        product_shop_type: product.product_shop_type,
        queryID,
        regular_price: product.regular_price,
        sizes_available: product.variations
          ? getAvailableSizes(product.variations)
          : undefined,
        url: `product/${product.slug}`,
        gender: product.gender,
        product_variation: product.variations?.[0]?.id,
      })
  );

  const segmentProductEngaged = isClientWrapper(
    (
      product: SingleProduct,
      queryID?: string,
      objectID?: string,
      algoliaIndex?: string
    ) =>
      sendSegmentTrackEvent(SEGMENT_EVENTS.productEngaged, {
        ...requestSource,
        engagement_type: `${SEGMENT_ENGAGEMENT_TIMEOUT} seconds`,
        brand: product.brand.name,
        brand_id: product.brand.id,
        category: product?.translated_category_names || [],
        category_id: product?.translated_category_ids || [],
        currency: product?.currency?.code,
        image_url: product.images?.[0],
        index: algoliaIndex,
        is_personalised: product.is_personalised,
        labels: (product.labels || []).map(({ value }) => value),
        name: product.name,
        objectID,
        price: product.price,
        product_discount: product.product_discount,
        product_id: product.id,
        product_kids_gender: product.product_shop_type?.includes("kids")
          ? product.product_kids_gender
          : undefined,
        product_shop_type: product.product_shop_type,
        queryID,
        regular_price: product.regular_price,
        sizes_available: product.variations
          ? getAvailableSizes(product.variations)
          : undefined,
        url: `product/${product.slug}`,
        gender: product.gender,
        product_variation: product.variations?.[0]?.id,
      })
  );

  const segmentPromotionClickedOrViewed = isClientWrapper(
    ({
      id,
      name,
      creative,
      position,
      eventType,
      tab,
      promotionType,
      gatedActionType,
      shopType,
      redirectTo,
      path,
    }: Promotion & {
      tab?: string;
      gatedActionType?: string;
      shopType?: string;
      redirectTo?: string;
    }) => {
      return sendSegmentTrackEvent(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        gatedActionType
          ? SEGMENT_EVENTS.gatedPopupRedirected
          : eventType === PromotionEventType.click
          ? SEGMENT_EVENTS.clickedPromotion
          : SEGMENT_EVENTS.viewedPromotion,
        {
          ...requestSource,
          promo_id: id,
          promo_name: name,
          promo_creative: creative,
          promo_position: position,
          promo_type: promotionType,
          promo_path: path,
          shop_type: shopType,
          redirect_to: redirectTo,
          gated_action_type: gatedActionType,
          tab,
        }
      );
    }
  );

  const segmentReferAFriendViewed = isClientWrapper(() => {
    const eventName = SEGMENT_EVENTS.referAFriendFormViewed;
    const params = requestSource;

    if (isNativeApp) {
      return segmentNativeAppHandler(eventName, params, "track");
    } else {
      return sendSegmentTrackEvent(eventName, params);
    }
  });

  const segmentSearched = isClientWrapper(
    (
      query: string,
      numBrandResults: number,
      numCategoryResults: number,
      numProductsResults: number
    ) =>
      sendSegmentTrackEvent(SEGMENT_EVENTS.productsSearched, {
        ...requestSource,
        query,
        num_brand_results: numBrandResults,
        num_category_results: numCategoryResults,
        num_products_results: numProductsResults,
      })
  );

  const segmentUserEmailUpdated = isClientWrapper(() => {
    if (isNativeApp) {
      return segmentNativeAppHandler(
        SEGMENT_EVENTS.userEmailUpdated,
        requestSource,
        "track"
      );
    } else {
      return sendSegmentTrackEvent(
        SEGMENT_EVENTS.userEmailUpdated,
        requestSource
      );
    }
  });

  const segmentProductOutOfStockCT = isClientWrapper(
    (
      lineItem: LineItem,
      warning: string,
      countryCode: Iso2LetterLocale | null
    ) => {
      const attributesRaw = lineItem.variant?.attributesRaw;

      const selectedSize = getCTAttribute("size_value", attributesRaw);

      const getRegularPrice = (prices: ProductPrice[] | undefined | null) => {
        const localPrice = getLineItemPrice(prices, countryCode);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const regularPriceInCents = localPrice?.custom?.customFieldsRaw?.find(
          (field) => field.name === "list_price"
        )?.value.centAmount as number;

        return regularPriceInCents / 100;
      };

      return sendSegmentTrackEvent(SEGMENT_EVENTS.productOutOfStockCT, {
        ...requestSource,
        brand: getCTAttribute("brand_name", attributesRaw),
        brand_id: getCTAttribute("woo_brand_id", attributesRaw),
        category: [],
        category_id: [],
        image_url: getCTAttribute("main_image", attributesRaw),
        // is_personalized: null,
        labels: getCTAttribute("labels", attributesRaw),
        name: getCTAttribute("name", attributesRaw),
        price: lineItem.price.value.centAmount / 100,
        // product_discount: null,
        product_id: getCTAttribute("woo_product_id", attributesRaw),
        // product_kids_gender: null,
        // product_shop_type: null,
        product_size: selectedSize,
        quantity: lineItem.quantity as number,
        regular_price: getRegularPrice(lineItem?.variant?.prices),
        sku: lineItem?.variant?.sku,
        url: `product/${lineItem.productSlug as string}`,
        variant: selectedSize,
        warning,
      });
    }
  );

  const segmentDomainChanged = isClientWrapper(
    (domainName: string, callback: () => void = () => {}) =>
      sendSegmentTrackEvent(
        SEGMENT_EVENTS.domainChanged,
        {
          domain_selected: domainName,
        },
        callback
      )
  );

  const segmentToasterNotificationDisplayed = isClientWrapper(
    (promo_name: string | undefined, promo_path?: string) =>
      sendSegmentTrackEvent(SEGMENT_EVENTS.viewedPromotion, {
        promo_name,
        promo_position: "toaster-notification-1",
        promotion_type: SEGMENT_PROMOTION_TYPE.toasterNotification,
        promo_path,
      })
  );

  const segmentToasterNotificationDismissed = isClientWrapper(
    (promo_name: string | undefined, promo_path?: string) =>
      sendSegmentTrackEvent(SEGMENT_EVENTS.notificationDismissed, {
        promo_name,
        promo_position: "toaster-notification-1",
        promo_path,
      })
  );

  const segmentToasterNotificationClicked = isClientWrapper(
    (promo_name: string | undefined, promo_path?: string) =>
      sendSegmentTrackEvent(SEGMENT_EVENTS.clickedPromotion, {
        promo_name,
        promo_position: "toaster-notification-1",
        promotion_type: SEGMENT_PROMOTION_TYPE.toasterNotification,
        promo_path,
      })
  );

  const segmentSearchOverlayOpened = isClientWrapper(() =>
    sendSegmentTrackEvent(SEGMENT_EVENTS.searchOverlayOpen, {})
  );

  const segmentSearchOverlayClosed = isClientWrapper(
    (closedVia: "click x" | "click outside") =>
      sendSegmentTrackEvent(SEGMENT_EVENTS.searchOverlayClose, {
        search_close: closedVia,
      })
  );

  const segmentSearchOverlayCarouselsViewed = isClientWrapper(
    (carousel_type: CarouselType, carousel_position?: number) =>
      sendSegmentTrackEvent(SEGMENT_EVENTS.searchOverlayCarouselsViewed, {
        carousel_type,
        carousel_position,
      })
  );

  const segmentSearchLinkClicked = isClientWrapper(
    (type: SuggestionType, linkName: string) =>
      sendSegmentTrackEvent(SEGMENT_EVENTS.searchLinkClick, {
        search_link_type: type,
        search_link_name: linkName,
      })
  );

  const segmentSearchRecommendationClicked = isClientWrapper(
    (
      search_rec_type: CarouselType,
      search_rec_name: string,
      search_rec_position: number
    ) =>
      sendSegmentTrackEvent(SEGMENT_EVENTS.searchRecommendationClicked, {
        search_rec_type,
        search_rec_name,
        search_rec_position,
      })
  );

  const segmentViewSearchAllBrands = isClientWrapper(() =>
    sendSegmentTrackEvent(SEGMENT_EVENTS.viewSearchAllBrands, {})
  );

  const segmentViewSearchResultPage = isClientWrapper(() =>
    sendSegmentTrackEvent(SEGMENT_EVENTS.viewSearchResults, {})
  );

  const trackForgotPassword = isClientWrapper(() =>
    sendSegmentTrackEvent(SEGMENT_EVENTS.forgotPasswordFlowStarted, {})
  );

  const segmentPdpInfoBoxOpened = isClientWrapper((boxName: string) =>
    sendSegmentTrackEvent(SEGMENT_EVENTS.pdpInfoBoxOpened, {
      info_box_name: boxName,
    })
  );

  const segmentUserProfileUpdated = isClientWrapper(
    async (userData: UserUpdateData): Promise<void> => {
      if (!session?.user?.member_id) return;

      await Promise.all([
        sendSegmentTrackEvent(SEGMENT_EVENTS.userProfileUpdated, userData),
        segmentIdentify({
          first_name: userData.firstName,
          last_name: userData.lastName,
          birthday: userData.birthday,
          gender: userData.gender,
          phone_number: userData.phoneNumber,
          address: {
            postalCode: userData.address?.postalCode,
            country: userData.address?.country,
            city: userData.address?.city,
            street: userData.address?.street,
          },
        }),
      ]);
    }
  );

  const segmentViewedAuthBanner = isClientWrapper((page_name?: string) => {
    return sendSegmentTrackEvent(SEGMENT_EVENTS.authBannerViewed, {
      page_information_name: page_name,
    });
  });

  const segmentAuthBannerSelect = isClientWrapper(
    (data: AuthBannerData & { type: "sign_up" | "sign_in" }) => {
      return sendSegmentTrackEvent(SEGMENT_EVENTS.authBannerSelect, {
        page_type:
          typeof data.page_type === "number" ||
          typeof data.page_type === "string"
            ? PAGE_TYPE[data.page_type]
            : undefined,
        brand: data.brand,
        category: data.category,
        category_slug: data.category_slug,
        type: data.type,
        shop_type: data.shop_type,
      });
    }
  );

  const segmentAuthBannerClosed = isClientWrapper(
    (data: AuthBannerData & { popup_type: "sign_up" | "sign_in" }) => {
      return sendSegmentTrackEvent(SEGMENT_EVENTS.authPopupClosed, {
        page_type:
          typeof data.page_type === "number" ||
          typeof data.page_type === "string"
            ? PAGE_TYPE[data.page_type]
            : undefined,
        brand: data.brand,
        category: data.category,
        category_slug: data.category_slug,
        popup_type: data.popup_type,
        shop_type: data.shop_type,
      });
    }
  );

  const segmentGuestOptInBannerDismissed = isClientWrapper(
    (page_name?: string) => {
      return sendSegmentTrackEvent(SEGMENT_EVENTS.guestOptInBannerDismissed, {
        page_information_name: page_name,
      });
    }
  );

  const segmentGuestOptInBannerViewed = isClientWrapper(
    (page_name?: string) => {
      return sendSegmentTrackEvent(SEGMENT_EVENTS.guestOptInBannerViewed, {
        page_information_name: page_name,
      });
    }
  );

  const segmentGuestOptInBannerCtaClick = isClientWrapper(
    (page_name?: string) => {
      return sendSegmentTrackEvent(SEGMENT_EVENTS.guestOptInBannerCtaClick, {
        page_information_name: page_name,
      });
    }
  );

  const segmentGuestOptInPopupDismissed = isClientWrapper(
    (page_name?: string) => {
      return sendSegmentTrackEvent(SEGMENT_EVENTS.guestOptInPopupDismissed, {
        page_information_name: page_name,
      });
    }
  );

  const trackingGooglePayButtonClicked = isClientWrapper((cart_id: string) => {
    return sendSegmentTrackEvent(SEGMENT_EVENTS.googlePayButtonClicked, {
      ...requestSource,
      cart_id,
      email: session?.user?.email,
    });
  });

  const trackingCategoryItemClicked = isClientWrapper(
    (category_slug: string, category_name: string) => {
      return sendSegmentTrackEvent(SEGMENT_EVENTS.categoryItemClicked, {
        ...requestSource,
        category_slug,
        category_name,
      });
    }
  );

  const eppoRandomizedAssigment = isClientWrapper(
    (assignment: IAssignmentEvent) => {
      const { featureFlag, variation, ...rest } = assignment;
      const properties =
        featureFlag === "alert-banner-shopping-cart-web"
          ? { ...rest, featureFlag, variation: JSON.parse(variation) }
          : assignment;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return sendSegmentTrackEvent(SEGMENT_EVENTS.eppoRandomizedAssignment, {
        ...requestSource,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        userId: assignment.subject,
        event: "Eppo Randomized Assignment",
        type: "track",
        ...properties,
      });
    }
  );

  const segmentTrackingError = isClientWrapper(
    ({
      error_type,
      error_message,
    }: {
      error_type: string;
      error_message: string;
    }) => {
      return sendSegmentTrackEvent(SEGMENT_EVENTS.error, {
        ...requestSource,
        error_type,
        error_message,
      });
    }
  );

  const segmentMenuNavClicked = isClientWrapper(
    (
      menuType: string,
      menuClickType: string,
      menuClickName: string,
      menuSectionName?: string
    ) => {
      return sendSegmentTrackEvent(SEGMENT_EVENTS.menuNavClicked, {
        ...requestSource,
        menu_type: menuType,
        menu_click_type: menuClickType,
        menu_section_name: menuSectionName,
        menu_click_name: menuClickName,
      });
    }
  );

  const segmentOptInDeeplinkViewed = isClientWrapper(() => {
    return sendSegmentTrackEvent(SEGMENT_EVENTS.optInDeeplinkViewed, {});
  });

  const segmentGatedPopupOpened = isClientWrapper(
    ({
      pageName,
      shopType,
      actionSource,
      carouselType,
      productName,
      productId,
      redirectTo,
      productImageUrl,
      brandName,
      brandId,
      brandImageUrl,
      level,
      tab,
    }: GatedPopupData) => {
      if (brandId) {
        return sendSegmentTrackEvent(SEGMENT_EVENTS.gatedPopupOpened, {
          ...requestSource,
          page_information_name: pageName,
          shop_type: shopType,
          action_source: actionSource,
          carousel_type: carouselType,
          brand_name: brandName,
          brand_id: brandId,
          brand_image_url: brandImageUrl,
          redirect_to: redirectTo,
          gate_level: level,
          tab,
        });
      }
      return sendSegmentTrackEvent(SEGMENT_EVENTS.gatedPopupOpened, {
        ...requestSource,
        page_information_name: pageName,
        shop_type: shopType,
        action_source: actionSource,
        carousel_type: carouselType,
        product_name: productName,
        product_id: productId,
        product_image_url: productImageUrl,
        redirect_to: redirectTo,
        gate_level: level,
        tab,
      });
    }
  );

  const segmentGatedPopupLoginClicked = isClientWrapper(
    ({
      pageName,
      shopType,
      actionSource,
      carouselType,
      redirectTo,
      productName,
      productId,
      productImageUrl,
      brandName,
      brandId,
      brandImageUrl,
      level,
      tab,
    }: GatedPopupData) => {
      if (brandId) {
        return sendSegmentTrackEvent(SEGMENT_EVENTS.gatedPopupLoginClicked, {
          ...requestSource,
          page_information_name: pageName,
          shop_type: shopType,
          action_source: actionSource,
          carousel_type: carouselType,
          redirect_to: redirectTo,
          brand_name: brandName,
          brand_id: brandId,
          brand_image_url: brandImageUrl,
          gate_level: level,
          tab,
        });
      }
      return sendSegmentTrackEvent(SEGMENT_EVENTS.gatedPopupLoginClicked, {
        ...requestSource,
        page_information_name: pageName,
        shop_type: shopType,
        action_source: actionSource,
        carousel_type: carouselType,
        redirect_to: redirectTo,
        product_name: productName,
        product_id: productId,
        product_image_url: productImageUrl,
        gate_level: level,
        tab,
      });
    }
  );

  const segmentGatedPopupRegisterClicked = isClientWrapper(
    ({
      pageName,
      shopType,
      actionSource,
      carouselType,
      redirectTo,
      productName,
      productId,
      productImageUrl,
      brandName,
      brandId,
      brandImageUrl,
      level,
      tab,
    }: GatedPopupData) => {
      if (brandId) {
        return sendSegmentTrackEvent(SEGMENT_EVENTS.gatedPopupRegisterClicked, {
          ...requestSource,
          page_information_name: pageName,
          shop_type: shopType,
          action_source: actionSource,
          carousel_type: carouselType,
          redirect_to: redirectTo,
          brand_name: brandName,
          brand_id: brandId,
          brand_image_url: brandImageUrl,
          gate_level: level,
          tab,
        });
      }
      return sendSegmentTrackEvent(SEGMENT_EVENTS.gatedPopupRegisterClicked, {
        ...requestSource,
        page_information_name: pageName,
        shop_type: shopType,
        action_source: actionSource,
        carousel_type: carouselType,
        redirect_to: redirectTo,
        product_name: productName,
        product_id: productId,
        product_image_url: productImageUrl,
        gate_level: level,
        tab,
      });
    }
  );

  const segmentGatedPopupClosed = isClientWrapper(
    ({
      pageName,
      shopType,
      actionSource,
      carouselType,
      productName,
      productId,
      productImageUrl,
      redirectTo,
      brandName,
      brandId,
      brandImageUrl,
      level,
      tab,
    }: GatedPopupData) => {
      if (brandId) {
        return sendSegmentTrackEvent(SEGMENT_EVENTS.gatedPopupClosed, {
          ...requestSource,
          page_information_name: pageName,
          shop_type: shopType,
          action_source: actionSource,
          carousel_type: carouselType,
          brand_name: brandName,
          brand_id: brandId,
          brand_image_url: brandImageUrl,
          gate_level: level,
          redirect_to: redirectTo,
          tab,
        });
      }
      return sendSegmentTrackEvent(SEGMENT_EVENTS.gatedPopupClosed, {
        ...requestSource,
        page_information_name: pageName,
        shop_type: shopType,
        action_source: actionSource,
        carousel_type: carouselType,
        product_name: productName,
        product_id: productId,
        product_image_url: productImageUrl,
        gate_level: level,
        redirect_to: redirectTo,
        tab,
      });
    }
  );

  return {
    user,
    segmentIdentify,
    segmentBrandConsciousHowWeRateClicked,
    segmentBrandInfoOpened,
    brandFavourited,
    brandUnfavourited,
    brandFavouritedWhenLoggedOut,
    productListFilterReset,
    productListFiltered,
    segmentProductListViewed,
    segmentSignedIn,
    segmentSignedOut,
    segmentSignedUp,
    segmentCartViewed,
    segmentCheckoutStarted,
    segmentFirstCheckoutStepCompleted,
    segmentSecondCheckoutStepCompleted,
    segmentOrderCompleted,
    segmentCouponApplied,
    segmentCouponDenied,
    segmentCouponEntered,
    segmentCouponRemoved,
    segmentFormError,
    segmentFormTracking,
    segmentOnboarding,
    segmentOptIn,
    segmentPageViewed,
    segmentProductListClicked,
    segmentProductClicked,
    segmentPasswordUpdated,
    segmentProductAdded,
    segmentProductAddedOrRemoved,
    segmentProductFavouritedOrUnfavourited,
    segmentProductListFilterOpened,
    segmentProductOutOfStock,
    segmentProductSizeSelected,
    segmentProductSizeSelectorClicked,
    segmentProductViewed,
    segmentProductEngaged,
    segmentPromotionClickedOrViewed,
    segmentReferAFriendViewed,
    segmentSearched,
    segmentUserEmailUpdated,
    segmentProductOutOfStockCT,
    segmentDomainChanged,
    segmentToasterNotificationDisplayed,
    segmentToasterNotificationDismissed,
    segmentToasterNotificationClicked,
    segmentSearchOverlayOpened,
    segmentSearchOverlayClosed,
    segmentSearchOverlayCarouselsViewed,
    segmentSearchLinkClicked,
    segmentSearchRecommendationClicked,
    segmentViewSearchAllBrands,
    segmentViewSearchResultPage,
    trackForgotPassword,
    segmentPdpInfoBoxOpened,
    segmentUserProfileUpdated,
    segmentViewedAuthBanner,
    segmentAuthBannerSelect,
    segmentAuthBannerClosed,
    segmentGuestOptInBannerDismissed,
    segmentGuestOptInBannerViewed,
    segmentGuestOptInBannerCtaClick,
    segmentGuestOptInPopupDismissed,
    segmentTrackingError,
    trackingGooglePayButtonClicked,
    trackingCategoryItemClicked,
    eppoRandomizedAssigment,
    segmentMenuNavClicked,
    segmentOptInDeeplinkViewed,
    segmentGatedPopupOpened,
    segmentGatedPopupLoginClicked,
    segmentGatedPopupRegisterClicked,
    segmentGatedPopupClosed,
  };
};
