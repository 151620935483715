import cn from "classnames";
import { FC, PropsWithChildren, ReactNode, useRef } from "react";
import styled from "@emotion/styled";
import { useFocusFirstFocusable } from "src/hooks/useFocusFirstFocusable";
import { useLockBodyScroll } from "src/hooks/useLockBodyScroll";
import { useOnClickOutside } from "src/hooks/useOnClickOutside";
import { useEscapePress } from "src/hooks/useEscapePress";
import { Navigation } from "src/organisms/Header/Navigation";
import { Overlay } from "./Overlay";
import { Portal } from "src/atoms/Portal";
import { AnimatePresence, motion } from "framer-motion";

interface PropsStyled {
  fullWidth?: boolean;
  fullHeight: boolean;
  fullContentWidth?: boolean;
  hasMobileBottomAlign?: boolean;
  overFlow?: string | undefined;
  centerOnScreen?: boolean;
  maxWidth?: [string?, string?];
  rounded?: boolean;
  padding?: {
    blockStart?: string;
    blockEnd?: string;
    inlineStart?: string;
    inlineEnd?: string;
  };
  customWidth?: string;
}

const StyledModal = styled.div<PropsStyled>`
  width: ${({ customWidth }) => (customWidth ? customWidth : "100%")};
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ fullHeight }) => (fullHeight ? "100%" : "auto")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: ${({ hasMobileBottomAlign }) => hasMobileBottomAlign && "0"};

  ${({ padding }) =>
    padding && Object.keys(padding).length > 0
      ? `padding: ${padding.blockStart || 0} ${padding.inlineStart || 0} ${
          padding.blockEnd || 0
        } ${padding.inlineEnd || 0};`
      : ""}

  z-index: ${({ theme }) => theme.zIndex.modal};

  ${({ centerOnScreen }) =>
    centerOnScreen &&
    `position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);`}

  max-width: ${({ maxWidth }) => (maxWidth?.[0] ? maxWidth[0] : "initial")};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    max-width: ${({ maxWidth }) => (maxWidth?.[1] ? maxWidth[1] : "initial")};
  }

  .Modal__container {
    max-height: 100%;
    max-width: ${({ fullWidth }) => !fullWidth && "768px"};
    overflow: ${({ overFlow }) => (overFlow ? overFlow : "auto")};
    background: ${({ theme }) => theme.colors.tone.white};
    ${({ fullContentWidth }) => fullContentWidth && "width: 100%;"}
    border-radius: ${({ rounded }) => (rounded ? "8px" : "initial")};
  }

  .Modal__navigation {
    display: none;
  }

  .Modal__content {
    display: block;
    position: ${({ hasMobileBottomAlign }) =>
      hasMobileBottomAlign && "absolute"};
    bottom: ${({ hasMobileBottomAlign }) => hasMobileBottomAlign && "0"};
    width: ${({ hasMobileBottomAlign }) => hasMobileBottomAlign && "100%"};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    &:not(.Modal_withHeader) .Modal__container {
      max-width: none;
      width: ${({ fullWidth }) => (fullWidth ? "100%" : "initial")};
      height: ${({ fullHeight }) => (fullHeight ? "100%" : "initial")};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    .Modal__navigation {
      display: block;
    }

    .Modal__content {
      overflow: visible;
    }
  }

  &.Modal_withHeader {
    .Modal__container {
      background: none;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
      .Modal__container {
        width: 100%;
        align-self: flex-start;
        height: auto;
      }
    }
  }
`;

interface Props {
  classes?: {
    content?: string;
    modal?: string;
  };
  isOpen: boolean;
  withHeader?: boolean;
  onClose: () => void;
  fullWidth?: boolean;
  fullContentWidth?: boolean;
  fullHeight?: boolean;
  hasMobileBottomAlign?: boolean;
  children: ReactNode;
  overFlow?: string | undefined;
  centerOnScreen?: boolean;
  maxWidth?: [string?, string?];
  rounded?: boolean;
  padding?: {
    blockStart?: string;
    blockEnd?: string;
    inlineStart?: string;
    inlineEnd?: string;
  };
  customWidth?: string;
}

/*
  To support SSR this implementation doesn't use React.createPortal
  and renders modal in place. This could potentially cause issues.
  When rendered within a container with z-index lower then modal index,
  elements with higher z-index could overlay modal window.

  For example, in this case Header would overlap modal overlay even though
  modal has a higher z-index due to lower parent z-index:
  ```
    <div style={{ position: fixed, zIndex: 1001 }}>
      Header
    </div>
    <div style={{ position: fixed, zIndex: 1000 }}>
      <Modal>Modal Content</Modal>
    </div>
  ```

  To solve this issue general solution is to create react portal to the body root,
  but react portals doesn't work with server side rendering. If you have such case
  you can create a portal for specific modal using Portal component. To do this you should:
  1) Create a portal root in a _document.tsx
  2) Wrap your Modal in Portal component

  _document.tsx
  ```
    ...
    <body>
      <Main />
      <div id="user-modal" />
      ...
    ...
  ```

  ```
    <Portal selector="#user-modal">
      <Modal>
        Content
      </Modal>
    </Portal>
  ```
*/

const Modal: FC<PropsWithChildren<Props>> = ({
  classes,
  isOpen,
  onClose,
  withHeader,
  children,
  fullWidth,
  fullContentWidth,
  fullHeight = true,
  hasMobileBottomAlign,
  overFlow,
  centerOnScreen = false,
  maxWidth,
  rounded,
  padding,
  customWidth,
  ...rest
}) => {
  const modalContent = useRef<HTMLDivElement>(null);

  useLockBodyScroll(isOpen, modalContent);
  useOnClickOutside(modalContent, onClose);
  useFocusFirstFocusable(modalContent, isOpen);
  useEscapePress(onClose);

  const modalContentVariants = {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
    },
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Portal selector="#portal">
      <AnimatePresence>
        <Overlay isOpen={isOpen} />
        <StyledModal
          className={cn(classes?.modal, {
            Modal_withHeader: withHeader,
          })}
          customWidth={customWidth}
          fullWidth={fullWidth}
          fullHeight={fullHeight}
          fullContentWidth={fullContentWidth}
          overFlow={overFlow}
          hasMobileBottomAlign={hasMobileBottomAlign}
          centerOnScreen={centerOnScreen}
          maxWidth={maxWidth}
          rounded={rounded}
          padding={padding}
        >
          {isOpen && (
            <motion.div
              role="dialog"
              className={cn("Modal__container", classes && classes.content)}
              ref={modalContent}
              aria-modal="true"
              variants={modalContentVariants}
              key="modal"
              transition={{ duration: 0.225, ease: "easeInOut" }}
            >
              {withHeader && (
                <div onClick={onClose}>
                  <Navigation
                    className="Modal__navigation"
                    isLoginFlow={false}
                  />
                </div>
              )}
              <div className="Modal__content">{children}</div>
            </motion.div>
          )}
        </StyledModal>
      </AnimatePresence>
    </Portal>
  );
};

export { Modal };
