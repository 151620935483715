import { FC, SVGAttributes } from "react";

const FlagUsa: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="none"
    {...props}
  >
    <defs>
      <path id="pephv5z35a" d="M0 2H16V14H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="s9dl8e4ayb" fill="#fff">
        <use xlinkHref="#pephv5z35a" />
      </mask>
      <use fill="#FFF" xlinkHref="#pephv5z35a" />
      <g mask="url(#s9dl8e4ayb)">
        <g>
          <path
            fill="#C60B1E"
            fillRule="nonzero"
            d="M0 0H22.8V12H0z"
            transform="translate(-1.12 2)"
          />
          <path
            fill="#000"
            fillRule="nonzero"
            stroke="#FFF"
            strokeWidth=".923"
            d="M0 1.433h22.8m0 1.846H0m0 1.846h22.8m0 1.847H0m0 1.846h22.8m0 1.846H0"
            transform="translate(-1.12 2)"
          />
          <path
            fill="#039"
            fillRule="nonzero"
            d="M0 0H9.12V6.51H0z"
            transform="translate(-1.12 2)"
          />
          <g>
            <g fill="#FFF" fillRule="nonzero">
              <path
                d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                transform="translate(-1.12 2) translate(.406 .277) translate(.76 .646)"
              />
              <path
                d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                transform="translate(-1.12 2) translate(.406 .277) translate(.76 .646) translate(0 1.292)"
              />
              <path
                d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                transform="translate(-1.12 2) translate(.406 .277) translate(.76 .646) translate(0 2.585)"
              />
              <path
                d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                transform="translate(-1.12 2) translate(.406 .277) translate(.76 .646) translate(0 3.877)"
              />
            </g>
            <g fill="#FFF" fillRule="nonzero">
              <path
                d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                transform="translate(-1.12 2) translate(.406 .277) translate(1.52)"
              />
              <path
                d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                transform="translate(-1.12 2) translate(.406 .277) translate(1.52) translate(0 1.292)"
              />
              <path
                d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                transform="translate(-1.12 2) translate(.406 .277) translate(1.52) translate(0 2.585)"
              />
              <path
                d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                transform="translate(-1.12 2) translate(.406 .277) translate(1.52) translate(0 3.877)"
              />
              <g>
                <path
                  d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                  transform="translate(-1.12 2) translate(.406 .277) translate(1.52) translate(0 5.17)"
                />
              </g>
              <g>
                <path
                  d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                  transform="translate(-1.12 2) translate(.406 .277) translate(1.52) translate(.76 .646)"
                />
                <path
                  d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                  transform="translate(-1.12 2) translate(.406 .277) translate(1.52) translate(.76 .646) translate(0 1.292)"
                />
                <path
                  d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                  transform="translate(-1.12 2) translate(.406 .277) translate(1.52) translate(.76 .646) translate(0 2.585)"
                />
                <path
                  d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                  transform="translate(-1.12 2) translate(.406 .277) translate(1.52) translate(.76 .646) translate(0 3.877)"
                />
              </g>
            </g>
            <g fill="#FFF" fillRule="nonzero">
              <path
                d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                transform="translate(-1.12 2) translate(.406 .277) translate(3.04)"
              />
              <path
                d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                transform="translate(-1.12 2) translate(.406 .277) translate(3.04) translate(0 1.292)"
              />
              <path
                d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                transform="translate(-1.12 2) translate(.406 .277) translate(3.04) translate(0 2.585)"
              />
              <path
                d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                transform="translate(-1.12 2) translate(.406 .277) translate(3.04) translate(0 3.877)"
              />
              <g>
                <path
                  d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                  transform="translate(-1.12 2) translate(.406 .277) translate(3.04) translate(0 5.17)"
                />
              </g>
              <g>
                <path
                  d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                  transform="translate(-1.12 2) translate(.406 .277) translate(3.04) translate(.76 .646)"
                />
                <path
                  d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                  transform="translate(-1.12 2) translate(.406 .277) translate(3.04) translate(.76 .646) translate(0 1.292)"
                />
                <path
                  d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                  transform="translate(-1.12 2) translate(.406 .277) translate(3.04) translate(.76 .646) translate(0 2.585)"
                />
                <path
                  d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                  transform="translate(-1.12 2) translate(.406 .277) translate(3.04) translate(.76 .646) translate(0 3.877)"
                />
              </g>
              <g>
                <path
                  d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                  transform="translate(-1.12 2) translate(.406 .277) translate(3.04) translate(1.52)"
                />
                <path
                  d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                  transform="translate(-1.12 2) translate(.406 .277) translate(3.04) translate(1.52) translate(0 1.292)"
                />
                <path
                  d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                  transform="translate(-1.12 2) translate(.406 .277) translate(3.04) translate(1.52) translate(0 2.585)"
                />
                <path
                  d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                  transform="translate(-1.12 2) translate(.406 .277) translate(3.04) translate(1.52) translate(0 3.877)"
                />
                <g>
                  <path
                    d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                    transform="translate(-1.12 2) translate(.406 .277) translate(3.04) translate(1.52) translate(0 5.17)"
                  />
                </g>
                <g>
                  <path
                    d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                    transform="translate(-1.12 2) translate(.406 .277) translate(3.04) translate(1.52) translate(.76 .646)"
                  />
                  <path
                    d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                    transform="translate(-1.12 2) translate(.406 .277) translate(3.04) translate(1.52) translate(.76 .646) translate(0 1.292)"
                  />
                  <path
                    d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                    transform="translate(-1.12 2) translate(.406 .277) translate(3.04) translate(1.52) translate(.76 .646) translate(0 2.585)"
                  />
                  <path
                    d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                    transform="translate(-1.12 2) translate(.406 .277) translate(3.04) translate(1.52) translate(.76 .646) translate(0 3.877)"
                  />
                </g>
              </g>
            </g>
            <g fill="#FFF" fillRule="nonzero">
              <path
                d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                transform="translate(-1.12 2) translate(.406 .277) translate(6.08)"
              />
              <path
                d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                transform="translate(-1.12 2) translate(.406 .277) translate(6.08) translate(0 1.292)"
              />
              <path
                d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                transform="translate(-1.12 2) translate(.406 .277) translate(6.08) translate(0 2.585)"
              />
              <path
                d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                transform="translate(-1.12 2) translate(.406 .277) translate(6.08) translate(0 3.877)"
              />
              <g>
                <path
                  d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                  transform="translate(-1.12 2) translate(.406 .277) translate(6.08) translate(0 5.17)"
                />
              </g>
              <g>
                <path
                  d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                  transform="translate(-1.12 2) translate(.406 .277) translate(6.08) translate(.76 .646)"
                />
                <path
                  d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                  transform="translate(-1.12 2) translate(.406 .277) translate(6.08) translate(.76 .646) translate(0 1.292)"
                />
                <path
                  d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                  transform="translate(-1.12 2) translate(.406 .277) translate(6.08) translate(.76 .646) translate(0 2.585)"
                />
                <path
                  d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                  transform="translate(-1.12 2) translate(.406 .277) translate(6.08) translate(.76 .646) translate(0 3.877)"
                />
              </g>
            </g>
            <g fill="#FFF" fillRule="nonzero">
              <path
                d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                transform="translate(-1.12 2) translate(.406 .277) translate(7.6)"
              />
              <path
                d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                transform="translate(-1.12 2) translate(.406 .277) translate(7.6) translate(0 1.292)"
              />
              <path
                d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                transform="translate(-1.12 2) translate(.406 .277) translate(7.6) translate(0 2.585)"
              />
              <path
                d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                transform="translate(-1.12 2) translate(.406 .277) translate(7.6) translate(0 3.877)"
              />
              <g>
                <path
                  d="M0.354 0L0.571 0.668 0.003 0.255 0.705 0.255 0.137 0.668z"
                  transform="translate(-1.12 2) translate(.406 .277) translate(7.6) translate(0 5.17)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export { FlagUsa };
