import { forwardRef } from "react";
import { Transition } from "react-transition-group";
import { ShopType } from "src/types/graphql.d";
import { ResultsContent, StyledSearchMenu } from "./StyledMenu.styled";
import { MobileToggleGender } from "./MobileToggleGender";
import { localeFix } from "src/utils/genderLocale/genderLocale";
import AlgoliaSearchResults from "src/organisms/Search/AlgoliaSearchResults";
import DefaultSearch from "src/organisms/Search/DefaultSearch";

interface Props {
  onClose: () => void;
  onCloseOverlay: () => void;
  searchTerm: string;
  gender: string;
  isSearchOpened: boolean;
  animationTime?: number;
}

const AlgoliaSearch = forwardRef<HTMLDivElement, Props>(
  (
    {
      onClose,
      onCloseOverlay,
      searchTerm,
      gender,
      isSearchOpened,
      animationTime,
    },
    ref
  ) => {
    const shopType = localeFix({ gender }) as ShopType;

    return (
      <StyledSearchMenu ref={ref}>
        <Transition
          in={isSearchOpened}
          timeout={animationTime || 0}
          onExited={onCloseOverlay}
        >
          {(state) => (
            <>
              <MobileToggleGender
                animationTime={animationTime}
                onClose={!isSearchOpened}
              />
              <ResultsContent
                out={["exiting", "exited"].includes(state)}
                duration={animationTime}
              >
                {searchTerm.length >= 2 && (
                  <AlgoliaSearchResults
                    searchTerm={searchTerm}
                    shopType={shopType}
                  />
                )}
                {searchTerm.length < 2 && (
                  <DefaultSearch
                    shopType={shopType}
                    animationTime={animationTime}
                  />
                )}
              </ResultsContent>
            </>
          )}
        </Transition>
      </StyledSearchMenu>
    );
  }
);

AlgoliaSearch.displayName = "AlgoliaSearch";

export { AlgoliaSearch };
