import { Box, Flex } from "@otrium/core";
import styled from "@emotion/styled";
import { Content as AtomContent } from "src/atoms/Content";
import BaseButton from "src/atoms/Button/BaseButton";
import { Container } from "src/atoms/Container";

const Content = styled(AtomContent)`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.tone.white};
`;

const StyledSubMenu = styled(Flex)<{
  isOpenSearch: boolean;
}>`
  position: fixed;
  height: 64px;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 47px;
  z-index: 1202;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    position: relative;
    top: 0;
    z-index: 0;
  }

  ${({ isOpenSearch, theme }) =>
    !isOpenSearch &&
    `
    @media (max-width: ${theme.breakpoints[2]}) {
      z-index: -1;
    }
  `}
`;

const SubmenuContainer = styled(Box)<{ moveLeft?: boolean; duration?: number }>`
  list-style: none;
  height: 47px;
  display: none;
  width: calc(100% - 220px);
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 0px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: flex;
    margin-left: ${({ moveLeft }) => (moveLeft ? -window.innerWidth : 0)}px;
    transition: margin-left ${({ duration }) => duration}ms ease-in-out;
  }

  li {
    display: flex;
    align-items: center;

    &:first-of-type {
      a {
        margin-left: ${({ theme }) => theme.space.space0};
      }
    }

    &:last-of-type {
      a {
        margin-right: ${({ theme }) => theme.space.space0};
      }
    }
  }
`;

const SubmenuLink = styled(Box)<{ active: boolean; color: string }>`
  text-transform: capitalize;
  box-sizing: border-box;
  margin: ${({ theme }) =>
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `${theme.space.space0} ${theme.space.space24} ${theme.space.space0} ${theme.space.space0}`};
  ${({ active, color }) => active && `border-bottom: 2px solid ${color};`};
  font-weight: ${({ active }) => (active ? 600 : 400)};
  font-size: ${({ theme }) => theme.fontSizes.fontSize16};
  letter-spacing: 0.4px;
  white-space: nowrap;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin: ${({ theme }) =>
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${theme.space.space0} ${theme.space.space32} ${theme.space.space0} ${theme.space.space0}`};
  }
`;

const SearchContainer = styled(Flex)`
  position: relative;
  align-items: center;
  width: 204px;
  height: 32px;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.colors.primary.platinumLight};
  cursor: text;

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: none;
  }
`;

const SearchBox = styled.div`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 30px;
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.tone.anthraciteLight};
  width: 100%;
`;

const StyledButton = styled(BaseButton)`
  position: absolute;
  width: 40px;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 100;
  background: url(/static/images/white-gradient-bg.png) top left repeat-y;
  background-size: 100% 100%;
  transform: scaleX(-1);
  border: none;
  cursor: pointer;

  :after {
    content: "";
    display: block;
    background: url(/static/images/large-chevron-right-black.svg) no-repeat
      center;
    background-size: contain;
    width: 100%;
    height: 12px;
    position: relative;
    transform: scaleX(-1);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: none;
  }
`;

interface LinkProps {
  hideOnDesktop: boolean | undefined;
  hideOnMobile: boolean | undefined;
}

const StyledLink = styled.li<LinkProps>`
  display: ${(props) => (props.hideOnDesktop ? "none" : "list-item")};

  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: ${(props) => (props.hideOnMobile ? "none" : "list-item")};
  }
`;

const SubMenuContent = styled(Content)`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  ::after {
    content: "";
    right: 198px;
    top: 12px;
    position: absolute;
    width: 40px;
    height: 20px;
    box-shadow: -30px 5px 21px 8px #ffffff;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    ::after {
      display: none;
    }
  }
`;

const StyledSearchMenu = styled(Flex)`
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.tone.white};
  overflow: hidden;
  height: fit-content;
  max-height: 100%;
`;

const StyledFlex = styled(Flex)`
  position: fixed;
  top: 64px;
  height: 64px;
  width: 100%;
  background: ${({ theme }) => theme.colors.tone.white};
  z-index: 1400;
`;

const StyledContainer = styled(Container)`
  position: relative;
  width: 100%;
`;

export {
  SubmenuContainer,
  SubmenuLink,
  StyledSubMenu,
  StyledButton,
  StyledLink,
  SearchBox,
  SearchContainer,
  SubMenuContent,
  StyledSearchMenu,
  StyledFlex,
  StyledContainer,
};
